import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
//import DatePicker from "../../components/DatePicker";
//import Checkbox from "../../components/Checkbox";
import Select from '../../components/Select';
//import countries from "../../constants/countries";
//import translations from "../../translations/de";
import { formatDate } from '../../helpers/formatDate';
export default function getAccessFormInput({
    name,
    register,
    error,
    disabled,
    index,
    control,
    scanners,
    games,
}) {
    if (name === 'Games') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name="game_id"
                options={games.map((game) => {
                    return {
                        value: game.id,
                        label: formatDate(game.date, true) + ' - ' + game.away_team.title,
                    };
                })}
                control={control}
                error={error}
                disabled={disabled}
            />
        );
    }
    // else if (name === "accept_marketing")
    // {
    //     return (
    //         <Checkbox
    //             key={index}
    //             text={translateLabel(name)}
    //             name={name}
    //             {...register(name, { required: false })}
    //             disabled={disabled}
    //         />
    //     );
    // } else if (name === "birthday" || name === "scanned_at") {
    //     return (
    //         <DatePicker
    //             key={index}
    //             label={translateLabel(name)}
    //             selectText={translations.select_date}
    //             name={name}
    //             error={error}
    //             control={control}
    //             disabled={disabled}
    //             isRequired={false}
    //         />
    //     );
    // } else if (name === "country") {
    //     return (
    //         <Select
    //             key={index}
    //             label={translateLabel(name)}
    //             name="country"
    //             options={countries}
    //             control={control}
    //             error={error}
    //             disabled={disabled}
    //         />
    //     );
    // }
    // else if (name === "scanner_id")
    // {
    //     return (
    //         <Select
    //             key={index}
    //             name="scanner_id"
    //             label={translateLabel(name)}
    //             control={control}
    //             error={error}
    //             options={scanners.map((scanner) => {
    //                 return { value: scanner.id, label: scanner.title };
    //             })}
    //             placeholder="Select Scanner..."
    //             noOptionsMsg="No scenners found"
    //             isClearable
    //         />
    //     );
    // }
    else if (name !== 'Games') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: name === 'access_token' ? true : false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
