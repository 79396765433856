const removeTwoDigits = (number) => {
    let year = number.split('/');
    return `${year[0].slice(2)} / ${year[1].slice(2)}`;
};

const setDefaultSeason = (seasons, selectedSeason) => {
    if (selectedSeason) {
        let current = seasons.find((season) => season.id === selectedSeason);
        return { value: current.id, label: removeTwoDigits(current.title) };
    } else {
        let current = seasons.find((season) => season.current === true);
        return { value: current.id, label: removeTwoDigits(current.title) };
    }
};

export { removeTwoDigits, setDefaultSeason };
