import { types, flow } from 'mobx-state-tree';
import API from '../../api/fanAnswers';
import FanAnswer from './fanAnswer';

const FanAnswers = types
    .model('FanAnswers', {
        items: types.array(FanAnswer),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
    })
    .views((self) => ({
        getUser(fanAnswerID) {
            const answer = self.items.find((item) => item.id === fanAnswerID);
            return answer && answer.fan_user;
        },
    }))
    .actions((self) => ({
        getFanAnswers: flow(function* getFanAnswers(gameId) {
            try {
                const res = yield API.getFanAnswers(gameId, {
                    params: {
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.items = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                console.log(error);
            }
        }),
        deleteFanAnswer: flow(function* deleteFanAnswer(fanAnsId) {
            try {
                yield API.deleteFanAnswer(fanAnsId);
                const index = self.items.findIndex((ans) => ans.id === fanAnsId);
                self.items.splice(index, 1);
            } catch (error) {
                console.log(error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
    }));

export default FanAnswers;
