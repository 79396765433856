import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Table from '../../components/Table';
import formatName from '../../helpers/formatName';
import Loader from '../../components/Loader';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';

const Items = ({ store }) => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        store.selectedUser.getItems().then(() => setLoader(false));
    }, [store.selectedUser]);
    return loader ? (
        <div className="loader-wrapper">
            <Loader size="big" theme="dark" />
        </div>
    ) : (
        <div>
            {store.selectedUser.items.length !== 0 ? (
                <Table
                    isRowClickable={false}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Type',
                            accessor: 'acquire_type',
                            Cell: ({ value, row }) => <div>{formatName(value)}</div>,
                        },
                        {
                            Header: 'Name',
                            accessor: 'item_name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Image',
                            accessor: 'item_image',
                            Cell: ({ value, row }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="item" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: 'Quantity',
                            accessor: 'quantity',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Is used',
                            accessor: 'is_used',
                            Cell: ({ value, row }) => <div>{value === true ? 'YES' : 'NO'}</div>,
                        },
                    ]}
                    data={store.selectedUser.items.map(
                        ({ id, acquire_type, quantity, is_used, reward, shop_item }) => {
                            return {
                                id,
                                acquire_type,
                                quantity,
                                is_used,
                                item_name: reward ? reward.name : shop_item ? shop_item.name : '',
                                item_image: reward
                                    ? reward.image_url
                                    : shop_item
                                    ? shop_item.image_url
                                    : '',
                            };
                        },
                    )}
                />
            ) : (
                <p className="no-itmes">{`${store.selectedUser.first_name} ${translations.no_rewards_and_purchases}`}</p>
            )}
        </div>
    );
};

export default observer(Items);
