const mandatoryFields = [
    // 'screen_id',
    // 'banner_id',
    // 'position',
    // 'description',
    // 'target_url',
    // 'title',
    'size',
    'image_url',
];

export default mandatoryFields;
