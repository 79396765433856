import { useState, useRef, forwardRef, useEffect } from 'react';
import Button from '../../components/Button';
import translations from '../../translations/de';
import API from '../../api/vouchers';
import { FaUpload } from 'react-icons/fa';
import { FaWindowClose } from 'react-icons/fa';

import './importVoucherCodes.scss';

const ImportVoucherCodes = forwardRef(
    (
        {
            label = '',
            resetValue,
            disabled,
            pathToCloudFolder,
            readOnly,
            orderID,
            setOrderID,
            voucherType,
            currentPriceInCents,
            ...restProps
        },
        ref,
    ) => {
        const voucherRef = useRef();
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null);

        const handleUpload = () => {
            if (voucherRef.current.files[0]) {
                setLoading(true);
                setError(null);
                const formData = new FormData();
                formData.append('file', voucherRef?.current?.files[0]);

                const getVouchers = async () => {
                    try {
                        const res = await API.sendVoucherCodes(voucherType, formData, {
                            params: {
                                price: voucherType === 'eguma' ? currentPriceInCents : undefined,
                            },
                        });
                        resetValue(voucherRef?.current?.files[0]?.name);
                        setOrderID({ value: res.data.order_id, label: res.data.order_id });
                        setLoading(false);
                    } catch (err) {
                        voucherRef.current.value = '';
                        setLoading(false);
                        setError(err);
                    }
                };
                getVouchers();
            }
        };

        useEffect(() => {
            setError(null);
        }, [voucherType]);
        return (
            <div className="Input">
                <div className="label">
                    <span>{translations.voucher_code} </span>
                </div>
                <div className="Input-File">
                    <input
                        ref={ref}
                        disabled={disabled}
                        readOnly={readOnly}
                        type="text"
                        {...restProps}
                    />
                    <input
                        type="file"
                        disabled={disabled}
                        ref={voucherRef}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        style={{ display: 'none' }}
                        onChange={handleUpload}
                    />

                    {!disabled && (
                        <div className="Input-File-Controls">
                            {!orderID ? (
                                <Button
                                    title="Upload Local Files"
                                    loading={loading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        voucherRef.current.click();
                                    }}
                                >
                                    <FaUpload />
                                </Button>
                            ) : (
                                <Button
                                    title="Cancel"
                                    loading={loading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        resetValue('');
                                        setOrderID('');
                                    }}
                                >
                                    <FaWindowClose />
                                </Button>
                            )}
                        </div>
                    )}
                </div>
                {error && (
                    <span className="error-msg">
                        <p>{error.message}</p>
                    </span>
                )}
            </div>
        );
    },
);

export default ImportVoucherCodes;
