import { types, applySnapshot } from 'mobx-state-tree';

const ShopItem = types
    .model('ShopItem', {
        id: types.identifierNumber,
        name: types.optional(types.string, ''),
        image_url: types.optional(types.string, ''),
        price: types.optional(types.number, 0),
        available: types.optional(types.number, 0),
        active_num: types.optional(types.number, 0),
        expired: types.optional(types.number, 0),
        stock: types.optional(types.number, 0),
        start_stock: types.optional(types.number, 0),
        type: types.optional(types.string, 'avatar'),
        sold: types.optional(types.number, 0),
        used: types.maybeNull(types.number),
        expire_at: types.maybeNull(types.string),
        redeem_description: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        visible_until: types.maybeNull(types.string),
        active: types.optional(types.boolean, false),
        deactivate_on_sold: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        editItem(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default ShopItem;
