import React, { useState } from 'react';
import Button from '../Button';
import translations from '../../translations/de';
import './index.scss';

const ExportBtn = ({
    title = 'export.csv',
    label = translations.export,
    apiFunc,
    fileName = 'export',
    ...restProps
}) => {
    const [loading, setLoading] = useState(false);
    const exportList = async () => {
        setLoading(true);
        try {
            const res = await apiFunc();
            const blob = new Blob([res.data], { type: 'text/csv;charset=utf-8' });
            const listUrl = window.URL.createObjectURL(blob);
            downloadFile(listUrl, fileName);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const downloadFile = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
    };

    return (
        <div className="btn-wrapper" style={{ ...restProps.style }}>
            <Button
                title={title}
                label={label}
                onClick={() => exportList(1)}
                {...restProps}
                loading={loading}
            />
        </div>
    );
};

export default ExportBtn;
