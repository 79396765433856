import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import getFeedFormInput from './getFeedFormInput';
import translations from '../../translations/de';

const NewFeed = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [img, setImg] = useState(null);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    return (
        <Item
            links={[{ path: Paths.FEEDS, name: translations.feeds }]}
            name={`${translations.feed} ${translations.create}`}
            itemModel={{
                url: '',
                title_label: '',
                type: '',
                screen_title: '',
                data: '',
            }}
            edit={true}
            onSubmit={(event) => {
                error && setError(null);
                setLoading(true);
                Object.keys(event).forEach((key) => {
                    if (event[key] === '') {
                        event[key] = null;
                    }
                });
                store
                    .createFeed(event)
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.FEEDS);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, disabled, index }) =>
                getFeedFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setImg({ [name]: value }),
                })
            }
            resetProp={img}
        ></Item>
    );
};

export default NewFeed;
