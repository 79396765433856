import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/businessPartners';
import InputFile from '../../components/InputFile/index.js';
import Select from '../../components/Select';
import partnerTypes from '../../constants/partnerTypes';
import translations from '../../translations/de';

export default function getBusinessPartnerFormInput({
    name,
    register,
    error,
    disabled,
    index,
    resetValue,
    control,
}) {
    if (name === 'logo') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/business-partners"
            />
        );
    } else if (name === 'type') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name="type"
                options={partnerTypes}
                control={control}
                error={error}
                disabled={disabled}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={name === 'name' ? translations.company : translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
