import React from 'react';
import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';
import BusinessPartners from './BusinessPartners';
import BusinessPartner from './BusinessPartner';
import NewBusinessPartner from './NewBusinessPartner';
import NewProfileForm from './NewProfileForm';

const BusinessPartnersTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.BUSINESS_PARTNERS}>
                <BusinessPartners store={store} />
            </Route>
            <Route exact path={Paths.BUSINESS_PARTNER}>
                <BusinessPartner store={store} />
            </Route>
            <Route exact path={Paths.NEW_BUSINESS_PARTNER}>
                <NewBusinessPartner store={store} />
            </Route>
            <Route exact path={Paths.BUSINESS_PARTNER_NEW_PROFILE}>
                <NewProfileForm store={store} predefinedPartner={store.selectedPartner} />
            </Route>
        </Page>
    );
};

export default observer(BusinessPartnersTab);
