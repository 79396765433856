import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory, Link } from 'react-router-dom';
import { FaTrash, FaLongArrowAltRight, FaSearch, FaUndoAlt } from 'react-icons/fa';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import Pagination from './../../components/Pagination';
import translations from '../../translations/de';
import debounce from 'lodash.debounce';
import Input from '../../components/Input';
import { formatDate } from '../../helpers/formatDate';
import HomeGamesFilter from '../../components/HomeGamesFilter';
import InputSeason from '../../components/InputSeason/InputSeason';

const Passes = ({ store, matchID, preselectedSeason = null }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [passID, setPassID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);
    const [gameID, setGameID] = useState(null);

    const handlePass = (id) => {
        history.push(`/abos_freunde_ticket/${id}`);
    };
    const [selectedSeason, setSelectedSeason] = useState(null);

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const handleSeasonChange = (e) => {
        //remove game filter
        setGameID(null);
        setSelectedSeason(e);
    };

    const handleGameChange = (e) => {
        if (e) {
            store.selectedPage !== 1 && store.setSelectedPage(1);
        }
        setGameID(e);
    };

    useEffect(() => {
        if (selectedSeason) {
            if (gameID || matchID !== undefined) {
                const game_id = gameID ?? matchID;
                store.getPasses(searchQuery, game_id, selectedSeason);
            } else {
                store.getPasses(searchQuery, null, selectedSeason);
            }
        }
    }, [store, store.selectedPage, searchQuery, history, matchID, selectedSeason, gameID]);

    useEffect(() => {
        preselectedSeason && setSelectedSeason(preselectedSeason);
    }, [preselectedSeason]);

    return (
        <>
            <div className="tab-controls">
                <Input
                    label={translations.search_title}
                    placeholder={translations.search}
                    defaultValue={searchQuery}
                    onChange={onSearchChangeHandler}
                />
                <HomeGamesFilter
                    defaultValue={matchID && matchID}
                    activeSeason={selectedSeason}
                    filterByGameDate={(e) => handleGameChange(e.value)}
                />
                <InputSeason
                    selectedSeason={selectedSeason}
                    setSelectedSeason={(e) => handleSeasonChange(e)}
                />
            </div>

            {!store.dataLoaded || store.passes.length > 0 ? (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    isLoading={!store.dataLoaded}
                    isRowClickable={false}
                    tableName={translations.passesFriendship}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.match,
                            accessor: 'access_codes',
                            Cell: ({ value }) => (
                                <div>
                                    {value && formatDate(value.start_time)} <br />
                                    {value?.away_team ? value.away_team : null}
                                </div>
                            ),
                        },
                        {
                            Header: 'Ticket-Nr',
                            accessor: 'ticket_id',
                            Cell: ({ value }) => <div>{value ? value : '-'}</div>,
                        },
                        {
                            Header: translations.user,
                            accessor: 'user',
                            Cell: ({ value }) => (
                                <div>
                                    {value ? (
                                        <Link to={`/fans/${value.id}`}>
                                            <FaLongArrowAltRight />{' '}
                                            <span>{`${value.first_name} ${value.last_name}`}</span>
                                        </Link>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.seat,
                            accessor: 'seat',
                            Cell: ({ value }) => (
                                <div>
                                    Entrance: {value?.seat_group?.sector?.title} <br />
                                    Block: {value?.seat_group?.title} <br />
                                    Row: {value?.row} <br />
                                    Seat: {value?.num} <br />
                                </div>
                            ),
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div className="button-cell">
                                    <Button
                                        onClick={() => {
                                            handlePass(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>

                                    {isNotDeletedAlready(row) ? (
                                        <Button
                                            onClick={() => {
                                                setPassID(row.cells[0].value);
                                                setModal(true);
                                            }}
                                            title={translations.delete}
                                        >
                                            <FaTrash />
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => store.revivePass(row.cells[0].value)}
                                        >
                                            <FaUndoAlt />
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    data={store.passes.map(
                        ({
                            id,
                            ticket_id,
                            assign_token,
                            type,
                            user,
                            access_codes,
                            can_claim_freunde,
                            first_name,
                            last_name,
                            company,
                            deleted_at,
                            seat,
                        }) => {
                            return {
                                id,
                                access_codes: access_codes.length > 0 && access_codes[0],
                                ticket_id,
                                assign_token,
                                type,
                                name: first_name + ' ' + last_name,
                                user,
                                etickets: store.getNumOfPassAccesses(id),
                                barcode: store.getNumOfPassAccessCodes(id),
                                company,
                                can_claim_freunde,
                                deleted_at,
                                seat,
                            };
                        },
                    )}
                />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                    }}
                >
                    <div className="piechart-placeholder">No statistics data for passes.</div>
                </div>
            )}

            {modal && (
                <Modal
                    title="Delete Abo"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete this abo with id ${passID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deletePass(passID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(Passes);
