import React, { useState, useEffect, memo, useRef } from 'react';

import Select from '../Select';
import Loader from '../Loader';

import translations from '../../translations/de';
import { useSeasons } from '../../context/SeasonContext';

const InputSeason = ({ selectedSeason, setSelectedSeason, hideLabel = false, setAllSeasons }) => {
    const [seasonLoader, setSeasonLoader] = useState(false);
    const { seasons, currentSeason } = useSeasons();
    const selectRef = useRef(null);

    useEffect(() => {
        if (seasons?.length > 0) {
            setSeasonLoader(false);
            const seasonToSelect = seasons.find((season) => season.value === currentSeason);
            if (seasonToSelect && selectedSeason !== seasonToSelect.value) {
                if (selectRef.current && selectedSeason === null) {
                    const event = { value: seasonToSelect.value };
                    setSelectedSeason(seasonToSelect.value);
                    selectRef?.current?.onChange(event);
                }
            }
        }
    }, [seasons, currentSeason, selectedSeason, setSelectedSeason]);

    return (
        <>
            {!seasonLoader ? (
                seasons?.length > 0 && (
                    <Select
                        ref={selectRef}
                        key={selectedSeason + `-season`}
                        label={!hideLabel ? translations.season : null}
                        value={seasons.find((season) => season.value === selectedSeason)}
                        placeholder={translations.search}
                        options={seasons}
                        onChange={(event) => setSelectedSeason(event.value)}
                    />
                )
            ) : (
                <div
                    style={{
                        width: '217px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Loader size="small" theme="dark" />
                </div>
            )}
        </>
    );
};

export default memo(InputSeason);
