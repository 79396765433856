import { types, applySnapshot } from 'mobx-state-tree';

const Attendee = types
    .model('Attendee', {
        id: types.identifierNumber,
        name: types.optional(types.string, ''),
        // first_name: types.optional(types.string, ''),
        // last_name: types.optional(types.string, ''),
        email: types.optional(types.string, ''),
        phone: types.optional(types.string, ''),
        company: types.frozen({ name: types.string, type: types.string }),
    })
    .actions((self) => ({
        editEvent(edited) {
            applySnapshot(self, { ...self, edited });
        },
    }));

export default Attendee;
