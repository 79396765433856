import React, { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import getSeatMapFormInput from './getSeatMapFormInput';
import { observer } from 'mobx-react-lite';
import seatAPI from '../../api/seats';
import debounce from 'lodash.debounce';
import { getSnapshot } from 'mobx-state-tree';
import translations from '../../translations/de';

const NewSeatMap = ({ store }) => {
    const NewSeatMapModel = {
        row: '',
        num: '',
        seat_group: {
            id: '',
            title: '',
            sector: {
                title: '',
            },
        },
    };

    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const scenarios = getSnapshot(store.scenarios);
    const [searchSeatOriginalQuery, setSearchSeatOriginalQuery] = useState('');
    const [searchSeatMappedQuery, setSearchSeatMappedQuery] = useState('');
    const [originalSeats, setOriginalSeats] = useState([{ ...NewSeatMapModel }]);
    const [mappedSeats, setMappedSeats] = useState([{ ...NewSeatMapModel }]);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        const getOriginalSeats = async () => {
            try {
                const res = await seatAPI.getSeats({
                    params: {
                        search: searchSeatOriginalQuery,
                        limit: 50,
                    },
                });
                setOriginalSeats(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchSeatOriginalQuery && getOriginalSeats();
    }, [searchSeatOriginalQuery]);

    useEffect(() => {
        const getMappedSeats = async () => {
            try {
                const res = await seatAPI.getSeats({
                    params: {
                        search: searchSeatMappedQuery,
                        limit: 50,
                    },
                });
                setMappedSeats(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchSeatMappedQuery && getMappedSeats();
    }, [searchSeatMappedQuery]);

    const searchSeatOriginalHandler = debounce((value) => {
        setSearchSeatOriginalQuery(value);
    }, 500);

    const searchSeatMappedHandler = debounce((value) => {
        setSearchSeatMappedQuery(value);
    }, 500);

    return (
        <Item
            links={[{ path: Paths.SEAT_MAPS, name: translations.seat_assignments }]}
            name={`${translations.seat_assignment} ${translations.create}`}
            itemModel={{
                original_seat_id: '',
                mapped_seat_id: '',
                scenario_id: '',
            }}
            edit={true}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .addSeatMap({ ...event })
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.SEAT_MAPS);
                    })
                    .catch((error) => {
                        setError(error);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, isDisabled, index }) =>
                getSeatMapFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    isDisabled,
                    index,
                    originalSeats,
                    mappedSeats,
                    searchSeatOriginalHandler,
                    searchSeatMappedHandler,
                    scenarios: scenarios,
                })
            }
        ></Item>
    );
};

export default observer(NewSeatMap);
