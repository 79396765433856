import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import './Access.scss';
import AccessForm from './AccessForm';
import translations from '../../translations/de';
import API from '../../api/games.js';

const NewAccess = ({ store }) => {
    const history = useHistory();
    const location = useLocation();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);
    const accessDetails = {
        //access_token: "",
        game_id: '',
    };

    const accessOwner = {
        phone: '',
        first_name: '',
        last_name: '',
    };

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        API.getHomeGames()
            .then((res) => {
                setGames(res.data);
            })
            .catch((e) => console.log('greska: ', e));
    }, []);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .addAccess({
                ...e,
                pass_id: location?.state?.id ? parseInt(location.state.id) : null,
                access_token: history?.location?.state?.access_token,
                game_id: history?.location?.state?.game_id,
                seat_id: history?.location?.state?.seat_id,
            })
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return (
        <AccessForm
            edit={true}
            error={error}
            loading={loading}
            name={`${translations.access} ${translations.create}`}
            accessDetails={accessDetails}
            accessOwner={accessOwner}
            onSubmit={onSubmitHandler}
            onCancel={() => history.goBack()}
            games={games}
        />
    );
};

export default observer(NewAccess);
