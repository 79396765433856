import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearObjProps from '../../helpers/clearObjProps';

import { FaEdit } from 'react-icons/fa';
import ErrorMessages from '../ErrorMessages.js';

import Input from '../Input';
import DatePicker from '../DatePicker';
import Textarea from '../Textarea';
import Button from '../Button';
import translations from '../../translations/de';

import clearObjNulls from '../../helpers/clearObjNulls';
import gameStoreAPI from '../../api/games';

import './GameSetUp.scss';

export const getOpponentLogo = (gameStore) => {
    const homeTeamIsOwner = gameStore?.game?.home_team?.is_owner;
    const awayTeamIsOwner = gameStore?.game?.away_team?.is_owner;

    if (homeTeamIsOwner) {
        if (!awayTeamIsOwner && gameStore?.game?.away_team?.logo_url) {
            return gameStore?.game?.away_team?.logo_url;
        } else {
            return 'default_logo_url';
        }
    } else {
        return gameStore?.game?.home_team?.logo_url;
    }
};

const GameSetUp = ({ gameStore, isNewGame = false, id = false, onBack, onNext }) => {
    const gameObj = clearObjProps({ ...gameStore }, [
        'id',
        'questions',
        'rewards',
        'selectedQuestion',
        'archived',
        'ended',
        'fan_game_type',
        'published',
        'started',
        'result',
    ]);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        reset,
    } = useForm({
        defaultValues: gameObj,
    });
    const [edit, setEdit] = useState(isNewGame ? true : false);
    const [currentVals, setCurrentVals] = useState(gameObj);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isNewGame) {
            const subscription = watch((value) => {
                setCurrentVals(value);
            });
            return () => subscription.unsubscribe();
        }
    }, [watch, isNewGame]);

    const submitHandler = (e) => {
        error && setError(null);
        if (isNewGame) {
            gameStore.onChangeValues(e);
            onNext();
        } else {
            setLoading(true);
            var clearedObj = clearObjProps(e, [
                'id',
                'fan_game_type',
                'selectedQuestion',
                'archived',
                'published',
                'started',
                'ended',
                'result',
                'game_id',
                'selectedReward',
                'game',
                'user_count',
                'questions',
                'winner_fan_coins',
                'rewards_assigned',
                'title',
                'rewards',
                'questions',
                'season_id',
                'opponent_logo',
            ]);

            gameStoreAPI
                .editGame(gameStore.id, clearObjNulls(clearedObj))
                .then(() => {
                    setLoading(false);
                    setEdit(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setError(err);
                });
        }
    };

    const controlGameHandler = (control) => {
        error && setError(null);
        gameStore.controlGame(control).catch(setError);
    };

    return (
        <div className="Game-SetUp">
            <form onSubmit={handleSubmit(submitHandler)}>
                {!isNewGame && (
                    <div className="controls">
                        {id && <div className="id">{id}</div>}
                        {edit && (
                            <Button
                                label={translations.cancel}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    error && setError(null);
                                    setEdit(false);
                                    reset({ ...gameObj });
                                }}
                            />
                        )}
                        <Button
                            type={edit ? 'submit' : 'button'}
                            label={edit ? translations.save : translations.edit}
                            onClick={(e) => {
                                !edit && e.preventDefault();
                                setEdit(true);
                            }}
                            loading={loading && edit}
                        >
                            {!edit && <FaEdit />}
                        </Button>
                    </div>
                )}
                {!isNewGame && (
                    <ErrorMessages
                        messages={requsetErrorMessage(
                            error && error.message ? error.message : error,
                        )}
                    />
                )}
                {/* <div className="game-type">
                    FAN GAME TYPE: <span>{formatName(gameStore?.fan_game_type)}</span>
                </div>
                <div className="game-type">
                    OPPONENT: <img src={getOpponentLogo(gameStore)} alt="opponent_logo" />
                    <span>
                        {formatDate(gameStore?.game?.start_at)
                            ? formatDate(gameStore?.game?.date)
                            : 'Date'}{' '}
                        / {gameStore?.game?.location ?? 'Location'}
                    </span>
                </div> */}
                <Input
                    label="NAME"
                    name="name"
                    defaultValue={gameObj.name}
                    {...register('name', {
                        required: true,
                    })}
                    error={errors.name}
                    disabled={!edit}
                />
                <Textarea
                    label="DESCRIPTION"
                    name="description"
                    defaultValue={gameObj.description}
                    {...register('description', {
                        required: true,
                    })}
                    error={errors.description}
                    disabled={!edit}
                />
                <Input
                    label="POPUP TITLE"
                    name="popup_title"
                    defaultValue={gameObj.popup_title}
                    {...register('popup_title', {
                        required: true,
                    })}
                    error={errors.popup_title}
                    disabled={!edit}
                />
                <Textarea
                    label="POPUP TEXT"
                    name="popup_text"
                    defaultValue={gameObj.popup_text}
                    {...register('popup_text', {
                        required: true,
                    })}
                    error={errors.popup_text}
                    disabled={!edit}
                />
                <Input
                    label="PARTICIPATION FAN COINS"
                    name="participation_fan_coins"
                    type="number"
                    defaultValue={gameObj.participation_fan_coins}
                    {...register('participation_fan_coins', {
                        required: true,
                        valueAsNumber: true,
                    })}
                    error={errors.participation_fan_coins}
                    disabled={!edit}
                />
                <div className="row">
                    <DatePicker
                        label={`ARCHIVE AT ${gameStore.archived ? '(archived)' : ''}`}
                        selectText={translations.select_date}
                        name="archive_at"
                        defaultValue={gameObj.archived}
                        control={control}
                        error={errors.archive_at}
                        withTime
                        isRequired={false}
                        disabled={!edit}
                    />
                    {!isNewGame && (
                        <Button
                            type="button"
                            label="ARCHIVE"
                            disabled={gameStore.archived}
                            onClick={() => controlGameHandler('archive')}
                        />
                    )}
                </div>
                <div className="row">
                    <DatePicker
                        label={`PUBLISH AT ${gameStore.published ? '(published)' : ''}`}
                        selectText={translations.select_date}
                        name="publish_at"
                        defaultValue={gameObj.publish_at}
                        control={control}
                        error={errors.publish_at}
                        withTime
                        isRequired={false}
                        disabled={!edit}
                    />
                    {!isNewGame && (
                        <Button
                            type="button"
                            label={gameStore.published ? 'UNPUBLISH' : 'PUBLISH'}
                            onClick={() =>
                                gameStore.published
                                    ? controlGameHandler('unpublish')
                                    : controlGameHandler('publish')
                            }
                            disabled={gameStore.archived}
                        />
                    )}
                </div>
                <div className="row">
                    <DatePicker
                        label={`START AT ${gameStore.started ? '(started)' : ''}`}
                        selectText={translations.select_date}
                        name="start_at"
                        defaultValue={gameObj.start_at}
                        control={control}
                        error={errors.start_at}
                        withTime
                        isRequired={false}
                        disabled={!edit}
                    />
                    {!isNewGame && (
                        <Button
                            type="button"
                            label="START"
                            disabled={gameStore.started || gameStore.archived}
                            onClick={() => controlGameHandler('start')}
                        />
                    )}
                </div>
                <div className="row">
                    <DatePicker
                        label={`END AT ${gameStore.ended ? '(ended)' : ''}`}
                        selectText={translations.select_date}
                        name="end_at"
                        defaultValue={gameObj.end_at}
                        control={control}
                        error={errors.end_at}
                        withTime
                        isRequired={false}
                        disabled={!edit}
                    />
                    {!isNewGame && (
                        <Button
                            type="button"
                            label="END"
                            disabled={gameStore.ended || gameStore.archived}
                            onClick={() => controlGameHandler('end')}
                        />
                    )}
                </div>
                {isNewGame && (
                    <div className="controls">
                        <Button
                            type="button"
                            label="Back"
                            onClick={() => {
                                gameStore.onChangeValues({
                                    ...currentVals,
                                });
                                onBack();
                            }}
                        />
                        <Button type="submit" label="Submit" />
                    </div>
                )}
            </form>
        </div>
    );
};

export default observer(GameSetUp);
