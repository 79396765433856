const dateToISOStringInObj = (obj) => {
    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        if (value instanceof Date) {
            const ISOString = value.toISOString();
            obj[key] = ISOString;
        }
    });
    return obj;
};

export default dateToISOStringInObj;
