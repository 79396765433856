const mandatoryFields = [
    'start',
    'end',
    'title',
    'subtitle',
    'program',
    'location',
    'image_url',
    'address',
    'text',
];

export default mandatoryFields;
