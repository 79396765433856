import { types, applySnapshot } from 'mobx-state-tree';

const Sector = types
    .model('Sector', {
        id: types.identifierNumber,
        title: types.optional(types.string, ''),
    })
    .actions((self) => ({
        editSector(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));
export default Sector;
