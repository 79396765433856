import { useState } from 'react';
import Form from './Form';
import './Login.scss';
import Logo from './../../assets/images/logo/scb-logo.png';
import translations from '../../translations/de';
import ForgotPassword from '../ResetPasswordFlow';

const Login = ({ adminStore }) => {
    const [showResetForm, setShowResetForm] = useState();

    return !showResetForm ? (
        <div className="Login">
            <div className="Login-Container">
                <div className="logo">
                    <img src={Logo} alt="SCB Logo" />
                </div>
                <h2>{translations.app_name}</h2>
                <Form adminStore={adminStore} showResetForm={(e) => setShowResetForm(e)} />
            </div>
        </div>
    ) : (
        <ForgotPassword />
    );
};

export default Login;
