import React, { useEffect } from 'react';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';
import Fans from './Fans';
import Fan from './Fan';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';

const FansTab = ({ store, adminStore }) => {
    useEffect(() => {
        !store.seasonalMoodGameId && store.getSeasonalMoodGameId();
    }, [store]);

    useEffect(() => {
        store.ownersPlayers.length === 0 && store.getOwnersPlayers();
    }, [store]);

    useEffect(() => {
        return () => {
            //clean up
            store.selectUser(undefined);
        };
    }, [store]);

    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.FANS}>
                <Fans store={store} />
            </Route>
            <Route exact path={Paths.FAN}>
                <Fan store={store} adminStore={adminStore} />
            </Route>
        </Page>
    );
};

export default observer(FansTab);
