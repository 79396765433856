import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory, Link } from 'react-router-dom';
import Paths from '../../constants/paths';
import { FaPlus, FaTrash, FaSearch, FaLongArrowAltRight } from 'react-icons/fa';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import Pagination from './../../components/Pagination';
import translations from '../../translations/de';
import Input from '../../components/Input';
import debounce from 'lodash.debounce';

const Contacts = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [contactID, setContactID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);

    useEffect(() => {
        store.selectContact(undefined);
        store?.getContacts(searchQuery);
    }, [store, store.selectedPage, searchQuery]);

    const handleContact = (id) => {
        history.push(`${Paths.CONTACTS}/${id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

    return (
        <>
            <>
                {' '}
                <div className="tab-controls">
                    <Input
                        placeholder={translations.search}
                        defaultValue={store.searchQuery}
                        onChange={onSearchChangeHandler}
                    />
                </div>
                <div className="tab-controls">
                    <Button
                        label={translations.contact}
                        onClick={() => {
                            store.selectContact(undefined);
                            history.push(Paths.NEW_CONTACT);
                        }}
                    >
                        <FaPlus style={{ marginRight: '5px' }} />
                    </Button>
                </div>
            </>

            {store.contacts && store.contacts.length !== 0 && (
                <Table
                    tablePagination={
                        store?.totalPages > 1 && (
                            <Pagination
                                totalPages={store?.totalPages}
                                selectedPage={store?.selectedPage}
                                onSelectedPageChange={(selected) =>
                                    store?.setSelectedPage(selected)
                                }
                            />
                        )
                    }
                    tableName={translations.contacts}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.user,
                            accessor: 'user',
                            Cell: ({ value }) => (
                                <div>
                                    {' '}
                                    {value && (
                                        <Link to={`/fans/${value.id}`}>
                                            <FaLongArrowAltRight />

                                            {`${value.first_name} ${value.last_name}`}
                                        </Link>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.zip,
                            accessor: 'zip',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.city,
                            accessor: 'city',
                            Cell: ({ value }) => <div>{value}</div>,
                        },

                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleContact(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setContactID(row.cells[0].value);
                                            setModal(true);
                                        }}
                                        title={translations.delete}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.contacts.map(({ id, user, first_name, last_name, zip, city }) => {
                        return {
                            id,
                            user: user,
                            name: `${first_name ?? ''} ${last_name ?? ''}`,
                            zip,
                            city,
                        };
                    })}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Contact"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete this contact with id ${contactID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteContact(contactID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(Contacts);
