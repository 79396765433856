import React, { useState, useEffect, forwardRef } from 'react';
import { ChromePicker } from 'react-color';
import { Controller } from 'react-hook-form';
import './InputColor.scss';

const InputColor = forwardRef(({ name = '', label = '', value, control, disabled }, ref) => {
    const [color, setColor] = useState(value);
    const [showColorPicker, setShowColorPicker] = useState(false);

    var elem = document.getElementById(name);

    elem &&
        document.addEventListener('click', (e) => {
            elem && !elem.contains(e.target) && setShowColorPicker(false);
        });

    useEffect(() => {
        disabled && setColor(value);
    }, [disabled, value]);

    return (
        <div className="Input">
            {label && (
                <div className="label">
                    <span>{label} </span>
                </div>
            )}

            <div id={name} className="input-wrapper">
                <div
                    className="color-preview"
                    style={{
                        backgroundColor: color,
                        cursor: disabled ? '' : 'pointer',
                    }}
                    onClick={(e) => {
                        setShowColorPicker(true);
                    }}
                />
                <div className="color-picker_wrapper">
                    {showColorPicker && !disabled && (
                        <Controller
                            control={control}
                            name={name}
                            render={({ field: { onChange, value, name, ref } }) => {
                                return (
                                    <ChromePicker
                                        name={name}
                                        disableAlpha={true}
                                        ref={ref}
                                        color={value}
                                        onChange={(e) => {
                                            onChange(e.hex);
                                            setColor(e.hex);
                                        }}
                                        disabled={disabled}
                                    />
                                );
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

export default InputColor;
