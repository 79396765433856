import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import { useHistory } from 'react-router-dom';
import Pagination from './../../components/Pagination';
import translations from '../../translations/de';
import { formatDate, formatTime } from '../../helpers/formatDate';
import { useParams } from 'react-router-dom';
import API from '../../api/users';
import FinalScoreBreadcrumb from '../FinalScoreBreadcrumb/FinalScoreBreadcrumb';

const FinalScoreRanking = () => {
    const { fan_game_id } = useParams();
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        sessionStorage.setItem('activeFanGameTab', 'Final Score');
        const getEndGameRanking = async () => {
            const res = fan_game_id
                ? await API.getSingleEndGameRanging(fan_game_id, { params: { page: selectedPage } })
                : await API.getEndGameRanking();

            const { items, total_pages_count } = res.data;

            setUsers(items);
            setTotalPages(total_pages_count);
        };
        getEndGameRanking();
    }, [fan_game_id, selectedPage]);

    return (
        <>
            <FinalScoreBreadcrumb />
            <Table
                tableName={translations.ranking}
                tablePagination={
                    totalPages > 1 && (
                        <Pagination
                            totalPages={totalPages}
                            selectedPage={selectedPage}
                            onSelectedPageChange={(selected) => setSelectedPage(selected)}
                        />
                    )
                }
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'Voted at',
                        accessor: 'voted_at',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'Vote',
                        accessor: 'vote',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.fan_name,
                        accessor: 'fan_name',
                        Cell: ({ row, value }) => (
                            <div
                                className="link_text"
                                onClick={() => history.replace(`/fans/${row.cells[0].value}`)}
                            >
                                {value}
                            </div>
                        ),
                    },
                    {
                        Header: translations.first_name,
                        accessor: 'first_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.last_name,
                        accessor: 'last_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                ]}
                data={users?.map(({ id, fan_name, first_name, last_name, vote }) => {
                    return {
                        id,
                        voted_at: `${formatDate(vote.created_at)} ${formatTime(vote.created_at)}`,
                        fan_name,
                        vote: `${vote?.score_home_team} - ${vote?.score_away_team}`,
                        first_name,
                        last_name,
                    };
                })}
            />
        </>
    );
};

export default FinalScoreRanking;
