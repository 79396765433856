import { types, flow } from 'mobx-state-tree';
import Screen from './Screen';
import Banner from './Banner';
import API from '../../api/screens';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearEmptyFields from '../../helpers/clearEmptyFields';
import clearObjNulls from '../../helpers/clearObjNulls';

const ScreensStore = types
    .model('ScreensStore', {
        screens: types.optional(types.array(Screen), []),
        selectedScreen: types.maybe(types.reference(Screen)),
        selectedBanner: types.maybe(types.reference(Banner)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getScreens: flow(function* getScreens(searchQuery, tab) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield API.getScreens({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        limit: 10,
                        page: self.selectedPage,
                        tab: tab ? tab : undefined,
                    },
                });
                self.dataLoaded = true;
                self.screens = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        selectScreen(id) {
            self.selectedScreen = id;
        },
        getScreen: flow(function* getScreen(id) {
            self.error = '';
            self.selectedBanner = undefined;
            if (!self.selectedScreen) {
                try {
                    const res = yield API.getScreen(id);
                    self.dataLoaded = true;
                    self.screens = [];
                    self.screens.push(res.data);
                    self.selectedScreen = res.data.id;
                } catch (error) {
                    self.dataLoaded = true;
                    self.error = requsetErrorMessage(
                        error && error.message ? error.message : error,
                    );
                }
            }
        }),
        editScreen: flow(function* editScreen(edited) {
            try {
                const res = yield API.editScreen(self.selectedScreen.id, edited);
                self.selectedScreen.editScreen(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addScreen: flow(function* addScreen(screen) {
            try {
                const res = yield API.createScreen(clearEmptyFields(screen));
                self.screens.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteScreen: flow(function* deleteScreen(id) {
            try {
                yield API.deleteScreen(id);
                const index = self.screens.findIndex((s) => s.id === id);
                self.screens.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectBanner(id) {
            self.selectedBanner = id;
        },
        getBanner: flow(function* getBanner(screen_id, banner_id) {
            self.error = '';
            self.selectedBanner = undefined;
            self.screens = [];
            try {
                const screen = yield API.getScreen(screen_id);
                self.screens.push(screen.data);
                self.selectedScreen = screen?.data?.id;
                const banner = screen?.data?.banners.find(
                    (banner) => banner.id === parseInt(banner_id),
                );
                self.dataLoaded = true;
                self.selectedBanner = banner.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editBanner: flow(function* editBanner(edited) {
            try {
                const res = yield API.editBanner(self.selectedBanner.id, clearEmptyFields(clearObjNulls(edited)));
                self.selectedBanner.editBanner(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addBanner: flow(function* addBanner(banner) {
            try
            {
                isNaN(banner.position) && delete banner.position
                const res = yield API.createBanner(clearEmptyFields(clearObjNulls(banner)));
                self.selectedScreen.addBanner(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteBanner: flow(function* deleteBanner(id) {
            try {
                yield API.deleteBanner(id);
                const index = self.selectedScreen.banners.findIndex((s) => s.id === id);
                self.selectedScreen.banners[index].deleted_at = 'deleted';
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        reviveBanner: flow(function* reviveBanner(id) {
            try {
                yield API.reviveBanner(id.toString());
                const index = self.selectedScreen.banners.findIndex((p) => p.id === id);
                self.selectedScreen.banners[index].deleted_at = null;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),

        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default ScreensStore;
