import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import useModal from '../../customHooks/useModal';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { FaTrash, FaPlus, FaSearch } from 'react-icons/fa';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Pagination from './../../components/Pagination';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';

const SeatGroups = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [seatGroupID, setSeatGroupID] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        store.getSeatGroups(searchQuery);
    }, [store, store.selectedPage, searchQuery]);

    const handleSeatGroup = (id) => {
        store.selectSeatGroup(id);
        history.push(`/seat-groups/${store.selectedSeatGroup.id}`);
    };
    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);
    return (
        <>
            <div style={{ marginLeft: '2.5%' }}>
                <Input placeholder={translations.search} onChange={onSearchChangeHandler} />

                <Button
                    label={translations.seat_group}
                    onClick={() => {
                        store.selectSeatGroup(undefined);
                        history.push(Paths.NEW_SEAT_GROUP);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            <Table
                tablePagination={
                    store.totalPages > 1 && (
                        <Pagination
                            totalPages={store.totalPages}
                            selectedPage={store.selectedPage}
                            onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                        />
                    )
                }
                tableName={translations.seat_groups}
                columns={[
                    {
                        Header: 'Id',
                        accessor: 'id',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.sector,
                        accessor: 'sector',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },

                    {
                        Header: translations.title,
                        accessor: 'title',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: '',
                        accessor: 'action',
                        Cell: ({ row }) => (
                            <div>
                                <Button
                                    onClick={() => {
                                        handleSeatGroup(row.cells[0].value);
                                    }}
                                    title={translations.show}
                                >
                                    <FaSearch />
                                </Button>
                                <Button
                                    onClick={() => {
                                        setSeatGroupID(row.cells[0].value);
                                        setModal(true);
                                    }}
                                    title={translations.delete}
                                >
                                    <FaTrash />
                                </Button>
                            </div>
                        ),
                    },
                ]}
                data={store.seatGroups.map(({ id, sector, title }) => {
                    return {
                        id,
                        sector: sector.title,
                        title,
                    };
                })}
            />
            {modal && (
                <Modal
                    title="Delete Seat Group"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this seat group?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteSeatGroup(seatGroupID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(SeatGroups);
