import api from './apiLibrary';

const API = {
    getSeatMaps(params) {
        return api.get('/api/admin/seat-map', params);
    },
    getSeats(params) {
        return api.get('/api/admin/seat', params);
    },
    getScenarios(params) {
        return api.get('/api/admin/scenario', params);
    },
    createSeatMap(data) {
        return api.post('/api/admin/seat-map', data);
    },
    getSeatMap(id) {
        return api.get(`/api/admin/seat-map/${id}`);
    },
    editSeatMap(id, data) {
        return api.put(`/api/admin/seat-map/${id}`, data);
    },
    deleteSeatMap(id) {
        return api.delete(`/api/admin/seat-map/${id}`);
    },
};

export default API;
