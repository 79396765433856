import { Route } from 'react-router-dom';
import Page from './../../components/Page';
import Scanners from './Scanners';
import Scanner from './Scanner';
import NewScanner from './NewScanner';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const ScannersTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.SCANNERS}>
                <Scanners store={store} />
            </Route>
            <Route exact path={Paths.SCANNER}>
                <Scanner store={store} />
            </Route>
            <Route exact path={Paths.NEW_SCANNER}>
                <NewScanner store={store} />
            </Route>
        </Page>
    );
};

export default observer(ScannersTab);
