import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams } from 'react-router-dom';
import getSeatMapFormInput from './getSeatMapFormInput';
import seatAPI from '../../api/seats';
import debounce from 'lodash.debounce';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import clearObjProps from '../../helpers/clearObjProps';

const SeatMap = ({ store }) => {
    const { seat_map_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [originalSeats, setOriginalSeats] = useState(
        store?.selectedSeatMap?.original_seat ? [{ ...store.selectedSeatMap.original_seat }] : [],
    );
    const [mappedSeats, setMappedSeats] = useState(
        store?.selectedSeatMap?.mapped_seat ? [{ ...store.selectedSeatMap.mapped_seat }] : [],
    );
    const [searchSeatOriginalQuery, setSearchSeatOriginalQuery] = useState('');
    const [searchSeatMappedQuery, setSearchSeatMappedQuery] = useState('');
    const scenarios = store.scenarios;
    const seatMapModel = clearObjProps({ ...store.selectedSeatMap }, [
        'id',
        'original_seat',
        'mapped_seat',
    ]);
    const searchSeatOriginalHandler = debounce((value) => {
        setSearchSeatOriginalQuery(value);
    }, 500);

    const searchSeatMappedHandler = debounce((value) => {
        setSearchSeatMappedQuery(value);
    }, 500);

    useEffect(() => {
        !store.selectedSeatMap &&
            store.getSeatMap(seat_map_id).then(() => {
                setOriginalSeats([{ ...store.selectedSeatMap.original_seat }]);
                setMappedSeats([{ ...store.selectedSeatMap.mapped_seat }]);
            });
    }, [store, seat_map_id]);

    useEffect(() => {
        const getOriginalSeats = async () => {
            try {
                const res = await seatAPI.getSeats({
                    params: {
                        search: searchSeatOriginalQuery,
                        limit: 50,
                    },
                });
                setOriginalSeats(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchSeatOriginalQuery && getOriginalSeats();
    }, [searchSeatOriginalQuery]);

    useEffect(() => {
        const getMappedSeats = async () => {
            try {
                const res = await seatAPI.getSeats({
                    params: {
                        search: searchSeatMappedQuery,
                        limit: 50,
                    },
                });
                setMappedSeats(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchSeatMappedQuery && getMappedSeats();
    }, [searchSeatMappedQuery]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editSeatMap({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };
    return store.selectedSeatMap ? (
        <>
            <Item
                links={[{ path: Paths.SEAT_MAPS, name: translations.seat_assignments }]}
                name={store.selectedSeatMap.id}
                id={`[seatMap_ID: ${store.selectedSeatMap.id}]`}
                itemModel={seatMapModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getSeatMapFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        scenarios,
                        originalSeats,
                        mappedSeats,
                        searchSeatOriginalHandler,
                        searchSeatMappedHandler,
                    })
                }
            />
        </>
    ) : null;
};

export default observer(SeatMap);
