import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import InputFile from '../../components/InputFile/index.js';
import translations from '../../translations/de';
import InputColor from '../../components/InputColor';
import InputEmpty from '../../components/InputEmpty';
import InputJersey from '../../components/InputJersey/InputJersey.js';

export default function getTeamFormInput({
    name,
    value,
    register,
    error,
    control,
    disabled,
    index,
    resetValue,
}) {
    if (name === 'logo_url') {
        return (
            <InputFile
                key={index}
                preferredFileSize={translations.avatarSizeLabel}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/teams"
            />
        );
    } else if (
        name === 'home_color' ||
        name === 'home_text_color' ||
        name === 'away_color' ||
        name === 'away_text_color'
    ) {
        return (
            <InputColor
                value={value}
                key={index}
                name={name}
                control={control}
                label={translateLabel(name)}
                {...register(name, {
                    required: true,
                })}
                disabled={disabled}
            />
        );
    } else if (name === 'dummy') {
        return <InputEmpty key={index} />;
    } else if (name === 'home_jersey_url' || name === 'away_jersey_url') {
        return (
            <InputJersey
                key={index}
                centerLabel={true}
                value={value}
                preferredFileSize={translations.imageLabelSvg}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: false,
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/teams"
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
