import { types, flow } from 'mobx-state-tree';
import Reward from './Reward';
import API from '../../api/rewards';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearEmptyFields from '../../helpers/clearEmptyFields';
import clearObjNulls from '../../helpers/clearObjNulls';

const RewardsStore = types
    .model('RewardsStore', {
        rewards: types.array(Reward),
        selectedReward: types.maybe(types.reference(Reward)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getAllRewards: flow(function* getAllRewards(searchQuery, itemType, selectedSeason) {
            self.error = '';
            try {
                const res = yield API.getAllRewards({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        season: selectedSeason ? selectedSeason : undefined,
                        type: itemType ? itemType : undefined,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.rewards = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error)
            {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getReward: flow(function* getReward(id) {
            self.error = '';
            self.selectedReward = undefined;
            self.dataLoaded = false;
            try {
                const res = yield API.getReward(id);
                self.rewards = [];
                self.rewards.push(res.data);
                self.selectedReward = res.data.id;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        createReward: flow(function* createReward(reward) {
            try {
                const res = yield API.createReward(clearEmptyFields(clearObjNulls({...reward, voucher_type: reward.type === 'consumable' ? reward.voucher_type : undefined})), {
                });
                self.rewards.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        editReward: flow(function* editReward(edited) {
            try {
                const res = yield API.editReward(self.selectedReward.id, edited);
                self.selectedReward.editReward(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteReward: flow(function* deleteReward(id) {
            try {
                yield API.deleteReward(id);
                const index = self.rewards.findIndex((i) => i.id === id);
                self.rewards.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectReward(id) {
            self.selectedReward = id;
        },
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default RewardsStore;
