import api from './apiLibrary';

const API = {
    getPasses(params) {
        return api.get('/api/admin/pass?type=Freunde Ticket', params);
    },
    getPass(id, params) {
        return api.get(`/api/admin/pass/${id}?type=Freunde Ticket`, params);
    },
    createPass(data) {
        return api.post('/api/admin/pass?type=Freunde Ticket', data);
    },
    createAllTickets(id) {
        return api.post(`/api/admin/pass/${id}/createAccesses?type=Freunde Ticket`);
    },
    editPass(id, data) {
        return api.put(`/api/admin/pass/${id}?type=Freunde Ticket`, data);
    },
    deletePass(id) {
        return api.delete(`/api/admin/pass/${id}?type=Freunde Ticket`);
    },
    revivePass(id) {
        return api.put(`/api/admin/pass/${id}/revive?type=Freunde Ticket`);
    },
    getPassesFromFanID(id) {
        return api.get(`/api/admin/pass?user_id=${id}&type=Freunde Ticket`);
    },
};

export default API;
