import { types, flow, getSnapshot } from 'mobx-state-tree';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import BusinessEvent from './BusinessEvent';
import API from './../../api/businessEvents';
import BusinessProfile from '../businessProfilesStore/BusinessProfile';
import Attendee from './Attendee';

const BusinessEvents = types
    .model('Events', {
        events: types.array(BusinessEvent),
        creating_invitations: types.array(BusinessProfile),
        selectedEvent: types.maybe(types.reference(BusinessEvent)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        //users
        totalUserPages: types.optional(types.number, 0),
        selectedUserPage: types.optional(types.number, 1),
        invitations: types.optional(types.array(Attendee), []),
        attendees: types.optional(types.array(Attendee), []),
        participants: types.optional(types.array(Attendee), []),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
        profiles_remove: types.array(BusinessProfile),
        profiles_add: types.array(BusinessProfile),
    })
    .views((self) => ({
        getEvent(id) {
            return self.events.find((event) => event.id === id);
        },
    }))
    .actions((self) => ({
        getEvents: flow(function* getEvents(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getEvents({
                    params: {
                        search: searchQuery,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.events = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getEvent: flow(function* getEvent(id) {
            self.error = '';
            try {
                const res = yield API.getEvent(id);
                self.dataLoaded = true;
                self.events = [];
                self.events.push(res.data);
                // self.creating_invitations.push(res.data.profiles);
                self.selectedEvent = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getInvitations: flow(function* getInvitations() {
            self.error = '';
            try {
                const res = yield API.getInvitations(self.selectedEvent.id);
                self.dataLoaded = true;
                self.invitations = [];
                self.invitations.push(res.data.items);
                self.totalUserPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getParticipants: flow(function* getParticipants() {
            self.error = '';
            try {
                const res = yield API.getInvitations(self.selectedEvent.id);
                self.dataLoaded = true;
                self.participants = [];
                self.participants.push(res.data.items[0]);
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getAttendees: flow(function* getAttendees() {
            self.error = '';
            try {
                const res = yield API.getInvitations(self.selectedEvent.id);
                self.dataLoaded = true;
                self.attendees = [];
                self.attendees.push(res.data.items[0]);
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editEvent: flow(function* editEvent(edited) {
            try {
                var tempArray = []; // for adding
                var tempDelArray = []; /// for removing
                if (self.profiles_add !== null) {
                    self.profiles_add.map((profile) => {
                        return tempArray.push(profile.id);
                    });
                    self.profiles_remove.map((profile) => {
                        return tempDelArray.push(profile.id);
                    });
                    edited.profiles = tempArray;
                    edited.profiles_remove = tempDelArray;
                    self.selectedEvent.invitations = getSnapshot(self.creating_invitations);
                }
                const res = yield API.editEvent(self.selectedEvent.id, edited);
                self.selectedEvent.editEvent(res.data);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        deleteEvent: flow(function* deleteEvent(id) {
            try {
                yield API.deleteEvent(id);
                const index = self.events.findIndex((e) => e.id === id);
                self.events.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectEvent(id) {
            self.selectedEvent = id;
        },
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        setTotalPages(total_pages_count) {
            self.totalPages = total_pages_count;
        },
        invitaionsUpdate: flow(function* invitaionsUpdate() {
            const profiles = self.creating_invitations.map((i) => i.id);
            try {
                yield API.editEvent(self.selectedEvent.id, { profiles });
                self.selectedEvent.invitations = getSnapshot(self.creating_invitations);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        resetInvitaionsUpdate() {
            self.creating_invitations = getSnapshot(self.selectedEvent.invitations);
            self.profiles_add = getSnapshot([]);
            self.profiles_remove = getSnapshot([]);
        },
        setSentInvitations(data) {
            self.creating_invitations = [...data.invitations];
            self.selectedEvent.setAdditionalData(data);
        },

        /// this is used when creating new
        addInvitation(profile) {
            self.creating_invitations.push(profile);
        },
        removeInvitation(id) {
            self.creating_invitations = self.creating_invitations.filter(
                (invitation) => invitation.id !== id,
            );
        },
        //
        // this is used when editing
        addInvitationFromEdit(id) {
            const index = self.profiles_add.findIndex((profile) => profile.id === id);
            if (index !== -1) {
                // If the id exists, remove it (toggle off)
                self.profiles_add.splice(index, 1);
            } else {
                // If the id doesn't exist, add it (toggle on)
                self.profiles_add.push(id);
            }
        },
        removeInvitationFromEdit(id) {
            const index = self.profiles_remove.findIndex((profile) => profile.id === id);
            if (index !== -1) {
                self.profiles_remove.splice(index, 1);
            } else {
                self.profiles_remove.push(id);
            }
        },
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default BusinessEvents;
