import React, { useState, createContext } from 'react';
import ForgotPassword from './ForgotPassword';
import ResetCodeInput from './ResetCodeInput';
import ResetPassword from './ResetPassword';
import Login from '../Login';

export const ResetPasswordContext = createContext();

const ForgotPassFlow = () => {
    const [email, setEmail] = useState();
    const [code, setCode] = useState();
    const [newPassword, setNewPassword] = useState();
    const [step, setStep] = useState(0);
    const nextStep = () => {
        setStep((step) => step + 1);
    };

    const resetFlow = () => {
        setStep(-1); // return to login page
    };

    const showContent = () => {
        switch (step) {
            case 0:
                return <ForgotPassword resetFlow={resetFlow} />;
            case 1:
                return <ResetCodeInput />;
            case 2:
                return <ResetPassword />;
            default:
                return <Login />;
        }
    };

    return (
        <ResetPasswordContext.Provider
            value={{
                email,
                setEmail,
                code,
                setCode,
                newPassword,
                setNewPassword,
                nextStep,
                resetFlow,
            }}
        >
            {showContent()}
        </ResetPasswordContext.Provider>
    );
};

export default ForgotPassFlow;
