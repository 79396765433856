import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams } from 'react-router-dom';
import getInstantRewardsFormInput from '../InstantRewards/getInstantRewardsFormInput';
import translations from '../../translations/de';
import Breadcrumb from '../../components/Breadcrumb';
import ItemImage from '../../components/ItemImage';
import API from '../../api/fanGames';
import translateLabel from '../../helpers/translateLabel';
import { useHistory } from 'react-router-dom';

const FanGameDefaultReward = ({ store }) => {
    const { fan_game_id } = useParams();
    const [imageUrl, setImageUrl] = useState('');
    const [voucherType, setVoucherType] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const history = useHistory();

    const onSubmitHandler = (event) => {
        error && setError(null);
        let { id, created_at, updated_at, ...clearedData } = data;

        store
            .editFanGameDefaultReward(
                {
                    ...clearedData,
                    ...event,
                },
                fan_game_id,
            )
            .then((res) => {
                setData(res);
                setEdit(false);
            })
            .catch((err) => {
                setError(err);
            });
    };

    useEffect(() => {
        const fetchReward = async () => {
            try {
                const res = await API.getDefaultReward(fan_game_id);
                setData(res.data);
            } catch (e) {
                console.log(e);
            }
        };
        fetchReward();
    }, [fan_game_id]);

    let fanGameName = history?.location?.state?.activeTabName;

    return data ? (
        <>
            <div className="shop_item-header">
                <Breadcrumb
                    name={`${fanGameName !== '' && translateLabel(fanGameName) + ' - '}  ${
                        data.name
                    }`}
                    links={[{ path: '/fan-games', name: translations.fan_games }]}
                />
            </div>
            <Item
                links={[{ path: '/instant-rewards', name: translations.instant_rewards }]}
                name={data.name}
                hasNavigation={false}
                id={`[Fan Game Reward ID]: ${data.id}`}
                onSubmit={onSubmitHandler}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                edit={edit}
                onEdit={() => setEdit(true)}
                itemModel={{
                    name: data.name,
                    image_url: data.image_url,
                    redeem_description: data.redeem_description,
                    expire_at: data.expire_at,
                    popup_title: data.popup_title,
                    quantity: data.quantity,
                    acquired: data.acquired,
                    used: data.used,
                    popup_text: data.popup_text,
                    description: data.description,
                    type: data.type,
                    voucher_type: data.voucher_type,
                    price_in_cents: data.price_in_cents,
                    use_unused: data.use_unused,
                    sponsor_link: data.sponsor_link,
                }}
                error={error}
                loading={false}
                watchedProperties={['voucher_type']}
                watchedPropertyActions={{ voucher_type: setVoucherType }}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getInstantRewardsFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        resetImageValue: (name, value) => setImageUrl({ [name]: value }),
                        sendToRandom: true,
                        voucher_type: voucherType,
                        comesFromEdit: true,
                    })
                }
                resetProp={imageUrl}
            >
                <ItemImage imgSrc={data.image_url}></ItemImage>
            </Item>
        </>
    ) : null;
};

export default observer(FanGameDefaultReward);
