import { observer } from 'mobx-react-lite';
import Table from '../../components/Table';
import translations from '../../translations/de';
import { FaTrophy, FaSearch } from 'react-icons/fa';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import ItemImage from '../../components/ItemImage';

const Winners = ({ store }) => {
    const history = useHistory();

    const handleUser = (id) => {
        history.push(`/fans/${id}`);
    };


    return (
        <div>
            {store?.selectedLotteryGame?.winners && store.selectedLotteryGame.winners.length > 0 && (
                <Table
                    isRowClickable={false}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.avatar,
                            accessor: 'avatar',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} isAvatar={true} listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.is_winner,
                            accessor: 'is_winner',
                            Cell: ({ value }) => (
                                <div>
                                    {' '}
                                    {value === true && (
                                        <FaTrophy
                                            className="confirm"
                                            style={{ fontSize: '30px' }}
                                        />
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.tickets,
                            accessor: 'tickets',
                            Cell: ({ value }) => (
                                <div style={{ flexWrap: 'wrap' }}>
                                    {value?.length > 0 &&
                                        value.map((ticket, index) => (
                                            <span
                                                key={ticket.id}
                                                style={{
                                                    fontWeight: ticket.is_winner
                                                        ? 'bold'
                                                        : 'normal',
                                                }}
                                            >
                                                {`${ticket.number}`}
                                                {index !== value.length - 1 && `,`}
                                            </span>
                                        ))}
                                </div>
                            ),
                        },
                        {
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleUser(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store?.selectedLotteryGame?.winners?.map(
                        ({ id, first_name, last_name, avatar, is_winner, tickets }) => {
                            return {
                                id,
                                name: `${first_name} ${last_name}`,
                                avatar,
                                is_winner,
                                tickets,
                            };
                        },
                    )}
                />
            )}
        </div>
    );
};

export default observer(Winners);
