import Input from '../../components/Input';
import mandatoryFields from '../../configs/vouchers';
import Select from '../../components/Select';
import translateLabel from '../../helpers/translateLabel';
import translations from '../../translations/de';
import DatePicker from '../../components/DatePicker';
import Checkbox from '../../components/Checkbox';
import voucherTypes from '../../constants/voucherTypes';

export default function getVouchersFormInput({
    value,
    name,
    register,
    control,
    disabled,
    error,
    index,
    searchRewardsHandler,
    searchShopItemsHandler,
    rewards = [],
    shopItems = [],
    type,
    voucherType,
    reset,
    getValues,
    selectedItem,
    setSelectedItem,
    setOrderID,
}) {
    if (name === 'shop_item_id' && value !== null) {
        return (
            <Select
                key={index + `${value}`}
                onInputChange={searchShopItemsHandler}
                name={name}
                label={translateLabel(name.replace('_id', ''))}
                options={shopItems.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                })}
                onChangeCallback={(e) => {
                    let choosedItem = shopItems.filter((item) => item.id === e);
                    reset({
                        ...getValues(),
                        voucher_type: choosedItem[0]?.voucher_type ?? null,
                        order_id: choosedItem[0]?.order_id ?? null,
                    });
                    setOrderID(choosedItem[0]?.order_id ?? null);
                    setSelectedItem(choosedItem.length !== 0 ? true : false);
                }}
                placeholder={translations.searchShopItem}
                control={control}
                isRequired={true}
                error={error}
                disabled={disabled}
                noOptionsMsg={translations.noOptionShopItem}
                isClearable
            />
        );
    } else if (name === 'price_in_cents') {
        return voucherType === 'eguma' ? (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                control={control}
                defaultValue={value}
                disabled={disabled}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: true,
                    min: { value: 1, message: translations.positive_number },
                })}
                error={error}
            />
        ) : null;
    } else if (name === 'reward_id' && value !== null) {
        return (
            <Select
                key={index + `${value}`}
                onInputChange={searchRewardsHandler}
                onChangeCallback={(e) => {
                    let choosedItem = rewards.filter((item) => item.id === e);
                    reset({
                        ...getValues(),
                        voucher_type: choosedItem[0]?.voucher_type ?? null,
                        order_id: choosedItem[0]?.order_id ?? null,
                    });
                    setOrderID(choosedItem[0]?.order_id ?? null);
                    setSelectedItem(choosedItem.length !== 0 ? true : false);
                }}
                name={name}
                label={translateLabel(name.replace('_id', ''))}
                options={rewards.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                })}
                placeholder={translations.searchRewards}
                control={control}
                isRequired={true}
                error={error}
                disabled={disabled}
                noOptionsMsg={translations.noOptionRewards}
                isClearable
            />
        );
    } else if (name === 'expiry_date' || name === 'used_at') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText="Select a date..."
                name={name}
                defaultValue={value}
                control={control}
                disabled={true}
                error={error}
            />
        );
    } else if (name === 'voucher_code') {
        return (
            <Input
                key={index}
                control={control}
                label={translateLabel('voucher_code_table_single')}
                disabled={disabled}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
            />
        );
    } else if (name === 'order_id') {
        console.log(value);
        return (
            <Input
                key={index}
                control={control}
                label={translateLabel(name)}
                disabled={selectedItem || disabled}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
            />
        );
    } else if (name === 'voucher_type') {
        return (
            (type === 'consumable' || type === 'shop_item' || type === 'reward') && (
                <Select
                    key={index}
                    name={name}
                    label={translateLabel(name)}
                    options={voucherTypes.map((item) => {
                        return {
                            value: item.value,
                            label: item.label,
                        };
                    })}
                    defaultValue={voucherTypes[0]}
                    control={control}
                    isRequired={true}
                    error={error}
                    disabled={selectedItem || disabled}
                    noOptionsMsg={translations.noOptionRewards}
                />
            )
        );
    } else if (typeof value === 'string' || typeof value === 'number') {
        return (
            <Input
                key={index}
                control={control}
                label={translateLabel(name)}
                disabled={disabled}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                    min:
                        name === 'quantity'
                            ? {
                                  value: 1,
                                  message: translations.positive_number,
                              }
                            : undefined,
                })}
                error={error}
            />
        );
    } else if (name === 'eguma') {
        return (
            <Checkbox
                key={index}
                text={translateLabel(name)}
                name={name}
                {...register(name, { required: false })}
            ></Checkbox>
        );
    }
}
