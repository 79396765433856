import api from './apiLibrary';

const API = {
    getScanners(params) {
        return api.get('/api/admin/scanner', params);
    },
    getScanner(id, params) {
        return api.get(`/api/admin/scanner/${id}`, params);
    },
    createScanner(data) {
        return api.post('/api/admin/scanner', data);
    },
    editScanner(id, data) {
        return api.put(`/api/admin/scanner/${id}`, data);
    },
    deleteScanner(id) {
        return api.delete(`/api/admin/scanner/${id}`);
    },
};

export default API;
