import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import translations from '../../translations/de';
import translateLabel from '../../helpers/translateLabel';
import InputFile from '../../components/InputFile/index.js';
import mandatoryFields from '../../configs/user';
import countries from '../../constants/countries';
import genderTypes from '../../constants/genderTypes';

export default function geFanFormInput({
    value,
    name,
    register,
    control,
    error,
    disabled,
    index,
    resetValue,
    players,
    profiles,
    searchQueryHandler,
    bottomReached,
    onBlur,
    isLoading,
    currentUser = null,
}) {
    const isSuperAdmin = currentUser?.roles?.find((user) => user === 'SUPER_ADMIN') ?? false;

    if (name === 'birthday') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                error={error}
                control={control}
                disabled={disabled}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (typeof value === 'boolean') {
        return (
            <Checkbox
                key={index}
                text={translateLabel(name)}
                name={name}
                {...register(name, { required: false })}
                disabled={disabled}
            />
        );
    } else if (name === 'country') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name="country"
                options={countries}
                control={control}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'gender') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name="gender"
                options={genderTypes}
                error={error}
                control={control}
                disabled={disabled}
            />
        );
    } else if (name === 'favourite_player_id') {
        return (
            <Select
                key={index}
                label={translateLabel('favourite_player')}
                name={name}
                error={error}
                control={control}
                disabled={disabled}
                options={players}
                placeholder="Select Player..."
                isClearable
            />
        );
    } else if (name === 'avatar') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/fans"
            />
        );
    } else if (name === 'roles') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name="roles"
                options={[
                    { label: 'User', value: 'USER' },
                    { label: 'Business', value: 'BUSINESS' },
                ]}
                control={control}
                error={error}
                disabled={disabled || !isSuperAdmin}
                multiple={true}
            />
        );
    } else if (name === 'new_password') {
        return !disabled && isSuperAdmin ? (
            <Input
                key={index}
                ispassword={true}
                label={translateLabel(name.replace('new_', ''))}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                        message:
                            'Password must be at least 9 characters and include one lowercase letter, one uppercase letter, and one digit.',
                    },
                })}
                error={error}
                disabled={disabled}
            />
        ) : null;
    } else if (name === 'business_profile_id') {
        return (
            <Select
                key={index}
                onInputChange={searchQueryHandler}
                name={name}
                label={translateLabel(translations.business_profile)}
                options={profiles.map((profile) => {
                    return {
                        value: profile.id,
                        label: `${profile.first_name} ${profile.last_name}`,
                    };
                })}
                placeholder={translations.type_to_search_profiles}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg={translations.no_profiles_found}
                isClearable
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                bottomReached={bottomReached}
                onBlur={onBlur}
                isLoading={isLoading}
            />
        );
    } else if (typeof value === 'string' || typeof value === 'number') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
