import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../components/Input';
import Button from '../../components/Button';
import translations from '../../translations/de';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import ErrorMessages from '../../components/ErrorMessages.js';
import API from '../../api/accesses.js';
import translateLabel from '../../helpers/translateLabel';
import { FaLongArrowAltRight } from 'react-icons/fa';
import openLink from '../../helpers/printTicket';

const SmsForm = ({ access, showModal }) => {
    const {
        handleSubmit,
        formState: { errors },
        register,
        control,
    } = useForm({
        defaultValues: {
            phone: access?.phone ? access.phone : '',
        },
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const submitHandler = async (formData) => {
        setLoading(true);
        const sendSms = async () => {
            try {
                const res = await API.sendSmsTicket(access.id, {
                    phone: formData.phone,
                });
                if (res.status === 200 || res.status === 201) {
                    showModal(false);
                    setTimeout(() => {
                        alert('E-Ticket erfolgreich versendet!');
                    }, 300);
                    setLoading(false);
                } else {
                    setError(res.statusText);
                }
            } catch (e) {
                setLoading(false);
                setError(e);
            }
        };
        sendSms();
    };

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <div className="sms-form">
                <p className="link-label" style={{ margin: '0' }}>
                    Link:
                </p>
                <p
                    className="Input"
                    onClick={() =>
                        openLink(`https://my.scb.ch/eticket/${access.access_token}`, true)
                    }
                >
                    {' '}
                    <FaLongArrowAltRight /> {`https://my.scb.ch/eticket/${access.access_token}`}
                </p>
            </div>
            <Input
                key={'phone'}
                label={translateLabel('phone')}
                name="phone"
                control={control}
                error={errors.phone}
                {...register('phone', {
                    required: true,
                    pattern: {
                        value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/,
                        message: translateLabel('wrong_phone_format'),
                    },
                })}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    label={translations.cancel}
                    type="button"
                    style={{ marginRight: '20px' }}
                    onClick={() => showModal(false)}
                />
                <Button loading={loading} label={translations.save} type="submit"></Button>
            </div>
            <ErrorMessages
                messages={requsetErrorMessage(error && error.message ? error.message : error)}
            />
        </form>
    );
};

export default SmsForm;
