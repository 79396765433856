import { types, flow, applySnapshot } from 'mobx-state-tree';
import User from './../usersStore/User';
import API from '../../api/notifications';

const Notification = types
    .model('Notification', {
        id: types.identifierNumber,
        title: types.maybeNull(types.string),
        image: types.maybeNull(types.string),
        text: types.maybeNull(types.string),
        recipients: types.optional(types.array(User), []),
        totalRecipeintsPages: types.optional(types.number, 0),
        selectedRecipeintsPage: types.optional(types.number, 1),
        type: types.maybeNull(types.string),
    })
    .actions((self) => ({
        getRecipients: flow(function* getRecipients() {
            try {
                const res = yield API.getRecipients(self.id, {
                    params: {
                        limit: 10,
                        page: self.selectedRecipeintsPage,
                    },
                });
                self.recipients = res.data.items;
                self.totalRecipeintsPages = res.data.total_pages_count;
            } catch (error) {
                throw error.message;
            }
        }),
        editNotification(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
        setSelectedPage(page) {
            self.selectedRecipeintsPage = page;
        },
    }));

export default Notification;
