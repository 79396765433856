import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import getContactFormInput from './getContactFormInput';
import translations from '../../translations/de';
import API from '../../api/users';
import debounce from 'lodash.debounce';
import uniqueById from '../../helpers/uniqueById';

const NewContact = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await API.getUsers({
                    params: {
                        page: page,
                        search: searchQuery,
                        limit: 10,
                    },
                });
                if (page === 1) {
                    setUsers(uniqueById(res.data.items));
                } else {
                    setUsers((users) => uniqueById([...users, ...res.data.items]));
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        searchQuery && getUsers().then(() => setLoading(false));
    }, [page, searchQuery]);

    useEffect(() => {
        if (isLoading) {
            setPage((page) => page + 1);
        }
    }, [isLoading]);

    const searchQueryHandler = debounce((value) => {
        setSearchQuery(value);
    }, 500);

    const bottomReached = () => {
        setIsLoading(true);
    };

    return (
        <Item
            links={[{ path: Paths.CONTACTS, name: translations.contacts }]}
            name={`${translations.contact} ${translations.create}`}
            itemModel={{
                user_id: '',
                phone: '',
                first_name: '',
                last_name: '',
                street: '',
                zip: '',
                city: '',
                country: '',
                email: '',
                birthday: '',
            }}
            edit={true}
            onSubmit={(event) => {
                error && setError(null);
                setLoading(true);
                store
                    .addContact(event)
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.CONTACTS);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, disabled, index }) =>
                getContactFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    disabled,
                    index,
                    users,
                    searchQueryHandler,
                    bottomReached: bottomReached,
                    onBlur: () => setPage(1),
                    isLoading: isLoading,
                })
            }
        ></Item>
    );
};

export default NewContact;
