import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import { FaTrash, FaPlus, FaSearch, FaUndoAlt } from 'react-icons/fa';
import Pagination from './../../components/Pagination';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';
import partnerTypes from '../../constants/partnerTypes';
import ItemImage from '../../components/ItemImage';
import ExportBtn from '../../components/ExportBtn';
import API from '../../api/businessPartners';

const BusinessPartners = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [partnerID, setPartnerID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);
    const [partnerType, setPartnerType] = useState('');

    useEffect(() => {
        store.selectPartner(undefined); // clear up
        store.getPartners(searchQuery, partnerType);
    }, [store, store.selectedPage, searchQuery, partnerType]);

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 300);

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const handlePartner = (id) => {
        store.selectPartner(id);
        history.push(`/business-partners/${store.selectedPartner.id}`);
    };

    return (
        <>
            <div className="tab-controls">
                <Input
                    label="Business Partner Name"
                    placeholder={translations.search}
                    defaultValue={searchQuery}
                    onChange={onSearchChangeHandler}
                />
                <Select
                    label="Business Partner Type"
                    options={partnerTypes}
                    onChangeCallback={(e) => {
                        setPartnerType(e.value);
                        store.setSelectedPage(1);
                    }}
                    placeholder={translations.select}
                />
            </div>
            <div className="section-actions">
                <Button
                    label={translations.business_partner}
                    onClick={() => {
                        store.selectPartner(undefined);
                        store.resetProfiles();
                        history.push(Paths.NEW_BUSINESS_PARTNER);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
                <ExportBtn fileName='partners' apiFunc={() => API.exportPartners()} style={{ marginRight: '2.5%' }} />
            </div>
            {store.partners && store.partners.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName={'Partners'}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.company,
                            accessor: 'name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.image,
                            accessor: 'logo',
                            Cell: ({ value, row }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="avatar" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.type,
                            accessor: 'type',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.address,
                            accessor: 'address',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handlePartner(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    {isNotDeletedAlready(row) ? (
                                        <Button
                                            onClick={() => {
                                                setPartnerID(row.cells[0].value);
                                                setModal(true);
                                            }}
                                            title={translations.delete}
                                        >
                                            <FaTrash />
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => store.revivePartner(row.cells[0].value)}
                                        >
                                            <FaUndoAlt />
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    data={store.partners.map(({ id, name, logo, type, address, deleted_at }) => {
                        return {
                            id,
                            name,
                            logo,
                            type,
                            address,
                            deleted_at,
                        };
                    })}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Partner"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this partner?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deletePartner(partnerID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(BusinessPartners);
