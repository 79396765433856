import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import { FaPlus, FaSearch } from 'react-icons/fa';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';
import Select from '../../components/Select';

const Teams = ({ store }) => {
    const history = useHistory();
    const [league, setLeague] = useState({ label: 'National', value: 'national' });
    // league='national'
    useEffect(() => {
        store.getTeams(league.value);
    }, [store, league]);

    const handleTeam = (id) => {
        store.selectTeam(id);
        history.push(`/teams/${id}`);
    };

    return (
        <>
            <div className="tab-controls">
                <Select
                    label="League"
                    defaultValue={league}
                    options={[
                        { label: 'National', value: 'national' },
                        { label: 'Other', value: 'other' },
                    ]}
                    onChange={(e) => {
                        setLeague(e);
                    }}
                />
            </div>
            <div style={{ marginLeft: '2.5%' }}>
                <Button
                    label={translations.team}
                    onClick={() => {
                        store.selectTeam(undefined);
                        history.push(Paths.NEW_TEAM);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {store.teams && store.teams.length !== 0 && (
                <Table
                    tableName={translations.teams}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.logo,
                            accessor: 'logo_url',
                            Cell: ({ value, row }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="team" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.title,
                            accessor: 'title',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleTeam(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.teams.map(({ id, logo_url, title }) => {
                        return {
                            id,
                            logo_url,
                            title,
                        };
                    })}
                />
            )}
        </>
    );
};

export default observer(Teams);
