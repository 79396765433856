import { types, applySnapshot } from 'mobx-state-tree';
import Game from './../fanGamesStore/Game';
import Match from './../matchesStore/Match';

const Season = types
    .model('Season', {
        id: types.identifierNumber,
        title: types.optional(types.string, ''),
        current: types.optional(types.boolean, false),
        fan_games: types.optional(types.array(Game), []),
        games: types.optional(types.array(Match), []),
    })
    .actions((self) => ({
        editSeason(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Season;
