import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import { FaTrash, FaPlus, FaSearch } from 'react-icons/fa';
import translations from '../../translations/de';
import Loader from '../../components/Loader';
import Select from '../../components/Select';
import ItemImage from '../../components/ItemImage';
import InputSeason from '../../components/InputSeason/InputSeason';
import { IoMdRefresh } from 'react-icons/io';

const options = [
    { value: 'all', label: translations.all },
    { value: 'scb_home', label: translations.scb_home },
    { value: 'scb', label: 'SCB' },
];

const Matches = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [matchID, setMatchID] = useState(null);
    const [filter, setFilter] = useState(
        sessionStorage.getItem('match_filter')
            ? sessionStorage.getItem('match_filter')
            : 'scb_home',
    );
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);

    var nextMatchID = null;

    const handleMatch = (id) => {
        store.selectMatch(id);
        history.push(`/matches/${store.selectedMatch.id}`);
    };

    useEffect(() => {
        sessionStorage.setItem('match_filter', filter);
        filter && store.getMatches(filter, selectedSeason);
    }, [store, filter, selectedSeason]);

    //  scroll to the game with today's date
    nextMatchID = store.findNextMatch();
    return (
        <>
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        marginLeft: '2.5%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        label={translations.match}
                        onClick={() => {
                            store.selectMatch(undefined);
                            history.push(Paths.NEW_MATCH);
                        }}
                    >
                        <FaPlus style={{ marginRight: '5px' }} />
                    </Button>

                    <div
                        style={{
                            marginLeft: '20px',
                            width: '220px',
                            height: '40px',
                        }}
                    >
                        <Select
                            options={options}
                            isSearchable={false}
                            defaultValue={options.find((option) => option.value === filter)}
                            onChange={(e) => setFilter(e.value)}
                        />
                    </div>
                    <div style={{ width: '220px', marginLeft: '20px', height: '40px' }}>
                        <InputSeason
                            hideLabel={true}
                            selectedSeason={selectedSeason}
                            setSelectedSeason={setSelectedSeason}
                            setAllSeasons={setSeasons}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'flex-end',
                            marginRight: '2.5%',
                            marginLeft: '2.5%',
                            height: '40px',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Button
                            disabled={!store.dataLoaded}
                            label={translations.update_games}
                            onClick={() => store.updateMatches(seasons, selectedSeason, filter)}
                        >
                            <IoMdRefresh
                                className={`${!store.dataLoaded ? 'refreshing' : 'solid'}`}
                            />
                        </Button>
                    </div>
                </div>
                {!store.dataLoaded ? (
                    <Loader />
                ) : (
                    <>
                        {store.matches && store.matches.length !== 0 && (
                            <Table
                                tableName={translations.matches}
                                nextMatchID={nextMatchID}
                                columns={[
                                    {
                                        Header: 'ID',
                                        accessor: 'id',
                                        Cell: ({ value, row }) => <div>{value}</div>,
                                    },
                                    {
                                        Header: translations.home_team,
                                        accessor: 'home_team',
                                        Cell: ({ value, row }) => (
                                            <div>
                                                <ItemImage
                                                    imgSrc={value}
                                                    alt="answer"
                                                    listView={true}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: translations.away_team,
                                        accessor: 'away_team',
                                        Cell: ({ value, row }) => (
                                            <div>
                                                <ItemImage
                                                    imgSrc={value}
                                                    alt="answer"
                                                    listView={true}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        Header: translations.date,
                                        accessor: 'date',
                                        Cell: ({ row }) => (
                                            <div>{store.getMatchDate(row.cells[0].value)}</div>
                                        ),
                                    },
                                    {
                                        Header: translations.time,
                                        accessor: 'time',
                                        Cell: ({ row }) => (
                                            <div>{store.getMatchTime(row.cells[0].value)}</div>
                                        ),
                                    },
                                    {
                                        Header: translations.score,
                                        accessor: 'score',
                                        Cell: ({ value, row }) => <div>{value}</div>,
                                    },
                                    {
                                        Header: '',
                                        accessor: 'action',
                                        Cell: ({ row }) => (
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        handleMatch(row.cells[0].value);
                                                    }}
                                                    title={translations.show}
                                                >
                                                    <FaSearch />
                                                </Button>
                                                <Button
                                                    title={translations.delete}
                                                    onClick={() => {
                                                        setMatchID(row.cells[0].value);
                                                        setModal(true);
                                                    }}
                                                >
                                                    <FaTrash />
                                                </Button>
                                            </div>
                                        ),
                                    },
                                ]}
                                data={store.matches.map(
                                    ({
                                        id,
                                        home_team,
                                        away_team,
                                        score_away_team,
                                        score_home_team,
                                        date,
                                    }) => {
                                        return {
                                            id,
                                            home_team: home_team.logo_url,
                                            away_team: away_team.logo_url,
                                            score:
                                                new Date(date) < new Date()
                                                    ? `${score_home_team} : ${score_away_team}`
                                                    : '',
                                        };
                                    },
                                )}
                            />
                        )}
                    </>
                )}

                {modal && (
                    <Modal
                        title="Delete Match"
                        ref={modalRef}
                        onClose={() => setModal(false)}
                        maxWidth="400px"
                    >
                        <p style={{ color: '#333', marginBottom: '20px' }}>
                            Are you sure you want to delete this match?
                        </p>
                        <div className="Modal-Controls">
                            <Button
                                label="Yes"
                                onClick={() => {
                                    store.deleteMatch(matchID);
                                    setModal(false);
                                }}
                                style={{ width: '80px' }}
                            />
                            <Button
                                label="No"
                                onClick={() => setModal(false)}
                                style={{ width: '80px', marginLeft: '20px' }}
                            />
                        </div>
                    </Modal>
                )}
            </div>
        </>
    );
};

export default observer(Matches);
