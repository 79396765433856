import { useState, useEffect } from 'react';
import getRewardInput from '../../components/Rewards/getRewardInput';
import { observer } from 'mobx-react-lite';
import clearObjProps from '../../helpers/clearObjProps';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import { useParams } from 'react-router-dom';
import Item from '../../components/Item';
import ItemImage from '../../components/ItemImage';
import Breadcrumb from '../../components/Breadcrumb';
import TabSwitcher from '../../components/TabSwitcher';
import Acquirers from '../../components/Acquirers/Acquirers';
import Loader from '../../components/Loader';
import RewardVouchers from '../../components/RewardVouchers/RewardVouchers';

const Reward = ({ store }) => {
    const { reward_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [resetProp, setResetProp] = useState(null);
    const [unregisterProp, setUnregisterProp] = useState(undefined);
    const rewardModel = clearObjProps(
        {
            ...store.selectedReward,
        },
        ['id', 'use_unused', 'order_id'],
    );

    const handleTypeChange = (e, getValues) => {
        var formValues = getValues;
        switch (e) {
            case 'consumable':
                setResetProp({ ...formValues, fan_coins: 0 });
                break;
            case 'coins':
                setUnregisterProp('voucher_type');
                break;
            default:
                setResetProp({ ...formValues, fan_coins: 0 });
                setUnregisterProp('voucher_type');
        }
    };

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editReward({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        store.getReward(reward_id);
        return () => {
            store.selectReward(undefined);
        };
    }, [store, reward_id]);


    return store.dataLoaded ? (
        store.selectedReward ? (
            <>
                <div className="shop_item-header">
                    <Breadcrumb
                        name={store.selectedReward.name}
                        links={[
                            {
                                path: Paths.REWARDS,
                                name: translations.rewards,
                            },
                        ]}
                    />
                </div>
                <TabSwitcher initialTabName={translations.general}>
                    <TabSwitcher.Tabs>
                        <TabSwitcher.TabPill tabName={translations.general} />
                        <TabSwitcher.TabPill tabName={translations.acquirer} />
                        <TabSwitcher.TabPill tabName={translations.vouchers} />
                    </TabSwitcher.Tabs>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.general}>
                        <Item
                            hasNavigation={false}
                            id={`[ reward_ID: ${store.selectedReward.id}]`}
                            itemModel={{
                                ...rewardModel,
                                used: rewardModel.type === 'consumable' ? rewardModel.used : '/',
                            }}
                            edit={edit}
                            onSubmit={onSubmitHandler}
                            onEdit={() => setEdit(true)}
                            onCancel={() => {
                                error && setError(null);
                                setEdit(false);
                            }}
                            error={error}
                            loading={loading}
                            determineInputType={({
                                value,
                                name,
                                register,
                                error,
                                disabled,
                                index,
                                control,
                                getValues,
                            }) =>
                                getRewardInput({
                                    value,
                                    name,
                                    register,
                                    error,
                                    disabled,
                                    index,
                                    resetValue: (name, value) => setResetProp({ [name]: value }),
                                    control,
                                    handleTypeChange: (e) => handleTypeChange(e, getValues),
                                    type: store?.selectedReward?.type,
                                    voucherType: store?.selectedReward?.voucher_type,
                                    comesFromEdit: edit,
                                })
                            }
                            resetProp={resetProp}
                            unregisterProp={unregisterProp}
                        >
                            <ItemImage imgSrc={rewardModel.image_url} />
                        </Item>
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.acquirer}>
                        <Acquirers
                            reward_id={reward_id}
                            date_of_expire={store.selectedReward.expire_at}
                        />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.vouchers}>
                        {' '}
                        <RewardVouchers />
                    </TabSwitcher.TabPanel>
                </TabSwitcher>
            </>
        ) : null
    ) : (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {' '}
            <Loader size="big" theme="dark" />
        </div>
    );
};

export default observer(Reward);
