import React, { forwardRef } from 'react';
import Loader from './../Loader';
import './Button.scss';

const loaderStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
};

const Button = forwardRef(
    (
        {
            label = '',
            loading = false,
            theme = '',
            fullwidth = false,
            marginRight = false,
            children,
            disabled,
            ...restProps
        },
        ref,
    ) => (
        <button
            ref={ref}
            disabled={loading || disabled}
            className={[
                'Button',
                theme === 'dark' ? 'dark' : '',
                theme === 'light' ? 'light' : '',
                theme === 'gray' ? 'gray' : '',
                theme === 'export' ? 'export' : '',
                loading ? 'loader' : '',
                fullwidth ? 'fullwidth' : '',
                marginRight ? 'marginRight' : '',
            ].join(' ')}
            {...restProps}
        >
            {!loading && children}
            {label && <span>{label}</span>}

            {loading && <Loader style={loaderStyle} theme={theme === 'light' ? 'dark' : ''} />}
        </button>
    ),
);

export default Button;
