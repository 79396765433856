import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import { FaPlus, FaTrash, FaSearch, FaUndoAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import Pagination from './../../components/Pagination';
import translations from '../../translations/de';
import Input from '../../components/Input';
import debounce from 'lodash.debounce';
import { formatTime, formatDate } from '../../helpers/formatDate';

const Feeds = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [feedID, setFeedID] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleFeed = (id) => {
        store.selectFeed(id);
        history.push(`${Paths.FEEDS}/${id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const handleActive = (id, active) => {
        store.editActive(id, !active);
    };

    useEffect(() => {
        store.getFeeds(searchQuery);
    }, [store, store.selectedPage, searchQuery]);
    return (
        <>
            <div className="tab-controls">
                <Input placeholder={translations.search} onChange={onSearchChangeHandler} />
            </div>
            <div className="tab-controls">
                <Button
                    label={translations.feed}
                    onClick={() => {
                        store.selectFeed(undefined);
                        history.push(Paths.NEW_FEED);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {store.feeds && store.feeds.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName={translations.feeds}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.created_at,
                            accessor: 'created_at',
                            Cell: ({ value }) => (
                                <div>{`${formatDate(value)} ${formatTime(value)}`}</div>
                            ),
                        },
                        {
                            Header: translations.updated_at,
                            accessor: 'updated_at',
                            Cell: ({ value }) => (
                                <div>{`${formatDate(value)} ${formatTime(value)}`}</div>
                            ),
                        },
                        {
                            Header: translations.screen_title,
                            accessor: 'screen_title',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.type,
                            accessor: 'type',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ value, row }) => (
                                <div className="button-cell">
                                    <Button
                                        onClick={() => {
                                            handleFeed(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    {value ? (
                                        <Button
                                            onClick={() => handleActive(row.cells[0].value, value)}
                                        >
                                            <FaEye />
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => handleActive(row.cells[0].value, value)}
                                        >
                                            <FaEyeSlash />
                                        </Button>
                                    )}
                                    {isNotDeletedAlready(row) ? (
                                        <Button
                                            onClick={() => {
                                                setFeedID(row.cells[0].value);
                                                setModal(true);
                                            }}
                                            title={translations.delete}
                                        >
                                            <FaTrash />
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => store.reviveFeed(row.cells[0].value)}
                                        >
                                            <FaUndoAlt />
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    data={store.feeds.map(
                        ({ id, created_at, show, updated_at, type, screen_title, deleted_at }) => {
                            return {
                                id,
                                created_at,
                                updated_at,
                                screen_title,
                                type,
                                deleted_at,
                                action: show,
                            };
                        },
                    )}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Feed"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete this feed with id ${feedID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteFeed(feedID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(Feeds);
