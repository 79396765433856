import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import getContactFormInput from './getContactFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import { useParams, useHistory } from 'react-router-dom';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import API from '../../api/users';
import debounce from 'lodash.debounce';
import uniqueById from '../../helpers/uniqueById';

const Contact = ({ store }) => {
    const history = useHistory();
    const { contact_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        store.selectContact(undefined);
        store.getContact(contact_id).then(() => {
            setUsers([{ ...store.selectedContact.user }]);
            setLoading(false);
        });
    }, [store, contact_id]);

    useEffect(() => {
        return history.listen(() => {
            if (history.action === 'POP') {
                history.push('/contacts');
            }
        });
    }, [history]);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editContact({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    const bottomReached = () => {
        !isLoading && setIsLoading(true);
    };

    const searchQueryHandler = debounce((value) => {
        setSearchQuery(value);
    }, 500);

    useEffect(() => {
        if (isLoading) {
            setPage((page) => page + 1);
        }
    }, [isLoading]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await API.getUsers({
                    params: {
                        page: page,
                        search: searchQuery,
                        limit: 10,
                    },
                });
                if (page === 1) {
                    setUsers(uniqueById(res.data.items));
                } else {
                    setUsers((users) => uniqueById([...users, ...res.data.items]));
                }
            } catch (error) {
                console.log(error);
            }
        };

        searchQuery && getUsers();
        setIsLoading(false);
    }, [page, searchQuery]);

    return (
        <>
            {store?.selectedContact ? (
                <Item
                    links={[{ path: Paths.CONTACTS, name: translations.contacts }]}
                    name={`${store.selectedContact.first_name} ${store.selectedContact.last_name}`}
                    id={`[ contact_ID: ${store.selectedContact.id} ]`}
                    itemModel={clearObjProps({ ...store.selectedContact }, ['id', 'user'])}
                    edit={edit}
                    onSubmit={onSubmitHandler}
                    onEdit={() => setEdit(true)}
                    onCancel={() => {
                        error && setError(null);
                        setEdit(false);
                    }}
                    error={error}
                    loading={loading}
                    determineInputType={({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                    }) =>
                        getContactFormInput({
                            value,
                            name,
                            register,
                            control,
                            error,
                            disabled,
                            index,
                            users,
                            searchQueryHandler,
                            isLoading,
                            bottomReached: bottomReached,
                            onBlur: () => setPage(1),
                        })
                    }
                ></Item>
            ) : null}
        </>
    );
};

export default observer(Contact);
