import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import { FaLongArrowAltRight } from 'react-icons/fa';
import translations from '../../translations/de';
import Loader from '../../components/Loader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Paths from '../../constants/paths';

const Contacts = ({ store, user_id }) => {
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        store?.getContacts(user_id).then(() => setLoading(false));
    }, [store, user_id]);

    const handleReddirect = (id) => {
        history.push(`${Paths.CONTACTS}/${id}`);
    };

    return !loading ? (
        <>
            {store.contacts && store.contacts.length !== 0 && (
                <Table
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ row, value }) => (
                                <div onClick={() => handleReddirect(row.cells[0].value)}>
                                    {value && (
                                        <div style={{ color: 'blue', cursor: 'pointer' }}>
                                            <FaLongArrowAltRight />
                                            {value}
                                        </div>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.zip,
                            accessor: 'zip',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.city,
                            accessor: 'city',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                    ]}
                    data={store.contacts.map(({ id, first_name, last_name, zip, city }) => {
                        return {
                            id,
                            name: `${first_name ?? ''} ${last_name ?? ''}`,
                            zip,
                            city,
                        };
                    })}
                />
            )}
        </>
    ) : (
        <div className="loader-wrapper">
            <Loader size="big" theme="dark" />
        </div>
    );
};

export default observer(Contacts);
