import React, { useEffect } from 'react';
import Reward from '../../components/Rewards/Reward';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
const Index = ({ store }) => {
    const { fan_game_id } = useParams();

    useEffect(() => {
        store.getFanGame(fan_game_id);
    }, [store, fan_game_id]);

    return (
        <div className="Rewards">
            <Reward rewardStore={store} />
        </div>
    );
};

export default observer(Index);
