import React, { forwardRef } from 'react';
import './Textarea.scss';
import translations from '../../translations/de';

const Textarea = forwardRef(
    ({ json = false, value, label = '', error = false, ...restProps }, ref) => {
        return (
            <div className="Textarea">
                {label && (
                    <div className="label">
                        <span>{label} </span>
                    </div>
                )}

                <textarea
                    ref={ref}
                    className={`${json ? 'json-area' : ''} ${error ? 'error' : ''}`}
                    autoComplete="off"
                    {...restProps}
                />
                <span className="error-msg">{error && <p>{translations.required}</p>}</span>
            </div>
        );
    },
);

export default Textarea;
