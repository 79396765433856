import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import Page from '../../components/Page';
import News from './News.js';
import SingleNews from './SingleNews';
import CreateNews from './CreateNews';

const index = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.CREATE_NEWS}>
                <CreateNews store={store} />
            </Route>
            <Route exact path={Paths.NEWS}>
                <News store={store} />
            </Route>
            <Route exact path={Paths.SINGLE_NEWS}>
                <SingleNews store={store} />
            </Route>
        </Page>
    );
};

export default memo(observer(index));
