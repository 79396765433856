import Select from './../Select';
import { observer } from 'mobx-react-lite';
import Button from './../Button';
import { useForm } from 'react-hook-form';

const StepOne = ({ store, onNext, gameTypes = [] }) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: { fan_game_type: store.fan_game_type },
    });

    return (
        <form>
            <p className="title">Game Type</p>
            <Select
                name="fan_game_type"
                options={gameTypes}
                isRequired={true}
                error={errors.fan_game_type}
                control={control}
            />
            <div className="controls">
                <Button
                    type="submit"
                    label="Next"
                    onClick={handleSubmit((e) => {
                        store.onChangeValue('fan_game_type', e.fan_game_type);
                        onNext();
                    })}
                />
            </div>
        </form>
    );
};

export default observer(StepOne);
