import api from './apiLibrary';

const API = {
    getAllRewards(params) {
        return api.get('/api/admin/reward/instant', params);
    },
    getReward(id, data) {
        return api.get(`/api/admin/reward/instant/${id}`, data);
    },
    createReward(data, params) {
        return api.post(`/api/admin/reward/instant`, data, { params });
    },
    editReward(id, data) {
        return api.put(`/api/admin/reward/${id}`, data);
    },
    deleteReward(id) {
        return api.delete(`/api/admin/reward/${id}`);
    },
};

export default API;
