import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams, useHistory } from 'react-router-dom';
import getShopFormInput from './getShopFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import ItemImage from '../../components/ItemImage';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import TabSwitcher from '../../components/TabSwitcher';
import Breadcrumb from '../../components/Breadcrumb';
import Sales from '../../components/Sales/Sales';
import SalesStatistics from '../../components/Sales/SalesStatistics';
import API from '../../api/shopItems';
import RewardVouchers from '../../components/RewardVouchers/RewardVouchers';

const ShopItem = ({ store }) => {
    const history = useHistory();
    const { item_id } = useParams();
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [data, setData] = useState([]);
    const shopItemModel = clearObjProps(
        {
            ...store.selectedItem,
            used: store?.selectedItem?.type === 'consumable' ? store.selectedItem.used : '/',
        },
        ['id', 'stock', 'active_num', 'available', 'expired'],
    );
    const [resetProp, setResetProp] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.getShopItem(item_id);
        return () => {
            store.selectItem(undefined);
        };
    }, [store, item_id]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        delete event.used;
        store
            .editShopItem({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        const getSales = async () => {
            try {
                const res = await API.getSales(item_id, {
                    params: { page: selectedPage, limit: 10 },
                });
                setData(res.data.items);
                setTotalPages(res.total_pages_count);
            } catch (e) {
                console.log(e);
            }
        };
        getSales();
    }, [item_id, selectedPage]);

    useEffect(() => {
        return history.listen(() => {
            if (history.action === 'POP') {
                history.replace({ pathname: '/shop' });
            }
        });
    }, [history]);

    return store.selectedItem ? (
        <>
            <div className="shop_item-header">
                <Breadcrumb
                    name={store.selectedItem.name}
                    links={[{ path: Paths.SHOP, name: translations.shop_items }]}
                />
            </div>
            <TabSwitcher initialTabName={translations.general}>
                {' '}
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName={translations.general} />
                    <TabSwitcher.TabPill tabName={translations.sales} />
                    <TabSwitcher.TabPill tabName={translations.statistics} />
                    <TabSwitcher.TabPill tabName={translations.voucher} />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName={translations.general}>
                    <Item
                        hasNavigation={false}
                        id={`[ item_ID: ${store.selectedItem.id} ]`}
                        itemModel={shopItemModel}
                        edit={edit}
                        onSubmit={onSubmitHandler}
                        onEdit={() => setEdit(true)}
                        onCancel={() => {
                            error && setError(null);
                            setEdit(false);
                        }}
                        error={error}
                        loading={loading}
                        determineInputType={({
                            value,
                            name,
                            register,
                            error,
                            disabled,
                            index,
                            control,
                        }) =>
                            getShopFormInput({
                                value,
                                name,
                                register,
                                error,
                                disabled,
                                index,
                                resetImageValue: (name, value) => setResetProp({ [name]: value }),
                                control,
                                isFromEditView: true,
                            })
                        }
                        resetProp={resetProp}
                    >
                        <ItemImage imgSrc={store.selectedItem.image_url} />
                    </Item>
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.sales}>
                    <Sales
                        data={data}
                        shop_item_id={item_id}
                        totalPages={totalPages}
                        setPage={(pageNum) => setSelectedPage(pageNum)}
                    />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.statistics}>
                    <SalesStatistics acquiredItem={store.selectedItem} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.voucher}>
                    <RewardVouchers />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </>
    ) : null;
};

export default observer(ShopItem);
