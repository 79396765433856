import React, { useState, useEffect } from 'react';
import APIShopItems from '../../api/shopItems';

import Item from '../../components/Item';
import ItemImage from '../../components/ItemImage';

import getShopFormInput from '../ShopItems/getShopFormInput';

const LotteryTicket = ({ lotteryStore }) => {
    const [ticket, setTicket] = useState();
    const [editTicket, setEditTicket] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resetProp, setResetProp] = useState();

    const onSubmitLotteryTicket = async (edited) => {
        setLoading(true);
        try {
            const res = await APIShopItems.editShopItem(ticket.id, edited);
            setTicket(res.data);
            setEditTicket(false);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }

        loading && editTicket();
    };

    useEffect(() => {
        const getTicket = async () => {
            try {
                const res = await APIShopItems.getShopItem(
                    lotteryStore?.selectedLotteryGame?.shop_item_id,
                );
                setTicket(res.data);
            } catch (e) {
                console.error(e);
            }
        };
        lotteryStore.selectedLotteryGame && getTicket();
    }, [lotteryStore]);

    return ticket ? (
        <Item
            hasNavigation={false}
            id={`[ Lottery_ticket_id: ${lotteryStore?.selectedLotteryGame?.shop_item_id} ]`}
            itemModel={{
                name: ticket.name,
                image_url: ticket.image_url,
                price: ticket.price,
                start_stock: ticket.start_stock,
                type: ticket.type,
                sold: ticket.sold,
                used: ticket.used,
                expire_at: ticket.expire_at,
                redeem_description: ticket.redeem_description,
                description: ticket.description,
                visible_until: ticket.visible_until,
                active: ticket.active,
                deactivate_on_sold: ticket.deactivate_on_sold,
            }}
            edit={editTicket}
            onSubmit={onSubmitLotteryTicket}
            onEdit={() => setEditTicket(true)}
            onCancel={() => {
                setEditTicket(false);
            }}
            loading={loading}
            determineInputType={({ value, name, register, error, disabled, index, control }) =>
                getShopFormInput({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    resetImageValue: (name, value) => setResetProp({ [name]: value }),
                    control,
                    isFromEditView: true,
                })
            }
            resetProp={resetProp}
        >
            <ItemImage imgSrc={ticket.image_url} />
        </Item>
    ) : null;
};

export default LotteryTicket;
