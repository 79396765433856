import { useState, useEffect } from 'react';
import getRewardInput from './getRewardInput';
import Button from '../Button';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import clearObjProps from '../../helpers/clearObjProps';
import ErrorMessages from '../ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import Breadcrumb from '../Breadcrumb';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import FindRewardTemplate from '../FindRewardTemplate';

const Reward = ({ rewardStore }) => {
    const { reward_id } = useParams();
    const rewardObj = clearObjProps(
        {
            ...rewardStore?.selectedGame?.rewards?.find(
                (reward) => reward.id === JSON.parse(reward_id),
            ),
        },
        ['id', 'use_unused'],
    );

    var reward = Object.keys(rewardObj).map((key) => {
        return { name: key, value: rewardObj[key] };
    });

    rewardObj.used = rewardObj?.type === 'consumable' ? rewardObj.type : '/';

    const [type, setType] = useState(rewardObj?.type);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [resetProp, setResetProp] = useState(null);

    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
        getValues,
    } = useForm({
        defaultValues: rewardObj,
    });

    const onSave = (id, event) => {
        setLoading(true);

        if (id) {
            rewardStore.selectedGame
                .editReward(id, event)
                .then(() => {
                    setEdit(false);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log('error');
                    setError(err);
                    setLoading(false);
                    setEdit(false);
                });
        } else {
            rewardStore.selectedGame
                .addReward(event)
                .then()
                .catch((err) => {
                    setError(err);
                    setLoading(false);
                });
        }
    };

    const resetForm = () => {
        setEdit(false);
        reset();
    };

    const handleTypeChange = (e) => {
        var formValues = getValues();
        switch (e) {
            case 'consumable':
                reset({ ...formValues, fan_coins: 0 });
                break;
            case 'coins':
                unregister('voucher_type');
                break;
            default:
                reset({ ...formValues, fan_coins: 0 });
                unregister('voucher_type');
        }
        setType(e);
    };

    useEffect(() => {
        if (resetProp) {
            reset({ ...watch(), ...resetProp });
        }
    }, [resetProp, reset, watch]);

    return rewardObj ? (
        <div className="Item">
            <div className="Item-Header">
                <Breadcrumb
                    name={translations.reward}
                    links={[
                        { path: Paths.FAN_GAMES, name: translations.fan_games },
                        {
                            path: `${Paths.FAN_GAMES}/${rewardStore?.selectedGame?.id}`,
                            name: rewardStore?.selectedGame?.name,
                        },
                    ]}
                />
                <div className="Item-Header-Controls">
                    {edit ? (
                        <>
                            <Button
                                type="button"
                                label={translations.cancel}
                                onClick={() => resetForm()}
                            />
                            <Button
                                style={{ marginLeft: '10px' }}
                                label={translations.save}
                                type="submit"
                                onClick={handleSubmit((e) => onSave(reward_id, e))}
                                loading={loading}
                            />
                        </>
                    ) : (
                        <Button
                            type="button"
                            label={translations.edit}
                            onClick={() => setEdit(true)}
                        />
                    )}
                </div>
            </div>
            <div style={{ paddingTop: '2%' }} />

            <div className="Item-Content">
                {!rewardStore.selectedGame && (
                    <div style={{ width: '49%' }}>
                        <FindRewardTemplate
                            setResetProp={setResetProp}
                            rewardObj={rewardObj}
                            setType={(e) => setType(e)}
                        />
                    </div>
                )}

                <form>
                    <div className="Reward-Form">
                        {rewardObj.length !== 0 &&
                            reward.map((data, index) =>
                                getRewardInput({
                                    disabled: !edit,
                                    value: data.value,
                                    name: data.name,
                                    register,
                                    control,
                                    error: errors[data.name],
                                    index,
                                    resetValue: (name, value) => setResetProp({ [name]: value }),
                                    handleTypeChange: (e) => handleTypeChange(e),
                                    type,
                                    comesFromEdit: !edit,
                                }),
                            )}
                    </div>

                    <ErrorMessages
                        messages={requsetErrorMessage(
                            error && error.message ? error.message : error,
                        )}
                    />
                </form>
            </div>
        </div>
    ) : (
        <Loader theme="dark" size="big" />
    );
};

export default observer(Reward);
