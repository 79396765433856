const partnerTypes = [
    { label: 'Kunde', value: 'Kunde' },
    { label: 'Partner', value: 'Partner' },
    { label: 'Member', value: 'Member' },
    { label: 'Silbersponsor', value: 'Silbersponsor' },
    { label: 'Goldsponsor', value: 'Goldsponsor' },
    { label: 'Hauptsponsor', value: 'Hauptsponsor' },
    { label: 'Andere', value: 'Andere' },
];

export default partnerTypes;
