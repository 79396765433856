import apiLibrary from './apiLibrary';

const API = {
    getVersions(params) {
        return apiLibrary.get('/api/version/min', params);
    },
    setVersion(version) {
        return apiLibrary.post('/api/admin/version/set', version);
    },
};

export default API;
