import React, { forwardRef } from 'react';
import deviceImg from './../../assets/images/phone_device.png';
import './EventView.scss';
import { FaTimes } from 'react-icons/fa';
import { formatDate, formatTime } from '../../helpers/formatDate';

const EventView = forwardRef(({ onClose, store }, ref) => {
    return (
        <div className="EventView">
            <div className="device" ref={ref}>
                <img src={deviceImg} alt="phone" />
                <div className="content-wrapper">
                    <div className="content">
                        <div className="content-header">
                            <p>{formatDate(store.start, true)}</p>
                            <FaTimes className="close-btn" onClick={onClose} />
                        </div>
                        <div className="content-image">
                            {store.image_url && <img src={store.image_url} alt="event" />}
                            <p className="title">{store.title}</p>
                        </div>
                        <div className="content-body">
                            <p className="subtitle">{store.subtitle}</p>
                            <p className="description">{store.text}</p>
                            <hr />
                            <div className="details">
                                <p className="subtitle">EVENT DETAILS</p>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1, marginRight: '10px' }}>
                                        <span className="detail-name">DATUM/ZEIT</span>
                                        <span className="detail-value">
                                            {formatDate(store.start, true)} <br />
                                            {`${formatTime(store.start, true)} - ${formatTime(
                                                store.end,
                                                true,
                                            )}`}
                                        </span>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <span className="detail-name">Lokalität</span>
                                        <span className="detail-value">{store.location}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1, marginRight: '10px' }}>
                                        <span className="detail-name">ADRESSE</span>
                                        <span className="detail-value link">{store.address}</span>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <span className="detail-name">PROGRAMM</span>
                                        <span className="detail-value">{store.program}</span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default EventView;
