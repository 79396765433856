import api from './apiLibrary';

const API = {
    getAllAnswers(params) {
        return api.get('/api/admin/fan-game-answer', params);
    },
    createAnswer(data) {
        return api.post('/api/admin/fan-game-answer', data);
    },
    editAnswer(id, data) {
        return api.put(`/api/admin/fan-game-answer/${id}`, data);
    },
    deleteAnswer(id) {
        return api.delete(`/api/admin/fan-game-answer/${id}`);
    },
};

export default API;
