import { Route } from 'react-router-dom';
import Page from '../../components/Page';
import LotteryGames from './LotteryGames';
import LotteryGame from './LotteryGame';
import NewLotteryGame from './NewLotteryGame';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js/index.js';
import { observer } from 'mobx-react-lite';

const LotteryGamesTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.LOTTERY_GAMES}>
                <LotteryGames store={store} />
            </Route>
            <Route exact path={Paths.LOTTERY_GAME}>
                <LotteryGame store={store} />
            </Route>
            <Route exact path={Paths.NEW_LOTTERY_GAME}>
                {' '}
                <NewLotteryGame store={store} />
            </Route>
        </Page>
    );
};

export default observer(LotteryGamesTab);
