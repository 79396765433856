import api from './apiLibrary';

const API = {
    getDefaultRewardStatistics(params) {
        return api.get(`/api/admin/default-reward/statistics`, params);
    },
    getDefaultRewards(params) {
        return api.get(`/api/admin/default-reward`, params);
    },
    getDefaultReward(fan_game_id) {
        return api.get(`/api/admin/default-reward/${fan_game_id}`);
    },
    createDefaultReward(data) {
        return api.post(`/api/admin/default-reward`, data);
    },
    editDefaultReward(data, fan_game_id) {
        return api.put(`/api/admin/default-reward/${fan_game_id}`, data);
    },
    deleteDefaultReward(id) {
        return api.delete(`/api/admin/default-reward/${id}`);
    },
};

export default API;
