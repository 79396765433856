import React, { useRef, useState, useEffect } from 'react';
import Button from '../../../components/Button';
import EventView from '../../../components/EventView';
import useModal from '../../../customHooks/useModal';
import { useForm } from 'react-hook-form';
import getBusinessEventFormInput from './../getBusinessEventFormInput';
import { observer } from 'mobx-react-lite';
import clearObjProps from '../../../helpers/clearObjProps';
import ErrorMessages from '../../../components/ErrorMessages.js';

const StepOne = ({ store, onNext }) => {
    const BusinessEventModel = clearObjProps({ ...store }, [
        'creating_invitations',
        'selected_partners',
        'selected_partner_types',
        'partners_profiles',
    ]);
    const eventViewRef = useRef(null);
    const [image, setImage] = useState(BusinessEventModel.image_url);
    const [error, setError] = useState('');
    const [event, showEvent] = useModal(false, eventViewRef);
    const formData = Object.keys(BusinessEventModel).map((key) => {
        return { name: key, value: BusinessEventModel[key] };
    });
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
        getValues,
        setValue,
    } = useForm({
        defaultValues: { ...BusinessEventModel, image_url: image },
    });
    const watchIsRespondable = watch('is_respondable');

    const previewHandler = async () => {
        const values = getValues();
        await store.updateEventData(values);
        showEvent(true);
    };

    const checkDates = (start, end) => {
        if (end <= start) {
            setError('End date must be after Start date');
            return false;
        } else {
            return true;
        }
    };

    const nextHandler = async (e) => {
        if (!checkDates(e.start, e.end)) {
            return;
        } else {
            e.lat = latitude;
            e.long = longitude;
            await store.updateEventData(e);
            onNext();
        }
    };

    useEffect(() => {
        if (image) {
            reset({ ...watch(), ...image });
        }
    }, [image, reset, watch]);

    useEffect(() => {
        if (watchIsRespondable) {
            setValue('time_to_respond', null);
        }
    }, [watchIsRespondable, setValue]);

    return (
        <>
            <form>
                <div className="controls">
                    <Button type="button" label="Preview" onClick={previewHandler} />

                    <Button type="button" label="Next" onClick={handleSubmit(nextHandler)} />
                </div>
                {formData.map((data, index) =>
                    getBusinessEventFormInput({
                        value: data.value,
                        name: data.name,
                        register,
                        control,
                        error: errors[data.name],
                        index,
                        resetValue: (name, value) => setImage({ [name]: value }),
                        setCoordinates: (lat, long) => {
                            setLatitude(lat);
                            setLongitude(long);
                        },
                        watchIsRespondable,
                    }),
                )}
            </form>
            <ErrorMessages messages={error} />
            {event && (
                <EventView store={store} ref={eventViewRef} onClose={() => showEvent(false)} />
            )}
        </>
    );
};

export default observer(StepOne);
