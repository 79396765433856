import { types, applySnapshot } from 'mobx-state-tree';

const Scanner = types
    .model('Scanner', {
        id: types.identifierNumber,
        device_id: types.optional(types.string, ''),
        title: types.optional(types.string, ''),
        enabled: types.optional(types.boolean, false),
        covid_token: types.maybeNull(types.string),
        covid_env: types.maybeNull(types.string),
        app_version: types.optional(types.string, ''),
    })
    .actions((self) => ({
        editScanner(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Scanner;
