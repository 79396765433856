export default function ProgressBar({ completed }) {
    const containerStyle = {
        height: 5,
        width: '100%',
        backgroundColor: '#e0e0de',
    };

    const fillerStyle = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: '#e73c37',
    };

    return (
        <div style={containerStyle}>
            <div style={fillerStyle}></div>
        </div>
    );
}
