import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import useModal from '../../customHooks/useModal';

import Paths from '../../constants/paths';
import translations from '../../translations/de';
import { FaTrash, FaPlus, FaSearch, FaLongArrowAltRight, FaUndoAlt } from 'react-icons/fa';

import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Pagination from './../../components/Pagination';
import Input from '../../components/Input';
import ExportBtn from '../../components/ExportBtn';

import API from '../../api/businessProfiles';

const BusinessProfiles = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [profileID, setProfileID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);

    useEffect(() => {
        store.getProfiles(searchQuery);
    }, [store, store.selectedPage, searchQuery]);

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

    const handleProfile = (id) => {
        store.selectProfile(id);
        history.push(`/business-profiles/${store.selectedProfile.id}`);
    };

    return (
        <>
            <div style={{ marginLeft: '2.5%' }}>
                <Input
                    placeholder={translations.search}
                    defaultValue={searchQuery}
                    onChange={onSearchChangeHandler}
                />
            </div>
            <div className="section-actions">
                <Button
                    label={translations.business_profile}
                    onClick={() => {
                        store.selectProfile(undefined);
                        history.push(Paths.NEW_BUSINESS_PROFILE);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
                <ExportBtn fileName='profiles' apiFunc={() => API.exportProfiles()} style={{ marginRight: '2.5%' }} />
            </div>
            {store.profiles && store.profiles.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName="Profiles"
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.company,
                            accessor: 'company_name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },

                        {
                            Header: translations.first_name,
                            accessor: 'first_name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.last_name,
                            accessor: 'last_name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.email,
                            accessor: 'email',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.user,
                            accessor: 'user',
                            Cell: ({ value }) => (
                                <div>
                                    {value && (
                                        <div
                                            className="link-label"
                                            onClick={() => history.replace(`/fans/${value.id}`)}
                                        >
                                            <FaLongArrowAltRight />
                                            {`${value.first_name} ${value.last_name}`}
                                        </div>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.phone,
                            accessor: 'phone',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleProfile(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>

                                    {isNotDeletedAlready(row) ? (
                                        <Button
                                            onClick={() => {
                                                setProfileID(row.cells[0].value);
                                                setModal(true);
                                            }}
                                            title={translations.delete}
                                        >
                                            <FaTrash />
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => store.reviveProfile(row.cells[0].value)}
                                        >
                                            <FaUndoAlt />
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    data={store.profiles.map(
                        ({
                            id,
                            company,
                            first_name,
                            last_name,
                            email,
                            phone,
                            deleted_at,
                            user,
                        }) => {
                            return {
                                id: typeof id === 'number' ? id : null,
                                company_name:
                                    company && typeof company.name === 'string'
                                        ? company.name
                                        : null,
                                first_name: typeof first_name === 'string' ? first_name : null,
                                last_name: typeof last_name === 'string' ? last_name : null,
                                email: typeof email === 'string' ? email : null,
                                user,
                                phone: typeof phone === 'string' ? phone : null,
                                deleted_at,
                            };
                        },
                    )}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Profile"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this profile?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteProfile(profileID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(BusinessProfiles);
