import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CreateGame from '../../components/CreateGame';
import GameStore from '../../models/fanGamesStore/Game';
import { useParams } from 'react-router-dom';
import gameTypes from '../../constants/matchGameTypes';
import translations from '../../translations/de';

const NewFanGame = ({ store }) => {
    const { match_id } = useParams();
    const game = GameStore.create({ id: 0 });

    useEffect(() => {
        store.getMatch(match_id);
    }, [store, match_id]); 
    
    return store.selectedMatch ? (
        <CreateGame
            links={[
                { path: '/matches', name: translations.matches },
                {
                    path: `/matches/${store.selectedMatch.id}`,
                    name:
                        store.selectedMatch.home_team.title +
                        ' - ' +
                        store.selectedMatch.away_team.title,
                },
            ]}
            name={`Fan Game ${translations.create}`}
            game={game}
            gameTypes={gameTypes}
        />
    ) : null;
};

export default observer(NewFanGame);
