import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import { useForm } from 'react-hook-form';
import getLotteryGameFormInput from './getLotteryGameFormInput';
import debounce from 'lodash.debounce';
import API from '../../api/rewards';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import ErrorMessages from '../../components/ErrorMessages.js';

const gameModel = {
    draw_at: '',
    start_at: '',
    visible_until: '',
    name: '',
    image_url: '',
    price: 0,
    start_stock: 0,
    description: '',
    active: false,
    deactivate_on_sold: false,
    draw_when_sold_out: false,
    send_notification: false,
};

const lotteryData = Object.keys(gameModel).map((key) => {
    return { name: key, value: gameModel[key] };
});

const rewardModel = {
    reward_name: '',
    reward_image_url: '',
    reward_fan_coins: 0,
    reward_expire_at: '',
    reward_type: 'avatar',
    reward_popup_title: '',
    reward_popup_text: '',
    reward_description: '',
    reward_redeem_description: '',
    voucher_type: null,
};

const rewardData = Object.keys(rewardModel).map((key) => {
    return {
        name: key,
        value: rewardModel[key],
    };
});

const NewLotteryGame = ({ store }) => {
    const [rewards, setRewards] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [startAtEnabled, setStartAtEnabled] = useState(true);
    const [resetProp, setResetProp] = useState(null);
    const [showVoucherTypes, setShowVoucherTypes] = useState(false);
    const [error, setError] = useState(null);
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
    } = useForm({ defaultValues: { ...gameModel, ...rewardModel } });
    const watchActive = watch('active');

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        const getItems = async () => {
            try {
                const res = await API.getAllRewards({
                    params: {
                        search: searchQuery,
                        limit: 20,
                    },
                });
                setRewards(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        getItems();
    }, [searchQuery]);

    useEffect(() => {
        if (resetProp) {
            reset({ ...watch(), ...resetProp });
        }
    }, [resetProp, reset, watch]);

    useEffect(() => {
        setStartAtEnabled(!watch('active'));
    }, [watchActive, watch]);

    const chooseFromShopHander = (e) => {
        const item = e?.value && rewards.find((i) => i.name === e.value);
        setResetProp(
            item
                ? {
                      reward_image_url: item.image_url ?? '',
                      reward_name: item.name ?? '',
                      reward_description: item.description ?? '',
                      reward_redeem_description: item.redeem_description ?? '',
                      reward_type: item.type ?? 'avatar',
                      reward_expire_at: item.expire_at ?? null,
                      reward_popup_text: item.popup_text ?? '',
                      reward_popup_title: item.popup_title ?? '',
                  }
                : {
                      reward_image_url: '',
                      reward_name: '',
                      reward_description: '',
                      reward_redeem_description: '',
                      reward_type: 'avatar',
                      reward_expire_at: null,
                      reward_popup_text: '',
                      reward_popup_title: '',
                  },
        );
    };

    const searchItemHander = debounce((value) => {
        setSearchQuery(value);
    }, 500);

    const onSubmitHandler = (lottery) => {
        setLoading(true);
        store
            .createLotteryGame(lottery)
            .then(() => {
                setLoading(false);
                history.push(Paths.LOTTERY_GAMES);
            })
            .catch((error) => {
                setLoading(false);
                setError(error);
            });
    };

    const handleTypeChange = (type) => {
        setShowVoucherTypes(type === 'consumable');
    };

    return (
        <div className="Lottery">
            <div className="Lottery-Header">
                <Breadcrumb
                    links={[{ path: Paths.LOTTERY_GAMES, name: translations.lottery_games }]}
                    name={translations.lottery_game_new}
                />
                <div className="controls">
                    <Button type="button" onClick={() => history.goBack()}>
                        {translations.cancel}
                    </Button>
                    <Button
                        type="submit"
                        onClick={handleSubmit(onSubmitHandler)}
                        loading={loading}
                        label={translations.save}
                    />
                </div>
            </div>
            <div className="Lottery-Content">
                <ErrorMessages
                    messages={requsetErrorMessage(error && error.message ? error.message : error)}
                />
                <form>
                    <div className="row">
                        <p className="title">Lottery Data</p>
                    </div>
                    {lotteryData.map((data, index) =>
                        getLotteryGameFormInput({
                            value: data.value,
                            name: data.name,
                            register,
                            control,
                            error: errors[data.name],
                            index,
                            resetValue: (name, value) => setResetProp({ [name]: value }),
                            startAtEnabled,
                        }),
                    )}
                    <div className="row">
                        <p className="title">Reward</p>
                        <Select
                            label={translations.choose_template}
                            options={rewards.map((item) => ({
                                value: item.name,
                                label: item.name,
                            }))}
                            placeholder={translations.search_reward}
                            noOptionsMsg="No items found"
                            onInputChange={searchItemHander}
                            onChangeCallback={chooseFromShopHander}
                            isClearable
                        />
                    </div>

                    {rewardData.map((data, index) =>
                        getLotteryGameFormInput({
                            value: data.value,
                            name: data.name,
                            register,
                            control,
                            error: errors[data.name],
                            index,
                            resetValue: (name, value) => setResetProp({ [name]: value }),
                            handleTypeChange,
                            showVoucherTypes,
                        }),
                    )}
                </form>
            </div>
        </div>
    );
};

export default NewLotteryGame;
