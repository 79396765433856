import React, { useState, useEffect, useRef } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import Button from '../../components/Button';
import Pagination from './../../components/Pagination';
import { FaList, FaSearch, FaPlus, FaTrash } from 'react-icons/fa';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';
import Loader from '../../components/Loader';

const Notifications = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const deleteModalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [deleteModal, setDeleteModal] = useModal(false, deleteModalRef);
    const [notificationId, setNotificationID] = useState(null);
    const [loadingRowId, setLoadingRowId] = useState(null);

    useEffect(() => {
        store.getNotifications();
    }, [store, store.selectedPage]);

    const handleNotification = (id) => {
        store.selectNotification(id);
        history.push(`/notifications/${id}`);
    };

    const closeModal = () => {
        store.selectNotification(undefined);
        setModal(false);
    };

    const showParticipants = (id) => {
        setLoadingRowId(id);
        store.selectNotification(id);
        store.selectedNotification.getRecipients().then(() => {
            setModal(true);
            setLoadingRowId(null);
        });
    };

    const deleteNotification = () => {
        setLoadingRowId(notificationId);
        store.deleteNotification(notificationId).then(() => setLoadingRowId(null));
    };

    return (
        <>
            <div style={{ marginLeft: '2.5%' }}>
                <Button
                    label={`${translations.notification}`}
                    onClick={() => {
                        store.selectNotification(undefined);
                        history.push(Paths.NEW_NOTIFICATION);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            <Table
                tablePagination={
                    store.totalPages > 1 && (
                        <Pagination
                            totalPages={store.totalPages}
                            selectedPage={store.selectedPage}
                            onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                        />
                    )
                }
                isRowClickable={false}
                tableName={translations.notifications}
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.title,
                        accessor: 'title',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.image,
                        accessor: 'image',
                        Cell: ({ value }) => (
                            <div>
                                <ItemImage imgSrc={value} listView={true} />
                            </div>
                        ),
                    },
                    {
                        Header: translations.text,
                        accessor: 'text',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.recipients,
                        accessor: 'recipients',
                        Cell: ({ row }) =>
                            loadingRowId === row.cells[0].value ? (
                                <Loader theme="dark" size="large" />
                            ) : (
                                <div>
                                    <Button
                                        disabled={loadingRowId !== null}
                                        title={translations.show}
                                        onClick={() => {
                                            showParticipants(row.cells[0].value);
                                        }}
                                    >
                                        <FaList />
                                    </Button>
                                    <Button
                                        disabled={loadingRowId !== null}
                                        title={translations.edit}
                                        type="button"
                                        onClick={() => {
                                            handleNotification(row.cells[0].value);
                                        }}
                                    >
                                        <FaSearch />
                                    </Button>
                                    <Button
                                        disabled={loadingRowId !== null}
                                        title={translations.delete}
                                        onClick={() => {
                                            setNotificationID(row.cells[0].value);
                                            setDeleteModal(true);
                                        }}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                    },
                ]}
                data={store.notifications.map(({ id, title, image, text }) => {
                    return {
                        id,
                        title,
                        image,
                        text,
                    };
                })}
            />
            {modal && store.selectedNotification.recipients.length !== 0 && (
                <Modal
                    title={`${translations.recipients} (${translations.notification} id: ${store.selectedNotification.id})`}
                    ref={modalRef}
                    onClose={closeModal}
                    maxWidth="800px"
                >
                    <Table
                        isRowClickable={false}
                        columns={[
                            {
                                Header: 'ID',
                                accessor: 'id',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.first_name,
                                accessor: 'first_name',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.last_name,
                                accessor: 'last_name',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.email,
                                accessor: 'email',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                        ]}
                        data={store.selectedNotification.recipients.map(
                            ({ id, first_name, last_name, email }) => {
                                return {
                                    id,
                                    first_name,
                                    last_name,
                                    email,
                                };
                            },
                        )}
                    />
                    {store.selectedNotification.totalRecipeintsPages > 1 && (
                        <Pagination
                            totalPages={store.selectedNotification.totalRecipeintsPages}
                            selectedPage={store.selectedNotification.selectedRecipeintsPage}
                            onSelectedPageChange={(selected) => {
                                store.selectedNotification.setSelectedPage(selected);
                                store.selectedNotification.getRecipients();
                            }}
                        />
                    )}
                    <div className="Modal-Controls" style={{ marginLeft: '2.5%' }}>
                        <Button label={translations.close} onClick={closeModal} />
                    </div>
                </Modal>
            )}
            {deleteModal && (
                <Modal
                    title="Delete Notification"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this notification?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                deleteNotification();
                                setDeleteModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setDeleteModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(Notifications);
