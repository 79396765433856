import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { FaSearch, FaCheck, FaTimes, FaShopify, FaAward } from 'react-icons/fa';
import translations from '../../translations/de';
import debounce from 'lodash.debounce';
import APIVouchers from '../../api/vouchers';

import Table from '../../components/Table';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Pagination from './../../components/Pagination';
import { useHistory } from 'react-router-dom';

import { formatTime, formatDate } from '../../helpers/formatDate';

const RewardVouchers = () => {
    const { reward_id, item_id } = useParams();
    const history = useHistory();
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [vouchers, setVouchers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleVoucher = (id) => {
        history.push(`/vouchers/${id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    useEffect(() => {
        const getVouchers = async () => {
            try {
                const params = {
                    reward_id: reward_id,
                    shop_item_id: item_id,
                    page: selectedPage,
                    limit: 10,
                };
                if (searchQuery !== '') {
                    params.search = searchQuery;
                }
                var res = await APIVouchers.getVouchersFromReward({
                    params,
                });
                setVouchers(res.data.items);
                setTotalPages(res.data.total_pages_count);
            } catch (err) {
                console.error('Error fetching vouchers: ', err);
            }
        };
        (reward_id && getVouchers()) || (item_id && getVouchers());
    }, [reward_id, item_id, selectedPage, searchQuery]);

    return (
        <div style={{ width: '100%' }}>
            <div className="tab-controls">
                <Input placeholder={translations.search} onChange={onSearchChangeHandler} />
            </div>
            {
                <Table
                    tablePagination={
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                onSelectedPageChange={(selected) => setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName={translations.vouchers}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.price_in_cents,
                            accessor: 'price_in_cents',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.type,
                            accessor: 'item_type',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.order_id,
                            accessor: 'order_id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.voucher_code,
                            accessor: 'voucher_code',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.used,
                            accessor: 'used',
                            Cell: ({ value }) => (
                                <div>
                                    {value === true ? (
                                        <FaCheck className="confirm" />
                                    ) : (
                                        <FaTimes className="error" />
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.expiry_date,
                            accessor: 'expiry_date',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleVoucher(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={
                        vouchers.length > 0
                            ? vouchers.map(
                                  ({
                                      id,
                                      price_in_cents,
                                      voucher_code,
                                      redeem_data,
                                      expiry_date,
                                      order_id,
                                      shop_item_id,
                                      reward_id,
                                  }) => {
                                      return {
                                          id,
                                          price_in_cents,
                                          voucher_code,
                                          used: redeem_data ? true : false,
                                          expiry_date: `${formatDate(expiry_date)} - ${formatTime(
                                              expiry_date,
                                          )}`,
                                          order_id,
                                          item_type: shop_item_id ? (
                                              <FaShopify
                                                  title="Shop item"
                                                  style={{ color: 'green', fontSize: '24px' }}
                                              />
                                          ) : reward_id ? (
                                              <FaAward
                                                  title="Reward"
                                                  style={{ color: 'green', fontSize: '24px' }}
                                              />
                                          ) : (
                                              <FaTimes className="error" />
                                          ),
                                      };
                                  },
                              )
                            : []
                    }
                />
            }
        </div>
    );
};
export default RewardVouchers;
