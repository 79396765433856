import { types, applySnapshot } from 'mobx-state-tree';

const Banner = types
    .model('Banner', {
        id: types.identifierNumber,
        title: types.maybeNull(types.string),
        image_url: types.maybeNull(types.string),
        position: types.maybeNull(types.number),
        size: types.maybeNull(types.string),
        target_url: types.maybeNull(types.string),
        banner_id: types.number,
        screen_id: types.number,
        btn_title: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        deleted_at: types.maybeNull(types.string),
    })
    .actions((self) => ({
        editBanner(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Banner;
