import React from 'react';
import { Route } from 'react-router-dom';
import Page from './../../components/Page';
import Feeds from './Feeds';
import Feed from './Feed';
import NewFeed from './NewFeed';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const FeedsTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.FEEDS}>
                <Feeds store={store} />
            </Route>
            <Route exact path={Paths.FEED}>
                <Feed store={store} />
            </Route>
            <Route exact path={Paths.NEW_FEED}>
                <NewFeed store={store} />
            </Route>
        </Page>
    );
};

export default observer(FeedsTab);
