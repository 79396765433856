const fanGamesTabs = [
    { label: 'All', value: 'all' },
    { label: 'Favorite Player', value: 'favorite_player' },
    { label: 'First Scorer', value: 'first_scorer' },
    { label: 'Live Mood', value: 'live_mood' },
    { label: 'Seasonal Mood', value: 'seasonal_mood' },
    { label: 'Final Score', value: 'end_game_result' },
];

export default fanGamesTabs;
