import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import getScreenFormInput from './getScreenFormInput';
import translations from '../../translations/de';

const NewScreen = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    return (
        <Item
            links={[{ path: Paths.SCREENS, name: translations.app_screens }]}
            name={`${translations.app_screen} ${translations.create}`}
            itemModel={{ name: '', tab: '' }}
            edit={true}
            onSubmit={(event) => {
                error && setError(null);
                setLoading(true);
                store
                    .addScreen(event)
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.SCREENS);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, control, register, error, disabled, index }) =>
                getScreenFormInput({
                    value,
                    name,
                    control,
                    register,
                    error,
                    disabled,
                    index,
                })
            }
        ></Item>
    );
};

export default NewScreen;
