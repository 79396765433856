import MatchesStore from '../models/matchesStore/Matches';
import CalendarStore from '../models/calendarStore/Calendar';
import NotificationsStore from '../models/notificationsStore/Notifications';
import ScreensStore from '../models/screensStore/Screens';
import ScannersStore from '../models/scannersStore/Scanners';
import PassesStore from '../models/passesStore/Passes';
import PassesFriendshipStore from '../models/passesFriendshipStore/Passes';
import AcessesStore from '../models/accessesStore/Accesses';
import SectorsStore from '../models/sectorsStore/Sectors';
import SeatGroupsStore from '../models/SeatGroupsStore/SeatGroups';
import SeatsStore from '../models/seatsStore/Seats';
import SeatMapsStore from '../models/seatMapsStore/SeatMaps';
import BusineProfilesStore from '../models/businessProfilesStore/BusinessProfiles';
import InstantRewardsStore from '../models/instantRewardsStore/instantRewards';
import VouchersStore from '../models/vouchersStore/Vouchers';
import BusinessPartnersStore from '../models/businessPartnersStore/BusinessPartners';
import MatchStatisticsStore from '../models/matchStatisticsStore/MatchStatistics';
import MatchesOverviewStore from '../models/matchOverviewStore/MatchesOverviews';
import BusinessEventsStore from '../models/businessEventsStore/BusinessEvents';
import FanGamesStore from '../models/fanGamesStore/Games';
import ContactsStore from '../models/contactsStore/Contacts';
import LotteryGamesStore from '../models/lotteryGamesStore/LotteryGames';
import SeasonsStore from '../models/seasonsStore/Seasons';
import UsersStore from '../models/usersStore/Users';
import AdminsStore from '../models/adminsStore/Admins';
import ShopStore from '../models/shopStore/ShopItems';
import TeamsStore from '../models/teamsStore/Teams';
import FeedsStore from '../models/feedsStore/Feeds';
import RewardsStore from '../models/rewardsStore/Rewards';
import NewsStore from '../models/newsStore/News';

const teams = TeamsStore.create();
const shopItems = ShopStore.create();
const admins = AdminsStore.create();
const fans = UsersStore.create();
const seasons = SeasonsStore.create();
const matches = MatchesStore.create({ currentSeasonId: null });
const calendar = CalendarStore.create({ selectedDate: new Date() });
const notifications = NotificationsStore.create();
const screens = ScreensStore.create();
const scanners = ScannersStore.create();
const passes = PassesStore.create();
const passesFriendship = PassesFriendshipStore.create();
const accesses = AcessesStore.create();
const sectors = SectorsStore.create();
const seatGroups = SeatGroupsStore.create();
const seats = SeatsStore.create();
const seatMaps = SeatMapsStore.create();
const businessProfiles = BusineProfilesStore.create();
const businessPartners = BusinessPartnersStore.create();
const businessEvents = BusinessEventsStore.create();
const instantRewards = InstantRewardsStore.create();
const vouchers = VouchersStore.create();
const matchStatistics = MatchStatisticsStore.create();
const matchesOverview = MatchesOverviewStore.create();
const fanGames = FanGamesStore.create();
const contacts = ContactsStore.create();
const lotteryGames = LotteryGamesStore.create();
const feeds = FeedsStore.create();
const rewards = RewardsStore.create();
const news = NewsStore.create();

const stores = {
    teams,
    shopItems,
    admins,
    fans,
    seasons,
    matches,
    calendar,
    notifications,
    screens,
    scanners,
    passes,
    passesFriendship,
    accesses,
    sectors,
    seatGroups,
    seats,
    seatMaps,
    businessProfiles,
    businessPartners,
    businessEvents,
    instantRewards,
    vouchers,
    matchStatistics,
    matchesOverview,
    fanGames,
    contacts,
    lotteryGames,
    feeds,
    rewards,
    news,
};

export default stores;
