import { types, flow } from 'mobx-state-tree';
import Feed from './Feed';
import API from '../../api/feeds';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearObjNulls from '../../helpers/clearObjNulls';

const FeedsStore = types
    .model('FeedsStore', {
        feeds: types.optional(types.array(Feed), []),
        selectedFeed: types.maybe(types.reference(Feed)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getFeeds: flow(function* getFeeds(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getFeeds({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                /// stringify data first
                var tempFeeds = [];
                res.data.items.forEach((feed) => {
                    if (feed.data !== null) {
                        tempFeeds.push({ ...feed, data: JSON.stringify(feed.data, undefined, 2) });
                    } else {
                        tempFeeds.push(feed);
                    }
                });
                self.feeds = tempFeeds;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        selectFeed(id) {
            self.selectedFeed = id;
        },
        getFeed: flow(function* getFeed(id) {
            self.error = '';
            try {
                const res = yield API.getFeed(id);
                self.dataLoaded = true;
                self.feeds = [];
                var tempFeed = res.data;
                if (tempFeed.data !== null) {
                    tempFeed.data = JSON.stringify(res.data.data, undefined, 2);
                }
                self.feeds.push(tempFeed);
                self.selectedFeed = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editFeed: flow(function* editFeed(edited) {
            try {
                var parsed = edited;
                parsed.data = JSON.parse(edited.data);
                delete edited.deleted_at;
                const res = yield API.editFeed(self.selectedFeed.id, { ...parsed });
                var new_edited = res.data;
                new_edited.data = JSON.stringify(new_edited.data);
                self.selectedFeed.editFeed(res.data);
            } catch (error) {
                self.dataLoaded = true;
                throw error;
            }
        }),
        editActive: flow(function* editActive(id, active) {
            self.selectedFeed = id;
            try {
                const res = yield API.setActiveFeedStatus(id, active);
                var tempFeed = res.data;
                if (tempFeed.data !== null) {
                    tempFeed.data = JSON.stringify(tempFeed.data, undefined, 2);
                }

                self.feeds.forEach((item, index) => {
                    if (item.id === id) {
                        self.feeds.splice(index, 1);
                        self.feeds.splice(index, 0, tempFeed);
                    }
                });
            } catch (error) {
                throw error;
            }
        }),
        createFeed: flow(function* createFeed(feed) {
            try {
                feed.data = JSON.parse(feed.data);
                const res = yield API.createFeed(clearObjNulls(feed));
                var stringified = res.data;
                stringified.data = JSON.stringify(stringified.data);
                self.feeds.push(stringified);
            } catch (error) {
                throw error;
            }
        }),
        deleteFeed: flow(function* deleteFeed(id) {
            try {
                yield API.deleteFeed(id);
                const index = self.feeds.findIndex((s) => s.id === id);
                self.feeds[index].deleted_at = 'deleted';
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        reviveFeed: flow(function* reviveFeed(id) {
            try {
                yield API.reviveFeed(id);
                const index = self.feeds.findIndex((s) => s.id === id);
                self.feeds[index].deleted_at = null;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default FeedsStore;
