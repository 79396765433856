import React from 'react';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import InstantRewards from './InstantRewards';
import InstantReward from './InstantReward';
import NewInstantReward from './NewInstantReward';

const InstantRewardTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.INSTANT_REWARDS}>
                <InstantRewards store={store} />
            </Route>
            <Route exact path={Paths.INSTANT_REWARD}>
                <InstantReward store={store} />
            </Route>
            <Route exact path={Paths.NEW_INSTANT_REWARD}>
                <NewInstantReward store={store} />
            </Route>
        </Page>
    );
};

export default observer(InstantRewardTab);
