const drawerSections = {
    MANAGEMENT: 'ADMIN', //  ADMIN
    USERS: 'USERS', //  ADMIN_USER
    TICKETING: 'TICKETING', //  ADMIN_TICKETING
    FAN_INTERACTION: 'FAN INTERACTION', //  ADMIN_FAN_INTERACTION
    BUSINESS: 'BUSINESS', //  ADMIN_BUSINESS
    GASTRO: 'GASTRO', //  ADMIN_GASTRO
    OTHER: 'OTHER',
};

export default drawerSections;
