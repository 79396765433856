import api from './apiLibrary';

const API = {
    getSectors(params) {
        return api.get('/api/admin/sector', params);
    },
    getSeatGroups(params) {
        return api.get('/api/admin/seat-group', params);
    },
    getSeats(params) {
        return api.get('/api/admin/seat', params);
    },
    addSeat(data) {
        return api.post('/api/admin/seat', data);
    },
    editSeat(id, data) {
        return api.put(`/api/admin/seat/${id}`, data);
    },
    getSeat(id) {
        return api.get(`/api/admin/seat/${id}`);
    },
    deleteSeat(id) {
        return api.delete(`/api/admin/seat/${id}`);
    },
};

export default API;
