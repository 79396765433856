import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import Input from '../../components/Input';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import getBusinessPartnerFormInput from './getBusinessPartnerFormInput';
import translations from '../../translations/de';
import APIProfiles from './../../api/businessProfiles';
import ProfilesTable from './ProfilesTable';
import debounce from 'lodash.debounce';

const NewBusinessPartner = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        const getProfiles = async () => {
            try {
                const res = await APIProfiles.getProfiles({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        page,
                    },
                });
                setProfiles(res.data.items);
                setTotalPages(res.data.total_pages_count);
            } catch (error) {
                console.log(error);
            }
        };
        getProfiles();
    }, [store, page, searchQuery]);

    const onSearchProfileHandler = debounce((e) => {
        setPage(1);
        setSearchQuery(e.target.value);
    }, 200);
    return (
        <>
            <Item
                links={[{ path: Paths.BUSINESS_PARTNERS, name: translations.business_partners }]}
                name={`${translations.business_partner} ${translations.create}`}
                itemModel={{
                    name: null,
                    logo: logo,
                    type: null,
                    address: null,
                    location: null,
                    zip: null,
                    postbox: null,
                }}
                edit={true}
                onSubmit={(event) => {
                    setLoading(true);
                    error && setError(null);
                    store
                        .addPartner({ ...event })
                        .then(() => {
                            setLoading(false);
                            history.push(Paths.BUSINESS_PARTNERS);
                        })
                        .catch((err) => {
                            setError(err);
                            setLoading(false);
                        });
                }}
                onCancel={() => history.goBack()}
                error={error}
                loading={loading}
                determineInputType={({
                    value,
                    name,
                    register,
                    control,
                    error,
                    isDisabled,
                    index,
                }) =>
                    getBusinessPartnerFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        isDisabled,
                        index,
                        resetValue: (name, value) => setLogo({ [name]: value }),
                    })
                }
                resetProp={logo}
            ></Item>
            <div className="tab-controls" style={{ width: '96%' }}>
                <h2 style={{ width: '100%', fontWeight: 400, borderBottom: '1px solid lightGray' }}>
                    Add Business Profiles
                </h2>
                <Input
                    label={translations.people}
                    placeholder={translations.search_name_email}
                    onChange={onSearchProfileHandler}
                    style={{ margin: '10px 0' }}
                />
            </div>
            <ProfilesTable
                users={profiles}
                store={store}
                onPageChange={(p) => setPage(p)}
                totalPages={totalPages}
                selectedPage={page}
                isDisabled={true}
            />
        </>
    );
};

export default observer(NewBusinessPartner);
