import api from './apiLibrary';

const API = {
    getFanAnswers(id, params) {
        return api.get(`/api/admin/fan-answers/fan-game/${id}`, params);
    },
    deleteFanAnswer(id) {
        return api.delete(`/api/admin/fan-answers/${id}`);
    },
};

export default API;
