import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import getBusinessProfileFormInput from './getBusinessProfileFormInput';
import API from './../../api/users';
import APIPartner from './../../api/businessPartners';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';

const NewBusinessProfile = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [users, setUsers] = useState([]);
    const [partners, setPartners] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryPartner, setSearchQueryPartner] = useState('');

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await API.getUsers({
                    params: {
                        search: searchQuery,
                        limit: 50,
                    },
                });
                setUsers(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };

        searchQuery && getUsers();
    }, [searchQuery]);

    useEffect(() => {
        const getPartners = async () => {
            try {
                const res = await APIPartner.getPartners({
                    params: {
                        search: searchQueryPartner,
                        limit: 50,
                    },
                });
                setPartners(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchQueryPartner && getPartners();
    }, [searchQueryPartner]);

    const searchQueryHandler = debounce((value) => {
        setSearchQuery(value);
    }, 500);
    const searchQueryHandlerPartner = debounce((value) => {
        setSearchQueryPartner(value);
    }, 500);

    return (
        <Item
            links={[{ path: Paths.BUSINESS_PROFILES, name: translations.business_profiles }]}
            name={`${translations.business_profile} ${translations.create}`}
            itemModel={{
                first_name: null,
                last_name: null,
                avatar: avatar,
                gender: 'male',
                role: null,
                phone_mobile: null,
                phone: null,
                email: null,
                user_id: null,
                company_id: null,
            }}
            edit={true}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .addProfile({ ...event })
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.BUSINESS_PROFILES);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, isDisabled, index }) =>
                getBusinessProfileFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    isDisabled,
                    index,
                    users: users,
                    searchQueryHandler,
                    searchQueryHandlerPartner,
                    partners: partners,
                    resetValue: (name, value) => setAvatar({ [name]: value }),
                })
            }
            resetProp={avatar}
        ></Item>
    );
};

export default observer(NewBusinessProfile);
