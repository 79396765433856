import React, { useState, useRef, useContext } from 'react';
import translations from '../../translations/de.js';
import { useForm } from 'react-hook-form';

import Input from '../../components/Input/index.js';
import Button from '../../components/Button/index.js';
import ErrorMessages from '../../components/ErrorMessages.js/index.js';
import Modal from '../../components/Modal/index.js';
import Logo from './../../assets/images/logo/scb-logo.png';
import requsetErrorMessage from '../../helpers/requsetErrorMessage.js';

import API from '../../api/auth.js';
import useModal from '../../customHooks/useModal.js';

import './style.scss';
import { ResetPasswordContext } from './index.js';

const ForgotPassword = () => {
    const { setEmail, nextStep, resetFlow } = useContext(ResetPasswordContext);
    const modalRef = useRef(null);
    const [modalResult, setModalResult] = useModal(false, modalRef);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ criteriaMode: 'all' });

    const handleForgottenPassword = async (e) => {
        try {
            const res = await API.forgotPassword({
                email: e.email,
            });
            if (res.status === 201) {
                setEmail(e.email);
                setModalResult(true);
            }
        } catch (e) {
            setErrorMessage(requsetErrorMessage(e.message));
        }
    };

    return (
        <div className="Login">
            <div className="Login-Container">
                <div className="logo">
                    <img src={Logo} alt="SCB Logo" />
                </div>
                <h2>{translations.app_name}</h2>
                <form onSubmit={handleSubmit(handleForgottenPassword)}>
                    <Input
                        label={translations.email}
                        type="email"
                        {...register('email', { required: true })}
                        error={errors.email}
                    />

                    <ErrorMessages messages={errorMessage} />
                    <Button type="submit" label={translations.send_code} fullwidth />
                    <br />
                </form>
                <Button
                    theme="gray"
                    label={translations.cancel}
                    fullwidth
                    onClick={() => resetFlow()}
                />
            </div>
            {modalResult && (
                <Modal
                    title={translations.success}
                    ref={modalRef}
                    onClose={() => {
                        setModalResult(false);
                        nextStep();
                    }}
                    maxWidth="500px"
                >
                    <p style={{ color: 'black' }}>{translations.forgot_password_success_message}</p>
                </Modal>
            )}
        </div>
    );
};

export default ForgotPassword;
