import React, { useState, useRef } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import translations from '../../translations/de';
import Pagination from '../../components/Pagination';
import { useHistory } from 'react-router-dom';
import ItemImage from '../../components/ItemImage';
import Button from '../../components/Button';
import useModal from '../../customHooks/useModal';
import { FaTrash, FaUndoAlt, FaUnlink, FaSearch } from 'react-icons/fa';
import Modal from '../../components/Modal';

const BusinessProfileTable = ({
    users,
    store,
    isDisabled = false,
    totalPages = 1,
    selectedPage = 1,
    onPageChange,
}) => {
    const history = useHistory();
    const [profileID, setProfileID] = useState(null);
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const checkIfChecked = (id) => {
        if (store?.selectedPartner?.profiles?.some((item) => item.id === id)) {
            return true;
        } else {
            return false;
        }
    };

    const checkOriginLocation = () => {
        return history.location.pathname.includes('business-partners') ? true : false;
    };

    return (
        users.length !== 0 && (
            <>
                {' '}
                {totalPages > 1 && isDisabled && (
                    <Pagination
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        onSelectedPageChange={(selected) => onPageChange(selected)}
                    />
                )}
                <Table
                    isRowClickable={false}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.avatar,
                            accessor: 'avatar',
                            Cell: ({ value }) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ItemImage
                                        imgSrc={value}
                                        alt="avatar"
                                        isAvatar={true}
                                        listView={true}
                                    />
                                </div>
                            ),
                        },
                        {
                            Header: translations.first_name,
                            accessor: 'first_name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.last_name,
                            accessor: 'last_name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.role,
                            accessor: 'role',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.email,
                            accessor: 'email',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.phone_mobile,
                            accessor: 'phone_mobile',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.phone,
                            accessor: 'phone',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'deleted_at',

                            Cell: ({ row }) =>
                                !isDisabled ? (
                                    <div>
                                        <Button
                                            onClick={() =>
                                                history.push(
                                                    `/business-profiles/${row.cells[0].value}`,
                                                )
                                            }
                                        >
                                            <FaSearch />
                                        </Button>
                                        {isNotDeletedAlready(row) ? (
                                            <Button
                                                onClick={() => {
                                                    setProfileID(row.cells[0].value);
                                                    setModal(true);
                                                }}
                                                title={translations.delete}
                                            >
                                                {checkOriginLocation ? <FaUnlink /> : <FaTrash />}
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() =>
                                                    store.reviveProfile(row.cells[0].value)
                                                }
                                            >
                                                <FaUndoAlt />
                                            </Button>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <input
                                            type="checkbox"
                                            disabled={false}
                                            defaultChecked={checkIfChecked(row.cells[0].value)}
                                            onClick={(e) => {
                                                if (e.target.checked) {
                                                    store.addProfile(row.cells[0].value);
                                                } else if (!e.target.checked) {
                                                    store.deleteProfile(row.cells[0].value, false);
                                                }
                                            }}
                                            title="Check"
                                        ></input>
                                    </div>
                                ),
                        },
                    ]}
                    data={users.map(
                        ({
                            id,
                            avatar,
                            first_name,
                            last_name,
                            role,
                            email,
                            phone_mobile,
                            phone,
                            deleted_at,
                        }) => {
                            return {
                                id,
                                avatar,
                                first_name,
                                last_name,
                                role,
                                email,
                                phone_mobile,
                                phone,
                                deleted_at,
                            };
                        },
                    )}
                />
                {modal && (
                    <Modal
                        title="Delete Profile"
                        ref={modalRef}
                        onClose={() => setModal(false)}
                        maxWidth="400px"
                    >
                        <p style={{ color: '#333', marginBottom: '20px' }}>
                            Are you sure you want to delete this profile?
                        </p>
                        <div className="Modal-Controls">
                            <Button
                                label="Yes"
                                onClick={() => {
                                    store.deleteProfile(profileID);
                                    setModal(false);
                                }}
                                style={{ width: '80px' }}
                            />
                            <Button
                                label="No"
                                onClick={() => setModal(false)}
                                style={{ width: '80px', marginLeft: '20px' }}
                            />
                        </div>
                    </Modal>
                )}
                {modal && (
                    <Modal
                        title="Delete Profile"
                        ref={modalRef}
                        onClose={() => setModal(false)}
                        maxWidth="400px"
                    >
                        <p style={{ color: '#333', marginBottom: '20px' }}>
                            Are you sure you want to delete this profile?
                        </p>
                        <div className="Modal-Controls">
                            <Button
                                label="Yes"
                                onClick={() => {
                                    store.deleteProfile(profileID);
                                    setModal(false);
                                }}
                                style={{ width: '80px' }}
                            />
                            <Button
                                label="No"
                                onClick={() => setModal(false)}
                                style={{ width: '80px', marginLeft: '20px' }}
                            />
                        </div>
                    </Modal>
                )}
            </>
        )
    );
};

export default observer(BusinessProfileTable);
