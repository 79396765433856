import translateLabel from '../../helpers/translateLabel';
import translations from '../../translations/de';

import Input from '../../components/Input';
import InputFile from '../../components/InputFile';
import Textarea from '../../components/Textarea';

export default function getNotificationFormInput({
    name,
    register,
    control,
    error,
    disabled,
    index,
    resetValue,
}) {
    if (name === 'image') {
        return (
            <InputFile
                key={index}
                label={translations.image}
                name={name}
                {...register(name, {
                    required: false,
                })}
                error={error}
                control={control}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/notification-items"
            />
        );
    } else if (name === 'text') {
        return (
            <Textarea
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, { required: true })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
