import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaTrash, FaLongArrowAltRight, FaSearch } from 'react-icons/fa';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import { formatDate } from '../../helpers/formatDate';
import Pagination from './../../components/Pagination';
import translations from '../../translations/de';
import Input from '../../components/Input';
import debounce from 'lodash.debounce';
import HomeGamesFilter from '../../components/HomeGamesFilter';
import InputSeason from '../../components/InputSeason/InputSeason';

const Accesses = ({ store }) => {
    const history = useHistory();
    const location = useLocation();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [accessID, setAccessID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [gameID, setGameID] = useState(null);

    const passID =
        location.search && location.search.includes('?pass_id=')
            ? location.search.split('?pass_id=')[1]
            : null;

    useEffect(() => {
        selectedSeason && store.getAccesses(passID, searchQuery, gameID, selectedSeason);
    }, [store, store.selectedPage, passID, gameID, searchQuery, selectedSeason]);

    const handleAccess = (id) => {
        store.selectAccess(id);
        history.push(`/etickets/${id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

      const handleSeasonChange = (e) => {
          //remove game filter
          setGameID(null);
          setSelectedSeason(e);
      };

      const handleGameChange = (e) => {
          if (e) {
              store.selectedPage !== 1 && store.setSelectedPage(1);
          }
          setGameID(e);
      };

      return (
          <>
              <div className="tab-controls">
                  <Input
                      label={translations.search_title}
                      placeholder={translations.search}
                      defaultValue={searchQuery}
                      onChange={onSearchChangeHandler}
                  />
                  <HomeGamesFilter
                      filterByGameDate={(e) => handleGameChange(e.value)}
                      activeSeason={selectedSeason}
                  />
                  <InputSeason
                      selectedSeason={selectedSeason}
                      setSelectedSeason={(e) => handleSeasonChange(e)}
                  />
              </div>
              {
                  <Table
                      isLoading={!store.dataLoaded}
                      tablePagination={
                          store.totalPages > 1 && (
                              <Pagination
                                  totalPages={store.totalPages}
                                  selectedPage={store.selectedPage}
                                  onSelectedPageChange={(selected) =>
                                      store.setSelectedPage(selected)
                                  }
                              />
                          )
                      }
                      isRowClickable={false}
                      tableName="e-Tickets"
                      columns={[
                          {
                              Header: 'ID',
                              accessor: 'id',
                              Cell: ({ value }) => <div>{value}</div>,
                          },
                          {
                              Header: translations.match,
                              accessor: 'game',
                              Cell: ({ value }) => (
                                  <div>
                                      {value && formatDate(value.date)} <br />
                                      {value?.away_team?.title ? value.away_team.title : null}
                                  </div>
                              ),
                          },
                          {
                              Header: translations.pass,
                              accessor: 'abo',
                              Cell: ({ value }) => (
                                  <div>
                                      {value.id && (
                                          <Link to={`/abos/${value.id}`}>
                                              <FaLongArrowAltRight />
                                              {value.token}
                                          </Link>
                                      )}
                                  </div>
                              ),
                          },
                          {
                              Header: translations.seat,
                              accessor: 'seat',
                              Cell: ({ value }) => (
                                  <div>
                                      Entrance: {value?.seat_group?.sector?.title ?? ''} <br />
                                      Block: {value.seat_group.title} <br />
                                      Row: {value.row} <br />
                                      Seat: {value.num} <br />
                                  </div>
                              ),
                          },
                          {
                              Header: '',
                              accessor: 'action',
                              Cell: ({ row }) => (
                                  <div>
                                      <Button
                                          onClick={() => {
                                              handleAccess(row.cells[0].value);
                                          }}
                                          title={translations.show}
                                      >
                                          <FaSearch />
                                      </Button>
                                      <Button
                                          onClick={() => {
                                              setAccessID(row.cells[0].value);
                                              setModal(true);
                                          }}
                                          title={translations.delete}
                                      >
                                          <FaTrash />
                                      </Button>
                                  </div>
                              ),
                          },
                      ]}
                      data={store.accesses?.map(({ id, game, pass }) => {
                          return {
                              id,
                              game,
                              abo: {
                                  id: pass?.id,
                                  token: pass?.assign_token,
                              },
                              seat: pass?.seat,
                          };
                      })}
                  />
              }
              {modal && (
                  <Modal
                      title="Delete e-Ticket"
                      ref={modalRef}
                      onClose={() => setModal(false)}
                      maxWidth="400px"
                  >
                      <p style={{ color: '#333', marginBottom: '20px' }}>
                          {`Are you sure that you want to delete this e-ticket with id ${accessID}?`}
                      </p>
                      <div className="Modal-Controls">
                          <Button
                              label="Yes"
                              onClick={() => {
                                  store.deleteAccess(accessID);
                                  setModal(false);
                              }}
                              style={{ width: '80px' }}
                          />
                          <Button
                              label="No"
                              onClick={() => setModal(false)}
                              style={{ width: '80px', marginLeft: '20px' }}
                          />
                      </div>
                  </Modal>
              )}
          </>
      );
};

export default observer(Accesses);
