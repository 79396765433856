import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import { FaTrash, FaPlus, FaSearch } from 'react-icons/fa';
import Pagination from './../../components/Pagination';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';
import { formatDate } from '../../helpers/formatDate';
import BusinessEventsMainTable from './BusinessEventsMainTable';
import ItemImage from '../../components/ItemImage';

const BusinessEvents = ({ store, newEventStore }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [eventID, setEventID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);

    useEffect(() => {
        store.getEvents(searchQuery);
    }, [store, store.selectedPage, searchQuery]);

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

    const handleEvent = (id) => {
        store.selectEvent(id);
        history.push(`/business-events/${store.selectedEvent.id}`);
    };

    const getEventStatus = (eventStart, eventEnd) => {
        const current = new Date();
        const start = new Date(eventStart);
        const end = new Date(eventEnd);
        if (start < current && end < current) {
            return translations.event_passed;
        } else if (start < current && end > current) {
            return translations.event_ongoing;
        } else if (start > current && end > current) {
            return translations.event_upcoming;
        } else return '';
    };

    return (
        <>
            <div style={{ marginLeft: '2.5%' }}>
                <Input
                    placeholder={translations.search}
                    defaultValue={searchQuery}
                    onChange={onSearchChangeHandler}
                />
                <Button
                    label={translations.business_event}
                    onClick={() => {
                        store.selectEvent(undefined);
                        newEventStore.resetNewEvent();
                        history.push(Paths.NEW_BUSINESS_EVENT);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {store.events && store.events.length !== 0 && (
                <BusinessEventsMainTable
                    isBusinessEvents={true}
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName={translations.business_events}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.date,
                            accessor: 'date',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.title,
                            accessor: 'title',
                            Cell: ({ value }) => (
                                <div>
                                    <p className="truncate-text">{value}</p>
                                </div>
                            ),
                        },
                        {
                            Header: translations.image,
                            accessor: 'image_url',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="event" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.status,
                            accessor: 'status',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleEvent(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    <Button
                                        title={translations.delete}
                                        onClick={() => {
                                            setEventID(row.cells[0].value);
                                            setModal(true);
                                        }}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.events.map(({ id, start, end, title, image_url }) => {
                        return {
                            id,
                            date: formatDate(start),
                            title,
                            image_url,
                            status: getEventStatus(start, end),
                        };
                    })}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Event"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this event?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteEvent(eventID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(BusinessEvents);
