import { types, applySnapshot } from 'mobx-state-tree';
import Seat from './../seatsStore/Seat';

const SeatMap = types
    .model('SeatMap', {
        id: types.identifierNumber,
        scenario_id: types.number,
        mapped_seat_id: types.number,
        original_seat_id: types.number,
        mapped_seat: types.maybeNull(Seat),
        original_seat: types.maybeNull(Seat),
    })
    .actions((self) => ({
        editSeatMap(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default SeatMap;
