import React from 'react';
import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/businessEvents';
import DatePicker from '../../components/DatePicker';
import InputFile from '../../components/InputFile';
import Textarea from '../../components/Textarea';
import Checkbox from '../../components/Checkbox';
import SearchPlaceInput from '../../components/SearchPlace';
import translations from '../../translations/de';

export default function getBusinessEventFormInput({
    name,
    value,
    register,
    control,
    error,
    disabled,
    index,
    resetValue,
    setCoordinates,
    watchIsRespondable,
}) {
    if (name === 'start' || name === 'end') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                defaultValue={value}
                control={control}
                disabled={disabled}
                error={error}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                minDate={new Date()}
                withTime
            />
        );
    } else if (name === 'time_to_respond') {
        return watchIsRespondable ? (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                defaultValue={value}
                control={control}
                disabled={disabled}
                error={error}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                minDate={new Date()}
                withTime
            />
        ) : null; 
    } else if (name === 'image_url') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: true,
                })}
                error={error}
                control={control}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/business-events"
            />
        );
    } else if (name === 'address') {
        return (
            <SearchPlaceInput
                key={index}
                label={translateLabel(name)}
                name={name}
                control={control}
                disabled={disabled}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                setCoordinates={setCoordinates}
            />
        );
    } else if (name === 'text') {
        return (
            <Textarea
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'subtitle' || name === 'program') {
        return (
            <Textarea
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (typeof value === 'boolean')
    {
        return (
            <Checkbox
                key={index}
                text={translateLabel(name)}
                name={name}
                {...register(name, { required: false })}
                disabled={disabled}
            />
        );
    } else if (name !== 'lat' && name !== 'long') {
        return (
            <Input
                key={index}
                label={name === 'location' ? 'Lokalität' : translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
