import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';

import API from '../../api/users';

import { formatDate, formatTime } from '../../helpers/formatDate';

import translations from '../../translations/de';
import Paths from '../../constants/paths';

const TransactionsTable = ({ user_id }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const getBalanceHistory = async () => {
            try {
                const res = await API.getUserBalanceHistory(user_id, {
                    params: { page: currentPage },
                });
                setData(res?.data?.items);
                setTotalPages(res?.data?.total_pages_count);
            } catch (e) {
                setLoading(false);
            }
        };
        getBalanceHistory();
    }, [user_id, currentPage]);

    const openFanGame = (fan_game_id) => {
        fan_game_id && history.push(`${Paths.FAN_GAMES}/${fan_game_id}`);
    };

    return !loading ? (
        <>
            {data?.length !== 0 && (
                <Table
                    tableName={translations.balance_history}
                    tablePagination={
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={currentPage}
                                onSelectedPageChange={(selected) => setCurrentPage(selected)}
                            />
                        )
                    }
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.balance_before,
                            accessor: 'balance_before',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.balance_after,
                            accessor: 'balance_after',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.action,
                            accessor: 'action_type',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.fan_game,
                            accessor: 'fan_game',
                            Cell: ({ value }) => (
                                <div onClick={() => openFanGame(value[1])}>
                                    {value && (
                                        <p style={{ color: 'blue', cursor: 'pointer' }}>
                                            {value[0]}
                                        </p>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.created_at,
                            accessor: 'created_at',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                    ]}
                    data={data.map(
                        ({
                            id,
                            balance_before,
                            balance_after,
                            action_type,
                            created_at,
                            fan_game,
                        }) => {
                            return {
                                id,
                                balance_before,
                                balance_after,
                                action_type,
                                fan_game: fan_game ? [fan_game.name, fan_game.id] : null,
                                created_at: `${formatDate(created_at)} ${formatTime(created_at)}`,
                            };
                        },
                    )}
                />
            )}
        </>
    ) : (
        <div className="loader-wrapper">
            <Loader size="big" theme="dark" />
        </div>
    );
};

export default TransactionsTable;
