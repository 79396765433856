import { types } from 'mobx-state-tree';

const Mood = types.model('Mood', {
    count: types.number,
    fan_game_answer_image_url: types.optional(types.string, ''),
    fan_game_answer_text: types.optional(types.string, ''),
    percentage: types.maybeNull(types.number),
});

export default Mood;
