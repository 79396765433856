import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import './../Passes/Pass.scss';
import PassForm from './PassForm';
import API from './../../api/seasons';
import translations from '../../translations/de';

const NewPass = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [seasons, setSeasons] = useState(null);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        const getSeasons = async () => {
            try {
                const res = await API.getSeasons();
                setSeasons(res.data.map((season) => ({ value: season.id, label: season.title })));
            } catch (error) {
                console.log(error);
            }
        };
        getSeasons();
    }, []);

    const passDetails = {
        ticket_id: '',
        assign_token: '',
        type: '',
        //create_anonymous_links: false,
    };

    const passBuyer = {
        company: '',
        first_name: '',
        last_name: '',
        street: '',
        zip: '',
        city: '',
        country: '',
    };

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .addPass({
                ...e,
            })
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return (
        <PassForm
            edit={true}
            error={error}
            loading={loading}
            name={`${translations.pass} ${translations.create}`}
            passDetails={passDetails}
            passBuyer={passBuyer}
            onSubmit={onSubmitHandler}
            onCancel={() => history.goBack()}
            seasons={seasons}
            creatingNewEntity={true}
        />
    );
};

export default observer(NewPass);
