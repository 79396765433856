import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import './../Passes/Pass.scss';
import PassForm from './PassForm';
import filterObject from '../../helpers/filterObject';

const Pass = ({ store }) => {
    const { abo_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const passDetails = filterObject(store.selectedPass, [
        'ticket_id',
        'type',
        //"create_anonymous_links",
    ]);

    const passBuyer = filterObject(store.selectedPass, [
        'first_name',
        'last_name',
        'street',
        'zip',
        'city',
        'country',
    ]);

    useEffect(() => {
        store.getPass(abo_id);
    }, [store, abo_id]);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editPass({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedPass ? (
        <PassForm
            selectedPass={store.selectedPass}
            edit={edit}
            error={error}
            loading={loading}
            name={store.selectedPass.assign_token}
            passId={store.selectedPass.id}
            user={store.selectedPass.user}
            seat={store.selectedPass.seat}
            numOfETickets={store.getNumOfPassAccesses(store.selectedPass.id)}
            passDetails={passDetails}
            passBuyer={passBuyer}
            onSubmit={onSubmitHandler}
            onEdit={() => setEdit(true)}
            onCancel={() => {
                error && setError(null);
                setEdit(false);
            }}
            creatingNewEntity={false}
        />
    ) : null;
};

export default observer(Pass);
