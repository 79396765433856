import { types } from 'mobx-state-tree';
import { applySnapshot } from 'mobx-state-tree';
const SingleNews = types
    .model('SingleNews', {
        //id: types.identifierNumber,
        title: types.optional(types.string, ''),
        guid: types.maybeNull(types.string, ''),
        description: types.optional(types.string, ''),
        image: types.optional(types.string, ''),
        link: types.optional(types.string, ''),
        publishing_date: types.optional(types.string, ''),
        deleted_at: types.maybeNull(types.string),
        content: types.optional(types.string, ''),
        hide: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        editNews(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default SingleNews;
