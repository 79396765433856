import { types } from 'mobx-state-tree';
import GameAnswer from './GameAnswer';

const NewQuestion = types.model('New Question', {
    title: types.optional(types.string, ''),
    image_url: types.optional(types.string, ''),
    text: types.optional(types.string, ''),
    fan_game_answers: types.optional(types.array(GameAnswer), []),
});

export default NewQuestion;
