import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import Item from '../../components/Item';
import ItemImage from '../../components/ItemImage';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import getBusinessPartnerFormInput from './getBusinessPartnerFormInput';
import ProfilesTable from './ProfilesTable';
import clearObjProps from '../../helpers/clearObjProps';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import './businessPartner.scss';
import API from '../../api/businessProfiles';
import Input from '../../components/Input';
import debounce from 'lodash.debounce';

const BusinessPartner = ({ store }) => {
    const history = useHistory();
    const { partner_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resetProp, setResetProp] = useState(null);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        store.getPartner(partner_id);
    }, [store, partner_id]);

    useEffect(() => {
        if (edit) {
            const getProfiles = async () => {
                try {
                    const res = await API.getProfiles({
                        params: {
                            search: searchQuery ? searchQuery : undefined,
                            page,
                        },
                    });
                    setData(res.data.items);
                    setTotalPages(res.data.total_pages_count);
                } catch (error) {
                    console.log(error);
                }
            };
            getProfiles();
        }
    }, [edit, page, searchQuery]);

    useEffect(() => {
        if (edit) {
            store.selectedPartner.profiles.forEach((profile) => {
                store.addProfile(profile.id);
            });
        } else {
            store.resetProfiles();
        }
    }, [edit, store]);

    const BusinessPartnerModel = clearObjProps({ ...store.selectedPartner }, [
        'id',
        'profiles',
        'deleted_at',
    ]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editPartner({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    const onSearchProfileHandler = debounce((e) => {
        setPage(1);
        setSearchQuery(e.target.value);
    }, 200);

    return store.selectedPartner ? (
        <>
            {!edit && (
                <>
                    <div className="profiles-list">
                        <h2>
                            {store.selectedPartner.name} / {translations.business_profiles}
                        </h2>
                        <div
                            style={{
                                width: '200px',
                            }}
                        ></div>

                        <Button
                            style={{
                                marginLeft: '2.5%',
                            }}
                            label={translations.business_profile}
                            onClick={() => {
                                history.push(
                                    `/business-partners/${store.selectedPartner.id}/new_business_profile`,
                                );
                            }}
                        >
                            {' '}
                            <FaPlus style={{ marginRight: '5px' }} />
                        </Button>
                    </div>
                    {store.selectedPartner.profiles && (
                        <>
                            <ProfilesTable
                                users={store.selectedPartner.profiles}
                                store={store}
                                isDisabled={edit}
                                onPageChange={(p) => setPage(p)}
                                totalPages={totalPages}
                                selectedPage={page}
                            />
                        </>
                    )}
                </>
            )}

            <div className="space" />
            <Item
                links={[{ path: Paths.BUSINESS_PARTNERS, name: translations.business_partner }]}
                name={store.selectedPartner.name}
                id={`[Business_Partner_ID: ${store.selectedPartner.id}]`}
                itemModel={BusinessPartnerModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getBusinessPartnerFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        resetValue: (name, value) => setResetProp({ [name]: value }),
                    })
                }
                resetProp={resetProp}
            >
                <ItemImage imgSrc={store.selectedPartner.logo} />
            </Item>
            {edit && (
                <div className="tab-controls" style={{ width: '96%' }}>
                    <h2
                        style={{
                            width: '100%',
                            fontWeight: 400,
                            borderBottom: '1px solid lightGray',
                        }}
                    >
                        Add Business Profiles
                    </h2>
                    <Input
                        label={translations.people}
                        placeholder={translations.search_name_email}
                        onChange={onSearchProfileHandler}
                        style={{ margin: '10px 0' }}
                    />
                </div>
            )}
            {edit && (
                <ProfilesTable
                    users={data}
                    store={store}
                    isDisabled={true}
                    onPageChange={(p) => setPage(p)}
                    totalPages={totalPages}
                    selectedPage={page}
                />
            )}
        </>
    ) : null;
};

export default observer(BusinessPartner);
