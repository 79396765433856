import React, { useState } from 'react';
import Table from '../../components/Table';
// import Input from "../../components/Input";
import { observer } from 'mobx-react-lite';
import Pagination from '../../components/Pagination';
import translations from '../../translations/de';
import { useHistory } from 'react-router-dom';

const UsersTable = ({
    users,
    store,
    isDisabled = false,
    totalPages = 1,
    selectedPage = 1,
    onPageChange,
}) => {
    const history = useHistory();
    const [checkedUsers, setCheckedUsers] = useState([]);

    const checkIfChecked = (id) => {
        if (store.recipients.includes(id)) {
            return true;
        } else {
            return false;
        }
    };

    const handleUser = (id) => {
        if (!isDisabled) {
            if (store.recipients.includes(id)) {
                store.removeRecipient(id);
                setCheckedUsers(checkedUsers.filter((t) => t !== id));
            } else {
                store.addRecipient(id);
                setCheckedUsers((checkedUsers) => [...checkedUsers, id]);
            }
        } else {
            history.push(`/fans/${id}`);
        }
    };

    return (
        users.length !== 0 && (
            <>
                <Table
                    tablePagination={
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                onSelectedPageChange={(selected) => onPageChange(selected)}
                            />
                        )
                    }
                    tableName={' '}
                    isRowClickable={true}
                    columns={[
                        {
                            Header: `ID`,
                            accessor: 'id',
                            Cell: ({ value, row }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                            ),
                        },
                        {
                            Header: `${translations.name}`,
                            accessor: 'first_name',
                            Cell: ({ value, row }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                            ),
                        },
                        {
                            Header: `${translations.last_name}`,
                            accessor: 'last_name',
                            Cell: ({ value, row }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                            ),
                        },
                        {
                            Header: `${translations.email}`,
                            accessor: 'email',
                            Cell: ({ value, row }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                            ),
                        },
                        {
                            Header: '',
                            accessor: 'check',
                            Cell: ({ row }) =>
                                !isDisabled && (
                                    <div onClick={() => handleUser(row.cells[0].value)}>
                                        <input
                                            type="checkbox"
                                            disabled={isDisabled}
                                            defaultChecked={checkIfChecked(row.cells[0].value)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (e.target.checked) {
                                                    store.addRecipient(row.cells[0].value);
                                                } else if (!e.target.checked) {
                                                    store.removeRecipient(row.cells[0].value);
                                                }
                                            }}
                                            title="Check"
                                        ></input>
                                    </div>
                                ),
                        },
                    ]}
                    data={users.map(({ id, first_name, last_name, email }) => {
                        return {
                            id,
                            first_name,
                            last_name,
                            email,
                        };
                    })}
                />
                {!isDisabled && totalPages > 1 && (
                    <Pagination
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        onSelectedPageChange={(selected) => onPageChange(selected)}
                    />
                )}
            </>
        )
    );
};

export default observer(UsersTable);
