import { useState, useEffect } from 'react';
import Select from '../Select';
import Button from '../Button';
import Input from '../Input';
import { useForm } from 'react-hook-form';
import API from '../../api/teams';
import APIGame from '../../api/games';
import ErrorMessages from '../ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import translations from '../../translations/de';

const SetResult = ({ store, onClose }) => {
    const [teamId, setTeamId] = useState(null);
    const [expand, setExpand] = useState(false);
    const [players, setPlayers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            team_id: '',
            scorer_id: '',
            assist_one_id: '',
            assist_two_id: '',
            minutes: 0,
        },
    });

    useEffect(() => {
        const getPlayers = async () => {
            try {
                const res = await API.getTeam(teamId);
                setPlayers(
                    res.data.players.map((player) => ({
                        value: player.id,
                        label: player.first_name + ' ' + player.last_name,
                    })),
                );
            } catch (error) {
                console.log(error.message);
            }
        };
        if (teamId) {
            getPlayers();
            setExpand(true);
        }
    }, [teamId]);

    const submitHandler = async (e) => {
        error && setError(null);
        setLoading(true);
        const result = { ...e };
        //set empty values ("") to null (null values will not be sent in a result body)
        Object.keys(result).forEach((key) => {
            let value = result[key];
            if (value === '') {
                result[key] = null;
            }
        });
        try {
            const res = await APIGame.setFirstScorerResult(store.id, result);
            store.setGameResult(res.data.fan_game_details);
            setLoading(false);
            onClose();
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };
    return (
        <form className="set-result" onSubmit={handleSubmit(submitHandler)}>
            <Select
                name="team_id"
                placeholder="Select Team"
                label="Team that scored first:"
                options={store.questions[0].fan_game_answers.map((ans) => ({
                    value: ans.team_id,
                    label: ans.text,
                }))}
                isRequired={true}
                error={errors.team_id}
                control={control}
                onChangeCallback={(id) => setTeamId(id)}
            />
            {expand ? (
                <>
                    <Select
                        name="scorer_id"
                        placeholder="Select Player"
                        label="Player that scored first:"
                        options={players}
                        isRequired={true}
                        error={errors.scorer_id}
                        control={control}
                        noOptionsMsg="No players"
                    />
                    <Select
                        name="assist_one_id"
                        placeholder="Select Player"
                        label="Assisted By:"
                        options={players}
                        isRequired={false}
                        error={errors.assist_one_id}
                        control={control}
                        isClearable
                        noOptionsMsg="No players"
                    />
                    <Select
                        name="assist_two_id"
                        placeholder="Select Player"
                        label="Second Assisted By:"
                        options={players}
                        isRequired={false}
                        error={errors.assist_two_id}
                        control={control}
                        isClearable
                        noOptionsMsg="No players"
                    />
                    <Input
                        label="Minute Scored:"
                        name="minutes"
                        type="number"
                        min="0"
                        max="90"
                        {...register('minutes', {
                            required: true,
                            valueAsNumber: true,
                        })}
                        error={errors.minutes}
                    />
                </>
            ) : null}
            <div className="set-result-controls">
                <Button label={translations.cancel} type="button" onClick={onClose} />
                <Button loading={loading} label={translations.save} type="submit"></Button>
            </div>
            <ErrorMessages
                messages={requsetErrorMessage(error && error.message ? error.message : error)}
            />
        </form>
    );
};

export default SetResult;
