import React from 'react';
import useAcquirers from '../../customHooks/useAcquirers';
import translations from '../../translations/de';
import Table from '../Table';
import { useHistory } from 'react-router-dom';
import Loader from '../Loader';
import Pagination from '../Pagination';
import { formatDate, formatTime } from '../../helpers/formatDate';
const Acquirers = ({ reward_id = null, shop_item_id = null, date_of_expire }) => {
    const [data, loading, totalPages, selectedPage, setPage] = useAcquirers(
        reward_id,
        shop_item_id,
    );
    const history = useHistory();

    const handleUser = (id) => {
        history.push(`/fans/${id}`);
    };

    return data?.length > 0 ? (
        <Table
            tableName={translations.acquirer}
            tablePagination={
                totalPages > 1 && (
                    <Pagination
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        onSelectedPageChange={(selected) => setPage(selected)}
                    />
                )
            }
            isRowClickable={true}
            columns={[
                {
                    Header: 'ID',
                    accessor: 'id',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
                {
                    Header: translations.name,
                    accessor: 'name',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
                {
                    Header: translations.date_of_scan,
                    accessor: 'date_of_scan',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
                {
                    Header: translations.date_of_expire,
                    accessor: 'expire_at',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
            ]}
            data={data.map(({ user, date_of_scan, voucher }) => {
                return {
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    date_of_scan: date_of_scan
                        ? `${formatDate(date_of_scan)} ${formatTime(date_of_scan)}`
                        : '/',
                    expire_at: voucher?.expiry_date
                        ? `${formatDate(voucher.expiry_date)} ${formatTime(voucher.expiry_date)}`
                        : '/',
                };
            })}
        />
    ) : loading ? (
        <div className="loader-wrapper">
            <Loader size="big" theme="dark" />
        </div>
    ) : (
        <div className="no-data">No Data Yet...</div>
    );
};

export default Acquirers;
