import React, { useState } from 'react';
import Input from './../../components/Input';
import Button from './../../components/Button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import API from './../../api/auth';
import ErrorMessages from './../../components/ErrorMessages.js';
import requsetErrorMessage from './../../helpers/requsetErrorMessage';
import Paths from '../../constants/paths';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import translations from '../../translations/de';

const Form = ({ adminStore, showResetForm }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorLoginMessage, setErrorLoginMessage] = useState('');
    const history = useHistory();
    const reCAPTCHA = useRef();
    const reCaptchaToken = useRef('');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ criteriaMode: 'all' });

    const goToStartingPage = () => {
        /// for different roles goes to different home page
        if (adminStore.roles.includes('ADMIN') || adminStore.roles.includes('ADMIN_USER'))
            history.push(Paths.HOME);
        else if (adminStore.roles.includes('ADMIN_TICKETING')) history.push(Paths.PASSES);
        else if (adminStore.roles.includes('ADMIN_FAN_INTERACTION')) history.push(Paths.TEAMS);
        else if (adminStore.roles.includes('ADMIN_BUSINESS')) history.push(Paths.BUSINESS_EVENTS);
        else if (adminStore.roles.includes('ADMIN_GASTRO')) history.push(Paths.INSTANT_REWARDS);
    };

    const handleLogin = async (e) => {
        if (!reCaptchaToken.current) {
            reCaptchaToken.current = await reCAPTCHA.current.executeAsync();
        }

        try {
            setIsLoading(true);
            const res = await API.login({
                email: e.email,
                password: e.password,
                token: reCaptchaToken.current,
            });
            adminStore.setAdmin(res.data);
            goToStartingPage();
        } catch (error) {
            setIsLoading(false);
            if (error.message === 'UserErrors.InvalidAdminRecaptcha') {
                setErrorLoginMessage(
                    requsetErrorMessage('Something went wrong, please reload page'),
                );
            } else {
                setErrorLoginMessage(
                    requsetErrorMessage(
                        error && error.message
                            ? error.message
                            : 'Server not working! Please contact your administrator.',
                    ),
                );
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(handleLogin)}>
            <Input
                label={translations.username}
                {...register('email', { required: true })}
                error={errors.email}
                disabled={isLoading}
            />

            <Input
                ispassword
                label={translations.password}
                {...register('password', { required: true })}
                error={errors.password}
                disabled={isLoading}
            />
            <span className="forgot-password-link" onClick={() => showResetForm(true)}>
                Forgot password?
            </span>
            <Button
                type="submit"
                label={translations.login}
                loading={isLoading}
                disabled={isLoading}
                fullwidth
            />

            <ErrorMessages messages={errorLoginMessage} />
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                size="invisible"
                ref={reCAPTCHA}
            />
        </form>
    );
};

export default Form;
