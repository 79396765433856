import api from './apiLibrary';

const API = {
    getAllNews(params) {
        return api.get('/api/admin/news', params);
    },
    getSingleNews(guid, params) {
        return api.get(`/api/admin/news/${guid}`, params);
    },
    createNews(data) {
        return api.post('/api/admin/news', data);
    },
    editNews(guid, data) {
        return api.put(`/api/admin/news/${guid}`, data);
    },
    deleteNews(guid) {
        return api.delete(`/api/admin/news/${guid}`);
    },
    reviveNews(guid) {
        return api.patch(`/api/admin/news/undelete/${guid}`);
    },
    hideNews(guid) {
        return api.patch(`/api/admin/news/hide/${guid}`);
    },
    refreshDatabase() {
        return api.post(`/api/admin/news/refetch`);
    },
};

export default API;
