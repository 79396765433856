import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import getMatchFormInput from './getMatchFormInput';
import translations from '../../translations/de';

const NewMatch = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const ownerID = store.teams.length && store.teams.find((t) => t.is_owner).id;
    const [homeTeamOptions, setHomeTeamOptions] = useState(store.teams);
    const [awayTeamOptions, setAwayTeamOptions] = useState(store.teams);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        !store.currentSeasonId && store.getCurrentSeasonId();
    }, [store]);

    const onTeamChangeHandler = (field, teamID) => {
        if (field === 'home_team_id') {
            if (!teamID) {
                setHomeTeamOptions(store.teams);
                return;
            }
            if (teamID === ownerID) {
                setAwayTeamOptions(store.teams.filter((t) => t.id !== teamID));
            } else {
                setAwayTeamOptions([store.teams.find((t) => t.id === ownerID)]);
            }
        } else if (field === 'away_team_id') {
            if (!teamID) {
                setAwayTeamOptions(store.teams);
                return;
            }
            if (teamID === ownerID) {
                setHomeTeamOptions(store.teams.filter((t) => t.id !== teamID));
            } else {
                setHomeTeamOptions([store.teams.find((t) => t.id === ownerID)]);
            }
        }
    };

    return (
        <Item
            links={[{ path: '/matches', name: translations.matches }]}
            name={`${translations.match} ${translations.create}`}
            itemModel={{
                date: '',
                location: '',
                home_team_id: '',
                away_team_id: '',
            }}
            edit={true}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .addMatch({ ...event })
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.MATCHES);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, isDisabled, index }) =>
                getMatchFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    isDisabled,
                    index,
                    teams:
                        name === 'home_team_id'
                            ? homeTeamOptions
                            : name === 'away_team_id'
                            ? awayTeamOptions
                            : null,
                    onTeamChange: onTeamChangeHandler,
                })
            }
        />
    );
};

export default observer(NewMatch);
