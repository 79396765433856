import { types, applySnapshot } from 'mobx-state-tree';
import SeatGroup from './../SeatGroupsStore/SeatGroup';

const Seat = types
    .model('Seat', {
        id: types.identifierNumber,
        row: '',
        num: '',
        seat_group: types.maybeNull(SeatGroup),
    })
    .actions((self) => ({
        editSeat(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));
export default Seat;
