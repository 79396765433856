import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useModal from '../../customHooks/useModal';
import Button from '../../components/Button';
import { FaTrash, FaUndoAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import { IoReload } from 'react-icons/io5';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Pagination from './../../components/Pagination';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';
import { formatDate, formatTime } from '../../helpers/formatDate';
import API from '../../api/news';

const News = ({ store }) => {
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [searchQuery, setSearchQuery] = useState('');
    const [newsID, setNewsID] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.getAllNews(searchQuery, store.selectedPage);
    }, [store, store.selectedPage, searchQuery]);

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const refreshDatabase = async () => {
        setLoading(true);
        try {
            await API.refreshDatabase();
            setLoading(false);
            store.getAllNews(searchQuery);
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    return (
        <>
            <div style={{ marginLeft: '2.5%' }}>
                <Input placeholder={translations.search} onChange={onSearchChangeHandler} />
                <Button
                    loading={loading}
                    label={translations.refetch_news}
                    onClick={() => {
                        refreshDatabase();
                    }}
                >
                    <IoReload style={{ marginRight: '5px' }} />
                </Button>
            </div>
            <Table
                tablePagination={
                    store.totalPages > 1 && (
                        <Pagination
                            totalPages={store.totalPages}
                            selectedPage={store.selectedPage}
                            onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                        />
                    )
                }
                tableName={translations.news}
                columns={[
                    {
                        //Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value }) => <div>{}</div>,
                    },
                    {
                        Header: translations.publishing_date,
                        accessor: 'publishing_date',
                        Cell: ({ value }) => (
                            <div>{`${formatDate(value)} ${formatTime(value)}`}</div>
                        ),
                    },
                    {
                        Header: translations.title,
                        accessor: 'title',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'Guid',
                        accessor: 'guid',
                        isHidden: true,
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.image,
                        accessor: 'image',
                        Cell: ({ value }) => (
                            <div>
                                <ItemImage imgSrc={value} alt="reward" listView={true} />
                            </div>
                        ),
                    },
                    {
                        Header: '',
                        accessor: 'hide',
                        Cell: ({ value, row }) => (
                            <div>
                                {/* <Button
                                    onClick={() => handleNews(row.cells[0].value)}
                                    title="Detailed Preview"
                                >
                                    {' '}
                                    <FaSearch />
                                </Button> */}
                                {isNotDeletedAlready(row) && (
                                    <Button
                                        onClick={() => store.hideNews(row.cells[3].value)}
                                        title="Hide news"
                                    >
                                        {value ? <FaEyeSlash /> : <FaEye />}
                                    </Button>
                                )}
                                {isNotDeletedAlready(row) ? (
                                    <Button
                                        onClick={() => {
                                            setNewsID(row.cells[3].value);
                                            setModal(true);
                                        }}
                                        title={translations.delete}
                                    >
                                        <FaTrash />
                                    </Button>
                                ) : (
                                    <Button onClick={() => store.reviveNews(row.cells[3].value)}>
                                        <FaUndoAlt />
                                    </Button>
                                )}
                            </div>
                        ),
                    },
                ]}
                data={store.news.map(
                    ({ title, publishing_date, deleted_at, guid, image, hide }) => {
                        return {
                            title,
                            publishing_date,
                            deleted_at,
                            guid,
                            image,
                            hide,
                        };
                    },
                )}
            />
            {modal && (
                <Modal
                    title="Delete News"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this news?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteNews(newsID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(News);
