import api from './apiLibrary';

const API = {
    getVouchers(params) {
        return api.get('/api/admin/voucher', params);
    },
    getVoucher(id, data) {
        return api.get(`/api/admin/voucher/${id}`, data);
    },
    getVouchersFromReward(params) {
        return api.get(`/api/admin/voucher`, params);
    },
    sendVoucherCodes(type, data, params) {
        return api.post(`/api/admin/voucher/import-codes-xlsx/${type}`, data, params);
    },
    createVoucher(data) {
        return api.post(`/api/admin/voucher/create`, data);
    },
    editVoucher(id, data) {
        return api.put(`/api/admin/voucher/${id}`, data);
    },
    deleteVoucher(id) {
        return api.delete(`/api/admin/voucher/${id}`);
    },
};

export default API;
