import Input from '../../components/Input';
import Select from '../../components/Select';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/seatMap';
import translations from '../../translations/de';

export default function getSeatMapFormInput({
    name,
    register,
    control,
    error,
    disabled,
    index,
    seats,
    scenarios,
    originalSeats,
    mappedSeats,
    searchSeatOriginalHandler,
    searchSeatMappedHandler,
}) {
    if (name === 'original_seat_id') {
        return (
            <Select
                key={index}
                onInputChange={searchSeatOriginalHandler}
                name={name}
                label={translateLabel(name.replace('_id', ''))}
                options={originalSeats.map((seat) => {
                    return {
                        value: seat.id,
                        label:
                            'E: ' +
                            seat.seat_group.sector.title +
                            '; B: ' +
                            ' ' +
                            seat.seat_group.title +
                            '; R: ' +
                            seat.row +
                            '; S: ' +
                            seat.num +
                            ';',
                    };
                })}
                placeholder={translations.type_to_search_seats}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg={translations.no_seats_found}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                isClearable
            />
        );
    } else if (name === 'mapped_seat_id') {
        return (
            <Select
                key={index}
                onInputChange={searchSeatMappedHandler}
                name={name}
                label={translateLabel('assigned_seat')}
                options={mappedSeats.map((seat) => {
                    return {
                        value: seat.id,
                        label:
                            'E: ' +
                            seat.seat_group.sector.title +
                            '; B: ' +
                            ' ' +
                            seat.seat_group.title +
                            '; R: ' +
                            seat.row +
                            '; S: ' +
                            seat.num +
                            ';',
                    };
                })}
                placeholder={translations.type_to_search_seats}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg={translations.no_seats_found}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                isClearable
            />
        );
    } else if (name === 'scenario_id') {
        return (
            <Select
                key={index}
                name={name}
                label={translateLabel(name.replace('_id', ''))}
                options={scenarios.map((scenario) => {
                    return {
                        value: scenario.id,
                        label: scenario.title,
                    };
                })}
                control={control}
                error={error}
                disabled={disabled}
                noOptionMsg="No scenarios"
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                isClearable
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
