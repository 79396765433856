import React from 'react';
import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import Select from '../../components/Select';
import countries from '../../constants/countries';
import translations from '../../translations/de';
import Checkbox from '../../components/Checkbox/index.js';

export default function getPassFormInput({
    value,
    name,
    register,
    error,
    disabled,
    index,
    control,
    newEdit = false,
}) {
    if (name === 'ticket_id') {
        return (
            <React.Fragment key={name}>
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name="ticket_id"
                    error={error}
                    control={control}
                    {...register(name, {
                        required: true,
                        pattern: {
                            value: /^\S+$/,
                            message: translateLabel('white_space_error'),
                        },
                    })}
                    disabled={newEdit ? false : true}
                />
            </React.Fragment>
        );
    } else if (name === 'deleted_at') {
        return (
            <React.Fragment key={name}>
                <Checkbox
                    disabled={disabled}
                    key={index}
                    text={translateLabel(name)}
                    name={name}
                    {...register(name, { required: false })}
                />
            </React.Fragment>
        );
    } else if (name === 'country') {
        return (
            <React.Fragment key={name}>
                <Select
                    key={index}
                    label={translateLabel(name)}
                    name="country"
                    options={countries}
                    control={control}
                    error={error}
                    disabled={disabled}
                />
            </React.Fragment>
        );
    } else if (name === 'assign_token') {
        return (
            <React.Fragment key={name}>
                <Input
                    key={index}
                    label={translateLabel('assign_token')}
                    name={'assign_token'}
                    {...register(name, {
                        required: translations.required,
                        pattern: {
                            value: /[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/,
                            message:
                                'Token must have XXX-XXX-XXX format, where X is letter or number',
                        },
                    })}
                    error={error}
                    disabled={disabled}
                />
            </React.Fragment>
        );
    } else if (name === 'type') {
        return (
            <React.Fragment key={name}>
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    type={typeof value}
                    {...register(name, {
                        required: false,
                        valueAsNumber: typeof value === 'number' ? true : false,
                    })}
                    error={error}
                    disabled={true}
                />
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment key={name}>
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    type={typeof value}
                    {...register(name, {
                        required: false,
                        valueAsNumber: typeof value === 'number' ? true : false,
                    })}
                    error={error}
                    disabled={disabled}
                />
            </React.Fragment>
        );
    }
}
