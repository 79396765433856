import React, { useEffect, useState } from 'react';
import API from '../../api/passes';
import { useParams } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import translations from '../../translations/de';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { formatDate, formatTime } from '../../helpers/formatDate';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import ItemImage from '../../components/ItemImage';

export const PassGames = ({ adminStore }) => {
    const { abo_id } = useParams();
    const [games, setGames] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);

    const handleMatch = (id) => {
        history.push(`/matches/${id}`);
    };

    useEffect(() => {
        const getGames = async () => {
            setLoading(true);
            try {
                const res = await API.getGames(abo_id, {
                    params: {
                        limit: 10,
                        page: selectedPage,
                    },
                });
                setGames(res.data);
                setLoading(false);
                setTotalPages(res.data.total_pages_count);
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        };
        abo_id && getGames();
    }, [abo_id, selectedPage]);

    return (
        <>
            {' '}
            {loading ? (
                <Loader size="big" theme="dark" />
            ) : (
                games &&
                games.length !== 0 && (
                    <Table
                        tableName={translations.matches}
                        tablePagination={
                            totalPages > 1 && (
                                <Pagination
                                    totalPages={totalPages}
                                    selectedPage={selectedPage}
                                    onSelectedPageChange={(selected) => setSelectedPage(selected)}
                                />
                            )
                        }
                        columns={[
                            {
                                Header: 'ID',
                                accessor: 'id',
                                Cell: ({ value, row }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.date,
                                accessor: 'date',
                                Cell: ({ value, row }) => (
                                    <div>{`${formatDate(value)} ${formatTime(value)}`}</div>
                                ),
                            },
                            {
                                Header: translations.opponent,
                                accessor: 'away_team_logo',
                                Cell: ({ value }) => (
                                    <div>
                                        <ItemImage
                                            imgSrc={value}
                                            alt="away team logo"
                                            listView={true}
                                        />
                                    </div>
                                ),
                            },
                            {
                                Header: translations.scan,
                                accessor: 'scanned_at',
                                Cell: ({ value, row }) => <div>{value}</div>,
                            },
                            {
                                Header: '',
                                accessor: 'action',
                                Cell: ({ row }) => (
                                    <div>
                                        {adminStore.roles.includes('ADMIN') && (
                                            <Button
                                                onClick={() => {
                                                    handleMatch(row.cells[0].value);
                                                }}
                                                title={translations.show}
                                            >
                                                <FaSearch />
                                            </Button>
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                        data={games.map(({ id, away_team, accesses, date }) => {
                            return {
                                id,
                                date,
                                scanned_at:
                                    accesses.length !== 0
                                        ? accesses[0].scanned_at
                                            ? `${formatDate(accesses[0]?.scanned_at)} ${formatTime(
                                                  accesses[0]?.scanned_at,
                                              )}`
                                            : translations.not_scanned
                                        : translations.no_access,
                                away_team_logo: away_team?.logo_url,
                            };
                        })}
                    />
                )
            )}
        </>
    );
};

export default PassGames;
