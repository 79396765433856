import React from 'react';
import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/feeds';
import InputFile from '../../components/InputFile/index.js';
import Textarea from '../../components/Textarea';
import Checkbox from '../../components/Checkbox';
import DatePicker from '../../components/DatePicker';
import translations from '../../translations/de';

export default function getFeedFormInput({
    control,
    value,
    name,
    register,
    error,
    disabled,
    index,
    resetValue,
}) {
    if (name === 'url') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/feeds"
            />
        );
    } else if (name === 'created_at' || name === 'updated_at') {
        return (
            <React.Fragment key={name}>
                <DatePicker
                    disabled={true}
                    key={index}
                    label={translateLabel(name)}
                    selectText={translations.select_date}
                    name={name}
                    defaultValue={value}
                    control={control}
                    error={error}
                    withTime
                    isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                />
            </React.Fragment>
        );
    } else if (name === 'data') {
        return (
            <React.Fragment key={name}>
                <Textarea
                    json={true}
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    type="text"
                    defaultValue={null}
                    {...register(name, {
                        required: mandatoryFields.find((field) => (field === name ? true : false)),
                    })}
                    error={error}
                    disabled={disabled}
                />
            </React.Fragment>
        );
    } else if (name === 'deleted_at') {
        return (
            <React.Fragment key={name}>
                <Checkbox
                    disabled={disabled}
                    key={index}
                    text={translateLabel(name)}
                    name={name}
                    {...register(name, { required: false })}
                />
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment key={name}>
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    {...register(name, {
                        required: mandatoryFields.find((field) => (field === name ? true : false)),
                    })}
                    error={error}
                    disabled={disabled}
                />
            </React.Fragment>
        );
    }
}
