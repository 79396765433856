import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import translations from '../../translations/de';
import Pagination from '../../components/Pagination';
import { FaCheck } from 'react-icons/fa';

const BusinessProfileTable = ({
    store = [],
    profiles = [],
    isDisabled = false,
    totalPages = 1,
    selectedPage = 1,
    onPageChange,
    hasRemove = false,
    className = 'block',
    isEdit = false,
}) => {
    const [localProfiles, setLocalProfiles] = useState([]);

    const handleProfile = (id, value) => {
        if (isEdit) {
            //find profile with correct id and update it's is_invited to true
            const profile = localProfiles.find((pr) => pr.id === id);
            if (!value) {
                const updatedProfiles = localProfiles.map((pr) =>
                    pr.id === id ? { ...pr, is_invited: true } : pr,
                );
                setLocalProfiles(updatedProfiles);
                store.addInvitationFromEdit(profile);
            } else {
                const updatedProfiles = localProfiles.map((pr) =>
                    pr.id === id ? { ...pr, is_invited: false } : pr,
                );
                setLocalProfiles(updatedProfiles);
                store.removeInvitationFromEdit(profile);
                return false;
            }
        } else {
            const profile = store.creating_invitations.find((pr) => pr.id === id);
            if (profile) {
                const updatedProfiles = localProfiles.map((pr) =>
                    pr.id === id ? { ...pr, is_invited: false } : pr,
                );
                setLocalProfiles(updatedProfiles);
                store.removeInvitation(id);
            } else {
                const profile = profiles.find((pr) => pr.id === id);
                const updatedProfiles = localProfiles.map((pr) =>
                    pr.id === id ? { ...pr, is_invited: true } : pr,
                );
                setLocalProfiles(updatedProfiles);
                store.addInvitation(profile);
            }
        }
    };

    useEffect(() => {
        setLocalProfiles(profiles);
    }, [profiles]);

    return (
        <div className={className}>
            {localProfiles.length !== 0 ? (
                <Table
                    tableName={translations.business_profiles}
                    tablePagination={
                        !isDisabled &&
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                onSelectedPageChange={(selected) => onPageChange(selected)}
                            />
                        )
                    }
                    isRowClickable={!isDisabled}
                    columns={[
                        {
                            Header: `ID`,
                            accessor: 'id',
                            Cell: ({ value, row }) => (
                                <div
                                    onClick={() =>
                                        !isDisabled &&
                                        handleProfile(row.cells[0].value, row.cells[6].value)
                                    }
                                >
                                    {value}
                                </div>
                            ),
                        },
                        {
                            Header: `${translations.name}`,
                            accessor: 'name',
                            Cell: ({ value, row }) => (
                                <div
                                    onClick={() =>
                                        !isDisabled &&
                                        handleProfile(row.cells[0].value, row.cells[6].value)
                                    }
                                >
                                    {value}
                                </div>
                            ),
                        },
                        {
                            Header: `${translations.email}`,
                            accessor: 'email',
                            Cell: ({ value, row }) => (
                                <div
                                    onClick={() =>
                                        !isDisabled &&
                                        handleProfile(row.cells[0].value, row.cells[6].value)
                                    }
                                >
                                    {value}
                                </div>
                            ),
                        },
                        {
                            Header: `${translations.partner}`,
                            accessor: 'partner_name',
                            Cell: ({ value, row }) => (
                                <div
                                    onClick={() =>
                                        !isDisabled &&
                                        handleProfile(row.cells[0].value, row.cells[6].value)
                                    }
                                >
                                    {value}
                                </div>
                            ),
                        },
                        {
                            Header: 'Partner type',
                            accessor: 'partner_type',
                            Cell: ({ value, row }) => (
                                <div
                                    onClick={() =>
                                        !isDisabled &&
                                        handleProfile(row.cells[0].value, row.cells[6].value)
                                    }
                                >
                                    {value}
                                </div>
                            ),
                        },
                        {
                            Header: `${translations.phone}`,
                            accessor: 'phone',
                            Cell: ({ value, row }) => (
                                <div
                                    onClick={() =>
                                        !isDisabled &&
                                        handleProfile(row.cells[0].value, row.cells[6].value)
                                    }
                                >
                                    {value}
                                </div>
                            ),
                        },
                        {
                            Header: translations.call,
                            accessor: 'is_invited',

                            Cell: ({ value, row }) => {
                                return !isDisabled ? (
                                    <div
                                        onClick={() =>
                                            handleProfile(row.cells[0].value, row.cells[6].value)
                                        }
                                    >
                                        {value ? (
                                            <FaCheck className="check confirm" />
                                        ) : (
                                            <span className="check"></span>
                                        )}
                                    </div>
                                ) : hasRemove ? (
                                    <div
                                        className="remove"
                                        onClick={() => handleProfile(row.cells[0].value)}
                                    >
                                        Remove
                                    </div>
                                ) : null;
                            },
                        },
                    ]}
                    data={localProfiles.map(
                        ({ id, name, company, avatar, email, phone, is_invited }) => {
                            return {
                                id,
                                name,
                                email,
                                partner_name: company?.name,
                                partner_type: company?.type,
                                avatar,
                                phone,
                                is_invited,
                            };
                        },
                    )}
                />
            ) : (
                <div className="no-data">No Data Yet...</div>
            )}
            {}
        </div>
    );
};

export default observer(BusinessProfileTable);
