import { types, applySnapshot } from 'mobx-state-tree';
import Pass from '../passesStore/Pass';

const Access = types
    .model('Access', {
        id: types.identifierNumber,
        access_id: types.maybeNull(types.number),
        access_token: types.maybeNull(types.string),
        accept_marketing: types.maybeNull(types.boolean),
        pass_id: types.maybeNull(types.number),
        game_id: types.maybeNull(types.number),
        seat_id: types.maybeNull(types.number),
        first_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        street: types.maybeNull(types.string),
        zip: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        company: types.maybeNull(types.string),
        birthday: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        game: types.frozen({
            id: '',
            date: '',
            away_team: {
                title: '',
            },
            home_team: {
                title: '',
            },
        }),
        pass: types.frozen(Pass),
        seat: types.frozen({
            num: '',
            row: '',
            seat_group: {
                title: '',
                sector: {
                    title: '',
                },
            },
        }),
        scanned_at: types.maybeNull(types.string),
        scanner_id: types.maybeNull(types.number),
    })
    .actions((self) => ({
        editAccess(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Access;
