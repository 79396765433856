import api from './apiLibrary';

const API = {
    getAdmins(params) {
        return api.get('/api/admin', params);
    },
    getAdmin(id, params) {
        return api.get(`/api/admin/${id}`, params);
    },
    editAdmin(id, data) {
        return api.put(`/api/admin/${id}`, data);
    },
    createAdmin(data) {
        return api.post('/api/admin', data);
    },
    deleteAdmin(id) {
        return api.delete(`/api/admin/${id}`);
    },
    reviveAdmin(id) {
        return api.put(`/api/admin/${id}/revive`);
    },
};

export default API;
