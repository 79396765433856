import React from 'react';
import { Tooltip, Legend, PieChart, Pie, LabelList } from 'recharts';
import translations from '../../translations/de';

const SalesStatistics = ({ acquiredItem }) => {
    var chartObj = [];

    const handleData = () => {
        acquiredItem.active_num > 0 &&
            chartObj.push({
                value: acquiredItem.active_num,
                name: translations.shop_item_active,
                fill: 'red',
            });
        acquiredItem.used > 0 &&
            chartObj.push({
                value: acquiredItem.used,
                name: translations.shop_item_used,
                fill: '#ccccff',
            });
        acquiredItem.expired > 0 &&
            chartObj.push({
                value: acquiredItem.expired,
                name: translations.shop_item_expired,
                fill: '#009999',
            });
        acquiredItem.available > 0 &&
            chartObj.push({
                value: acquiredItem.available,
                name: translations.shop_item_available,
                fill: 'orange',
            });
    };

    const renderLabel = (payload) => {
        return payload + '%';
    };

    function CustomTooltipPass({ payload }) {
        var value = payload[0]?.payload?.payload;

        switch (value?.name) {
            case translations.shop_item_active:
                return (
                    <div className="tooltip-wrapper shop-item">
                        <p>{`${value.value} ${translations.active_label}`}</p>
                    </div>
                );
            case translations.shop_item_available:
                return (
                    <div className="tooltip-wrapper shop-item">
                        <p>{`${value.value} ${translations.available_label}`}</p>
                    </div>
                );
            case translations.used:
                return (
                    <div className="tooltip-wrapper shop-item">
                        <p>{`${value.value} ${translations.used_label}`}</p>
                    </div>
                );
            case translations.shop_item_expired:
                return (
                    <div className="tooltip-wrapper shop-item">
                        <p>{`${value.value} ${translations.expired_label}`}</p>
                    </div>
                );
            default:
                return <div className="tooltip-wrapper"></div>;
        }
    }

    handleData();
    return (
        <div className="content-wrapper">
            <div className="charts-wrapper">
                <div className="chart-holder">
                    <PieChart width={600} height={400} style={{ outline: 'none' }}>
                        <Pie
                            animationBegin={0}
                            animationDuration={800}
                            style={{ outline: 'none', overflow: 'visible' }}
                            data={chartObj}
                            position="right"
                            dataKey={'value'}
                            cx="50%"
                            cy="50%"
                            outerRadius={130}
                            fill="#ff3300"
                            label
                            transitions
                        >
                            <LabelList data={chartObj} dataKey={`used`} formatter={renderLabel} />
                        </Pie>
                        <Tooltip content={<CustomTooltipPass />} />
                        <Legend layout="vertical" verticalAlign="top" align="right" />
                    </PieChart>
                </div>
            </div>
        </div>
    );
};

export default SalesStatistics;
