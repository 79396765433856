import api from './apiLibrary';

const API = {
    getScreens(params) {
        return api.get('/api/admin/screen', params);
    },
    getScreen(id, params) {
        return api.get(`/api/admin/screen/${id}`, params);
    },
    createScreen(data) {
        return api.post('/api/admin/screen', data);
    },
    editScreen(id, data) {
        return api.put(`/api/admin/screen/${id}`, data);
    },
    deleteScreen(id) {
        return api.delete(`/api/admin/screen/${id}`);
    },
    getBanner(id, params) {
        return api.get(`/api/admin/banner/${id}`, params);
    },
    getBannerTypes() {
        return api.get('/api/admin/banner');
    },
    createBanner(data) {
        return api.post('/api/admin/screen-has-banner', data);
    },
    editBanner(id, data) {
        return api.put(`/api/admin/screen-has-banner/${id}`, data);
    },
    deleteBanner(id) {
        return api.delete(`/api/admin/screen-has-banner/${id}`);
    },
    reviveBanner(id) {
        return api.put(`/api/admin/screen-has-banner/${id}/revive`);
    },
};

export default API;
