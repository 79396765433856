import { types, applySnapshot } from 'mobx-state-tree';

const Feed = types
    .model('Feed', {
        id: types.identifierNumber,
        url: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        show: types.maybeNull(types.boolean),
        title_label: types.maybeNull(types.string),
        screen_title: types.maybeNull(types.string),
        created_at: types.maybeNull(types.string),
        updated_at: types.maybeNull(types.string),
        deleted_at: types.maybeNull(types.string),
        data: types.maybeNull(types.frozen(types.string)),
    })
    .actions((self) => ({
        editFeed(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));
export default Feed;
