import { types, applySnapshot, flow } from 'mobx-state-tree';
import Item from './Item';
import Mood from './Mood';
import API from './../../api/users';
import APIgames from './../../api/games';
import BusinessProfile from '../businessProfilesStore/BusinessProfile';

const User = types
    .model('User', {
        id: types.identifierNumber,
        email: types.optional(types.string, ''),
        has_verified_email: types.optional(types.boolean, false),
        roles: types.array(types.string),
        new_password: types.maybeNull(types.string),
        first_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        gender: types.enumeration('Gender', ['male', 'female', 'other']),
        avatar: types.maybeNull(types.string),
        birthday: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        street: types.maybeNull(types.string),
        zip: types.maybeNull(types.string),
        fan_name: types.maybeNull(types.string),
        fan_points: types.optional(types.number, 0),
        fan_coins: types.optional(types.number, 0),
        apple_id: types.maybeNull(types.string),
        accept_marketing: types.optional(types.boolean, false),
        items: types.optional(types.array(Item), []),
        seasonalMoodStats: types.optional(types.array(Mood), []),
        seasonalMoodGameId: types.maybeNull(types.number),
        favourite_player_id: types.maybeNull(types.number),
        fan_name_censored: types.optional(types.boolean, false),
        business_profile: types.maybeNull(types.frozen(BusinessProfile)),
        business_profile_id: types.maybeNull(types.number),
    })
    .actions((self) => ({
        editUser(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
        getItems: flow(function* getItems() {
            try {
                const res = yield API.getItems(self.id);
                self.items = res.data.items;
            } catch (error) {
                console.log('error => ' + error);
            }
        }),
        getSeasonalMoodStats: flow(function* getSeasonalMoodStats(gameId) {
            if (gameId) {
                try {
                    const res = yield APIgames.getUsersSeasonalMoodStats(gameId, self.id);
                    self.seasonalMoodStats = res.data;
                } catch (error) {
                    console.log('error => ' + error);
                }
            } else {
                self.seasonalMoodStats = [];
            }
        }),
    }));

export default User;
