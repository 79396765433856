import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import getBusinessProfileFormInput from './../BusinessProfiles/getBusinessProfileFormInput';
import API from './../../api/users';
import APIProfile from './../../api/businessProfiles';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';

const NewProfileForm = ({ store }) => {
    const history = useHistory();
    const { partner_id } = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        !store.selectedPartner && store.getPartner(partner_id);
    }, [store, partner_id]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await API.getUsers({
                    params: {
                        search: searchQuery,
                        limit: 50,
                    },
                });
                setUsers(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };

        searchQuery && getUsers();
    }, [searchQuery]);

    const searchQueryHandler = debounce((value) => {
        setSearchQuery(value);
    }, 500);

    const createProfile = async (event) => {
        setLoading(true);
        error && setError(null);
        try {
            !event.user_id && delete event.user_id;
            await APIProfile.createProfile({ ...event, company_id: store.selectedPartner.id });
            history.goBack();
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };
    return store.selectedPartner ? (
        <Item
            links={[
                { path: '/business-partners', name: translations.business_partners },
                {
                    path: `/business-partners/${store.selectedPartner.id}`,
                    name: store.selectedPartner.name,
                },
            ]}
            name={`${translations.business_profile} ${translations.create}`}
            itemModel={{
                first_name: '',
                last_name: '',
                avatar: avatar,
                gender: 'female',
                role: '',
                phone_mobile: '',
                phone: '',
                email: '',
                user_id: '',
            }}
            edit={true}
            onSubmit={createProfile}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, isDisabled, index }) =>
                getBusinessProfileFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    isDisabled,
                    index,
                    users: users,
                    searchQueryHandler,
                    partners: [],
                    resetValue: (name, value) => setAvatar({ [name]: value }),
                })
            }
            resetProp={avatar}
        ></Item>
    ) : null;
};

export default observer(NewProfileForm);
