import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import Select from '../../components/Select';
import tabs from '../../constants/ScreenTabs';
import translations from '../../translations/de';

export default function getScreenFormInput({
    value,
    name,
    control,
    register,
    error,
    disabled,
    index,
}) {
    if (name === 'tab') {
        return (
            <Select
                key={index}
                label={translations.tab_name}
                name={name}
                options={tabs}
                control={control}
                error={error}
                disabled={disabled}
            />
        );
    } else
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
            />
        );
}
