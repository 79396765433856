import React from 'react';
import Button from '../../../components/Button';
import { observer } from 'mobx-react-lite';
import TabSwitcher from '../../../components/TabSwitcher';
import translations from '../../../translations/de';
import Invitations from '../Invitations';
import BusinessProfileTable from '../BusinessProfileTable';

const StepTwo = ({ store, onNext, onBack }) => {
    return (
        <>
            <div className="controls">
                <Button label="Back" onClick={onBack} />
                <Button label="Next" onClick={onNext} />
            </div>
            <TabSwitcher initialTabName={translations.people}>
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName={translations.people} />
                    <TabSwitcher.TabPill
                        tabName={translations.selected_people}
                        tabCount={store.creating_invitations.length}
                    />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName={translations.people}>
                    <Invitations store={store} title={translations.select_people} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.selected_people}>
                    <BusinessProfileTable
                        profiles={[...store.creating_invitations]}
                        store={store}
                        hasRemove
                        isDisabled
                    />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </>
    );
};

export default observer(StepTwo);
