import { useState, useEffect } from 'react';
import getInput from './getInput';
import Button from '../Button';
import { observer } from 'mobx-react-lite';
import Quote from './Quote';
import Modal from '../Modal';
import { useForm } from 'react-hook-form';
import clearObjProps from '../../helpers/clearObjProps';
import ErrorMessages from '../ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import translations from '../../translations/de';

const Answer = ({ answerStore, gameType, onClose, onSave, error, loading }) => {
    const propsToRemove = [
        'id',
        'quotes',
        ...(gameType === 'first_scorer' ? ['player_id'] : []),
        ...(gameType === 'live_mood' || gameType === 'seasonal_mood'
            ? ['player_id', 'team_id']
            : []),
    ];

    const answerObj = clearObjProps({ ...answerStore }, propsToRemove);
    const answer = Object.keys(answerObj).map((key) => {
        return { name: key, value: answerObj[key] };
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm({
        defaultValues: answerObj,
    });

    const [resetProp, setResetProp] = useState(null);

    useEffect(() => {
        if (resetProp) {
            reset({ ...watch(), ...resetProp });
        }
    }, [resetProp, reset, watch]);

    return (
        <Modal title="Answer" onClose={onClose}>
            {answerStore && (
                <div className="Answer">
                    <form>
                        <div className="Answer-Form">
                            {answer.length !== 0 &&
                                answer.map((data, index) =>
                                    getInput({
                                        value: data.value,
                                        name: data.name,
                                        readOnly:
                                            gameType === 'first_scorer' &&
                                            (data.name === 'text' ||
                                                data.name === 'team_id' ||
                                                data.name === 'image_url')
                                                ? true
                                                : false,
                                        register,
                                        error: errors[data.name],
                                        index,
                                        resetValue: (name, value) =>
                                            setResetProp({ [name]: value }),
                                    }),
                                )}
                        </div>
                        {/* <Button
                            label="Add Quote"
                            type="button"
                            onClick={() =>
                                answerStore.addQuote(
                                    QuoteInstance.create({
                                        text: "",
                                    }),
                                )
                            }
                        /> */}
                        {answerStore.quotes &&
                            answerStore.quotes.length !== 0 &&
                            answerStore.quotes.map((quote, i) => (
                                <Quote
                                    key={i}
                                    quote={quote}
                                    id={i}
                                    onDelete={(id) => answerStore.deleteQuote(id)}
                                />
                            ))}
                        <div className="Answer-Controls">
                            <Button type="button" label={translations.cancel} onClick={onClose} />
                            <Button
                                label="Submit"
                                type="submit"
                                onClick={handleSubmit((e) => {
                                    onSave(answerStore.id, { ...e });
                                })}
                                loading={loading}
                            />
                        </div>
                        <ErrorMessages
                            messages={requsetErrorMessage(
                                error && error.message ? error.message : error,
                            )}
                        />
                    </form>
                </div>
            )}
        </Modal>
    );
};

export default observer(Answer);
