import { types, flow } from 'mobx-state-tree';
import Access from './Access';
import API from '../../api/accesses';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearEmptyFields from '../../helpers/clearEmptyFields';

const AccessesStore = types
    .model('AccessesStore', {
        accesses: types.optional(types.array(Access), []),
        selectedAccess: types.maybe(types.reference(Access)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getAccesses: flow(function* getAccesses(passID, searchQuery, gameID, selectedSeason) {
            try {
                self.dataLoaded = false;
                const res = yield API.getAccesses({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        pass_id: passID,
                        season_id: selectedSeason ?? undefined,
                        limit: 50,
                        page: self.selectedPage,
                        game_id: gameID ? gameID : undefined,
                    },
                });
                self.dataLoaded = true;
                self.accesses = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        clear() {
            self.accesses = [];
            self.selectedPage = 1;
        },
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        selectAccess(id) {
            self.selectedAccess = id;
        },
        getAccess: flow(function* getAccess(id) {
            self.error = '';
            try {
                const res = yield API.getAccess(id);
                self.dataLoaded = true;
                self.accesses = [];
                self.accesses.push(res.data);
                self.selectedAccess = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editAccess: flow(function* editAccess(edited) {
            try {
                const res = yield API.editAccess(self.selectedAccess.id, {
                    ...self.selectedAccess,
                    ...edited,
                });
                self.selectedAccess.editAccess(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addAccess: flow(function* addAccess(access) {
            try {
                const res = yield API.createAccess(clearEmptyFields(access));
                self.accesses.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteAccess: flow(function* deleteAccess(id) {
            try {
                yield API.deleteAccess(id);
                const index = self.accesses.findIndex((s) => s.id === id);
                self.accesses.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default AccessesStore;
