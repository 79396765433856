import { types, flow } from 'mobx-state-tree';
import API from '../../api/seatMaps';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import SeatMap from './seatMap';
import Seat from './../seatsStore/Seat';
import Scenario from './Scenario';

const SeatMaps = types
    .model('SeatMaps', {
        seatMaps: types.array(SeatMap),
        seats: types.array(Seat),
        scenarios: types.array(Scenario),
        selectedSeatMap: types.maybe(types.reference(SeatMap)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getSeatMaps: flow(function* getSeatMap(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getSeatMaps({
                    params: {
                        search: searchQuery,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.seatMaps = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getSeats: flow(function* getSeats() {
            self.error = '';
            try {
                const res = yield API.getSeats();
                self.seats = res.data.items;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getScenarios: flow(function* getScenarios() {
            self.error = '';
            try {
                const res = yield API.getScenarios();
                self.scenarios = res.data;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        selectSeatMap(id) {
            self.selectedSeatMap = id;
        },
        getSeatMap: flow(function* getSeatMap(id) {
            self.error = '';
            try {
                const res = yield API.getSeatMap(id);
                self.seatMaps = [];
                self.seatMaps.push(res.data);
                self.selectedSeatMap = res.data.id;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editSeatMap: flow(function* editSeatMap(edited) {
            try {
                const res = yield API.editSeatMap(self.selectedSeatMap.id, edited);
                self.selectedSeatMap.editSeatMap(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addSeatMap: flow(function* addSeatMap(newSeatMap) {
            try {
                const res = yield API.createSeatMap(newSeatMap);
                self.seatMaps.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteSeatMap: flow(function* deleteSeatMap(id) {
            try {
                yield API.deleteSeatMap(id);
                const index = self.seatMaps.findIndex((s) => s.id === id);
                self.seatMaps.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default SeatMaps;
