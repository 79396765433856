import api from './apiLibrary';

const API = {
    getShopItems(params) {
        return api.get('/api/admin/shop-item', params);
    },
    createShopItem(data) {
        return api.post(`/api/admin/shop-item`, data);
    },
    editShopItem(id, data) {
        return api.put(`/api/admin/shop-item/${id}`, data);
    },
    editActive(id, params) {
        return api.patch(`/api/admin/shop-item/${id}/showHide`, undefined, params);
    },
    getShopItem(id) {
        return api.get(`/api/admin/shop-item/${id}`);
    },
    deleteShopItem(id) {
        return api.delete(`/api/admin/shop-item/${id}`);
    },
    getBuyers(shop_item_id, params) {
        return api.get(`/api/admin/shop-item/${shop_item_id}/users`, params);
    },
    getSales(shop_item_id, params) {
        return api.get(`/api/admin/acquired-item?shop_item_id=${shop_item_id}`, params);
    },
};

export default API;
