import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import getSeatFormInput from './getSeatFormInput';
import { useParams } from 'react-router-dom';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import API from '../../api/seats';

const Seat = ({ store }) => {
    const { seat_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sectors, setSectors] = useState([]);
    const [seatGroups, setSeatGroups] = useState([]);

    useEffect(() => {
        setLoading(true);
        store.getSeat(seat_id);

        setLoading(false);
        return () => {
            store.selectSeat(undefined);
        };
    }, [store, seat_id]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editSeat({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            });
    };

    useEffect(() => {
        const getSectors = async () => {
            try {
                const res = await API.getSectors();
                setSectors(res.data.items);
            } catch (e) {
                console.log(e);
            }
        };
        const getSeatGroups = async () => {
            try {
                const res = await API.getSeatGroups();
                setSeatGroups(res.data.items);
            } catch (e) {
                console.log(e);
            }
        };

        getSectors();
        getSeatGroups();
    }, []);

    return store.selectedSeat ? (
        <>
            <Item
                links={[{ path: Paths.SEATS, name: translations.seat }]}
                name={store.selectedSeat.id}
                id={`[seat_ID: ${store.selectedSeat.id}]`}
                itemModel={{
                    sector_id: store.selectedSeat.seat_group.sector_id,
                    seat_group_id: store.selectedSeat.seat_group.id,
                    row: store.selectedSeat.row,
                    num: store.selectedSeat.num,
                }}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getSeatFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        sectors,
                        seatGroups,
                    })
                }
            ></Item>
        </>
    ) : null;
};

export default observer(Seat);
