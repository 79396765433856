import { types, applySnapshot } from 'mobx-state-tree';
import BusinessProfile from './../businessProfilesStore/BusinessProfile';

const BusinessPartner = types
    .model('Partner', {
        id: types.identifierNumber,
        name: types.maybeNull(types.string),
        address: types.maybeNull(types.string),
        zip: types.maybeNull(types.string),
        location: types.maybeNull(types.string),
        postbox: types.maybeNull(types.string),
        logo: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        deleted_at: types.maybeNull(types.string),
        profiles: types.maybeNull(types.array(BusinessProfile)),
    })
    .actions((self) => ({
        editPartner(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
        removeProfile(id) {
            const index = self.profiles.findIndex((p) => p.id === id);
            self.profiles.splice(index, 1);
        },
    }));
export default BusinessPartner;
