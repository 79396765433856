import React from 'react';
import Page from '../../components/Page';
import Paths from '../../constants/paths';
import { Route } from 'react-router-dom';

const index = () => {
    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}></div>
            <Route exact path={Paths.MARKETING_KPI}>
                <p>To be implemented...</p>
            </Route>
        </Page>
    );
};

export default index;
