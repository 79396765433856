import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import getMatchFormInput from '../../pages/Matches/getMatchFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import '../../pages/Matches/Match.scss';
import API from '../../api/matches';
import translations from '../../translations/de';
import clearObjNulls from '../../helpers/clearObjNulls';

const Match = ({ game }) => {
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const matchModel = clearObjProps({ ...game }, [
        'id',
        'home_team',
        'away_team',
        'fan_games',
        'season_id',
        'is_postponed',
        'external_id',
        'status',
        'decision_types',
        'last_foul_number',
        'overtimes',
        'has_ended',
        'status_label',
        'decision_type',
        'round',
        'is_draw_made',
        'draw_date',
        'gates_open',
        'league_acronym',
        'qualification_id',
        'scenario_id',
    ]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        if (
            Number(event.score_home_team) ===
            Number(event.one_third_home_score) +
                Number(event.two_thirds_home_score) +
                Number(event.three_thirds_home_score)
        ) {
            if (
                Number(event.score_away_team) ===
                Number(event.one_third_away_score) +
                    Number(event.two_thirds_away_score) +
                    Number(event.three_thirds_away_score)
            ) {
                API.editMatch(
                    game.id,
                    clearObjNulls({
                        ...event,
                        score_home_team: Number(event.score_home_team),
                        score_away_team: Number(event.score_away_team),
                        one_third_home_score: Number(event.one_third_home_score),
                        one_third_away_score: Number(event.one_third_away_score),
                        two_thirds_home_score: Number(event.two_thirds_home_score),
                        two_thirds_away_score: Number(event.two_thirds_away_score),
                        three_thirds_home_score: Number(event.three_thirds_home_score),
                        three_thirds_away_score: Number(event.three_thirds_away_score),
                        penalties: Number(event.penalties),
                    }),
                )
                    .then(() => {
                        setEdit(false);
                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            } else {
                setError(translations.guest_score_error);
                setLoading(false);
            }
        } else {
            setError(translations.home_score_error);
            setLoading(false);
        }
    };

    return game ? (
        <>
            <Item
                name={game.home_team.title + ' - ' + game.away_team.title}
                id={`[ match_ID: ${game.id} ]`}
                itemModel={{ ...matchModel }}
                edit={edit}
                error={error}
                loading={loading}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getMatchFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        teams: [],
                    })
                }
            >
                {' '}
                <div className="Item-Content-Image">
                    <img src={game.home_team.logo_url} alt="team one" />
                    VS
                    <img src={game.away_team.logo_url} alt="team two" />
                </div>
            </Item>
        </>
    ) : null;
};

export default observer(Match);
