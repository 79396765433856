import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Game from '../../components/Game';
import { useParams } from 'react-router-dom';
import FanAnswersStore from '../../models/fanAnswersStore/fanAnswers';
import translations from '../../translations/de';
import Paths from '../../constants/paths';

const FanGame = ({ store }) => {
    const { match_id, fan_game_id } = useParams();
    const fanAnswers = FanAnswersStore.create();

    useEffect(() => {
        store.getGame(match_id, fan_game_id);
    }, [store, match_id, fan_game_id]);

    return store.selectedGame ? (
        <Game
            store={store}
            links={[
                {
                    path: Paths.MATCHES,
                    name: translations.matches,

                    onThisLinkClick: () => {
                        store.selectGame(undefined);
                    },
                },
                {
                    path: `/matches/${store.selectedMatch.id}`,
                    name:
                        store.selectedMatch.home_team.title +
                        ' - ' +
                        store.selectedMatch.away_team.title,
                },
            ]}
            fanAnswers={fanAnswers}
        />
    ) : null;
};

export default observer(FanGame);
