import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FaGift, FaSearch } from 'react-icons/fa';
import { BsQuestionSquareFill, BsListUl } from 'react-icons/bs';
import { GiJeweledChalice } from 'react-icons/gi';
import Table from '../../components/Table';
import Button from '../../components/Button';
import Pagination from './../../components/Pagination';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import ItemImage from '../../components/ItemImage';
import FanGameMatchDetails from '../../components/FanGameMatchDetails';
import translations from '../../translations/de';
import { formatDate, formatTime } from '../../helpers/formatDate';
import './FanGames.scss';
import API from '../../api/games';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const FinalScoreGames = ({ store, searchQuery, gameStatus, season, tab }) => {
    const [gameModal, setGameModal] = useModal(false);
    const [selectedGame, setSelectedGame] = useState(null);
    const history = useHistory();

    useEffect(() => {
        sessionStorage.setItem('activeFanGameTab', 'Final Score');
        store.getFanGames(searchQuery, gameStatus, tab, season);
    }, [store, store.selectedPage, searchQuery, gameStatus, season, tab]);

    const handleFanGame = (id, action) => {
        API.getGame(id).then((res) => {
            setSelectedGame(res.data.fan_game_details);
            switch (action) {
                case 'game':
                    setGameModal(true);
                    break;

                default:
                    break;
            }
        });
    };

    const showFanGame = (id) => {
        store.getFanGame(id).then(() => {
            store.selectedGame && history.push(`/fan-games/${store.selectedGame.id}`);
        });
    };

    const handleRewards = (game_id) => {
        store.selectFanGame(game_id);
        history.push({
            pathname: `/fan-games/${game_id}/end-game-result-rewards`,
            state: { activeTabName: 'All' },
        });
    };

    const handleWinners = (fan_game_id) => {
        history.push({
            pathname: `/fan-games/${fan_game_id}/end-game-result-winners`,
            state: { activeTabName: 'All' },
        });
    };

    const handleRanking = (fan_game_id) => {
        history.push({
            pathname: `/fan-games/${fan_game_id}/end-game-result-ranking`,
            state: { activeTabName: 'All' },
        });
    };

    return (
        <div style={{ width: '100%' }}>
            {store.fan_games && store.fan_games.length !== 0 ? (
                store.dataLoaded ? (
                    <Table
                        tablePagination={
                            store.totalPages > 1 && (
                                <Pagination
                                    totalPages={store.totalPages}
                                    selectedPage={store.selectedPage}
                                    onSelectedPageChange={(selected) =>
                                        store.setSelectedPage(selected)
                                    }
                                />
                            )
                        }
                        tableName={translations.fan_games}
                        columns={[
                            {
                                Header: 'ID',
                                accessor: 'id',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: 'Date',
                                accessor: 'start_at',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: 'vs',
                                accessor: 'opponent_logo',
                                Cell: ({ value }) => (
                                    <div>
                                        <ItemImage imgSrc={value} alt="reward" listView={true} />
                                    </div>
                                ),
                            },
                            {
                                Header: '# of users',
                                accessor: 'user_count',
                                Cell: ({ value }) => <div>{value}</div>,
                            },

                            {
                                Header: 'Actions',
                                accessor: 'action',
                                Cell: ({ row }) => (
                                    <div>
                                        <Button
                                            title="Details"
                                            onClick={() => showFanGame(row.cells[0].value)}
                                        >
                                            <FaSearch />
                                        </Button>
                                        <Button
                                            title="Game"
                                            onClick={() =>
                                                handleFanGame(row.cells[0].value, 'game')
                                            }
                                        >
                                            <BsQuestionSquareFill />
                                        </Button>
                                        <Button
                                            title="Rewards"
                                            onClick={() => handleRewards(row.cells[0].value)}
                                        >
                                            <FaGift />
                                        </Button>
                                        <Button
                                            title="Ranking"
                                            onClick={() => handleRanking(row.cells[0].value)}
                                        >
                                            <BsListUl />
                                        </Button>
                                        <Button
                                            title="Winners"
                                            onClick={() => handleWinners(row.cells[0].value)}
                                        >
                                            <GiJeweledChalice />
                                        </Button>
                                    </div>
                                ),
                            },
                        ]}
                        data={store.fan_games.map(({ id, start_at, opponent_logo, user_count }) => {
                            return {
                                id,
                                start_at: `${formatDate(start_at)} ${formatTime(start_at)}`,
                                opponent_logo,
                                user_count,
                            };
                        })}
                    />
                ) : (
                    <Loader size="big" theme="dark" />
                )
            ) : (
                <div className="no-data">No Data Yet...</div>
            )}
            {gameModal && (
                <Modal
                    title={translations.game}
                    maxWidth="900px"
                    onClose={() => setGameModal(false)}
                >
                    <FanGameMatchDetails game={selectedGame.game} />
                </Modal>
            )}
        </div>
    );
};

export default observer(FinalScoreGames);
