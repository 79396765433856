import { observer } from 'mobx-react-lite';
import Button from '../Button';
import './Rewards.scss';
import Table from '../Table';
import { FaTrash, FaSearch, FaPlus } from 'react-icons/fa';
import translations from '../../translations/de';
import { useHistory, useParams } from 'react-router-dom';

const Rewards = ({ gameStore }) => {
    const history = useHistory();
    const { fan_game_id } = useParams();

    return (
        <>
            <Button
                label="Reward"
                onClick={() => {
                    history.push({
                        pathname: `/fan-games/${fan_game_id}/new_reward`,
                    });
                }}
            >
                <FaPlus style={{ marginRight: '5px' }} />
            </Button>
            {gameStore.rewards && gameStore.rewards.length !== 0 && (
                <Table
                    isRowClickable={false}
                    tableName="Rewards"
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.image,
                            accessor: 'image_url',
                            Cell: ({ value, row }) => (
                                <div>
                                    <img src={value} alt="reward" />
                                </div>
                            ),
                        },
                        {
                            Header: translations.description,
                            accessor: 'description',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.type,
                            accessor: 'type',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Actions',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        title={translations.details}
                                        onClick={() => {
                                            history.push({
                                                pathname: `/rewards/${row.cells[0].value}`,
                                            });
                                        }}
                                    >
                                        <FaSearch />
                                    </Button>
                                    <Button
                                        title={translations.delete}
                                        onClick={() => {
                                            gameStore.deleteReward(row.cells[0].value);
                                        }}
                                        style={{ marginRight: '5px' }}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={gameStore.rewards.map(
                        ({ id, name, image_url, description, type, used, acquired }) => {
                            return {
                                id,
                                name,
                                image_url,
                                description,
                                type: translations[type],
                                used,
                                acquired,
                            };
                        },
                    )}
                />
            )}
        </>
    );
};

export default observer(Rewards);
