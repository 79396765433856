import { Route } from 'react-router-dom';
import Page from './../../components/Page';
import Screen from './Screen';
import Banner from './Banner';
import NewScreen from './NewScreen';
import NewBanner from './NewBanner';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';
import ScreenTabs from './ScreenTabs';

const ScreensTab = ({ store }) => {
    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.SCREENS}>
                <ScreenTabs store={store} />
            </Route>
            <Route exact path={Paths.SCREEN}>
                <Screen store={store} />
            </Route>
            <Route exact path={Paths.SCREEN_BANNER}>
                <Banner store={store} />
            </Route>
            <Route exact path={Paths.NEW_SCREEN}>
                <NewScreen store={store} />
            </Route>
            <Route exact path={Paths.SCREEN_NEW_BANNER}>
                <NewBanner store={store} />
            </Route>
        </Page>
    );
};

export default observer(ScreensTab);
