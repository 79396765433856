import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import { FaTrash, FaPlus, FaSearch } from 'react-icons/fa';
import translations from '../../translations/de';
import debounce from 'lodash.debounce';
import Input from '../../components/Input';
import Pagination from './../../components/Pagination';
import ItemImage from '../../components/ItemImage';
import { formatTime, formatDate } from '../../helpers/formatDate';

const InstantRewards = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [instantRewardID, setInstantRewardID] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        store.getRewards(searchQuery);
    }, [store, store.selectedPage, searchQuery]);

    const handleReward = (id) => {
        store.selectInstantReward(id);
        history.push(`/instant-rewards/${store.selectedInstantReward.id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    return (
        <div style={{ width: '100%' }}>
            <div className="tab-controls">
                <Input placeholder={translations.search} onChange={onSearchChangeHandler} />
            </div>
            <div className="tab-controls">
                <Button
                    label={translations.voucher}
                    onClick={() => {
                        store.selectInstantReward(undefined);
                        store.resetRecipients();
                        history.push(Paths.NEW_INSTANT_REWARD);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>

            {store.rewards && store.rewards.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName={translations.vouchers}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.name}`,
                            accessor: 'name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.image}`,
                            accessor: 'image_url',
                            Cell: ({ value, row }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="reward" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: `${translations.expire_at}`,
                            accessor: 'expire_at',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.stock}`,
                            accessor: 'quantity',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.rewarded}`,
                            accessor: 'acquired',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.used}`,
                            accessor: 'used',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.type,
                            accessor: 'type',
                            Cell: ({ value }) => <div>{translations[value]}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleReward(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    <Button
                                        title={translations.delete}
                                        onClick={() => {
                                            setInstantRewardID(row.cells[0].value);
                                            setModal(true);
                                        }}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.rewards.map(
                        ({ id, name, image_url, expire_at, quantity, acquired, used, type }) => {
                            return {
                                id,
                                name,
                                image_url,
                                expire_at: `${formatDate(expire_at)} ${formatTime(expire_at)}`,

                                quantity,
                                acquired,
                                used: type === 'consumable' ? used : '/',
                                type,
                            };
                        },
                    )}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Instant Reward"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this reward?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteInstantReward(instantRewardID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default observer(InstantRewards);
