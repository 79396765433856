import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import getBusinessEventFormInput from './getBusinessEventFormInput';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';
import clearObjProps from '../../helpers/clearObjProps';
import './BusinessEvent.scss';
import Breadcrumb from '../../components/Breadcrumb';
import TabSwitcher from '../../components/TabSwitcher';
import Invitations from './Invitations';
import Button from '../../components/Button';
import ErrorMessages from '../../components/ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import InvitationsTab from './InvitationsTab.js';
import ParticipantsTab from './ParticipantsTab.js';
import AttendeesTab from './AttendeesTab.js';

const BusinessEvent = ({ store }) => {
    const { event_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [resetProp, setResetProp] = useState(null);
    const [resetTab, setResetTab] = useState(false);
    const BusinessEventModel = clearObjProps({ ...store.selectedEvent }, [
        'id',
        'attendees',
        'invitations',
        'participants',
        'lat',
        'long',
    ]);
    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);
    const [is_respondable, setIsRespondable] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        reset,
        
    } = useForm({
        defaultValues: { ...BusinessEventModel },
    });
    const watchIsRespondable = watch('is_respondable');

    const formData = Object.keys(BusinessEventModel).map((key) => {
        return { name: key, value: BusinessEventModel[key] };
    });

    const checkDates = (start, end) => {
        if (new Date(end) <= new Date(start)) {
            setError('End date must be after Start date');
            return false;
        } else {
            return true;
        }
    };

    const onSubmitHandler = (event) => {
        error && setError(null);
        if (!checkDates(event.start, event.end)) {
            return;
        } else {
            error && setError(null);
            store
                .editEvent({
                    ...event,
                    time_to_respond: is_respondable ? event.time_to_respond : undefined,
                    lat: latitude,
                    long: longitude,
                })
                .then(() => {
                    setEdit(false);
                    
                })
                .catch((error) => {
                    setError(error);
                });
        }
    };

    const handleEdit = () =>
    {
        setResetTab(true); // Set the flag to trigger tab reset
        setEdit(!edit);
    };

    useEffect(() => {
        if (resetTab) {
            setResetTab(false); // Clear the flag after resetting the tab
        }
    }, [resetTab]);

    useEffect(() => {
        if (store.selectedEvent) {
            const cleanedEvent = clearObjProps({ ...store.selectedEvent }, [
                'id',
                'attendees',
                'invitations',
                'participants',
                'lat',
                'long',
            ]);
            reset(cleanedEvent); 
        }
    }, [store.selectedEvent, reset]);

    useEffect(() => {
        //reset form default values in case any value from store updates before submiting form
        if (resetProp) {
            reset({ ...watch(), ...resetProp });
            setResetProp(null);
        }
    }, [resetProp, reset, watch]);

    useEffect(() => {
        store.getEvent(event_id);
    }, [store, event_id]);

    useEffect(() => {
        // Handle changes in is_respondable without conflicting setValue calls
        if (watchIsRespondable !== undefined) {
            setIsRespondable(watchIsRespondable);
        }
    }, [watchIsRespondable]); // Remove setValue here to avoid conflicts

    return store.selectedEvent ? (
        <div className="Event">
            <div className="Event-Header mb">
                <Breadcrumb
                    links={[{ path: Paths.BUSINESS_EVENTS, name: translations.business_events }]}
                    name={store.selectedEvent.title}
                />
                {edit ? (
                    <>
                        <Button
                            onClick={() => {
                                //store.resetInvitaionsUpdate();
                                reset();
                                error && setError(null);
                                handleEdit();
                            }}
                        >
                            {translations.cancel}
                        </Button>
                        <Button onClick={handleSubmit(onSubmitHandler)}>{translations.save}</Button>
                    </>
                ) : (
                    <Button onClick={() => handleEdit()}>{translations.edit}</Button>
                )}
            </div>

            <TabSwitcher
                initialTabName={translations.event_details}
                resetOnInitialTab={resetTab ? translations.event_details : null}
            >
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName={translations.event_details}></TabSwitcher.TabPill>
                    {edit ? (
                        <TabSwitcher.TabPill tabName={translations.people} />
                    ) : (
                        <>
                            <TabSwitcher.TabPill tabName={translations.invitations} />
                            <TabSwitcher.TabPill tabName={translations.participants} />
                            <TabSwitcher.TabPill tabName={translations.attendees} />
                        </>
                    )}
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName={translations.event_details}>
                    <div className="Item-Content">
                        <ErrorMessages
                            messages={requsetErrorMessage(
                                error && error.message ? error.message : error,
                            )}
                        ></ErrorMessages>
                        <form>
                            <ItemImage imgSrc={store.selectedEvent.image_url} />
                            {store.selectedEvent.id && (
                                <div className="id">{`[ event_ID: ${store.selectedEvent.id}]`}</div>
                            )}
                            {formData.map((data, index) =>
                                getBusinessEventFormInput({
                                    value: data.value,
                                    name: data.name,
                                    register,
                                    control,
                                    error: errors[data.name],
                                    resetValue: (name, value) => setResetProp({ [name]: value }),
                                    disabled: !edit,
                                    index,
                                    setCoordinates: (lat, long) => {
                                        setLatitude(lat);
                                        setLongitude(long);
                                    },
                                    watchIsRespondable: is_respondable,
                                }),
                            )}
                        </form>
                    </div>
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.invitations}>
                    <InvitationsTab />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.participants}>
                    <ParticipantsTab />
                   
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.attendees}>
                    <AttendeesTab />
    
                </TabSwitcher.TabPanel>
               {edit && <TabSwitcher.TabPanel activeWhenTabName={translations.people}>
                    <Invitations store={store} title={translations.select_people} isEdit={true} />
                </TabSwitcher.TabPanel>}
                
            </TabSwitcher>
        </div>
    ) : null;
};;

export default observer(BusinessEvent);
