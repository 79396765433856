import React, { useState } from 'react';
import './CreateGame.scss';
import Breadcrumb from '../Breadcrumb';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { useHistory } from 'react-router-dom';
import clearObjProps from '../../helpers/clearObjProps';
import ErrorMessages from '../ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';

export default function CreatGame({ name = '', links = [], game, gameTypes = [], store }) {
    const [step, setStep] = useState(game && 1);
    const [error, setError] = useState(null);
    const history = useHistory();

    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        error && setError(null);
        setStep(step - 1);
    };

    const handleGameCreation = () => {
        error && setError(null);
        const newGame = clearObjProps({ ...game }, [
            'id',
            'fan_game_type',
            'rewards',
            'archived',
            'published',
            'started',
            'ended',
        ]);
        store
            .addGame(newGame)
            .then(() => history.push(`${links[links.length - 1].path}`))
            .catch(setError);
    };

    return (
        <div className="Create-Game">
            <div className="Create-Game-Header">
                <Breadcrumb name={name} links={links} />
            </div>
            <div className="Create-Game-Content">
                {step === 1 && <StepOne store={game} gameTypes={gameTypes} onNext={handleNext} />}
                {step === 2 && (
                    <StepTwo store={game} onNext={handleGameCreation} onBack={handleBack} />
                )}
                <ErrorMessages
                    messages={requsetErrorMessage(error && error.message ? error.message : error)}
                />
            </div>
        </div>
    );
}
