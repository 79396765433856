import { useEffect } from 'react';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import './calendar.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Event from './Event';
import { useHistory } from 'react-router-dom';

moment.locale('en', { week: { dow: 1, doy: 1 } });
const localizer = momentLocalizer(moment);

const Home = ({ matchesStore, calendarStore, adminStore }) => {
    const hasRoleAdmin = adminStore.roles.find((role) => role === 'ADMIN');
    const nextMatchId = matchesStore.findNextMatch();
    useEffect(() => {
        matchesStore.getHomeMatches();
    }, [matchesStore]);
    const history = useHistory();
    const myEventsList = matchesStore.matches.map((event) => {
        return (
            event.date && {
                start: new Date(event.date),
                end: new Date(event.date),
                allDay: false,
                home_team_name: event.home_team.totomat_acronym,
                home_team_img: event.home_team.logo_url,
                away_team_name: event.away_team.totomat_acronym,
                away_team_img: event.away_team.logo_url,
                location: event.location,
                id: event.id,
                isNext: nextMatchId === event.id ? true : false,
            }
        );
    });

    return (
        <Page>
            <div className="calendar-wrapper">
                <Calendar
                    localizer={localizer}
                    events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    views={['month']}
                    components={{
                        event: Event,
                    }}
                    onSelectEvent={({ id }) => {
                        hasRoleAdmin && matchesStore.selectMatch(id);
                        hasRoleAdmin && history.push(`/matches/${matchesStore.selectedMatch.id}`);
                    }}
                    onNavigate={(date) => calendarStore.selectDate(date)}
                    date={calendarStore.selectedDate}
                />
            </div>
        </Page>
    );
};

export default observer(Home);
