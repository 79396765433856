import React, { useEffect, useState } from 'react';
import APIReward from '../../api/rewards';

import Item from '../../components/Item';
import ItemImage from '../../components/ItemImage';

import getLotteryGameFormInput from './getLotteryGameFormInput';

const LotteryReward = ({ lotteryStore }) => {
    const [reward, setReward] = useState();
    const [editReward, setEditReward] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resetProp, setResetProp] = useState();

    const onSubmitLotteryReward = async (edited) => {
        setLoading(true);
        try {
            const res = await APIReward.editReward(reward.id, edited);
            setReward(res.data);
            setEditReward(false);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }

        loading && editReward();
    };

    useEffect(() => {
        const getReward = async () => {
            try {
                const res = await APIReward.getReward(lotteryStore.selectedLotteryGame.reward_id);
                setReward(res.data);
            } catch (e) {
                console.error(e);
            }
        };
        lotteryStore.selectedLotteryGame && getReward();
    }, [lotteryStore]);

    return (
        <div>
            {' '}
            {reward ? (
                <Item
                    hasNavigation={false}
                    id={`[ Reward_id: ${lotteryStore?.selectedLotteryGame?.reward_id} ]`}
                    itemModel={{
                        name: reward.name,
                        image_url: reward.image_url,
                        fan_coins: reward.fan_coins,
                        expire_at: reward.expire_at,
                        type: reward.type,
                        popup_title: reward.popup_title,
                        popup_text: reward.popup_text,
                        description: reward.description,
                        redeem_description: reward.redeem_description,
                    }}
                    edit={editReward}
                    onSubmit={onSubmitLotteryReward}
                    onEdit={() => setEditReward(true)}
                    onCancel={() => {
                        setEditReward(false);
                    }}
                    loading={loading}
                    determineInputType={({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                    }) =>
                        getLotteryGameFormInput({
                            value,
                            name,
                            register,
                            control,
                            error,
                            disabled,
                            index,
                            resetValue: (name, value) => setResetProp({ [name]: value }),
                            startAtEnabled: false,
                        })
                    }
                    resetProp={resetProp}
                >
                    {' '}
                    <ItemImage imgSrc={reward.image_url} />
                </Item>
            ) : null}
        </div>
    );
};

export default LotteryReward;
