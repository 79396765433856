import { types, applySnapshot } from 'mobx-state-tree';

const Player = types
    .model('Player', {
        id: types.identifierNumber,
        first_name: types.optional(types.string, ''),
        last_name: types.optional(types.string, ''),
        team_id: types.optional(types.number, 0),
        avatar: types.maybeNull(types.string, ''),
        external_player_id: types.maybeNull(types.number),
        position: types.optional(types.string, ''),
        number: types.optional(types.string, ''),
        shot_side: types.optional(types.string, ''),
        player_points: types.optional(types.number, 0),
        date_of_birth: types.maybeNull(types.string),
        height: types.maybeNull(types.string, ''),
        weight: types.maybeNull(types.string, ''),
        nation: types.maybeNull(types.string, ''),
        rooster: types.optional(types.boolean, false),
        loaned: types.maybeNull(types.boolean),
        deleted_at: types.maybeNull(types.string),
    })
    .actions((self) => ({
        editPlayer(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Player;
