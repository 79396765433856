import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Page from './../../components/Page';
import Passes from './Passes';
import Pass from './Pass';
import NewPass from './NewPass';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const PassesTab = ({ store, adminStore }) => {
    useEffect(() => {
        return () => {
            //clean up
            store.selectPass(undefined);
        };
    }, [store]);

    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.PASSES}>
                <Passes store={store} />
            </Route>
            <Route exact path={Paths.PASS}>
                <Pass store={store} adminStore={adminStore} />
            </Route>
            <Route exact path={Paths.NEW_PASS}>
                <NewPass store={store} />
            </Route>
        </Page>
    );
};

export default observer(PassesTab);
