import { types, flow } from 'mobx-state-tree';
import Notification from './Notification';
import API from '../../api/notifications';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';

const Notifications = types
    .model('Notifications', {
        notifications: types.array(Notification),
        selectedNotification: types.maybe(types.reference(Notification)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getNotifications: flow(function* getNotifications() {
            self.error = '';
            try {
                const res = yield API.getNotifications({
                    params: {
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.notifications = res.data.items;
                self.totalPages = res.data.total_pages_count;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getNotification: flow(function* getNotification(id) {
            self.error = '';
            try {
                const res = yield API.getNotification(id);
                self.notifications = [];
                self.notifications.push(res.data);
                self.selectedNotification = res.data.id;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editNotification: flow(function* editNotification(edited) {
            try {
                const res = yield API.editNotification(self.selectedNotification.id, { ...edited });
                self.selectedNotification.editNotification(res.data);
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        deleteNotification: flow(function* deleteNotification(id) {
            try {
                yield API.deleteNotification(id);
                const index = self.notifications.findIndex((n) => n.id === id);
                self.notifications.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        deleteRecipient(id) {
            console.log('ovog brisem: ', id);
        },
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        selectNotification(id) {
            self.selectedNotification = id;
        },
        sendNotification: flow(function* sendNotification(notif, sponsors = false) {
            notif.type = 'admin_push';
            try {
                yield API.sendNotification(notif, sponsors);
                self.notifications.push(notif);
            } catch (error) {
                throw error;
            }
        }),
        setError(err) {
            self.error = err;
        },
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default Notifications;
