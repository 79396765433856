import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import {
    FaTrash,
    FaPlus,
    FaFile,
    FaSearch,
    FaCheck,
    FaTimes,
    FaShopify,
    FaAward,
} from 'react-icons/fa';
import translations from '../../translations/de';

import useModal from '../../customHooks/useModal';

import Input from '../../components/Input';
import Table from '../../components/Table';
import Button from '../../components/Button';
import Pagination from './../../components/Pagination';
import Select from '../../components/Select';
import Modal from '../../components/Modal';

import Paths from '../../constants/paths';
import voucherTypes from '../../constants/voucherTypes';

const Vouchers = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [voucherID, setVoucherID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(undefined);
    const [selectedVoucherType, setSelectedVoucherType] = useState(null);

    const handleVoucher = (id) => {
        store.selectVoucher(id);
        history.push(`/vouchers/${store.selectedVoucher.id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    const onVoucherTypeChangeHandler = (e) => {
        setSelectedVoucherType(e.value);
    };

    useEffect(() => {
        store.getVouchers(searchQuery, selectedVoucherType);
    }, [store, store.selectedPage, searchQuery, selectedVoucherType]);

    const tableColumns = [
        {
            Header: 'ID',
            accessor: 'id',
            Cell: ({ value }) => <div>{value}</div>,
        },
        {
            Header: translations.type,
            accessor: 'item_type',
            Cell: ({ value }) => <div>{value}</div>,
        },
        {
            Header: translations.item_id,
            accessor: 'item_id',
            Cell: ({ value }) => <div>{value}</div>,
        },
        {
            Header: translations.order_id,
            accessor: 'order_id',
            Cell: ({ value }) => <div>{value}</div>,
        },
        {
            Header: translations.voucher_code_table,
            accessor: 'voucher_code',
            Cell: ({ value }) => <div>{value}</div>,
        },
        {
            Header: translations.used,
            accessor: 'used',
            Cell: ({ value }) => (
                <div>
                    {value === true ? (
                        <FaCheck className="confirm" />
                    ) : (
                        <FaTimes className="error" />
                    )}
                </div>
            ),
        },
        {
            Header: translations.assigned,
            accessor: 'acquired_item_id',
            Cell: ({ value }) => (
                <div>{value ? <FaCheck className="confirm" /> : <FaTimes className="error" />}</div>
            ),
        },
        {
            Header: '',
            accessor: 'action',
            Cell: ({ row }) => (
                <div>
                    <Button
                        onClick={() => handleVoucher(row.cells[0].value)}
                        title={translations.show}
                    >
                        <FaSearch />
                    </Button>
                    <Button
                        onClick={() => {
                            setVoucherID(row.cells[0].value);
                            setModal(true);
                        }}
                        title={translations.delete}
                    >
                        <FaTrash />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <div className="tab-controls">
                <Input placeholder={translations.search} onChange={onSearchChangeHandler} />
                <Select
                    defaultValue={{ label: translations.all, value: null }}
                    options={[{ label: translations.all, value: null }, ...voucherTypes]}
                    placeholder={translations.search}
                    onChange={onVoucherTypeChangeHandler}
                />
            </div>
            <div className="tab-controls">
                <Button
                    label={translations.new_voucher_manually}
                    onClick={() => {
                        store.selectVoucher(undefined);
                        history.push(Paths.NEW_VOUCHER);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
                <div style={{ marginLeft: '25px' }}>
                    <Button
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                            store.selectVoucher(undefined);
                            history.push(Paths.NEW_VOUCHER_FROM_FILE);
                        }}
                    >
                        <FaPlus style={{ marginRight: '5px' }} />
                        {translations.new_voucher_from_file}
                        <FaFile style={{ marginLeft: '5px' }} />
                    </Button>
                </div>
            </div>
            <Table
                tablePagination={
                    store.totalPages > 1 && (
                        <Pagination
                            totalPages={store.totalPages}
                            selectedPage={store.selectedPage}
                            onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                        />
                    )
                }
                tableName={translations.vouchers}
                columns={tableColumns}
                data={store.vouchers.map(
                    ({
                        id,
                        voucher_code,
                        redeem_data,
                        acquired_item_id,
                        shop_item_id,
                        reward_id,
                        order_id,
                    }) => {
                        return {
                            id,
                            order_id,
                            voucher_code,
                            used: redeem_data ? true : false,
                            acquired_item_id,
                            item_id: reward_id ? (
                                <div
                                    className="link-label"
                                    onClick={() => history.push(`/rewards/${reward_id}`)}
                                >
                                    {reward_id}
                                </div>
                            ) : shop_item_id ? (
                                <div
                                    className="link-label"
                                    onClick={() => history.push(`/shop/${shop_item_id}`)}
                                >
                                    {shop_item_id}
                                </div>
                            ) : acquired_item_id ? (
                                acquired_item_id
                            ) : (
                                'No item'
                            ),
                            item_type: shop_item_id ? (
                                <FaShopify
                                    title="Shop item"
                                    style={{ color: 'green', fontSize: '24px' }}
                                />
                            ) : reward_id ? (
                                <FaAward
                                    title="Reward"
                                    style={{ color: 'green', fontSize: '24px' }}
                                />
                            ) : (
                                <FaTimes className="error" />
                            ),
                        };
                    },
                )}
            />
            {modal && (
                <Modal
                    title="Delete Voucher"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this voucher?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteVoucher(voucherID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default observer(Vouchers);
