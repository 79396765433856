const mandatoryFields = [
    'name',
    'image_url',
    'description',
    'price',
    'start_stock',
    'visible_until',
    'reward_name',
    'reward_description',
    'reward_image_url',
    'reward_popup_text',
    'reward_popup_title',
    'reward_type',
    'reward_expire_at',
];

export default mandatoryFields;
