import { Route } from 'react-router-dom';
import Page from '../../components/Page';
import Accesses from './Accesses';
import Access from './Access';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js/index.js';
import { observer } from 'mobx-react-lite';
import NewAccess from './NewAccess';
import { useEffect } from 'react';

const AccessesTab = ({ store }) => {
    useEffect(() => {
        return () => {
            store.clear();
        };
    }, [store]);

    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.E_TICKETS}>
                <Accesses store={store} />
            </Route>
            <Route exact path={Paths.E_TICKET}>
                <Access store={store} />
            </Route>
            <Route exact path={Paths.NEW_E_TICKET}>
                <NewAccess store={store} />
            </Route>
        </Page>
    );
};

export default observer(AccessesTab);
