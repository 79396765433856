import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import Items from './Items';
import Item from './Item';
import NewItem from './NewItem';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const ShopTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.SHOP}>
                <Items store={store} />
            </Route>
            <Route exact path={Paths.SHOP_ITEM}>
                <Item store={store} />
            </Route>
            <Route exact path={Paths.SHOP_NEW_ITEM}>
                <NewItem store={store} />
            </Route>
        </Page>
    );
};

export default observer(ShopTab);
