import React, { useEffect, useState, useRef } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Pagination from './../../components/Pagination';
import Input from './../../components/Input';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';
import Button from '../../components/Button';
import ExportBtn from '../../components/ExportBtn';
import {
    FaSearch,
    FaUserSlash,
    FaTrash,
    FaUndoAlt,
    FaCheck,
    FaTimes,
    FaEye,
    FaEyeSlash,
} from 'react-icons/fa';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import API from '../../api/users';

const Fans = ({ store }) => {
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);
    const [userID, setUserID] = useState('');
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);

    useEffect(() => {
        store.getUsers(searchQuery);
        return () => {
            store.setSearchQuery('');
        };
    }, [store, store.selectedPage, searchQuery]);

    const handleUser = (id) => {
        store.selectUser(id);
        history.push(`/fans/${store.selectedUser.id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const censorFanName = (id) => {
        store.selectUser(id);
        var tempObj = store.users.find((user) => user.id === id);
        store.editFanName(tempObj);
    };

    return (
        <>
            <div className="Fan">
                <div className="header-controls">
                    <Input
                        placeholder={translations.search}
                        defaultValue={store.searchQuery}
                        onChange={onSearchChangeHandler}
                    />
                    <ExportBtn
                        label={translations.export_users}
                        fileName='users'
                        apiFunc={() => API.exportUsers()}
                    />
                </div>
            </div>
            <Table
                tablePagination={
                    store.totalPages > 1 && (
                        <Pagination
                            totalPages={store.totalPages}
                            selectedPage={store.selectedPage}
                            onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                        />
                    )
                }
                tableName={translations.user}
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.first_name,
                        accessor: 'first_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.last_name,
                        accessor: 'last_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.email,
                        accessor: 'email',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.fan_name,
                        accessor: 'fan_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.has_verified_email,
                        accessor: 'has_verified_email',
                        Cell: ({ value }) => (
                            <div>
                                {value === true ? (
                                    <FaCheck
                                        className="confirm"
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                ) : (
                                    <FaTimes
                                        className="error"
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                )}
                            </div>
                        ),
                    },
                    {
                        Header: translations.is_deleted,
                        accessor: 'deleted_at',
                        Cell: ({ value, row }) => (
                            <div>
                                {value !== null ? (
                                    <FaUserSlash
                                        style={{ width: '20px', height: '20px' }}
                                        className="error"
                                    />
                                ) : (
                                    <FaTimes
                                        style={{ width: '20px', height: '20px' }}
                                        className="error"
                                    />
                                )}
                            </div>
                        ),
                    },
                    {
                        Header: '',
                        accessor: 'action',
                        Cell: ({ value, row }) => (
                            <div className="button-cell">
                                <Button
                                    onClick={() => {
                                        handleUser(row.cells[0].value);
                                    }}
                                    title={translations.show}
                                >
                                    <FaSearch />
                                </Button>
                                <Button
                                    onClick={() => censorFanName(row.cells[0].value)}
                                    title="Censor Fan Name"
                                >
                                    {value ? <FaEyeSlash /> : <FaEye />}
                                </Button>
                                {isNotDeletedAlready(row) ? (
                                    <Button
                                        onClick={() => {
                                            setUserID(row.cells[0].value);
                                            setModal(true);
                                        }}
                                        title={translations.delete}
                                    >
                                        <FaTrash />
                                    </Button>
                                ) : (
                                    <Button onClick={() => store.reviveUser(row.cells[0].value)}>
                                        <FaUndoAlt />
                                    </Button>
                                )}
                            </div>
                        ),
                    },
                ]}
                data={store.users.map(
                    ({
                        id,
                        first_name,
                        last_name,
                        email,
                        fan_name,
                        has_verified_email,
                        deleted_at,
                        fan_name_censored,
                    }) => {
                        return {
                            id,
                            first_name,
                            last_name,
                            email,
                            fan_name,
                            has_verified_email,
                            deleted_at,
                            action: fan_name_censored,
                        };
                    },
                )}
            />
            {modal && (
                <Modal
                    title="Delete User"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete user with id ${userID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteUser(userID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(Fans);
