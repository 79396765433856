import React, { useCallback, useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory, Link } from 'react-router-dom';

import {
    FaCheck,
    FaTimes,
    FaTrash,
    FaLongArrowAltRight,
    FaSearch,
    FaUndoAlt,
} from 'react-icons/fa';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import Pagination from './../../components/Pagination';
import translations from '../../translations/de';
import debounce from 'lodash.debounce';
import Input from '../../components/Input';
import InputSeason from '../../components/InputSeason/InputSeason';
import PassesNotifications from '../../components/PassesNotifications';
import ExportBtn from '../../components/ExportBtn';
import API from '../../api/passes';

const Passes = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [passID, setPassID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [sendNotifications, setSendNotifications] = useState(false);

    const getPasses = useCallback(() => {
        selectedSeason && store.getPasses(searchQuery, selectedSeason);
    }, [store, searchQuery, selectedSeason]);

    useEffect(() => {
        getPasses();
    }, [store, store.selectedPage, selectedSeason, getPasses]);

    const handlePass = (id) => {
        store.selectPass(undefined);
        history.push(`/abos/${id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    return !sendNotifications ? (
        <>
            <div className="tab-wrapper">
                <div className="tab-controls">
                    <Input
                        label={translations.search}
                        placeholder={translations.search}
                        defaultValue={store.searchQuery}
                        onChange={onSearchChangeHandler}
                    />
                    <InputSeason
                        selectedSeason={selectedSeason}
                        setSelectedSeason={setSelectedSeason}
                    />
                </div>
                <div className="tab-controls_advanced">
                    <Button
                        label={translations.notifications}
                        onClick={() => setSendNotifications(true)}
                    />
                    <ExportBtn
                        label={translations.ticket_export}
                        fileName="Passes"
                        apiFunc={() => API.exportPasses({ params: { season_id: selectedSeason } })}
                    />
                </div>
            </div>
            {store.passes && store.passes.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    isRowClickable={false}
                    tableName={translations.passes}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Ticket-Nr',
                            accessor: 'ticket_id',
                            Cell: ({ value }) => <div>{value ? value : '-'}</div>,
                        },
                        {
                            Header: 'BarCodes',
                            accessor: 'barcode',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.assign_token,
                            accessor: 'assign_token',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Firma',
                            accessor: 'company',
                            Cell: ({ value }) => <div>{value ? value : '-'}</div>,
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value ? value : '-'}</div>,
                        },
                        {
                            Header: translations.user,
                            accessor: 'user',
                            Cell: ({ value }) => (
                                <div
                                    onClick={() =>
                                        value?.id
                                            ? history.push({
                                                  pathname: `/fans/${value.id}`,
                                                  state: {
                                                      activeTabName: 'Abos',
                                                      selectedSeason,
                                                  },
                                              })
                                            : null
                                    }
                                >
                                    {value ? (
                                        <span className="user_link">
                                            <FaLongArrowAltRight />{' '}
                                            <span>{`${value.first_name} ${value.last_name}`}</span>
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.accesses,
                            accessor: 'etickets',
                            Cell: ({ row, value }) => (
                                <div>
                                    {value ? (
                                        <Link to={`/etickets?pass_id=${row.cells[0].value}`}>
                                            <FaLongArrowAltRight />{' '}
                                            {`${translations.show} [${value}]`}
                                        </Link>
                                    ) : (
                                        '0'
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.can_claim_freunde,
                            accessor: 'can_claim_freunde',
                            Cell: ({ value, row }) => (
                                <div>
                                    {value === true ? (
                                        <FaCheck className="confirm" />
                                    ) : (
                                        <FaTimes className="error" />
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.seat,
                            accessor: 'seat',
                            Cell: ({ value }) => (
                                <div>
                                    Entrance: {value?.seat_group?.sector?.title} <br />
                                    Block: {value?.seat_group?.title} <br />
                                    Row: {value?.row} <br />
                                    Seat: {value?.num} <br />
                                </div>
                            ),
                        },

                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div className="button-cell">
                                    <Button
                                        onClick={() => {
                                            handlePass(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>

                                    {isNotDeletedAlready(row) ? (
                                        <Button
                                            onClick={() => {
                                                setPassID(row.cells[0].value);
                                                setModal(true);
                                            }}
                                            title={translations.delete}
                                        >
                                            <FaTrash />
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => store.revivePass(row.cells[0].value)}
                                        >
                                            <FaUndoAlt />
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    data={store.passes.map(
                        ({
                            id,
                            ticket_id,
                            assign_token,
                            type,
                            user,
                            accesses,
                            accesses_num,
                            barcodes_num,
                            seat,
                            can_claim_freunde,
                            season,
                            first_name,
                            last_name,
                            company,
                            deleted_at,
                        }) => {
                            return {
                                id,
                                ticket_id,
                                assign_token,
                                type,
                                name: first_name + ' ' + last_name,
                                user,
                                etickets: accesses_num,
                                seat,
                                barcode: barcodes_num,
                                company,
                                can_claim_freunde,
                                deleted_at,
                            };
                        },
                    )}
                />
            )}

            {modal && (
                <Modal
                    title="Delete Abo"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete this abo with id ${passID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deletePass(passID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    ) : (
        <PassesNotifications closeScreen={() => setSendNotifications(false)} />
    );
};

export default observer(Passes);
