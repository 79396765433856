import { types, flow } from 'mobx-state-tree';
import LotteryGame from './LotteryGame';
import API from '../../api/lotteryGames';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearEmptyFields from '../../helpers/clearEmptyFields';
import clearObjNulls from '../../helpers/clearObjNulls';

const LotteryGames = types
    .model('Lottery Games', {
        lotteryGames: types.array(LotteryGame),
        selectedLotteryGame: types.maybe(types.reference(LotteryGame)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getLotteryGames: flow(function* getLotteryGames(search) {
            self.error = '';
            try {
                const res = yield API.getLotteryGames({
                    params: {
                        limit: 10,
                        page: self.selectedPage,
                        search: search ? search : undefined,
                    },
                });
                self.dataLoaded = true;
                self.lotteryGames = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        selectLotteryGame(id) {
            self.selectedLotteryGame = id;
        },
        getLotteryGame: flow(function* getLotteryGame(id) {
            self.error = '';
            try {
                const res = yield API.getLotteryGame(id);
                if (!self.selectedLotteryGame) {
                    self.lotteryGames = [];
                    self.lotteryGames.push(res.data);
                    self.selectedLotteryGame = res.data.id;
                } else {
                    self.selectedLotteryGame.reward = res.data.reward;
                    self.selectedLotteryGame.winners = res.data.winners;
                }
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getLotteryGameParticipiants: flow(function* getLotteryGameParticipiants(page) {
            self.error = '';
            try {
                const res = yield API.getLotteryUsers(self.selectedLotteryGame.id, {
                    params: {
                        limit: 10,
                        page: page,
                    },
                });
                self.selectedLotteryGame.participants = res.data.items;
                return res.data.total_pages_count;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editLotteryGameDetails: flow(function* editLotteryGameDetails(edited) {
            var lottery = {
                draw_when_sold_out: edited.draw_when_sold_out,
                send_notification: edited.send_notification,
                draw_at: edited.draw_at,
                shop_item: {
                    name: edited.name,
                    image_url: edited.image_url,
                    price: edited.price,
                    stock: edited.stock,
                    start_stock: edited.start_stock,
                    sold: edited.sold,
                    active: edited.active,
                    description: edited.description,
                    deactivate_on_sold: edited.deactivate_on_sold,
                    visible_until: edited.visible_until,
                },
            };
            if (!edited.active) {
                lottery.start_at = edited.start_at;
            }
            try {
                const res = yield API.editLotteryGame(
                    self.selectedLotteryGame.id,
                    clearEmptyFields(clearObjNulls(lottery)),
                );
                self.selectedLotteryGame.editLotteryGame(res.data);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        createLotteryGame: flow(function* createLotteryGame(lottery) {
            const newLottery = {
                draw_when_sold_out: lottery.draw_when_sold_out,
                draw_at: lottery.draw_at,
                send_notification: lottery.send_notification,
                shop_item: {
                    name: lottery.name,
                    image_url: lottery.image_url,
                    price: lottery.price,
                    stock: lottery.stock,
                    start_stock: lottery.start_stock,
                    sold: lottery.sold,
                    active: lottery.active,
                    description: lottery.description,
                    deactivate_on_sold: lottery.deactivate_on_sold,
                    visible_until: lottery.visible_until,
                },
                reward: {
                    name: lottery.reward_name,
                    image_url: lottery.reward_image_url,
                    fan_coins: lottery.reward_fan_coins,
                    expire_at: lottery.reward_expire_at,
                    type: lottery.reward_type,
                    description: lottery.reward_description,
                    redeem_description: lottery.reward_redeem_description,
                    popup_title: lottery.reward_popup_title,
                    popup_text: lottery.reward_popup_text,
                },
            };
            if (!lottery.active) {
                newLottery.start_at = lottery.start_at;
            }
            try {
                const res = yield API.createLotteryGame(
                    clearEmptyFields(
                        newLottery,
                        lottery.voucher_type
                            ? { params: { voucher_type: lottery.voucher_type } }
                            : null,
                    ),
                );
                self.lotteryGames.push(res.data);
            } catch (error) {
                throw requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        drawLottery: flow(function* drawLottery() {
            self.error = '';
            try {
                const res = yield API.drawLotteryGame(self.selectedLotteryGame.id);
                self.selectedLotteryGame.draw_at = res.data.draw_at;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default LotteryGames;
