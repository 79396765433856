import { types, flow } from 'mobx-state-tree';
import API from './../../api/businessPartners';
import APIProfiles from './../../api/businessProfiles';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import BusinessPartner from './BusinessPartner';

const BusinessPartners = types
    .model('Partners', {
        partners: types.array(BusinessPartner),
        selectedPartner: types.maybe(types.reference(BusinessPartner)),
        profiles: types.array(types.number),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getPartners: flow(function* getPartners(searchQuery, partnerType) {
            self.error = '';
            try {
                const res = yield API.getPartners({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        type: partnerType ? partnerType : undefined,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.partners = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getPartner: flow(function* getPartner(id) {
            self.error = '';
            try {
                const res = yield API.getPartner(id);
                if (self.selectedPartner) {
                    // self.selectedPartner.profiles = res.data.profiles;
                } else {
                    self.partners = [];
                    self.partners.push(res.data);
                    self.selectedPartner = res.data.id;
                }
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        addPartner: flow(function* addPartner(newPartner) {
            try {
                newPartner.profiles = self.profiles;
                const res = yield API.createPartner(newPartner);
                self.partners.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        editPartner: flow(function* editPartner(edited) {
            try {
                const res = yield API.editPartner(self.selectedPartner.id, {
                    ...edited,
                    profiles: self.profiles,
                });
                self.selectedPartner.editPartner(res.data);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        deletePartner: flow(function* deletePartner(id) {
            try {
                yield API.deletePartner(id.toString());
                const index = self.partners.findIndex((p) => p.id === id);
                self.partners[index].deleted_at = 'deleted';
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        revivePartner: flow(function* revivePartner(id) {
            try {
                yield API.revivePartner(id.toString());
                const index = self.partners.findIndex((p) => p.id === id);
                self.partners[index].deleted_at = null;
            } catch (e) {
                console.log(e);
            }
        }),
        selectPartner(id) {
            self.selectedPartner = id;
            self?.selectedPartner?.profiles &&
                self.selectedPartner.profiles.forEach((profile) => self.profiles.push(profile.id));
        },
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        setTotalPages(total_pages_count) {
            self.totalPages = total_pages_count;
        },
        addProfile(id) {
            !self.profiles.includes(id) && self.profiles.push(id);
        },
        deleteProfile(id, sendRequest = true) {
            if (sendRequest) {
                try {
                    APIProfiles.editProfile(id, { company_id: null });
                    self.selectedPartner.profiles = self.selectedPartner.profiles.filter(
                        (profile) => profile.id !== id,
                    );
                } catch (e) {
                    console.log(e);
                }
            }
            self.profiles = self.profiles.filter((profile) => profile !== id);
        },
        resetProfiles() {
            self.profiles = [];
        },
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default BusinessPartners;
