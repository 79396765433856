import Button from '../../../components/Button';
import { observer } from 'mobx-react-lite';
import BusinessProfileTable from '../BusinessProfileTable';

const StepThree = ({ store, onNext, onBack }) => {
    return (
        <>
            <div className="controls">
                <Button label="Back" onClick={onBack} />
                <Button
                    style={{ width: '200px' }}
                    label="Einladung versenden &amp; Prozess beenden"
                    onClick={onNext}
                />
            </div>

            <BusinessProfileTable
                profiles={[...store.creating_invitations]}
                store={store}
                hasRemove
                isDisabled
            />
        </>
    );
};

export default observer(StepThree);
