import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import API from '../../api/mobileVersion';
import Item from '../../components/Item';
import translations from '../../translations/de';
import translateLabel from '../../helpers/translateLabel';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';

const MobileVersion = () => {
    const [version, setVersion] = useState(null);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        API.getVersions()
            .then((res) => setVersion(res.data))
            .catch((e) => console.log(e));
    }, []);

    return (
        version && (
            <Page>
                <Item
                    name={translations.mobile_version}
                    itemModel={{
                        mandatory: version.mandatory,
                        is_playoff: version.is_playoff,
                        version: version.version,
                    }}
                    edit={edit}
                    onEdit={() => setEdit(!edit)}
                    onSubmit={(event) => {
                        setLoading(true);
                        API.setVersion(event)
                            .then(() => {
                                setLoading(false);
                                setEdit(!edit);
                            })
                            .catch((err) => {
                                setLoading(false);
                            });
                    }}
                    onCancel={() => setEdit(!edit)}
                    loading={loading}
                    determineInputType={({ name, register, error, disabled, index }) => {
                        if (name === 'mandatory' || name === 'is_playoff') {
                            return (
                                <Checkbox
                                    key={index}
                                    text={translateLabel(name)}
                                    name={name}
                                    disabled={!edit}
                                    {...register(name, { required: false })}
                                />
                            );
                        } else {
                            return (
                                <Input
                                    key={index}
                                    label={translateLabel(name)}
                                    name={name}
                                    type="text"
                                    {...register(name, {
                                        required: true,
                                    })}
                                    error={error}
                                    disabled={disabled}
                                />
                            );
                        }
                    }}
                ></Item>
            </Page>
        )
    );
};

export default MobileVersion;
