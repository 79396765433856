import React from 'react';
import Page from '../../components/Page';
import ErrorMessages from '../../components/ErrorMessages.js/index.js';
import Sectors from './Sectors';
import NewSector from './NewSector';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import { observer } from 'mobx-react-lite';
import Sector from './Sector';

const index = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.SECTORS}>
                <Sectors store={store} />
            </Route>
            <Route exact path={Paths.SECTOR}>
                <Sector store={store} />
            </Route>
            <Route exact path={Paths.NEW_SECTOR}>
                <NewSector store={store} />
            </Route>
        </Page>
    );
};

export default observer(index);
