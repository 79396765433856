import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/lotteryGame';
import DatePicker from '../../components/DatePicker';
import InputFile from '../../components/InputFile';
import Textarea from '../../components/Textarea';
import Checkbox from '../../components/Checkbox';
import voucherTypes from '../../constants/voucherTypes';
import Select from '../../components/Select';
import rewardTypes from '../../constants/rewardTypes';
import translations from '../../translations/de';

export default function getLotteryGameFormInput({
    name,
    value,
    register,
    control,
    error,
    disabled,
    index,
    resetValue,
    handleTypeChange,
    showVoucherTypes,
    startAtEnabled,
}) {
    if (typeof value === 'boolean') {
        return (
            <Checkbox
                key={index}
                text={translateLabel(
                    name.includes('reward_')
                        ? name.split('reward_')[1]
                        : name === 'active'
                        ? 'Start Now'
                        : name,
                )}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                disabled={disabled}
            />
        );
    } else if (name === 'start_at') {
        // special case for Datepicker so it's isolated
        return (
            <DatePicker
                key={index}
                label={translateLabel(name.includes('reward_') ? name.split('reward_')[1] : name)}
                selectText={translations.select_date}
                name={name}
                defaultValue={value}
                control={control}
                disabled={disabled ? true : !startAtEnabled}
                error={error}
                withTime={true}
                isRequired={startAtEnabled}
                minDate={new Date()}
            />
        );
    } else if (name === 'draw_at' || name === 'visible_until' || name === 'expire_at' || name === 'reward_expire_at') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name.includes('reward_') ? name.split('reward_')[1] : name)}
                selectText={translations.select_date}
                name={name}
                defaultValue={value}
                control={control}
                disabled={name === 'start_at' ? startAtEnabled : disabled}
                error={error}
                withTime={true}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                minDate={new Date()}
            />
        );
    } else if (name === 'image_url' || name === 'reward_image_url') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name.includes('reward_') ? name.split('reward_')[1] : name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                control={control}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/lottery-items"
            />
        );
    } else if (
        name === 'description' ||
        name === 'reward_description' ||
        name === 'reward_redeem_description' ||
        name === 'reward_popup_text'
    ) {
        return (
            <Textarea
                key={index}
                label={translateLabel(name.includes('reward_') ? name.split('reward_')[1] : name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'reward_type') {
        return (
            <Select
                key={index}
                label={translateLabel('type')}
                onChangeCallback={(e) => handleTypeChange(e)}
                name={name}
                isSearchable={false}
                options={rewardTypes}
                control={control}
                error={error}
                disabled={disabled}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'voucher_type') {
        return (
            showVoucherTypes === true && (
                <Select
                    key={index}
                    text={translateLabel(name)}
                    label={translateLabel(name)}
                    name={name}
                    isSearchable={false}
                    options={voucherTypes}
                    control={control}
                    error={error}
                    disabled={disabled}
                    isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                />
            )
        );
    } else if (name === 'start_stock' || name === 'price') {
        return (
            <Input
                key={index}
                label={translateLabel(name.includes('reward_') ? name.split('reward_')[1] : name)}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: true,
                    min: { value: 1, message: translations.positive_number },
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name.includes('reward_') ? name.split('reward_')[1] : name)}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
