import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import SeatMaps from './SeatMaps';
import SeatMap from './SeatMap';
import NewSeatMap from './NewSeatMap';

const SeatMapTab = ({ store }) => {
    useEffect(() => {
        store.getSeatMaps();
        store.getSeats();
        store.getScenarios();
    }, [store]);

    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.SEAT_MAPS}>
                <SeatMaps store={store} />
            </Route>
            <Route exact path={Paths.SEAT_MAP}>
                <SeatMap store={store} />
            </Route>
            <Route exact path={Paths.NEW_SEAT_MAP}>
                <NewSeatMap store={store} />
            </Route>
        </Page>
    );
};

export default observer(SeatMapTab);
