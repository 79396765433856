import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import translations from '../../translations/de';
import APIRewards from '../../api/rewards';
import debounce from 'lodash.debounce';
import FindRewardTemplate from '../../components/FindRewardTemplate';
import Paths from '../../constants/paths';
import Button from '../../components/Button';
import getRewardInput from '../../components/Rewards/getRewardInput';
import ErrorMessages from '../../components/ErrorMessages.js';
import Breadcrumb from '../../components/Breadcrumb';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import { useForm, useWatch } from 'react-hook-form';
import { createRewardClearFields } from '../../helpers/createRewardClearFields';

import '../InstantRewards/style.scss';
import translateLabel from '../../helpers/translateLabel.js';

/**
 * This component is form for creating new default rewards for fan games
 * @param {Object} store - Store object
 *  
 */


const FanGameDefaultReward = ({ store }) => {
    const history = useHistory();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchOrders, setSearchOrders] = useState('');
    const [orders, setOrders] = useState([]);
    const [disableVoucherFields, setDisableVoucherFields] = useState(false);
    const [type, setType] = useState('');
    const [voucherType, setVoucherType] = useState(null);
    const [voucherOrderID, setVoucherOrderID] = useState('');
    const [orderID, setOrderID] = useState('');

    const rewardObj = {
        image_url: '',
        name: '',
        redeem_description: '',
        popup_title: '',
        popup_text: '',
        description: null,
        quantity: 0,
        expire_at: '',
        type: 'avatar',
        fan_coins: 0,
        voucher_type: '',
        single_voucher_check: false,
        shared_code: null,
        dummy: '',
        importCodesField: voucherOrderID,
        sponsor_link: null,
        price_in_cents: 0,
        order_id: orderID,
    };

    var reward = Object.keys(rewardObj).map((key) => {
        return { name: key, value: rewardObj[key] };
    });

    const [resetProp, setResetProp] = useState(null);

    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
        getValues,
    } = useForm({
        defaultValues: rewardObj,
    });

    //  watched props
    let priceInCents = useWatch({ control, name: 'price_in_cents' });
    let showSingleVoucherCodeField = useWatch({ control, name: 'single_voucher_check' });

    const searchOrdersHandler = debounce((value) => {
        setSearchOrders(value);
    }, 500);

    const handleVoucher = (e) => {
        if (e) {
            setOrders([]);
            setOrderID('');
            setVoucherOrderID('');
            var values = {
                ...getValues(),
                order_id: null,
                importCodesField: '',
            };
            reset(values);
        }
        setVoucherType(e);
    };

    const handleTypeChange = (e) => {
        var formValues = getValues();
        switch (e) {
            case 'consumable':
                reset({ ...formValues, fan_coins: 0 });
                break;
            case 'coins':
                unregister('voucher_type');
                reset({ ...formValues, fan_coins: 0 });
                break;
            default:
                reset({ ...formValues, fan_coins: 0 });
                unregister('voucher_type');
        }
        setType(e);
    };

    const handleOrder = (e) => {
        if (e) {
            reset({
                ...getValues(),
                type: 'consumable',
                fan_coins: 0,
                price_in_cents: 0,
                use_unused: false,
                sponsor_link: '',
            });
            setType('consumable');

            setDisableVoucherFields(true);
        } else {
            reset({
                ...getValues(),
                fan_coins: 0,
                use_unused: false,
            });
            setDisableVoucherFields(false);
        }
    };

    const onSave = async (event) => {
        setError(null);
        setLoading(true);
        const {
            importCodesField,
            single_voucher_check,
            dummy,
            use_unused,
            voucher_type,
            shared_code,
            type,
            ...clearedEvent
        } = event;

        if (event.voucher_type === '')
        {
            setError('Voucher Type field is required!')
            setLoading(false)
            return
        }
       


        // Prepare form data
        const formData = createRewardClearFields({
            ...clearedEvent,
            order_id: orderID ? orderID?.value ?? orderID : event.order_id,
            voucher_type: voucher_type,
            price_in_cents: voucher_type === 'eguma' ? Number(event.price_in_cents) : 0,
            shared_code: showSingleVoucherCodeField ? shared_code : null,
            type: disableVoucherFields ? 'consumable' : type,
            season_id: history?.location?.state?.selectedSeason,
            fan_game_type: history?.location?.state?.activeTabName,
        });
        

        try {
            await store.addDefaultReward(formData);
            setLoading(false);
            history.goBack();
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        const getOrders = async () => {
            try {
                const res = await APIRewards.getOrders({
                    params: { search: searchOrders, voucher_type: voucherType },
                });
                setOrders(res.data);
            } catch (e) {
                console.error(e);
            }
        };
        searchOrders !== '' && getOrders();
    }, [searchOrders, voucherType]);

    useEffect(() => {
        if (resetProp) {
            reset({ ...watch(), ...resetProp });
        }
    }, [resetProp, reset, watch]);

    let fanGameName = history?.location?.state?.activeTabName;

    return (
        <>
            <div className="Item">
                <div className="Item-Header">
                    <Breadcrumb
                        name={`${translateLabel(fanGameName)} - New default reward`}
                        links={[{ path: Paths.FAN_GAMES, name: translations.fan_games }]}
                    />
                    <Button
                        style={{ marginLeft: '10px' }}
                        label={translations.save}
                        type="submit"
                        onClick={handleSubmit((e) => onSave(e))}
                        loading={loading}
                    />
                </div>
                <div style={{ paddingTop: '2%' }} />

                <div className="Item-Content">
                    <div style={{ width: '49%' }}>
                        <FindRewardTemplate
                            setResetProp={setResetProp}
                            rewardObj={rewardObj}
                            setType={(e) => setType(e)}
                            isDisabled={disableVoucherFields}
                            setVoucherType={setVoucherType}
                        />
                    </div>
                    <form>
                        {rewardObj.length !== 0 &&
                            reward.map((data, index) =>
                                getRewardInput({
                                    disabled: false,
                                    value: data.value,
                                    name: data.name,
                                    register,
                                    control,
                                    error: errors[data.name],
                                    index,
                                    searchOrdersHandler,
                                    resetValue: (name, value) => setResetProp({ [name]: value }),
                                    resetVoucherValue: (name, value) => {
                                        setVoucherOrderID({ [name]: value });
                                        setResetProp({ [name]: value });
                                    },
                                    handleTypeChange: (e) => handleTypeChange(e),
                                    handleVoucherTypeChange: (e) =>
                                        handleVoucher(
                                            e,
                                            setOrders,
                                            setVoucherType,
                                            getValues,
                                            reset,
                                            () => setDisableVoucherFields(false),
                                            setOrderID,
                                            setVoucherOrderID,
                                        ),
                                    voucherType,
                                    orderID,
                                    setOrderID: (value) => setOrderID(value),
                                    type,
                                    orders,
                                    handleOrder: (e) => handleOrder(e),
                                    disableVoucherFields,
                                    price_in_cents: priceInCents,
                                    showSingleVoucherCodeField,
                                }),
                            )}
                    </form>

                    <ErrorMessages
                        messages={requsetErrorMessage(
                            error && error.message ? error.message : error,
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default observer(FanGameDefaultReward);
