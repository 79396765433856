import React from 'react';
import Table from '../../components/Table';

//import { useHistory } from 'react-router-dom';
const AboDialog = ({ passes }) => {
    //const history = useHistory();

    // const openTicket = (id) => {
    //     history.push(`/etickets/${id}`);
    // };
    return (
        <Table
            isRowClickable={true}
            columns={[
                {
                    Header: 'ID',
                    accessor: 'access_code_id',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: 'Event',
                    accessor: 'event_id',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: 'Bar Code',
                    accessor: 'access_token',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: 'Platznummer',
                    accessor: 'block_label',
                    Cell: ({ value }) => <div>{value}</div>,
                },
            ]}
            data={passes.map(({ access_code_id, event_id, access_token, block_label }) => {
                return {
                    access_code_id,
                    event_id,
                    access_token,
                    block_label,
                };
            })}
        />
    );
};

export default AboDialog;
