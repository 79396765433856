import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import Item from '../../components/Item';
import getRewardInput from '../../components/Rewards/getRewardInput';
import FindRewardTemplate from '../../components/FindRewardTemplate/index';
import { createRewardClearFields } from '../../helpers/createRewardClearFields';
import API from '../../api/rewards';
import debounce from 'lodash.debounce';

export const handleVoucher = (
    e,
    setOrders,
    setVoucherType,
    getValues,
    reset,
    onClear,
    setOrderID,
    setVoucherOrderID,
) => {
    if (e) {
        setOrders([]);
        setOrderID('');
        setVoucherOrderID('');
        var values = { ...getValues(), order_id: null, importCodesField: null };
        reset(values);
    } else {
        onClear();
    }
    setVoucherType(e);
};

const Reward = ({ store }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [type, setType] = useState('avatar');
    const [imageUrl, setImageUrl] = useState('');
    const [voucherOrderID, setVoucherOrderID] = useState('');
    const [searchOrders, setSearchOrders] = useState('');
    const [orders, setOrders] = useState([]);
    const [disableVoucherFields, setDisableVoucherFields] = useState(false);

    //fields for tracking
    const [orderID, setOrderID] = useState('');
    const [voucherType, setVoucherType] = useState(null);
    const [priceInCents, setPriceInCents] = useState(null);

    const rewardObj = {
        image_url: '',
        name: '',
        redeem_description: '',
        popup_title: '',
        quantity: 0,
        popup_text: '',
        description: '',
        expire_at: '',
        type: 'avatar',
        voucher_type: null,
        price_in_cents: 0,
        // special prop for deleting
        importCodesField: voucherOrderID,
        //
        order_id: orderID,
        fan_coins: 0,
        sponsor_link: null,
        use_unused: false,
    };

    const handleOrder = (e, getValues, reset) => {
        if (e) {
            reset({
                ...getValues(),
                type: 'consumable',
                fan_coins: 0,
                price_in_cents: 0,
                use_unused: false,
            });
            setType('consumable');
            setDisableVoucherFields(true);
        } else {
            reset({
                ...getValues(),
                fan_coins: 0,
                use_unused: false,
            });
            setDisableVoucherFields(false);
        }
    };

    const createReward = async (event) => {
        setLoading(true);
        error && setError(null);
        const formData = createRewardClearFields(event);

        try {
            await store.createReward(formData);
            setLoading(false);
            history.goBack();
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    };

    const searchOrdersHandler = debounce((value) => {
        setSearchOrders(value);
    }, 500);

    useEffect(() => {
        const getOrders = async () => {
            try {
                const res = await API.getOrders({
                    params: { search: searchOrders, voucher_type: voucherType },
                });
                setOrders(res.data);
            } catch (e) {
                console.error(e);
            }
        };
        searchOrders !== '' && getOrders();
    }, [searchOrders, voucherType]);

    return (
        <>
            <Item
                links={[{ path: Paths.REWARDS, name: translations.rewards }]}
                name={`${translations.reward} ${translations.create}`}
                itemModel={rewardObj}
                edit={true}
                onSubmit={(event) => {
                    delete event.importCodesField;
                    createReward({
                        ...event,
                        order_id: orderID !== '' ? orderID?.value : event.order_id,
                        use_unused: disableVoucherFields ? false : event.use_unused,
                        type: disableVoucherFields ? 'consumable' : event.type,
                    });
                }}
                onCancel={() => history.goBack()}
                watchedProperties={['price_in_cents']}
                watchedPropertyActions={{ price_in_cents: setPriceInCents }}
                error={error}
                loading={loading}
                determineInputType={({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    control,
                    getValues,
                    reset,
                }) =>
                    getRewardInput({
                        value,
                        name,
                        register,
                        error,
                        disabled,
                        index,
                        resetImageValue: (name, value) => setImageUrl({ [name]: value }),
                        resetVoucherValue: (name, value) => setVoucherOrderID({ [name]: value }),

                        control,
                        handleTypeChange: (e) => setType(e),
                        handleVoucherTypeChange: (e) =>
                            handleVoucher(
                                e,
                                setOrders,
                                setVoucherType,
                                getValues,
                                reset,
                                () => setDisableVoucherFields(false),
                                setOrderID,
                                setVoucherOrderID,
                            ),
                        type: type,
                        voucherType,
                        setOrderID: (value) => setOrderID(value),
                        orderID: orderID,
                        searchOrdersHandler,
                        handleOrder: (e) => handleOrder(e, getValues, reset),
                        orders,
                        disableVoucherFields,
                        price_in_cents: priceInCents,
                    })
                }
                resetProp={[voucherOrderID, imageUrl]}
            >
                <FindRewardTemplate
                    setResetProp={setImageUrl}
                    setType={setType}
                    rewardObj={rewardObj}
                    isDisabled={disableVoucherFields}
                />{' '}
            </Item>
        </>
    );
};

export default observer(Reward);
