import React, { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import getSeatGroupFormInput from './getSeatGroupFormInput';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';
import translations from '../../translations/de';

const NewSeatGroup = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const sectors = getSnapshot(store.sectors);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    return (
        <Item
            links={[{ path: Paths.SEAT_GROUPS, name: translations.seat_groups }]}
            name={`${translations.seat_group} ${translations.create}`}
            itemModel={{
                title: '',
                sector_id: '',
            }}
            edit={true}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .addSeatGroup({ ...event })
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.SEAT_GROUPS);
                    })
                    .catch((error) => {
                        setError(error);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, isDisabled, index }) =>
                getSeatGroupFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    isDisabled,
                    index,
                    sectors: sectors,
                })
            }
        ></Item>
    );
};

export default observer(NewSeatGroup);
