import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import ErrorMessages from './../../components/ErrorMessages.js';
import VouchersGastro from './VouchersGastro';
import Vouchers from './Vouchers';
import NewVoucher from './NewVoucher';
import NewGastroVoucher from './NewGastroVoucher';
import NewVoucherFileUpload from './NewVoucherFileUpload';
import NewGastroVoucherFileUpload from './NewGastroVoucherFileUpload';
import Voucher from './Voucher';

const VoucherTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.VOUCHERS}>
                <Vouchers store={store} />
            </Route>
            <Route exact path={Paths.VOUCHER}>
                <Voucher store={store} />
            </Route>
            <Route exact path={Paths.NEW_VOUCHER}>
                <NewVoucher store={store} />
            </Route>
            <Route exact path={Paths.NEW_VOUCHER_FROM_FILE}>
                <NewVoucherFileUpload store={store} />
            </Route>
            <Route exact path={Paths.VOUCHERS_GASTRO}>
                <VouchersGastro store={store} />
            </Route>
            <Route exact path={Paths.VOUCHER_GASTRO}>
                <Voucher store={store} />
            </Route>
            <Route exact path={Paths.NEW_GASTRO_VOUCHER_FROM_FILE}>
                <NewGastroVoucherFileUpload store={store} />
            </Route>
            <Route exact path={Paths.NEW_GASTRO_VOUCHER}>
                <NewGastroVoucher store={store} />
            </Route>
        </Page>
    );
};

export default observer(VoucherTab);
