import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import TabSwitcher from '../../components/TabSwitcher';
import homeNewsInactive from './../../assets/images/tab_icons/home_news_inactive.svg';
import homeNewsActive from './../../assets/images/tab_icons/home_news_active.svg';
import ticketsInactive from './../../assets/images/tab_icons/tickets_inactive.svg';
import ticketsActive from './../../assets/images/tab_icons/tickets_active.svg';
import gamesInactive from './../../assets/images/tab_icons/games_inactive.svg';
import gamesActive from './../../assets/images/tab_icons/games_active.svg';
import saisonInactive from './../../assets/images/tab_icons/saison_inactive.svg';
import saisonActive from './../../assets/images/tab_icons/saison_active.svg';
import businessInactive from './../../assets/images/tab_icons/business_inactive.svg';
import businessActive from './../../assets/images/tab_icons/business_active.svg';
import menuActive from './../../assets/images/tab_icons/menu_active.png';
import menuInactive from './../../assets/images/tab_icons/menu_inactive.svg';
import Screens from './Screens';

const ScreenTabs = ({ store }) => {
    const [tabName, setTabName] = useState('All');

    return (
        <>
            <TabSwitcher initialTabName="All" setParentTabName={(e) => setTabName(e)}>
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName="All" />
                    <TabSwitcher.TabPill
                        tabName="News"
                        tabIcon={tabName === 'News' ? homeNewsActive : homeNewsInactive}
                    />
                    <TabSwitcher.TabPill
                        tabName="Games"
                        tabIcon={tabName === 'Games' ? gamesActive : gamesInactive}
                    />
                    <TabSwitcher.TabPill
                        tabName="Saison"
                        tabIcon={tabName === 'Saison' ? saisonActive : saisonInactive}
                    />
                    <TabSwitcher.TabPill
                        tabName="Tickets"
                        tabIcon={tabName === 'Tickets' ? ticketsActive : ticketsInactive}
                    />
                    <TabSwitcher.TabPill
                        tabName="Business"
                        tabIcon={tabName === 'Business' ? businessActive : businessInactive}
                    />
                    <TabSwitcher.TabPill
                        tabName="Menu"
                        tabIcon={tabName === 'Menu' ? menuActive : menuInactive}
                    />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName="All">
                    <Screens store={store} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="News">
                    <Screens store={store} tab="news" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Games">
                    <Screens store={store} tab="games" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Saison">
                    <Screens store={store} tab="saison" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Tickets">
                    <Screens store={store} tab="tickets" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Business">
                    <Screens store={store} tab="business" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Menu">
                    <Screens store={store} tab="menu" />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </>
    );
};

export default observer(ScreenTabs);
