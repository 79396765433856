import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import './Pass.scss';
import PassForm from './PassForm';
import filterObject from '../../helpers/filterObject';
import TabSwitcher from '../../components/TabSwitcher';
import translations from '../../translations/de';
import PassGames from './PassGames';

const Pass = ({ store, adminStore }) => {
    const { abo_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const passDetails = filterObject(store.selectedPass, [
        'ticket_id',
        'assign_token',
        'type',
        //"create_anonymous_links",
    ]);

    const passBuyer = filterObject(store.selectedPass, [
        'company',
        'first_name',
        'last_name',
        'street',
        'zip',
        'city',
        'country',
        'can_claim_freunde',
    ]);

    useEffect(() => {
        store.getPass(abo_id);
    }, [store, abo_id]);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editPass({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };
    return store?.selectedPass ? (
        <TabSwitcher initialTabName={translations.general}>
            <TabSwitcher.Tabs>
                <TabSwitcher.TabPill tabName={translations.general} />
                <TabSwitcher.TabPill tabName={translations.passes_column} />
            </TabSwitcher.Tabs>
            <TabSwitcher.TabPanel activeWhenTabName={translations.general}>
                <PassForm
                    selectedPass={store.selectedPass}
                    edit={edit}
                    error={error}
                    loading={loading}
                    name={store.selectedPass.assign_token}
                    passId={store.selectedPass.id}
                    user={store.selectedPass.user}
                    seat={store.selectedPass.seat}
                    numOfETickets={store.getNumOfPassAccesses(store.selectedPass.id)}
                    passDetails={passDetails}
                    passBuyer={passBuyer}
                    numOfBarCodes={store.getNumOfPassAccessCodes(store.selectedPass.id)}
                    onSubmit={onSubmitHandler}
                    onEdit={() => setEdit(true)}
                    onCancel={() => {
                        error && setError(null);
                        setEdit(false);
                    }}
                    creatingNewEntity={false}
                />
            </TabSwitcher.TabPanel>
            <TabSwitcher.TabPanel activeWhenTabName={translations.passes_column}>
                <PassGames adminStore={adminStore} />
            </TabSwitcher.TabPanel>
        </TabSwitcher>
    ) : null;
};

export default observer(Pass);
