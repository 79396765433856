import { types, flow } from 'mobx-state-tree';
import API from '../../api/auth';

const AdminUser = types
    .model('AdminUser', {
        email: types.optional(types.string, ''),
        roles: types.array(types.string),
        first_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        avatar: types.maybeNull(types.string),
    })
    .views((self) => ({
        isAdminLoggedIn() {
            return self.email ? true : false;
        },
    }))
    .actions((self) => ({
        getAdminUser: flow(function* getAdminUser() {
            try {
                const res = yield API.getMeUser();
                self.email = res.data.email;
                self.roles = res.data.roles;
                self.first_name = res.data.first_name;
                self.last_name = res.data.last_name;
                self.avatar = res.data.avatar;
            } catch (error) {
                console.log(error);
            }
        }),
        setAdmin(admin) {
            self.email = admin.email;
            self.roles = admin.roles;
            self.first_name = admin.first_name;
            self.last_name = admin.last_name;
            self.avatar = admin.avatar;
        },
        removeAdmin() {
            self.email = '';
            self.roles = [];
            self.first_name = '';
            self.last_name = '';
            self.avatar = '';
        },
    }));

export default AdminUser;
