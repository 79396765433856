import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';
import FanGame from './FanGame';
import Paths from '../../constants/paths';
import ErrorMessages from './../../components/ErrorMessages.js';
import NewReward from '../Reward/NewReward';
import FanGameTabs from './FanGameTabs';
import FanGameRewards from '../../components/FanGameRewards';
import FinalScoreRanking from '../../components/FanGameRanking';
import FanGameWinners from '../../components/FanGameWinners';
import FanGameDefaultRewardNew from './FanGameDefaultRewardNew.js';
import FanGameDefaultReward from './FanGameDefaultReward.js';

const FanGamesTab = ({ store }) => {
    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.FAN_GAMES}>
                <FanGameTabs store={store} />
            </Route>

            <Route exact path={Paths.FAN_GAME}>
                <FanGame store={store} />
            </Route>
            <Route exact path={Paths.FAN_GAME_NEW_REWARD}>
                <NewReward store={store} />
            </Route>
            <Route exact path={Paths.FAN_GAME_END_GAME_REWARDS}>
                <FanGameRewards store={store} />
            </Route>
            <Route exact path={Paths.FAN_GAME_END_GAME_RESULT_WINNERS}>
                <FanGameWinners store={store} />
            </Route>
            <Route exact path={Paths.FAN_GAME_END_GAME_RANKING}>
                <FinalScoreRanking store={store} />
            </Route>
            <Route exact path={Paths.FAN_GAME_DEFAULT_REWARD}>
                <FanGameDefaultReward store={store} />
            </Route>
            <Route exact path={Paths.FAN_GAME_NEW_DEFAULT_REWARD}>
                <FanGameDefaultRewardNew store={store} />
            </Route>
        </Page>
    );
};

export default observer(FanGamesTab);
