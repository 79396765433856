import './Item.scss';
import Breadcrumb from '../Breadcrumb';
import Button from './../Button';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import ErrorMessages from '../ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import translations from '../../translations/de';
import { FaEdit } from 'react-icons/fa';

const Item = ({
    showBackButton = false,
    canEdit = true,
    name = '',
    id = false,
    links = [],
    itemModel = {},
    edit,
    onEdit,
    btnSaveLabel = undefined,
    onSubmit,
    onCancel,
    determineInputType,
    hasNavigation = true,
    children,
    formStyle = undefined,
    resetProp,
    error,
    loading = false,
    active = false,
    setFieldEnabled,
    unregisterProp = undefined,
    businessEventCustomLogic = false,
    watchedProperties = [], // An array of properties to watch
    watchedPropertyActions = {}, // An object with actions for each watched property
    insertDummyFieldAt = null,
    placeChildrenLast = false,
}) => {
    const formData = Object.keys(itemModel).map((key, index) => {
        return { name: key, value: itemModel[key] };
    });
    if (Array.isArray(insertDummyFieldAt)) {
        insertDummyFieldAt
            .sort((a, b) => b - a)
            .forEach((index) => {
                formData.splice(index, 0, { name: 'dummy', value: 'dummy' });
            });
    } else if (insertDummyFieldAt !== null) {
        formData.splice(insertDummyFieldAt, 0, { name: 'dummy', value: 'dummy' });
    }
    const hasItsForm = formData.length !== 0 ? true : false;

    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        reset,
        getValues,
        setValue,
    } = useForm({
        defaultValues: { ...itemModel },
    });
    const watchActive = active ? watch('active') : null;

    // Watch multiple properties
    const watchedProps = watchedProperties.map((property) => watch(property));

    useEffect(() => {
        const mergeProperties = (formValues, properties) => {
            if (Array.isArray(properties)) {
                return properties.reduce((acc, prop) => ({ ...acc, ...prop }), formValues);
            }
            return { ...formValues, ...properties };
        };

        //reset form default values in case any value from store updates before submiting form
        if (resetProp) {
            reset(mergeProperties(watch(), resetProp));
        }
    }, [resetProp, reset, watch]);

    useEffect(() => {
        if (unregisterProp) {
            unregister(unregisterProp);
        }
    }, [unregisterProp, unregister]);

    useEffect(() => {
        if (active) {
            setFieldEnabled(!watchActive);
        }
    }, [watch, watchActive, active, setFieldEnabled]);

    // Handle individual actions for each watched property
    useEffect(() => {
        watchedProperties.forEach((property, index) => {
            if (watchedPropertyActions[property]) {
                watchedPropertyActions[property](watchedProps[index]);
            }
        });
    }, [watchedProps, watchedPropertyActions, watchedProperties]);

    return (
        <div className={`Item ${!hasNavigation ? 'no-navigation' : ''}`}>
            <div className="Item-Header">
                {hasNavigation && (
                    <Breadcrumb name={name} links={links} showBackButton={showBackButton} />
                )}
                <div className="Item-Header-Controls">
                    {edit && onCancel && (
                        <Button
                            id="cancel-btn"
                            style={{ visibility: businessEventCustomLogic ? 'hidden' : '' }}
                            label={translations.cancel}
                            onClick={() => {
                                hasItsForm && reset({ ...itemModel });
                                onCancel();
                            }}
                        />
                    )}
                    {canEdit && (
                        <Button
                            loading={loading}
                            label={
                                edit
                                    ? btnSaveLabel
                                        ? btnSaveLabel
                                        : translations.save
                                    : translations.edit
                            }
                            onClick={
                                edit ? (hasItsForm ? handleSubmit(onSubmit) : onSubmit) : onEdit
                            }
                        >
                            {!edit && <FaEdit />}
                        </Button>
                    )}
                    {businessEventCustomLogic && (
                        <Button
                            id="submit-btn"
                            style={{ visibility: 'hidden' }}
                            loading={loading}
                            label={edit ? translations.save : translations.edit}
                            onClick={
                                edit ? (hasItsForm ? handleSubmit(onSubmit) : onSubmit) : onEdit
                            }
                        >
                            {!edit && <FaEdit />}
                        </Button>
                    )}
                </div>
            </div>
            <div className="Item-Content">
                <ErrorMessages
                    messages={requsetErrorMessage(error && error.message ? error.message : error)}
                />
                {hasItsForm ? (
                    <form style={formStyle}>
                        {!placeChildrenLast && children}
                        {id && <div className="id">{id}</div>}
                        {formData.map((data, index) =>
                            determineInputType({
                                value: data.value,
                                name: data.name,
                                register,
                                control,
                                error: errors[data.name],
                                disabled: !edit,
                                index,
                                getValues,
                                reset,
                                watch,
                                setValue,
                                unregister,
                            }),
                        )}
                        {placeChildrenLast && children}
                    </form>
                ) : (
                    <div>{children}</div>
                )}
            </div>
        </div>
    );
};

export default Item;
