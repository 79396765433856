import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { FaPlus, FaSearch } from 'react-icons/fa';
import debounce from 'lodash.debounce';

import Table from '../../components/Table';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Pagination from './../../components/Pagination';
import Input from '../../components/Input';

import Paths from '../../constants/paths';
import translations from '../../translations/de';
import useModal from '../../customHooks/useModal';

const Screens = ({ store, tab = null }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        store.getScreens(searchQuery, tab);
    }, [store, store.selectedPage, searchQuery, tab]);

    const handleScreen = (id) => {
        store.selectScreen(id);
        history.push(`/screens/${id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    const renderTable = () => {
        if (store?.screens?.length > 0) {
            return (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName={translations.app_screens}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        onClick={() => {
                                            handleScreen(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.screens.map(({ id, name }) => {
                        return {
                            id,
                            name,
                        };
                    })}
                />
            );
        }
        return null;
    };

    return (
        <>
            <div className="tab-controls">
                <Input placeholder={translations.search} onChange={onSearchChangeHandler} />
            </div>
            <div style={{ marginLeft: '2.5%', marginBottom: '2.5%' }}>
                <Button
                    label={translations.app_screen}
                    onClick={() => {
                        store.selectScreen(undefined);
                        history.push(Paths.NEW_SCREEN);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {renderTable()}
            {modal && (
                <Modal
                    title="Delete Screen"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this screen?
                    </p>
                </Modal>
            )}
        </>
    );
};

export default observer(Screens);
