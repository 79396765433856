import api from './apiLibrary';

const API = {
    getPasses(params) {
        return api.get('/api/admin/pass', params);
    },
    getGames(id, params) {
        return api.get(`/api/admin/pass/${id}/games`, params);
    },
    getPass(id, params) {
        return api.get(`/api/admin/pass/${id}`, params);
    },
    createPass(data) {
        return api.post('/api/admin/pass', data);
    },
    createAllTickets(id) {
        return api.post(`/api/admin/pass/${id}/createAccesses`);
    },
    editPass(id, data) {
        return api.put(`/api/admin/pass/${id}`, data);
    },
    deletePass(id) {
        return api.delete(`/api/admin/pass/${id}`);
    },
    revivePass(id) {
        return api.put(`/api/admin/pass/${id}/revive`);
    },
    getPassesFromFanID(id, params) {
        return api.get(`/api/admin/pass?user_id=${id}`, params);
    },
    getPassStatistics(params) {
        return api.get(`api/admin/pass/statistics`, params);
    },
    getNotificationData() {
        return api.get(`api/persistent-data/pass-send-email`);
    },
    setNotificationData(params) {
        return api.post(`api/persistent-data/pass-send-email`, params);
    },
    exportPasses(params) {
        return api.get(`api/admin/pass/export`, params);
    },
};

export default API;
