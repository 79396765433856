import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import Checkbox from '../../components/Checkbox';

export default function getScanerFormInput({ name, register, error, disabled, index }) {
    if (name === 'enabled') {
        return (
            <Checkbox
                key={index}
                text={translateLabel(name)}
                name={name}
                {...register(name, { required: false })}
                disabled={disabled}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: name === 'device_id' ? true : false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
