import React, { useState, useEffect } from 'react';
import API from '../../api/games';
import Table from '../Table';
import translations from '../../translations/de';
import { useParams } from 'react-router-dom';
import ItemImage from '../ItemImage';
import Button from '../Button';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Pagination from '../Pagination';
import FinalScoreBreadcrumb from '../FinalScoreBreadcrumb/FinalScoreBreadcrumb';

const FanGameWinners = () => {
    const [winners, setWinners] = useState([]);
    const { fan_game_id } = useParams();
    const history = useHistory();
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        sessionStorage.setItem('activeFanGameTab', 'Final Score');
        const getWinners = async () => {
            var res = await API.getWinnersFromFanGameID(fan_game_id, {
                params: { page: selectedPage },
            });
            const { items, total_pages_count } = res.data;
            setWinners(items);
            setTotalPages(total_pages_count);
        };
        getWinners();
    }, [fan_game_id, selectedPage]);

    const handleWinner = (user_id) => {
        history.push(`/fans/${user_id}`);
    };

    return (
        <>
            <FinalScoreBreadcrumb />
            {winners !== 0 && (
                <Table
                    tablePagination={
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                onSelectedPageChange={(selected) => setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName="Winners"
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.name,
                            accessor: 'first_name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.last_name,
                            accessor: 'last_name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.image,
                            accessor: 'image_url',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="reward" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.email,
                            accessor: 'email',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.fan_name,
                            accessor: 'fan_name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.reward,
                            accessor: 'reward_img',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="reward" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleWinner(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={winners.map(
                        ({ id, first_name, last_name, email, fan_name, image_url, reward }) => {
                            return {
                                id,
                                first_name,
                                last_name,
                                email,
                                fan_name,
                                image_url,
                                reward_img: reward.image_url,
                            };
                        },
                    )}
                />
            )}
        </>
    );
};

export default FanGameWinners;
