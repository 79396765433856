import { observer } from 'mobx-react-lite';
import GameSetUp from '../GameSetUp';

const StepTwo = ({ store, onNext, onBack }) => {
    return (
        <>
            <p className="title">Game Set Up</p>
            <GameSetUp gameStore={store} isNewGame onNext={onNext} onBack={onBack} />
        </>
    );
};

export default observer(StepTwo);
