import { types, applySnapshot } from 'mobx-state-tree';
import Access from './../accessesStore/Access';
import AccessCode from './../accessCodeStore/AccessCode';

const Pass = types
    .model('Pass', {
        id: types.identifierNumber,
        ticket_id: types.maybeNull(types.string),
        assign_token: types.optional(types.string, ''),
        type: types.maybeNull(types.string),
        first_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        street: types.maybeNull(types.string),
        zip: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        company: types.maybeNull(types.string),
        seat_id: types.maybeNull(types.number),
        can_claim_freunde: types.optional(types.boolean, false),
        seat: types.frozen({
            id: '',
            num: '',
            row: '',
            seat_group: {
                title: '',
                sector: {
                    title: '',
                },
            },
        }),
        season: types.frozen({
            title: '',
        }),
        season_id: types.maybeNull(types.number),
        user: types.maybeNull(
            types.frozen({ id: types.identifierNumber, first_name: '', last_name: '' }),
        ),
        user_id: types.maybeNull(types.number),
        create_anonymous_links: types.optional(types.boolean, false),
        external_customer_id: types.maybeNull(types.string),
        accesses: types.optional(types.array(Access), []),
        access_codes: types.optional(types.array(AccessCode), []),
        deleted_at: types.maybeNull(types.string, ''),
    })
    .views((self) => ({
        getNumOfPassAccesses() {
            return self.accesses.length;
        },
        getNumOfPassAccessCodes() {
            return self.access_codes.length;
        },
    }))
    .actions((self) => ({
        editPass(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Pass;
