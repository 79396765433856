const shootSide = [
    { label: 'Rechts', value: 'Rechts' },
    { label: 'Links', value: 'Links' },
];

const position = [
    { label: 'Torhüter', value: 'Torhüter' },
    { label: 'Verteidiger', value: 'Verteidiger' },
    { label: 'Stürmer', value: 'Stürmer' },
];

export { shootSide, position };
