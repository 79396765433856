import { types, flow } from 'mobx-state-tree';
import Admin from './Admin';
import API from './../../api/admins';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearEmptyFields from '../../helpers/clearEmptyFields';

const Admins = types
    .model('Admins', {
        admins: types.array(Admin),
        selectedAdmin: types.maybe(types.reference(Admin)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getAdmins: flow(function* getAdmins(searchQuery, role) {
            self.error = '';
            try {
                const res = yield API.getAdmins({
                    params: {
                        roles: role ? role : undefined,
                        search: searchQuery ? searchQuery : undefined,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.admins = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectAdmin(id) {
            self.selectedAdmin = id;
        },
        getAdmin: flow(function* getAdmin(id) {
            self.error = '';
            try {
                const res = yield API.getAdmin(id);
                self.dataLoaded = true;
                self.admins = [];
                self.admins.push(res.data);
                self.selectedAdmin = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editAdmin: flow(function* editAdmin(edited) {
            try {
                const res = yield API.editAdmin(self.selectedAdmin.id, clearEmptyFields(edited));
                self.selectedAdmin.editAdmin(res.data);
            } catch (error) {
                throw error;
            }
        }),
        createAdmin: flow(function* createAdmin(data) {
            try {
                const res = yield API.createAdmin(clearEmptyFields(data));
                self.admins.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteAdmin: flow(function* deleteAdmin(id) {
            try {
                yield API.deleteAdmin(id);
                var index = self.admins.findIndex((admin) => admin.id === id);
                self.admins[index].deleted_at = 'deleted';
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        reviveAdmin: flow(function* reviveAdmin(id) {
            try {
                yield API.reviveAdmin(id);
                var index = self.admins.findIndex((admin) => admin.id === id);
                self.admins[index].deleted_at = null;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default Admins;
