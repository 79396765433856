import { types, flow } from 'mobx-state-tree';
import ShopItem from './ShopItem';
import API from '../../api/shopItems';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearObjNulls from '../../helpers/clearObjNulls';

const ShopItems = types
    .model('ShopItems', {
        items: types.array(ShopItem),
        selectedItem: types.maybe(types.reference(ShopItem)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getShopItems: flow(function* getShopItems(searchQuery, itemType) {
            self.error = '';
            try {
                const res = yield API.getShopItems({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        limit: 10,
                        page: self.selectedPage,
                        type: itemType ? itemType : undefined,
                    },
                });
                self.dataLoaded = true;
                self.items = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        selectItem(id) {
            self.selectedItem = id;
        },
        getShopItem: flow(function* getShopItem(id) {
            self.error = '';
            try {
                const res = yield API.getShopItem(id);
                self.dataLoaded = true;
                self.items = [];
                self.items.push(res.data);
                self.selectedItem = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editShopItem: flow(function* editShopItem(edited) {
            try {
                const res = yield API.editShopItem(self.selectedItem.id, edited);
                self.selectedItem.editItem(res.data);
            } catch (error) {
                throw error;
            }
        }),
        editActive: flow(function* editActive(id) {
            self.selectedItem = id;
            try {
                const res = yield API.editActive(id, {
                    params: { active: !self.selectedItem.active },
                });
                self.items.forEach((item, index) => {
                    if (item.id === id) {
                        self.items.splice(index, 1);
                        self.items.splice(index, 0, res.data);
                    }
                });
            } catch (error) {
                throw error;
            }
        }),
        addItem: flow(function* addItem(item) {
            try {
                const res = yield API.createShopItem(clearObjNulls({ ...item }));
                self.items.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteItem: flow(function* deleteItem(id) {
            try {
                yield API.deleteShopItem(id);
                const index = self.items.findIndex((i) => i.id === id);
                self.items.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default ShopItems;
