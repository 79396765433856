import { types } from 'mobx-state-tree';
import Team from '../teamsStore/Team';
import Season from '../seasonsStore/Season';

const MatchOverview = types.model('MatchOverview', {
    // id: types.identifierNumber,
    game: types.frozen({
        id: types.number,
        away_team: types.maybe(types.reference(Team)),
        away_team_id: types.number,
        date: types.string,
        home_team_id: types.number,
        home_team: types.maybe(types.reference(Team)),
        season_id: types.number,
        season: types.maybe(types.reference(Season)),
    }),
    total: (types.number, 0),
    used: (types.number, 0),
    claimed: (types.number, 0),
});

export default MatchOverview;
