import { types, applySnapshot } from 'mobx-state-tree';
import Banner from './Banner';

const Screen = types
    .model('Screen', {
        id: types.identifierNumber,
        name: types.optional(types.string, ''),
        slug: types.optional(types.string, ''),
        tab: types.maybeNull(types.string),
        banners: types.optional(types.array(Banner), []),
    })
    .actions((self) => ({
        editScreen(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
        addBanner(banner) {
            self.banners.push(banner);
        },
    }));

export default Screen;
