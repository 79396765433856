import React, { useState, useEffect, useRef } from 'react';
import './../BusinessEvent.scss';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../../constants/paths';
import translations from '../../../translations/de';
import Button from '../../../components/Button';
import Breadcrumb from '../../../components/Breadcrumb';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import ErrorMessages from '../../../components/ErrorMessages.js';
import requsetErrorMessage from '../../../helpers/requsetErrorMessage';
import ProgressBar from '../../../components/ProgressBar';
import Modal from '../../../components/Modal';
import useModal from '../../../customHooks/useModal';

const NewBusinessEvent = ({ store, eventsStore }) => {
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('Event Details 1 von 3');
    const modalRef = useRef(null);
    const [confirmationModal, setConfirmationModal] = useModal(false, modalRef, loading);

    useEffect(() => {
        !eventsStore.dataLoaded && eventsStore.setDataLoaded();
    }, [eventsStore]);

    useEffect(() => {
        if (step === 1) {
            setTitle('EVENT DETAILS 1 von 3');
        } else if (step === 2) {
            setTitle('SELECT PEOPLE 2 von 3');
        } else if (step === 3) {
            setTitle('EINLADUNGEN 3 von 3');
        }
    }, [step]);

    const handleNext = () => {
        setStep(step + 1);
    };
    const handleBack = () => {
        error && setError(null);
        setStep(step - 1);
    };

    const createEvent = () => {
        error && setError(null);
        setLoading(true);
        store
            .createEvent()
            .then(() => {
                history.push(Paths.BUSINESS_EVENTS);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return (
        <>
            <div className="Event">
                <div className="Event-Header">
                    <Breadcrumb
                        name={`${translations.business_event} ${translations.create}: ${title}`}
                        links={[
                            { path: Paths.BUSINESS_EVENTS, name: translations.business_events },
                        ]}
                    />
                    <div className="controls">
                        <Button type="button" onClick={() => history.goBack()}>
                            {translations.cancel}
                        </Button>
                    </div>
                </div>
                <ProgressBar completed={(1 / 3) * step * 100} />
                <div className="Event-Content">
                    {step === 1 && <StepOne store={store} onNext={handleNext} />}
                    {step === 2 && (
                        <StepTwo store={store} onNext={handleNext} onBack={handleBack} />
                    )}
                    {step === 3 && (
                        <StepThree
                            store={store}
                            onNext={() => setConfirmationModal(true)}
                            onBack={handleBack}
                        />
                    )}
                </div>
            </div>
            {confirmationModal && (
                <Modal
                    title="Creating Event"
                    ref={modalRef}
                    onClose={() => setConfirmationModal(false)}
                    maxWidth="500px"
                    disabled={loading}
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Please check again the event details and the people you invited to the
                        event. Once you submit this action, people will receive an invitation email,
                        however event details you can update later... Submit?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={createEvent}
                            style={{ width: '80px' }}
                            loading={loading}
                        />
                        <Button
                            label="No"
                            onClick={() => setConfirmationModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                            disabled={loading}
                        />
                    </div>
                    <ErrorMessages
                        messages={requsetErrorMessage(
                            error && error.message ? error.message : error,
                        )}
                    />
                </Modal>
            )}
        </>
    );
};

export default observer(NewBusinessEvent);
