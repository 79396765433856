import { types, flow } from 'mobx-state-tree';
import Pass from './Pass';
import API from '../../api/passes';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';

const PassesStore = types
    .model('PassesStore', {
        passes: types.optional(types.array(Pass), []),
        selectedPass: types.maybe(types.reference(Pass)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .views((self) => ({
        getNumOfPassAccesses(id) {
            let pass = self.passes.find((pass) => pass.id === id);
            return pass.accesses.length;
        },
        getNumOfPassAccessCodes(id) {
            let pass = self.passes.find((pass) => pass.id === id);
            return pass.access_codes?.length;
        },
    }))
    .actions((self) => ({
        getPasses: flow(function* getPasses(searchQuery, selectedSeason) {
            self.error = '';
            try {
                const res = yield API.getPasses({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        season_id: selectedSeason ? selectedSeason : 4,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.passes = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        selectPass(id) {
            self.selectedPass = id;
        },
        getPass: flow(function* getPass(id) {
            self.error = '';
            self.dataLoaded = false
            self.passes = [];
            try
            {
                const res = yield API.getPass(id);
                self.passes.push(res.data);
                self.selectedPass = res.data.id;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editPass: flow(function* editPass(edited) {
            try {
                var editedPass = edited;
                const res = yield API.editPass(self.selectedPass.id, {
                    ...editedPass,
                    user_id: editedPass.user_id === '' ? null : editedPass.user_id,
                    season_id: self.selectedPass.season_id,
                });
                self.selectedPass.editPass(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addPass: flow(function* addPass(pass) {
            try {
                const res = yield API.createPass(pass);
                self.passes.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deletePass: flow(function* deletePass(id) {
            try {
                yield API.deletePass(id);
                const index = self.passes.findIndex((s) => s.id === id);
                self.passes[index].deleted_at = 'deleted'; /// this is just a flag so front show pass as deleted. After refreshing page, it will take original value and stay 'flagged as deleted'
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        revivePass: flow(function* revivePass(id) {
            try {
                yield API.revivePass(id);
                const index = self.passes.findIndex((s) => s.id === id);
                self.passes[index].deleted_at = null;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),

        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default PassesStore;
