import { types, flow } from 'mobx-state-tree';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import BusinessProfile from './BusinessProfile';
import API from './../../api/businessProfiles';
import clearObjNulls from '../../helpers/clearObjNulls';
import clearEmptyFields from '../../helpers/clearEmptyFields';

const BusinessProfiles = types
    .model('Profiles', {
        profiles: types.array(BusinessProfile),
        selectedProfile: types.maybe(types.reference(BusinessProfile)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getProfiles: flow(function* getProfiles(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getProfiles({
                    params: {
                        search: searchQuery,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.profiles = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),

        getProfile: flow(function* getProfile(id) {
            self.error = '';
            try {
                const res = yield API.getProfile(id);
                self.dataLoaded = true;
                self.profiles = [];
                self.profiles.push(res.data);
                self.selectedProfile = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        addProfile: flow(function* addProfile(newProfile) {
            try {
                !newProfile.user_id && delete newProfile.user_id;
                const res = yield API.createProfile(newProfile);
                self.profiles.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        editProfile: flow(function* editProfile(edited) {
            try {
                const res = yield API.editProfile(
                    self.selectedProfile.id,
                    clearObjNulls(clearEmptyFields({ ...edited })),
                );
                self.selectedProfile.editProfile(res.data);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        deleteProfile: flow(function* deleteProfile(id) {
            try {
                yield API.deleteProfile(id);
                const index = self.profiles.findIndex((p) => p.id === id);
                self.profiles[index].deleted_at = 'deleted';
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        reviveProfile: flow(function* reviveProfile(id) {
            try {
                yield API.reviveProfile(id);
                var index = self.profiles.findIndex((user) => user.id === id);
                self.profiles[index].deleted_at = null;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectProfile(id) {
            self.selectedProfile = id;
        },
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default BusinessProfiles;
