import { types, flow } from 'mobx-state-tree';
import SingleNews from './SingleNews';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import API from '../../api/news';
import clearObjNulls from '../../helpers/clearObjNulls';

const NewsStore = types
    .model('NewsStore', {
        news: types.optional(types.array(SingleNews), []),
        selectedNews: types.maybe(types.reference(SingleNews)),
        totalPages: types.maybe(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getAllNews: flow(function* getAllNews(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getAllNews({
                    params: {
                        search: searchQuery !== '' ? searchQuery : undefined,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.news = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        createNews: flow(function* createNews(item) {
            try {
                const res = yield API.createNews(clearObjNulls(item));
                self.news.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        editNews: flow(function* editNews(edited) {
            try {
                const res = yield API.editNews(self.selectedNews.id, edited);
                self.selectedNews.editNews(res.data);
            } catch (error) {
                throw error;
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        selectNews(id) {
            self.selectedNews = id;
        },
        getSingleNews: flow(function* getSingleNews(id) {
            self.error = '';
            try {
                const res = yield API.getSingleNews(id);
                self.dataLoaded = true;
                self.news = [];
                self.news.push(res.data);
                self.selectedNews = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        deleteNews: flow(function* deleteNews(guid) {
            try {
                yield API.deleteNews(guid);
                const index = self.news.findIndex((item) => item.guid === guid);
                self.news[index].deleted_at = 'deleted';
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        reviveNews: flow(function* reviveNews(guid) {
            try {
                yield API.reviveNews(guid);
                const index = self.news.findIndex((item) => item.guid === guid);
                self.news[index].deleted_at = null;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
        hideNews: flow(function* hideNews(guid) {
            try {
                const res = yield API.hideNews(guid);
                //self.selectedNews.editNews(res.data);
                var indexOf = self.news.findIndex((user) => user.guid === res.data.guid);
                self.news[indexOf] = res.data;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
    }));

export default NewsStore;
