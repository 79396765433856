import React, { useEffect } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Pagination from './../../components/Pagination';
import translations from '../../translations/de';
import { formatDate } from '../../helpers/formatDate';
import API from '../../api/users';

const FinalScoreGames = ({
    users,
    setUsers,
    selectedPage,
    setSelectedPage,
    setTotalPages,
    totalPages,
    season,
}) => {
    const history = useHistory();

    useEffect(() => {
        sessionStorage.removeItem('activeFanGameTab');
        const getUsers = async () => {
            var res = await API.getAllEndGameUsers('end_game_result', {
                params: { page: selectedPage, season_id: season },
            });
            setUsers(res.data.items);
            setTotalPages(res.data.total_pages_count);
        };
        getUsers();
    }, [selectedPage, season, setTotalPages, setUsers]);

    return (
        <>
            <Table
                tableName={translations.user}
                tablePagination={
                    totalPages > 1 && (
                        <Pagination
                            totalPages={totalPages}
                            selectedPage={selectedPage}
                            onSelectedPageChange={(selected) => setSelectedPage(selected)}
                        />
                    )
                }
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: 'Date joined',
                        accessor: 'date_joined',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.fan_name,
                        accessor: 'fan_name',
                        Cell: ({ row, value }) => (
                            <div
                                className="link_text"
                                onClick={() => history.push(`/fans/${row.cells[0].value}`)}
                            >
                                {value}
                            </div>
                        ),
                    },
                    {
                        Header: translations.first_name,
                        accessor: 'first_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.last_name,
                        accessor: 'last_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.fan_points,
                        accessor: 'fan_points',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.rank,
                        accessor: 'rank',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                ]}
                data={users?.map(
                    ({ id, date_joined, fan_name, first_name, last_name, fan_points, rank }) => {
                        return {
                            id,
                            date_joined: formatDate(date_joined),
                            fan_name,
                            first_name,
                            last_name,
                            fan_points,
                            rank,
                        };
                    },
                )}
            />
        </>
    );
};

export default observer(FinalScoreGames);
