import { observer } from 'mobx-react-lite';
import TabSwitcher from '../../components/TabSwitcher';
import FanGames from './FanGames';
import FinalScoreTabs from './FinalScoreTabs';

const FanGameTabs = ({ store }) => {
    return (
        <>
            <TabSwitcher
                switcherKey="parentTab"
                initialTabName={
                    sessionStorage.getItem('activeFanGameTab')
                        ? sessionStorage.getItem('activeFanGameTab')
                        : 'All'
                }
            >
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName="All" />
                    <TabSwitcher.TabPill tabName="Favorite Player" />
                    <TabSwitcher.TabPill tabName="First Scorer" />
                    <TabSwitcher.TabPill tabName="Live Mood" />
                    <TabSwitcher.TabPill tabName="Seasonal Mood" />
                    <TabSwitcher.TabPill tabName="Final Score" />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName="All">
                    <FanGames store={store} tab="all" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Favorite Player">
                    <FanGames store={store} tab="favorite_player" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="First Scorer">
                    <FanGames store={store} tab="first_scorer" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Live Mood">
                    <FanGames store={store} tab="live_mood" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Seasonal Mood">
                    <FanGames store={store} tab="seasonal_mood" />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Final Score">
                    <FinalScoreTabs store={store} tab="end_game_result" />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </>
    );
};

export default observer(FanGameTabs);
