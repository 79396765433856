import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import getScanerFormInput from './getScanerFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import { useParams } from 'react-router-dom';
import translations from '../../translations/de';
import Paths from '../../constants/paths';

const Scanner = ({ store }) => {
    const { scanner_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const scannerModel = clearObjProps({ ...store.selectedScanner }, ['id']);

    useEffect(() => {
        store.getScanner(scanner_id);
        return () => {
            store.selectScanner(undefined);
        };
    }, [store, scanner_id]);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editScanner({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedScanner ? (
        <>
            <Item
                links={[{ path: Paths.SCANNERS, name: translations.scanner }]}
                name={store.selectedScanner.title}
                id={`[ screen_ID: ${store.selectedScanner.id} ]`}
                itemModel={scannerModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ name, register, error, disabled, index }) =>
                    getScanerFormInput({
                        name,
                        register,
                        error,
                        disabled,
                        index,
                    })
                }
            ></Item>
        </>
    ) : null;
};

export default observer(Scanner);
