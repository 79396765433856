import { types, flow } from 'mobx-state-tree';
import API from './../../api/games';
import APIRewards from './../../api/fanGames';
import Game from './Game';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearObjNulls from '../../helpers/clearObjNulls';

const FanGames = types
    .model('FanGames', {
        fan_games: types.array(Game),
        selectedGame: types.maybe(types.reference(Game)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })

    .actions((self) => ({
        getFanGames: flow(function* getFanGames(search, gameStatus, tab, selectedSeason) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield API.getGames({
                    params: {
                        limit: 10,
                        page: self.selectedPage,
                        fan_game_type: tab === 'all' ? undefined : tab,
                        search: search !== '' ? search : undefined,
                        season_id: selectedSeason !== '' ? selectedSeason : undefined,
                        archived:
                            gameStatus === 'archived'
                                ? true
                                : gameStatus === '!archived&published'
                                ? false
                                : undefined,
                        started: gameStatus === 'started' ? true : undefined,
                        published:
                            gameStatus === 'published'
                                ? true
                                : gameStatus === '!archived&published'
                                ? true
                                : undefined,
                        ended: gameStatus === 'ended' ? true : undefined,
                    },
                });
                self.dataLoaded = true;
                self.fan_games = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        selectFanGame(id) {
            self.selectedGame = id;
        },
        selectFanGameDefaultReward(id) {
            self.selectedFanGameDefaultReward = id;
        },
        getFanGame: flow(function* getFanGame(game_id) {
            self.error = '';
            try {
                const game = yield API.getGame(game_id);
                self.fan_games = [];
                self.fan_games.push(game.data.fan_game_details);
                self.selectedGame = game.data.fan_game_details.id;
                self.selectedGame.rewards = game.data.fan_game_details.rewards;
                self.selectedGame.questions = game.data.fan_game_details.questions;

                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),

        addDefaultReward: flow(function* addDefaultReward(reward) {
            self.error = '';
            try {
                const res = yield APIRewards.createDefaultReward({
                    ...reward,
                });
                console.log(res);
            } catch (e) {
                console.error(e);
            }
        }),
        editFanGameDefaultReward: flow(function* editReward(edited, fan_game_id) {
            self.error = '';
            try {
                const res = yield APIRewards.editDefaultReward(
                    clearObjNulls({
                        ...edited,
                        sponsor_link: edited.sponsor_link === '' ? null : edited.sponsor_link,
                    }),
                    fan_game_id,
                );
                return res.data;
            } catch (error) {
                throw error;
            }
        }),
        getFanGameDefaultReward: flow(function* getDefaultReward(fan_game_id) {
            self.error = '';
            try {
                const res = yield APIRewards.getDefaultReward(fan_game_id);
                self.defaultRewards = [];
                self.selectedFanGameDefaultReward = res.data.id;
                console.log('ovo guram: ', res.data);
                self.defaultRewards.push(res.data);
                self.dataLoaded = true;
            } catch (err) {
                self.data = true;
                console.log(err);
            }
        }),
    }));

export default FanGames;
