import { useEffect, useState } from 'react';
import rewardAPI from '../api/rewards';
import shopItemAPI from '../api/shopItems';

const useAcquirers = (reward_id, shop_item_id) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);

    useEffect(() => {
        setLoading(true);
        reward_id &&
            rewardAPI
                .getWinners(reward_id, { params: { page: selectedPage } })
                .then((res) => {
                    setData(res.data.items);
                    setTotalPages(res.data.total_pages_count);
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                });

        shop_item_id &&
            shopItemAPI
                .getSales(shop_item_id, { params: { page: selectedPage } })
                .then((res) => {
                    setData(res.data.items);
                    setTotalPages(res.data.total_pages_count);
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                });
    }, [reward_id, shop_item_id, selectedPage]);

    return [data, loading, totalPages, selectedPage, (value) => setSelectedPage(value)];
};

export default useAcquirers;
