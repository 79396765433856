import { types, flow } from 'mobx-state-tree';
import API from '../../api/instantRewards';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import InstantReward from './InstantReward';
import clearObjNulls from '../../helpers/clearObjNulls';

const InstantRewards = types
    .model('InstantRewards', {
        rewards: types.array(InstantReward),
        recipients: types.array(types.number),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        selectedInstantReward: types.maybeNull(types.reference(InstantReward)),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
        sendToAll: types.optional(types.boolean, false),
    })
    .views((self) => ({
        getInstantReward(id) {
            return self.rewards.find((reward) => reward.id === id);
        },
    }))
    .actions((self) => ({
        getRewards: flow(function* getRewards(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getAllRewards({
                    params: {
                        search: searchQuery,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.rewards = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editInstantReward: flow(function* editInstantReward(edited) {
            self.error = '';
            try {
                const res = yield API.editReward(
                    self.selectedInstantReward.id,

                    clearObjNulls({
                        ...edited,
                        sponsor_link: edited.sponsor_link === '' ? null : edited.sponsor_link,
                    }),
                );
                self.selectedInstantReward.editInstantReward(res.data);
            } catch (error) {
                throw error;
            }
        }),
        resetRecipients() {
            self.recipients = [];
        },
        setSendToAll(value) {
            self.sendToAll = value;
        },
        selectInstantReward(id) {
            self.selectedInstantReward = id;
        },
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setTotalPages(total_pages_count) {
            self.totalPages = total_pages_count;
        },
        getInstantReward: flow(function* getInstantReward(id) {
            self.error = '';
            try {
                const res = yield API.getReward(id);
                self.rewards = [];
                self.rewards.push(res.data);
                self.selectedInstantReward = res.data.id;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                console.log(error);
            }
        }),
        addInstantReward: flow(function* addInstantReward(instantReward, send_notification) {
            try {
                instantReward.recipients = self.recipients;
                let params = { send_notification: send_notification };
                const res = yield API.createReward(instantReward, params);
                self.rewards.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addRecipient(id) {
            try {
                self.recipients.push(id);
            } catch (error) {
                console.log(error);
            }
        },
        removeRecipient(id) {
            try {
                self.recipients = self.recipients.filter((user) => user !== id);
            } catch (e) {
                console.log(e);
            }
        },
        deleteInstantReward: flow(function* deleteInstantReward(id) {
            try {
                yield API.deleteReward(id);
                const index = self.rewards.findIndex((r) => r.id === id);
                self.rewards.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default InstantRewards;
