import { types, flow } from 'mobx-state-tree';
import Team from './Team';
import Player from './Player';
import API from './../../api/teams';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';

const TeamsStore = types
    .model('TeamsStore', {
        teams: types.array(Team),
        selectedTeam: types.maybe(types.reference(Team)),
        selectedPlayer: types.maybe(types.reference(Player)),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getTeams: flow(function* getTeams(league) {
            self.error = '';
            try {
                const res = yield API.getTeams({ params: { league } });
                self.teams = res.data;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectTeam(id) {
            self.selectedTeam = id;
        },
        getTeam: flow(function* getTeam(id) {
            self.error = '';
            try {
                const res = yield API.getTeam(id);
                self.teams = [];
                self.teams.push(res.data);
                self.selectedTeam = res.data.id;
                self.selectedPlayer = undefined;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editTeam: flow(function* editTeam(edited) {
            try {
                const res = yield API.editTeam(self.selectedTeam.id, edited);
                self.selectedTeam.editTeam(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addTeam: flow(function* addTeam(team) {
            try {
                const res = yield API.createTeam(team);
                self.teams.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        selectPlayer(id) {
            self.selectedPlayer = id;
        },

        getPlayer: flow(function* getPlayer(team_id, player_id) {
            self.error = '';
            try {
                if (!self.selectedTeam) {
                    const team = yield API.getTeam(team_id);
                    if (!self.selectedTeam) {
                        self.teams = [];
                        self.teams.push(team.data);
                        self.selectedTeam = team.data.id;
                    } else {
                        self.selectedTeam.players = team.data.players;
                    }
                }
                const player = yield API.getPlayer(player_id);
                self.dataLoaded = true;

                self.selectedPlayer = player.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editPlayer: flow(function* editPlayer(edited) {
            try {
                const res = yield API.editPlayer(self.selectedPlayer.id, edited);
                self.selectedPlayer.editPlayer(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addPlayer: flow(function* addPlayer(player) {
            try {
                const res = yield API.createPlayer({ ...player, team_id: self.selectedTeam.id });
                self.selectedTeam.addPlayer(res.data);
            } catch (error) {
                throw error;
            }
        }),

        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default TeamsStore;
