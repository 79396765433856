export const createRewardClearFields = (event) => {
    const { type, voucher_type, price_in_cents, sponsor_link, fan_coins } = event;
    const rewardData = {
        ...event,
        voucher_type: type === 'consumable' ? voucher_type : undefined,
        price_in_cents:
            type === 'consumable' && voucher_type === 'eguma' ? Number(price_in_cents) : 0,
        sponsor_link:
            type === 'consumable' && voucher_type === 'external' ? sponsor_link : undefined,
        fan_coins: type === 'coins' ? fan_coins : 0,
    };
    return rewardData;
};
