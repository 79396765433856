import { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import translations from '../../translations/de';
import API from '../../api/passes.js';
import { useHistory } from 'react-router-dom';
import { FaTrash, FaSearch, FaUndoAlt, FaCheck, FaTimes } from 'react-icons/fa';
import useModal from '../../customHooks/useModal';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import InputSeason from '../../components/InputSeason/InputSeason';

const Tickets = ({ fanID }) => {
    const [loader, setLoader] = useState(false);
    const [passes, setPasses] = useState([]);
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [passID, setPassID] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(
        history?.location?.state?.selectedSeason ?? null,
    );

    const handlePass = (e) => {
        history.push(`/abos/${e}`);
    };

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const deletePass = (id) => {
        try {
            API.deletePass(id);
            const initialState = passes.map((pass) => {
                if (pass.id === id) {
                    return { ...pass, deleted_at: 'deleted' };
                }
                return pass;
            });
            setPasses(initialState);
        } catch (e) {
            console.log(e);
        }
    };

    const revivePass = (id) => {
        try {
            API.revivePass(id);
            const initialState = passes.map((pass) => {
                if (pass?.id === id) {
                    return { ...pass, deleted_at: null };
                }
                return pass;
            });
            setPasses(initialState);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        setLoader(true);
        const getPasses = async () => {
            try {
                const res = await API.getPassesFromFanID(fanID, {
                    params: { limit: 50, season_id: selectedSeason },
                });
                setPasses(res.data.items);
                setLoader(false);
            } catch (e) {
                console.log(e);
                setLoader(false);
            }
        };

        selectedSeason && getPasses();
    }, [fanID, selectedSeason]);

    useEffect(() => {
        return history.listen(() => {
            if (history.action === 'POP') {
                history.replace({ pathname: '/fans' });
            }
        });
    }, [history]);

    return (
        <>
            <div>
                {
                    <div className="tab-controls">
                        <div style={{ display: 'block' }}>
                            <InputSeason
                                selectedSeason={selectedSeason}
                                setSelectedSeason={setSelectedSeason}
                            />
                        </div>
                    </div>
                }

                {loader ? (
                    <div className="loader-wrapper">
                        <Loader size="big" theme="dark" />
                    </div>
                ) : passes.length !== 0 ? (
                    <Table
                        isRowClickable={false}
                        columns={[
                            {
                                Header: 'ID',
                                accessor: 'id',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: 'Ticket-Nr',
                                accessor: 'ticket_id',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.assign_token,
                                accessor: 'assign_token',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.name,
                                accessor: 'name',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.can_claim_freunde,
                                accessor: 'can_claim_freunde',
                                Cell: ({ value }) => (
                                    <div>
                                        {value === true ? (
                                            <FaCheck className="confirm" />
                                        ) : (
                                            <FaTimes className="error" />
                                        )}
                                    </div>
                                ),
                            },
                            {
                                Header: translations.seat,
                                accessor: 'seat',
                                Cell: ({ value }) => (
                                    <div>
                                        Entrance: {value.seat_group.sector.title} <br />
                                        Block: {value.seat_group.title} <br />
                                        Row: {value.row} <br />
                                        Seat: {value.num} <br />
                                    </div>
                                ),
                            },
                            {
                                Header: '',
                                accessor: 'action',
                                Cell: ({ row }) => (
                                    <div className="button-cell">
                                        <Button
                                            onClick={() => {
                                                handlePass(row.cells[0].value);
                                            }}
                                            title={translations.show}
                                        >
                                            <FaSearch />
                                        </Button>

                                        {isNotDeletedAlready(row) ? (
                                            <Button
                                                onClick={() => {
                                                    setPassID(row.cells[0].value);
                                                    setModal(true);
                                                }}
                                                title={translations.delete}
                                            >
                                                <FaTrash />
                                            </Button>
                                        ) : (
                                            <Button onClick={() => revivePass(row.cells[0].value)}>
                                                <FaUndoAlt />
                                            </Button>
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                        data={passes.map(
                            ({
                                id,
                                ticket_id,
                                assign_token,
                                first_name,
                                last_name,
                                seat,
                                can_claim_freunde,
                                deleted_at,
                            }) => {
                                return {
                                    id,
                                    ticket_id,
                                    assign_token,
                                    name: first_name + ' ' + last_name,
                                    seat,
                                    can_claim_freunde,
                                    deleted_at,
                                };
                            },
                        )}
                    ></Table>
                ) : (
                    <p className="no-itmes">No data yet!</p>
                )}
                {modal && (
                    <Modal
                        title="Delete Abo"
                        ref={modalRef}
                        onClose={() => setModal(false)}
                        maxWidth="400px"
                    >
                        <p style={{ color: '#333', marginBottom: '20px' }}>
                            {`Are you sure that you want to delete this abo with id ${passID}?`}
                        </p>
                        <div className="Modal-Controls">
                            <Button
                                label="Yes"
                                onClick={() => {
                                    deletePass(passID);
                                    setModal(false);
                                }}
                                style={{ width: '80px' }}
                            />
                            <Button
                                label="No"
                                onClick={() => setModal(false)}
                                style={{ width: '80px', marginLeft: '20px' }}
                            />
                        </div>
                    </Modal>
                )}
            </div>
        </>
    );
};

export default observer(Tickets);
