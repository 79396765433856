import Button from '../Button';

const GameResult = ({ result, onClose }) => {
    const firstScorerResult = result.first_scorer;
    return (
        <div className="result">
            <p className="result-label">Team that scored:</p>
            <div className="result-value">
                <img src={firstScorerResult.team.logo_url} alt="team" />
                <span>{firstScorerResult.team.title}</span>
            </div>
            <p className="result-label">Player that scored:</p>
            <div className="result-value">
                <img src={firstScorerResult.scorer.avatar} alt="team" />
                <span>
                    {firstScorerResult.scorer.first_name + ' ' + firstScorerResult.scorer.last_name}
                </span>
            </div>
            {firstScorerResult.minutes !== null && (
                <>
                    <p className="result-label">Minute Scored:</p>
                    <div className="result-value">{firstScorerResult.minutes}</div>
                </>
            )}
            {firstScorerResult.assist_one && (
                <>
                    <p className="result-label">Assisted by:</p>
                    <div className="result-value">
                        <img src={firstScorerResult.assist_one.avatar} alt="team" />
                        <span>
                            {firstScorerResult.assist_one.first_name +
                                ' ' +
                                firstScorerResult.assist_one.last_name}
                        </span>
                    </div>
                </>
            )}
            {firstScorerResult.assist_two && (
                <>
                    <p className="result-label">Second Assisted By:</p>
                    <div className="result-value">
                        <img src={firstScorerResult.assist_two.avatar} alt="team" />
                        <span>
                            {firstScorerResult.assist_two.first_name +
                                ' ' +
                                firstScorerResult.assist_two.last_name}
                        </span>
                    </div>
                </>
            )}
            <div className="result-controls">
                <Button label="OK" onClick={onClose} />
            </div>
        </div>
    );
};

export default GameResult;
