import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams } from 'react-router-dom';
import getBannerFormInput from './getBannerFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import ItemImage from '../../components/ItemImage';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import API from '../../api/screens';

const Banner = ({ store }) => {
    const { screen_id, banner_id } = useParams();
    const [resetProp, setResetProp] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [bannerTypes, setBannerTypes] = useState([]);

    useEffect(() => {
        const getTypes = async () => {
            setLoading(true);
            try {
                const res = await API.getBannerTypes();
                setBannerTypes(res.data.items);
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        };
        getTypes();
        store.getBanner(screen_id, banner_id);
    }, [store, screen_id, banner_id]);

    const bannerModel = clearObjProps({ ...store.selectedBanner }, ['id', 'deleted_at']);
    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editBanner({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedBanner ? (
        <>
            <Item
                links={[
                    { path: Paths.SCREENS, name: translations.app_screens },
                    {
                        path: `/screens/${store.selectedScreen.id}`,
                        name: store.selectedScreen.name,
                    },
                ]}
                name={store.selectedBanner.title}
                id={`[ banner_ID: ${store.selectedBanner.id} ]`}
                itemModel={bannerModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, error, disabled, index, control }) =>
                    getBannerFormInput({
                        value,
                        name,
                        register,
                        error,
                        disabled,
                        index,
                        control,
                        resetValue: (name, value) => setResetProp({ [name]: value }),
                        screens: name === 'screen_id' ? store.screens : null,
                        bannerTypes,
                    })
                }
                resetProp={resetProp}
            >
                <ItemImage imgSrc={store.selectedBanner.image_url} />
            </Item>
        </>
    ) : null;
};

export default observer(Banner);
