import React from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import clearObjProps from '../../helpers/clearObjProps';
import getVouchersFormInput from './getVouchersFormInput';
import translations from '../../translations/de';

const ReedemData = ({ store }) => {
    const redeemData_model = clearObjProps({ ...store.selectedVoucher.redeem_data }, []);

    return (
        <Item
            name={translations.redeem_data}
            itemModel={redeemData_model}
            canEdit={false}
            error={null}
            loading={false}
            determineInputType={({ value, name, register, control, error, disabled, index }) =>
                getVouchersFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    disabled,
                    index,
                })
            }
        ></Item>
    );
};

export default observer(ReedemData);
