import React, { useEffect, useState, useRef } from 'react';
import Table from '../Table';

import Button from '../Button';
import Modal from '../Modal';
import { FaPlus, FaTrash } from 'react-icons/fa';

import useModal from '../../customHooks/useModal';
import { useHistory } from 'react-router-dom';
import translations from '../../translations/de';

import Paths from '../../constants/paths';

import { observer } from 'mobx-react-lite';
import { FaSearch } from 'react-icons/fa';
import Loader from '../Loader';
import API from '../../api/fanGames';

const FanGameRewardTable = ({ activeTab, selectedSeason }) => {
    const history = useHistory();
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [defaultRewardID, setDefaultRewardID] = useState(null);

    const deleteDefaultReward = () => {
        try {
            API.deleteDefaultReward(defaultRewardID).then(() => {
                setData((prevData) => prevData.filter((reward) => reward.id !== defaultRewardID));
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const getDefaultsRewards = async () => {
            try {
                setLoading(true);
                const res = await API.getDefaultRewardStatistics({
                    params: {
                        fan_game_type: activeTab,
                        season_id: selectedSeason,
                    },
                });
                setData(res.data.stats);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        };
        selectedSeason && getDefaultsRewards();
    }, [activeTab, selectedSeason]);

    return (
        <>
            <div>
                <Button
                    label={translations.new_voucher}
                    title={translations.new_voucher}
                    onClick={() =>
                        history.push({
                            pathname: Paths.FAN_GAME_NEW_DEFAULT_REWARD,
                            state: {
                                activeTabName: activeTab,
                                selectedSeason,
                            },
                        })
                    }
                >
                    {' '}
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {!loading ? (
                <Table
                    tableName={translations.vouchers}
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'name',
                            isFixedWider: true,
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.created_total,
                            accessor: 'quantity',
                            title: 'Quantity of rewards',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.still_available,
                            accessor: 'still_available',
                            title: 'Still available rewards',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.per_game,
                            accessor: 'per_game',
                            title: 'Quantity of rewards per game',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.game_number,
                            accessor: 'game',
                            title: 'Possible games',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ value, row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            history.push({
                                                pathname: `/fan-games/fan-game-reward/${value}`,
                                                state: {
                                                    activeTabName: activeTab,
                                                },
                                            });
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setDefaultRewardID(value);
                                            setModal(true);
                                        }}
                                        title={translations.delete}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={data.map(
                        ({
                            id,
                            name,
                            total_rewards,
                            available_rewards,
                            quantity_per_game,
                            possible_games,
                        }) => {
                            return {
                                name,
                                quantity: total_rewards,
                                still_available: available_rewards,
                                per_game: quantity_per_game,
                                game: possible_games,
                                action: id,
                            };
                        },
                    )}
                />
            ) : (
                <Loader theme="dark" size="big" />
            )}
            {modal && (
                <Modal
                    title="Delete default reward"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this default reward?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                deleteDefaultReward();
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(FanGameRewardTable);
