import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import Notifications from './Notifications';
import Notification from './Notification';
import NewNotification from './NewNotification';
import './Notifications.scss';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const NotificationsTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.NOTIFICATIONS}>
                <Notifications store={store} />
            </Route>
            <Route exact path={Paths.NEW_NOTIFICATION}>
                <NewNotification store={store} />
            </Route>
            <Route exact path={Paths.NOTIFICATION}>
                <Notification store={store} />
            </Route>
        </Page>
    );
};

export default observer(NotificationsTab);
