import React, { useState, useEffect } from 'react';

import { formatDate } from '../../helpers/formatDate';

import Page from '../../components/Page';
import Table from '../../components/Table';
import InputSeason from '../../components/InputSeason/InputSeason';

import API from '../../api/passes';

import translations from '../../translations/de';
const TicketingKPI = () => {
    const [stats, setStats] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);

    useEffect(() => {
        const getPasses = async () => {
            try {
                const res = await API.getPassStatistics({ params: { season_id: selectedSeason } });
                setStats(res.data);
            } catch (err) {
                console.error(err);
            }
        };
        selectedSeason && getPasses();
    }, [selectedSeason]);

    return (
        <>
            <Page>
                <div style={{ width: '220px', marginLeft: '2.5%', height: '40px' }}>
                    <InputSeason
                        selectedSeason={selectedSeason}
                        setSelectedSeason={setSelectedSeason}
                    />
                </div>
                {
                    <Table
                        noIdColumn={true}
                        tableName={translations.match_overview}
                        columns={[
                            {
                                Header: translations.date,
                                accessor: 'date',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.opponent,
                                accessor: 'opponent',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.seasonal_pass,
                                accessor: 'seasonal',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.single_pass,
                                accessor: 'single',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.freunde_pass,
                                accessor: 'freunde',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.total,
                                accessor: 'total',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            // {
                            //     Header: translations.more,
                            //     accessor: '',
                            //     Cell: () => <div></div>,
                            // },
                        ]}
                        data={stats.map(({ date, opponent, seasonal, single, freunde, total }) => {
                            return {
                                date: formatDate(date),
                                opponent,
                                seasonal,
                                single,
                                freunde,
                                total,
                            };
                        })}
                    ></Table>
                }
            </Page>
        </>
    );
};

export default TicketingKPI;
