import { types, flow } from 'mobx-state-tree';
import User from './User';
import API from './../../api/users';
import APIseasons from './../../api/seasons';
import APItemas from '../../api/teams';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import Player from '../teamsStore/Player';
import Contact from '../contactsStore/Contact';

const Users = types
    .model('Users', {
        users: types.array(User),
        selectedUser: types.maybe(types.reference(User)),
        contacts: types.maybe(types.array(types.frozen(Contact))),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        seasonalMoodGameId: types.maybeNull(types.number),
        ownersPlayers: types.array(Player),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getUsers: flow(function* getUsers(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getUsers({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.users = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                console.log(error);
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getContacts: flow(function* getContacts(user_id) {
            // get contacts and place them into store
            self.error = '';
            try {
                const res = yield API.getContacts({
                    params: {
                        user_id: user_id,
                    },
                });
                self.dataLoaded = true;
                self.contacts = res.data.items;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectUser(id) {
            self.selectedUser = id;
        },
        getUser: flow(function* getUser(id) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield API.getUser(id);
                self.users = [];
                self.users.push(res.data);
                self.selectedUser = res.data.id;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editUser: flow(function* editUser(edited) {
            try
            {
                const res = yield API.editUser(self.selectedUser.id, {
                    ...edited,
                    new_password: edited.new_password !== '' && edited.new_password !== null ? edited.new_password : undefined,
                    favourite_player_id:
                        edited.favourite_player_id === '' ? null : edited.favourite_player_id,
                    business_profile_id: edited.business_profile_id === '' ? null: edited.business_profile_id
                });

                self.selectedUser.editUser(res.data);
            } catch (error) {
                throw error;
            }
        }),
        editFanName: flow(function* editFanName(edited) {
            var tempObj = edited;
            console.log(tempObj.id);
            try {
                const res = yield API.editUser(edited.id, {
                    fan_name_censored: !edited.fan_name_censored,
                });
                self.selectedUser.editUser(res.data);
                // change in list
                var indexOf = self.users.findIndex((user) => user.id === res.data.id);
                self.users[indexOf] = res.data;
            } catch (error) {
                throw error;
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        getSeasonalMoodGameId: flow(function* getSeasonalMoodGameId() {
            try {
                const res = yield APIseasons.getCurrnentSeason();
                const seasonalGame =
                    res.data.fan_games &&
                    res.data.fan_games.find((game) => game.fan_game_type === 'seasonal_mood');
                self.seasonalMoodGameId = seasonalGame && seasonalGame.id;
            } catch (error) {
                throw error;
            }
        }),
        getOwnersPlayers: flow(function* getOwnersPlayers() {
            try {
                const res = yield APItemas.getOwnerPlayers();
                self.ownersPlayers = res.data;
            } catch (error) {
                throw error;
            }
        }),
        deleteUser: flow(function* deleteUser(id) {
            try {
                yield API.deleteUser(id);
                const index = self.users.findIndex((user) => user.id === id);
                self.users[index].deleted_at = 'deleted';
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        reviveUser: flow(function* reviveUser(id) {
            try {
                yield API.reviveUser(id);
                const index = self.users.findIndex((user) => user.id === id);
                self.users[index].deleted_at = null;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
    }));

export default Users;
