import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import Item from '../../components/Item';
import getLotteryGameFormInput from './getLotteryGameFormInput';
import Paths from '../../constants/paths';
import TabSwitcher from '../../components/TabSwitcher';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import './LotteryGame.scss';
import translations from '../../translations/de';
import Breadcrumb from '../../components/Breadcrumb';
import Participants from './Participants';
import useModal from '../../customHooks/useModal';
import Winners from './Winners';
import LotteryReward from './LotteryReward';
import LotteryTicket from './LotteryTicket';

const LotteryGame = ({ store }) => {
    const { lottery_game_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resetProp, setResetProp] = useState(null);
    const modalRef = useRef(null);
    const [modalDraw, setModalDrwa] = useModal(false, modalRef);
    const [startAtEnabled, setStartAtEnabled] = useState(false);

    useEffect(() => {
        store.getLotteryGame(lottery_game_id);
    }, [store, lottery_game_id]);

    const onSubmitLotteryDetails = (lottery) => {
        setLoading(true);

        store
            .editLotteryGameDetails({
                ...lottery,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        setStartAtEnabled(false);
    };

    useEffect(() => {
        store.selectedLotteryGame &&
            !store.selectedLotteryGame.shop_item.active &&
            setStartAtEnabled(true);
    }, [store.selectedLotteryGame]);

    return store.selectedLotteryGame ? (
        <div className="Lottery">
            <div className="Lottery-Header">
                <Breadcrumb
                    links={[{ path: Paths.LOTTERY_GAMES, name: translations.lottery_games }]}
                    name={store.selectedLotteryGame.shop_item?.name}
                />
                <div className="controls">
                    {new Date(store.selectedLotteryGame.draw_at) > new Date() && (
                        <Button type="button" onClick={() => setModalDrwa(true)}>
                            {translations.draw_lottery}
                        </Button>
                    )}
                </div>
            </div>
            <TabSwitcher initialTabName={translations.lottery_details}>
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName={translations.lottery_details} />
                    <TabSwitcher.TabPill tabName={translations.lottery_ticket} />
                    <TabSwitcher.TabPill tabName={translations.reward} />
                    <TabSwitcher.TabPill tabName={translations.participants} />
                    <TabSwitcher.TabPill tabName={translations.winners} />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName={translations.lottery_details}>
                    <Item
                        hasNavigation={false}
                        id={`[ Lottery_game_id: ${store.selectedLotteryGame.id} ]`}
                        itemModel={{
                            draw_at: store.selectedLotteryGame.draw_at,
                            start_at: store.selectedLotteryGame.start_at,
                            draw_when_sold_out: store.selectedLotteryGame.draw_when_sold_out,
                            send_notification: store.selectedLotteryGame.send_notification,
                        }}
                        edit={edit}
                        onSubmit={onSubmitLotteryDetails}
                        onEdit={() => setEdit(true)}
                        onCancel={() => {
                            setEdit(false);
                        }}
                        loading={loading}
                        determineInputType={({
                            value,
                            name,
                            register,
                            control,
                            error,
                            disabled,
                            index,
                        }) =>
                            getLotteryGameFormInput({
                                value,
                                name,
                                register,
                                control,
                                error,
                                disabled,
                                index,
                                resetValue: (name, value) => setResetProp({ [name]: value }),
                                startAtEnabled,
                            })
                        }
                        resetProp={resetProp}
                        active={true}
                        setFieldEnabled={(e) => edit && setStartAtEnabled(e)}
                    />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.lottery_ticket}>
                    <LotteryTicket lotteryStore={store} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.reward}>
                    <LotteryReward lotteryStore={store} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.participants}>
                    <Participants store={store} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.winners}>
                    <Winners store={store} />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
            {modalDraw && (
                <Modal
                    title="Draw Lottery Game"
                    ref={modalRef}
                    onClose={() => setModalDrwa(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Draw Lottery and asign Rewards to users who participated?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store
                                    .drawLottery()
                                    .then(() => {
                                        setModalDrwa(false);
                                    })
                                    .catch(() => setModalDrwa(false));
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModalDrwa(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </div>
    ) : null;
};

export default observer(LotteryGame);
