import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useHistory, useParams } from 'react-router-dom';
import getBannerFormInput from './getBannerFormInput';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import API from '../../api/screens';

const NewBanner = ({ store }) => {
    const history = useHistory();
    const { screen_id } = useParams();
    const [image, setImage] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [bannerTypes, setBannerTypes] = useState([]);

    useEffect(() => {
        const getTypes = async () => {
            try {
                const res = await API.getBannerTypes();
                setBannerTypes(res.data.items);
            } catch (e) {
                console.log(e);
            }
        };
        getTypes();
        store.getScreen(screen_id);
    }, [store, screen_id]);

    return store.selectedScreen ? (
        <Item
            links={[
                { path: Paths.SCREENS, name: translations.app_screens },
                { path: `/screens/${store.selectedScreen.id}`, name: store.selectedScreen.name },
            ]}
            name={`${translations.banner} ${translations.create}`}
            itemModel={{
                title: '',
                image_url: image,
                position: '',
                size: '',
                banner_id: '',
                target_url: '',
                description: '',
            }}
            edit={true}
            error={error}
            loading={loading}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .addBanner({
                        ...event,
                        screen_id: parseInt(screen_id),
                        banner_id: parseInt(event.banner_id),
                        position: parseInt(event.position),
                    })
                    .then(() => {
                        setLoading(false);
                        history.push(`/screens/${store.selectedScreen.id}`);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            determineInputType={({ value, name, register, error, disabled, index, control }) =>
                getBannerFormInput({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setImage({ [name]: value }),
                    control,
                    screens: name === 'screen_id' ? store.screens : null,
                    bannerTypes,
                })
            }
            resetProp={image}
        ></Item>
    ) : null;
};

export default observer(NewBanner);
