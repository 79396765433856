import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import { observer } from 'mobx-react-lite';
import getSeatFormInput from './getSeatFormInput';
import { getSnapshot } from 'mobx-state-tree';
import translations from '../../translations/de';
import seatGroupAPI from '../../api/seatGroups.js';
import debounce from 'lodash.debounce';

const NewSeat = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const sectors = getSnapshot(store.sectors);
    const [seatGroups, setSeatGroups] = useState(getSnapshot(store.seatGroups));
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();

    }, [store]);

    useEffect(() => {
        const getSeatGroups = async () => {
            try {
                const res = await seatGroupAPI.getSeatGroups({
                    params: {
                        search: searchQuery,
                        limit: 1000,
                    },
                });
                setSeatGroups(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchQuery && getSeatGroups();
    }, [searchQuery]);

    const searchSeatGroup = debounce((value) => {
        setSearchQuery(value);
    }, 500);

    return (
        <Item
            links={[{ path: Paths.SEATS, name: translations.seats }]}
            name={`${translations.seat} ${translations.create}`}
            itemModel={{
                row: '',
                num: '',
                seat_group_id: '',
            }}
            edit={true}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .addSeat({ ...event })
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.SEATS);
                    })
                    .catch((error) => {
                        setError(error);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, isDisabled, index }) =>
                getSeatFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    isDisabled,
                    index,
                    sectors,
                    seatGroups,
                    searchSeatGroup,
                })
            }
        ></Item>
    );
};

export default observer(NewSeat);
