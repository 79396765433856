import { types } from 'mobx-state-tree';

const Quote = types
    .model('Quote', {
        text: types.maybeNull(types.string),
    })
    .actions((self) => ({
        onChangeValue(propName, value) {
            self[propName] = value;
        },
    }));

export default Quote;
