import { types, flow } from 'mobx-state-tree';
import API from '../../api/sectors';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import Sector from './Sector';

const SectorsStore = types
    .model('SectorsStore', {
        sectors: types.array(Sector),
        selectedSector: types.maybe(types.reference(Sector)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getSectors: flow(function* getSectors(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getSectors({
                    params: {
                        search: searchQuery,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.sectors = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        selectSector(id) {
            self.selectedSector = id;
        },
        getSector: flow(function* getSector(id) {
            self.error = '';
            try {
                const res = yield API.getSector(id);
                self.dataLoaded = true;
                self.sectors = [];
                self.sectors.push(res.data);
                self.selectedSector = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editSector: flow(function* editSector(edited) {
            try {
                const res = yield API.editSector(self.selectedSector.id, edited);
                self.selectedSector.editSector(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addSector: flow(function* addSector(newSector) {
            try {
                const res = yield API.createSector(newSector);
                self.sectors.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteSector: flow(function* deleteSector(id) {
            try {
                yield API.deleteSector(id);
                const index = self.sectors.findIndex((b) => b.id === id);
                self.sectors.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));
export default SectorsStore;
