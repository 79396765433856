import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import { formatDate, formatTime } from '../../helpers/formatDate';
import Table from '../../components/Table';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Pagination from './../../components/Pagination';
import { FaPlus, FaCheck, FaTimes, FaSearch } from 'react-icons/fa';
import debounce from 'lodash.debounce';

const LotteryGames = ({ store }) => {
    const history = useHistory();
    const [search, setSearch] = useState('');

    useEffect(() => {
        store.getLotteryGames(search);
    }, [store, store.selectedPage, search]);

    const handleLotteryGame = (id) => {
        store.selectLotteryGame(id);
        history.push(`${Paths.LOTTERY_GAMES}/${id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearch(e.target.value);
    }, 500);

    return (
        <>
            <div className="tab-controls">
                <Input
                    label={translations.lottery_game_name}
                    placeholder={translations.search}
                    onChange={onSearchChangeHandler}
                />
            </div>

            <Button
                label={translations.lottery_game}
                onClick={() => {
                    store.selectLotteryGame(undefined);
                    history.push(Paths.NEW_LOTTERY_GAME);
                }}
                style={{ marginLeft: '2.5%' }}
            >
                <FaPlus style={{ marginRight: '5px' }} />
            </Button>
            {store.lotteryGames && store.lotteryGames.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    isRowClickable={false}
                    tableName={translations.lottery_games}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.description,
                            accessor: 'description',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.drawn,
                            accessor: 'drawn',
                            Cell: ({ value }) => (
                                <div>
                                    {value === true ? (
                                        <FaCheck className="confirm" />
                                    ) : (
                                        <FaTimes className="error" />
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.drawn_at,
                            accessor: 'drawn_at',
                            Cell: ({ value }) => (
                                <div>
                                    {formatDate(value, true)} {formatTime(value, true)}
                                </div>
                            ),
                        },

                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleLotteryGame(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.lotteryGames.map(({ id, shop_item, draw_at }) => {
                        return {
                            id,
                            name: shop_item?.name ? shop_item.name : '',
                            description: shop_item?.description ? shop_item.description : '',
                            drawn: new Date(draw_at) < new Date() ? true : false,
                            drawn_at: draw_at,
                        };
                    })}
                />
            )}
        </>
    );
};

export default observer(LotteryGames);
