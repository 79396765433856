import { types } from 'mobx-state-tree';
import Pass from '../passesStore/Pass';

const AccessCode = types.model('AccessCode', {
    access_code_id: types.identifier,
    ticket_id: types.maybeNull(types.string),
    access_token: types.maybeNull(types.string),
    event_id: types.maybeNull(types.number),
    pass_id: types.maybeNull(types.number),
    game_id: types.maybeNull(types.number),
    row_label: types.maybeNull(types.string),
    seat_label: types.maybeNull(types.string),
    block_label: types.maybeNull(types.string),
    away_team: types.maybeNull(types.string),
    start_time: types.maybeNull(types.string),
    pass: types.frozen(Pass),
});

export default AccessCode;
