import { useState, useEffect } from 'react';
import getInput from './getInput';
import Button from '../Button';
import { observer } from 'mobx-react-lite';
import AnswerInstance from '../../models/questionsStore/GameAnswer';
import GameAnswer from './GameAnswer';
import Table from '../Table';
import useModal from '../../customHooks/useModal';
import { useForm } from 'react-hook-form';
import clearObjProps from '../../helpers/clearObjProps';
import answersConfig from '../../configs/answers';
import API from '../../api/teams';
import PlayerAnswers from './PlayerAnswers';
import { getSnapshot } from 'mobx-state-tree';
import { FaTrash, FaEdit, FaPlus } from 'react-icons/fa';
import ErrorMessages from '../ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import translations from '../../translations/de';

const Question = ({ questionStore, gameType, onSave, onCancel, error, loading }) => {
    const questionObj = clearObjProps({ ...questionStore }, ['id', 'fan_game_answers']);
    const question = Object.keys(questionObj).map((key) => {
        return { name: key, value: questionObj[key] };
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm({
        defaultValues: questionObj,
    });
    const [answers, setAnswers] = useState(questionStore.fan_game_answers);
    const [answer, setAnswer] = useState(null);
    const [answerModal, setAnswerModal] = useModal(false);
    const [resetProp, setResetProp] = useState(null);
    const [players, setPlayers] = useState(null);
    const [answerError, setAnswerError] = useState('');
    const [loadingAnswer, setLoadingAnswer] = useState(false);

    useEffect(() => {
        const getPlayers = async () => {
            try {
                const res = await API.getOwnerPlayers();
                setPlayers(res.data);
            } catch (error) {
                console.log(error.message);
            }
        };
        gameType === 'favorite_player' && getPlayers();
    }, [gameType]);

    useEffect(() => {
        if (answer) {
            setAnswerModal(true);
        } else {
            setAnswerModal(false);
        }
    }, [answer, setAnswerModal]);

    useEffect(() => {
        if (resetProp) {
            reset({ ...watch(), ...resetProp });
        }
    }, [resetProp, reset, watch]);

    const handleAnswer = (id) => {
        setAnswer(questionStore.getAnswer(id));
    };

    const onChangePlayerHandler = (player, isChecked) => {
        const existedAns = getSnapshot(questionStore.fan_game_answers).find(
            (a) => a.player_id === player.id,
        );
        if (isChecked && existedAns) {
            setAnswers([...answers, existedAns]);
        } else if (isChecked && !existedAns) {
            setAnswers([
                ...answers,
                {
                    player_id: player.id,
                    image_url: player.avatar,
                    text: `${player.first_name} ${player.last_name}`,
                },
            ]);
        } else if (!isChecked) {
            const newAnswers = answers.filter((player) => player.id !== existedAns.id);
            setAnswers(newAnswers);
        }
    };

    const selectAllPlayersHandler = (isChecked) => {
        if (isChecked) {
            setAnswers(
                players.map((player) => ({
                    player_id: player.id,
                    image_url: player.avatar,
                    text: `${player.first_name} ${player.last_name}`,
                })),
            );
        } else {
            setAnswers([]);
        }
    };

    const closeAnswerModal = () => {
        setLoadingAnswer(false);
        setAnswer(null);
        setAnswerError('');
    };

    return (
        <div className="Question">
            <form>
                <div className="Question-Form">
                    {question.length !== 0 &&
                        question.map((data, index) =>
                            getInput({
                                name: data.name,
                                value: data.value,
                                register,
                                index,
                                error: errors[data.name],
                                resetValue: (name, value) => setResetProp({ [name]: value }),
                            }),
                        )}
                </div>
            </form>
            {questionStore.id && answersConfig.addingAnswersAllowed(gameType) && (
                <Button
                    label="Answer"
                    onClick={() => {
                        setAnswer(
                            AnswerInstance.create({
                                id: null,
                                text: '',
                                image_url: '',
                                second_image_url: '',
                                sound_url: '',
                                video_url: '',
                                share_url: '',
                                share_title: '',
                                share_description: '',
                                twitter_hash_tags: '',
                                quotes: [],
                            }),
                        );
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            )}
            {questionStore.fan_game_answers &&
                questionStore.fan_game_answers.length !== 0 &&
                gameType !== 'favorite_player' && (
                    <Table
                        isRowClickable={false}
                        tableName="Answers"
                        columns={[
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({ value, row }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.text,
                                accessor: 'text',
                                Cell: ({ value, row }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.image,
                                accessor: 'image_url',
                                Cell: ({ value, row }) => (
                                    <div>
                                        <img src={value} alt="answer" />
                                    </div>
                                ),
                            },
                            {
                                Header: translations.description,
                                accessor: 'share_description',
                                Cell: ({ value, row }) => <div>{value}</div>,
                            },

                            {
                                Header: '',
                                accessor: 'action',
                                Cell: ({ row }) => (
                                    <div>
                                        <Button
                                            title={translations.edit}
                                            onClick={() => {
                                                handleAnswer(row.cells[0].value);
                                            }}
                                        >
                                            <FaEdit />
                                        </Button>
                                        {answersConfig.deletingAnswersAllowed(gameType) && (
                                            <Button
                                                title={translations.delete}
                                                onClick={() => {
                                                    questionStore.deleteAnswer(row.cells[0].value);
                                                }}
                                                style={{ marginRight: '5px' }}
                                            >
                                                <FaTrash />
                                            </Button>
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                        data={questionStore.fan_game_answers.map(
                            ({ id, text, image_url, share_description }) => {
                                return {
                                    id,
                                    text,
                                    image_url,
                                    share_description,
                                };
                            },
                        )}
                    />
                )}
            {players && (
                <PlayerAnswers
                    players={players}
                    answers={answers.map((a) => a.player_id)}
                    onChangePlayer={onChangePlayerHandler}
                    selectAllPlayers={selectAllPlayersHandler}
                />
            )}

            <div className="Question-Bottom">
                <Button type="button" label={translations.cancel} onClick={onCancel} />
                <Button
                    type="submit"
                    label={translations.save}
                    style={{ marginLeft: '10px' }}
                    onClick={handleSubmit((e) =>
                        onSave(questionStore.id, {
                            ...e,
                            fan_game_answers: answers,
                        }),
                    )}
                    loading={loading}
                />
            </div>

            <ErrorMessages
                messages={requsetErrorMessage(error && error.message ? error.message : error)}
            />

            {answerModal && (
                <GameAnswer
                    answerStore={answer}
                    gameType={gameType}
                    onSave={(answerID, answer) => {
                        setLoadingAnswer(true);
                        answerID
                            ? questionStore
                                  .editAnswer(answerID, answer)
                                  .then(closeAnswerModal)
                                  .catch((err) => {
                                      setAnswerError(err);
                                      setLoadingAnswer(false);
                                  })
                            : questionStore
                                  .addAnswer(answer)
                                  .then(closeAnswerModal)
                                  .catch((err) => {
                                      setAnswerError(err);
                                      setLoadingAnswer(false);
                                  });
                    }}
                    onClose={closeAnswerModal}
                    error={answerError}
                    loading={loadingAnswer}
                />
            )}
        </div>
    );
};

export default observer(Question);
