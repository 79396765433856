import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import ItemImage from '../../components/ItemImage';

const SeasonalMood = ({ store }) => {
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        store.selectedUser
            .getSeasonalMoodStats(store.seasonalMoodGameId)
            .then(() => setLoader(false));
    }, [store.selectedUser, store.seasonalMoodGameId]);

    return loader ? (
        <div className="loader-wrapper">
            <Loader size="big" theme="dark" />
        </div>
    ) : (
        <div>
            {store.selectedUser.seasonalMoodStats.length !== 0 ? (
                <Table
                    nextMatchID={null}
                    isRowClickable={false}
                    columns={[
                        {
                            Header: '',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Mood',
                            accessor: 'fan_game_answer_text',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Image',
                            accessor: 'fan_game_answer_image_url',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="mood" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: 'Count',
                            accessor: 'count',
                            Cell: ({ value }) => <div>{value}</div>,
                        },

                        {
                            Header: 'Percentage',
                            accessor: 'percentage',
                            Cell: ({ value }) => <div>{value ? `${value} %` : '0'}</div>,
                        },
                    ]}
                    data={store.selectedUser.seasonalMoodStats.map(
                        ({
                            count,
                            fan_game_answer_image_url,
                            fan_game_answer_text,
                            percentage,
                        }) => {
                            return {
                                count,
                                fan_game_answer_image_url,
                                fan_game_answer_text,
                                percentage:
                                    percentage && percentage !== 0
                                        ? percentage.toFixed(2)
                                        : percentage,
                            };
                        },
                    )}
                />
            ) : (
                <div className="no-data">No Data Yet...</div>
            )}
        </div>
    );
};

export default observer(SeasonalMood);
