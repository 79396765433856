import api from './apiLibrary';

const API = {
    getFeeds(params) {
        return api.get(`/api/admin/feed`, params);
    },
    getFeed(id, params) {
        return api.get(`/api/admin/feed/${id}`, params);
    },
    createFeed(data) {
        return api.post('/api/admin/feed', data);
    },
    editFeed(id, data) {
        return api.put(`/api/admin/feed/${id}`, data);
    },
    deleteFeed(id) {
        return api.delete(`/api/admin/feed/${id}`);
    },
    reviveFeed(id) {
        return api.put(`/api/admin/feed/${id}/revive`);
    },
    setActiveFeedStatus(id, status) {
        return api.patch(`/api/admin/feed/${id}/show-hide/${status}`);
    },
};

export default API;
