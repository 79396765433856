import { types, applySnapshot } from 'mobx-state-tree';
import User from '../usersStore/User';

const Contact = types
    .model('Contact', {
        id: types.identifierNumber,
        user_id: types.maybeNull(types.number),
        phone: types.maybeNull(types.string),
        first_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        street: types.maybeNull(types.string),
        zip: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        birthday: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        user: types.maybeNull(User),
    })
    .actions((self) => ({
        editContact(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Contact;
