import api from './apiLibrary';

const API = {
    login(data) {
        return api.post('/api/admin/auth/login', data);
    },
    logout() {
        return api.post('/api/admin/auth/logout');
    },
    getMeUser() {
        return api.get('/api/admin/user/me');
    },
    forgotPassword(params) {
        return api.post('/api/admin/auth/forgot-password', params);
    },
    resetPassword(params) {
        return api.post(`/api/admin/auth/set-forgot-password`, params);
    },
    verifyCode(params) {
        return api.post(`api/admin/auth/validate-forgot-password`, params);
    },
};

export default API;
