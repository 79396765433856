import React from 'react';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import Seats from './Seats';
import Seat from './Seat';
import NewSeat from './NewSeat';

const SeatsTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.SEATS}>
                <Seats store={store} />
            </Route>
            <Route exact path={Paths.SEAT}>
                <Seat store={store} />
            </Route>
            <Route exact path={Paths.NEW_SEAT}>
                <NewSeat store={store} />
            </Route>
        </Page>
    );
};
export default observer(SeatsTab);
