import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import translations from '../../translations/de';
import Button from '../../components/Button';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import { FaPlus, FaTrash, FaSearch } from 'react-icons/fa';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import rewardTypes from '../../constants/rewardTypes';
import Input from '../../components/Input';
import debounce from 'lodash.debounce';
import Select from '../../components/Select';
import ItemImage from '../../components/ItemImage';
import { formatDate } from '../../helpers/formatDate';
const Rewards = ({ store }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const history = useHistory();
    const modalRef = useRef(null);
    const [rewardID, setRewardID] = useState(null);
    const [itemType, setItemType] = useState('');
    const [modal, setModal] = useModal(false, modalRef);

    const handleReward = (id) => {
        store.selectReward(id);
        history.push(`/rewards/${store.selectedReward.id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

    useEffect(() => {
        store.getAllRewards(searchQuery, itemType);
    }, [store, store.selectedPage, searchQuery, itemType]);

    return (
        <>
            <div className="tab-controls">
                <Input
                    label={`${translations.reward} ${translations.name} / ${translations.description}`}
                    placeholder={translations.search}
                    defaultValue={searchQuery}
                    onChange={onSearchChangeHandler}
                />

                <Select
                    label={`${translations.reward} ${translations.type}`}
                    defaultValue={{ label: 'All', value: '' }}
                    options={[{ label: 'All', value: '' }, ...rewardTypes]}
                    onChange={(e) => {
                        store.setSelectedPage(1);
                        setItemType(e.value);
                    }}
                />
            </div>
            <Button
                label={translations.new_reward}
                onClick={() => {
                    store.selectReward(undefined);
                    history.push(Paths.NEW_REWARD);
                }}
                style={{
                    marginLeft: '2.5%',
                    minWidth: '170px',
                }}
            >
                <FaPlus style={{ marginLeft: '2.5%' }} />
            </Button>
            {store.rewards.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName="Rewards"
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },

                        {
                            Header: translations.image,
                            accessor: 'image_url',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="reward" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.expire_at,
                            accessor: 'expire_at',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.stock,
                            accessor: 'quantity',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.rewarded,
                            accessor: 'acquired',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.used,
                            accessor: 'used',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },

                        {
                            Header: translations.type,
                            accessor: 'type',
                            Cell: ({ value }) => <div>{translations[value]}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ value, row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleReward(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setRewardID(row.cells[0].value);
                                            setModal(true);
                                        }}
                                        title={translations.delete}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.rewards.map(
                        ({ id, name, image_url, type, used, acquired, quantity, expire_at }) => {
                            return {
                                id,
                                name,
                                image_url,
                                type,
                                used: type === 'consumable' ? used : '/',
                                acquired,
                                quantity,
                                expire_at: formatDate(expire_at),
                            };
                        },
                    )}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Reward"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this reward?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteReward(rewardID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(Rewards);
