import React from 'react';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import BusinessEvent from './BusinessEvent';
import BusinessEvents from './BusinessEvents';
import NewBusinessEvent from './newEvent';
import CreateEventStore from '../../models/businessEventsStore/CreatingBusinessEvent';

const BusinessEventsTab = ({ store }) => {
    const newEvent = CreateEventStore.create();
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.BUSINESS_EVENTS}>
                <BusinessEvents store={store} newEventStore={newEvent} />
            </Route>
            <Route exact path={Paths.BUSINESS_EVENT}>
                <BusinessEvent store={store} />
            </Route>
            <Route exact path={Paths.NEW_BUSINESS_EVENT}>
                <NewBusinessEvent store={newEvent} eventsStore={store} />
            </Route>
        </Page>
    );
};

export default observer(BusinessEventsTab);
