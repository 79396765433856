import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import Table from '../../components/Table';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { useHistory, useParams } from 'react-router-dom';
import useModal from '../../customHooks/useModal';
import getScreenFormInput from './getScreenFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import { FaPlus, FaSearch, FaTrash, FaUndoAlt } from 'react-icons/fa';
import Paths from '../../constants/paths';
import translations from '../../translations/de';

const Screen = ({ store }) => {
    const history = useHistory();
    const { screen_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const deleteModalRef = useRef(null);
    const [deleteModal, setDeleteModal] = useModal(false, deleteModalRef);
    const [bannerID, setBannerID] = useState(null);

    useEffect(() => {
        store.getScreen(screen_id);
        return () => {
            store.selectScreen(undefined);
        };
    }, [store, screen_id]);

    const screenModel = clearObjProps({ ...store.selectedScreen }, ['id', 'banners']);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editScreen({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const handleBanner = (id) => {
        history.push(`/screens/${screen_id}/banners/${id}`);
    };

    const deleteBanner = (id) => {
        store?.deleteBanner(id);
    };
    return store.selectedScreen ? (
        <>
            <Item
                links={[{ path: Paths.SCREENS, name: translations.app_screens }]}
                name={store.selectedScreen.name}
                id={`[ screen_ID: ${store.selectedScreen.id} ]`}
                itemModel={screenModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, control, register, error, disabled, index }) =>
                    getScreenFormInput({
                        value,
                        name,
                        control,
                        register,
                        error,
                        disabled,
                        index,
                    })
                }
            />
            <div style={{ marginLeft: '2.5%' }}>
                <Button
                    label={translations.banner}
                    onClick={() => {
                        store.selectBanner(undefined);
                        history.push(`/screens/${store.selectedScreen.id}/new_banner`);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {store.selectedScreen.banners.length !== 0 && (
                <Table
                    tableName={translations.banners}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Title',
                            accessor: 'title',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.image_url}`,
                            accessor: 'image_url',
                            Cell: ({ value, row }) => (
                                <div>
                                    <img src={value} alt="banner" />
                                </div>
                            ),
                        },
                        {
                            Header: 'Description',
                            accessor: 'description',
                            Cell: ({ row, value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Size',
                            accessor: 'size',
                            Cell: ({ row, value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Position',
                            accessor: 'position',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleBanner(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    {isNotDeletedAlready(row) ? (
                                        <Button
                                            onClick={() => {
                                                setBannerID(row.cells[0].value);
                                                setDeleteModal(true);
                                            }}
                                            title={translations.delete}
                                        >
                                            <FaTrash />
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => store.reviveBanner(row.cells[0].value)}
                                        >
                                            <FaUndoAlt />
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    data={store.selectedScreen.banners.map(
                        ({ id, title, image_url, description, size, position, deleted_at }) => {
                            return {
                                id,
                                title,
                                image_url,
                                description,
                                size,
                                position,
                                deleted_at,
                            };
                        },
                    )}
                />
            )}
            {deleteModal && (
                <Modal
                    title="Delete Banner"
                    ref={deleteModalRef}
                    onClose={() => setDeleteModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this banner?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                deleteBanner(bannerID);
                                setDeleteModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setDeleteModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    ) : null;
};

export default observer(Screen);
