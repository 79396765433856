import Input from '../Input';
import DatePicker from '../DatePicker';
import InputFile from '../InputFile';
import Select from '../Select';
import Textarea from '../Textarea';
import Checkbox from '../Checkbox';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/reward';
import rewardTypes from '../../constants/rewardTypes';
import translations from '../../translations/de';
import voucherTypes from '../../constants/voucherTypes';
import ImportVoucherCodes from '../ImportVoucherCodes/ImportVoucherCodes';
import InputEmpty from '../../components/InputEmpty';

export default function getRewardInput({
    value,
    name,
    register,
    control,
    error,
    index,
    resetValue,
    resetVoucherValue,
    handleTypeChange,
    handleVoucherTypeChange,
    disabled,
    type,
    voucherType,
    orderID,
    setOrderID,
    comesFromEdit = false,
    searchOrdersHandler,
    orders = [],
    handleOrder,
    disableVoucherFields,
    price_in_cents,
    showSingleVoucherCodeField = false,
}) {
    if (name === 'expire_at' || name === 'end_at') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                disabled={disabled}
                defaultValue={value}
                control={control}
                error={error}
                minDate={new Date()}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'dummy') {
        return <InputEmpty key={index} />;
    } else if (name === 'order_id')
    {
        return (
            voucherType !== 'internal' &&
            voucherType &&
            type === 'consumable' && (
                <Select
                    key={`index_${voucherType}-${orderID}`}
                    onInputChange={searchOrdersHandler}
                    name={name}
                    label={translateLabel(translations.order_id)}
                    options={orders.map((order) => {
                        return {
                            value: order,
                            label: `${order}`,
                        };
                    })}
                    control={control}
                    onChangeCallback={(e) => handleOrder(e)}
                    placeholder={translations.search_orders}
                    error={error}
                    defaultValue={value}
                    disabled={disabled || comesFromEdit || orderID !== ''}
                    noOptionsMsg={translations.no_orders_found}
                    isClearable
                    isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                />
            )
        );
    } else if (name === 'importCodesField') {
        const isFixedTypeValid =
            voucherType &&
            voucherType !== 'internal' &&
            !showSingleVoucherCodeField &&
            voucherType !== '' &&
            type === 'consumable';
            return isFixedTypeValid ? (
                <ImportVoucherCodes
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    {...register(name, {})}
                    error={error}
                    control={control}
                    disabled={disableVoucherFields ?? disabled}
                    orderID={orderID}
                    setOrderID={setOrderID}
                    voucherType={voucherType}
                    resetValue={(val) => resetVoucherValue(name, val)}
                    pathToCloudFolder="uploads/instant-rewards"
                    currentPriceInCents={price_in_cents}
                />
            ) : (
                <InputEmpty />
            );
    } else if (name === 'single_voucher_check') {
        return voucherType !== '' &&
            voucherType !== 'internal' &&
            voucherType !== null &&
            type === 'consumable' ? (
            <div className="shipping-wrapper">
                <p style={{ fontWeight: 'bold', margin: '0' }}>Codes:</p>
                <Checkbox
                    disabled={disabled}
                    key={index}
                    text={translateLabel(name)}
                    name={name}
                    {...register(name, { required: false })}
                />
            </div>
        ) : (
            <InputEmpty key={index} />
        );
    } else if (name === 'shared_code') {
        return showSingleVoucherCodeField &&
            voucherType !== 'internal' &&
            voucherType !== '' &&
            voucherType !== null ? (
            <div style={{ width: '49%' }}>
                <div style={{ height: '10px' }} />
                <Input
                    style={{ width: '100%' }}
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    disabled={disabled}
                    type={typeof value}
                    defaultValue={value}
                    {...register(name, {
                        required: mandatoryFields.find((field) => (field === name ? true : false)),
                    })}
                    error={error}
                />
            </div>
        ) : (
            <InputEmpty key={index} />
        );
    } else if (name === 'image_url') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                disabled={disabled}
                {...register(name, {
                    required: true,
                })}
                error={error}
                resetValue={(val) => resetValue(name, val)}
                squareResolution={true}
                pathToCloudFolder="uploads/rewards"
            />
        );
    } else if (name === 'type') {
        return (
            !comesFromEdit && (
                <Select
                    key={index}
                    isSearchable={false}
                    label={translateLabel(name)}
                    name={name}
                    disabled={disabled || disableVoucherFields}
                    options={rewardTypes}
                    control={control}
                    error={error}
                    onChangeCallback={(e) => handleTypeChange(e)}
                    isRequired={true}
                />
            )
        );
    } else if (name === 'description' || name === 'popup_text') {
        return (
            <Textarea
                key={index}
                label={translateLabel(name)}
                name={name}
                disabled={disabled}
                type="text"
                {...register(name, {
                    required: true,
                })}
                error={error}
                defaultValue={value}
            />
        );
    } else if (name === 'use_unused') {
        return (
            type === 'consumable' && (
                <Checkbox
                    disabled={disabled || disableVoucherFields}
                    key={index}
                    text={translateLabel(name)}
                    name={name}
                    {...register(name, { required: false })}
                />
            )
        );
    } else if (name === 'voucher_type') {
        return (
            type === 'consumable' &&
            !comesFromEdit && (
                <Select
                    key={`${voucherType}-` + index}
                    label={translateLabel(name)}
                    name={name}
                    isClearable={true}
                    disabled={disabled}
                    isSearchable={false}
                    onChangeCallback={(e) => handleVoucherTypeChange(e)}
                    options={voucherTypes}
                    control={control}
                    error={error}
                    isRequired={true}
                />
            )
        );
    } else if (name === 'fan_coins') {
        return (
            type === 'coins' &&
            !comesFromEdit && (
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    disabled={disabled}
                    {...register(name, {
                        required: mandatoryFields.find((field) => (field === name ? true : false)),
                        valueAsNumber: true,
                        min: { value: 1, message: translations.positive_number },
                    })}
                    error={error}
                />
            )
        );
    } else if (name === 'acquired' || name === 'used') {
        return (
            disabled && (
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    disabled={disabled}
                    type={typeof value}
                    defaultValue={value}
                    {...register(name, {
                        required: mandatoryFields.find((field) => (field === name ? true : false)),
                        valueAsNumber: typeof value === 'number' ? true : false,
                        min:
                            typeof value === 'number'
                                ? { value: 1, message: translations.positive_number }
                                : undefined,
                    })}
                    error={error}
                />
            )
        );
    } else if (name === 'sponsor_link') {
        return voucherType === 'external' && type === 'consumable' ? (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                disabled={disabled}
                defaultValue={value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    validate: {
                        checkUrl: (value) => {
                            if (!value) {
                                return true;
                            }
                            try {
                                new URL(value); // Try to create a URL object from the input
                                return true; // If successful, the URL is valid
                            } catch (error) {
                                return 'Invalid URL';
                            }
                        },
                    },
                })}
                error={error}
            />
        ) : null;
    } else if (name === 'price_in_cents') {
        return voucherType === 'eguma' && type === 'consumable' ? (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                control={control}
                defaultValue={value}
                disabled={disabled}
                readOnly={disableVoucherFields}
                {...register(name, {
                    required: disableVoucherFields
                        ? false
                        : mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: true,
                })}
                error={error}
            />
        ) : null;
    } else if (name !== 'fan_game_id') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                disabled={disabled}
                type={typeof value}
                defaultValue={value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                    min:
                        typeof value === 'number'
                            ? { value: 1, message: translations.positive_number }
                            : undefined,
                })}
                error={error}
            />
        );
    }
}
