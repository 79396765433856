import Input from '../Input';
import InputFile from '../InputFile';
import Textarea from '../Textarea';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/question';

export default function getInput({
    value,
    name,
    register,
    error,
    index,
    resetValue,
    readOnly = false,
    disabled = false,
}) {
    if (name === 'image_url' || name === 'second_image_url') {
        return (
            <InputFile
                disabled={disabled}
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: false,
                })}
                error={error}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/question-items"
                readOnly={readOnly}
            />
        );
    } else if (name === 'text' || name === 'share_description') {
        return (
            <Textarea
                disabled={disabled}
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
            />
        );
    } else if (typeof value === 'string' || typeof value === 'number') {
        return (
            <Input
                disabled={disabled}
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                defaultValue={value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                })}
                error={error}
                readOnly={readOnly}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                readOnly={readOnly}
            />
        );
    }
}
