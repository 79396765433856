import React from 'react';
import Paths from '../../constants/paths';
import { Route } from 'react-router-dom';
import translations from '../../translations/de';
import Page from '../../components/Page';
import TabSwitcher from '../../components/TabSwitcher';

import ShowRate from './ShowRate';
import Activations from './Activations';
import TicketsCreated from './TicketsCreated';

const AnalyticsIndex = () => {
    return (
        <Page>
            <Route exact path={Paths.ANALYTICS}>
                <TabSwitcher initialTabName={translations.show_rate}>
                    <TabSwitcher.Tabs>
                        <TabSwitcher.TabPill tabName={translations.show_rate} />
                        <TabSwitcher.TabPill tabName={translations.activations} />
                        <TabSwitcher.TabPill tabName={translations.tickets_created} />
                    </TabSwitcher.Tabs>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.show_rate}>
                        <ShowRate />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.activations}>
                        <Activations />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.tickets_created}>
                        <TicketsCreated />
                    </TabSwitcher.TabPanel>
                </TabSwitcher>
            </Route>
        </Page>
    );
};

export default AnalyticsIndex;
