import { types, flow } from 'mobx-state-tree';
import MatchOverview from './MatchOverview';
import API from '../../api/matchOverview';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import { formatDate, formatTime } from '../../helpers/formatDate';
const MatchesOverviewStore = types
    .model('MatchesOverviews', {
        matchesOverview: types.array(MatchOverview),
        selectedMatchOverview: types.maybe(MatchOverview),
        selectedMatch: types.maybe(types.number),
        activeTab: types.maybe(types.string, ''),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getMatchesOverviews: flow(function* getMatchesOverviews(selectedSeason) {
            self.error = '';
            self.dataLoaded = false;
            try {
                const res = yield API.getMatchesOverview({ params: { season_id: selectedSeason } });
                self.dataLoaded = true;
                //self.totalPages = res.data.total_pages_count;
                self.matchesOverview = res.data;
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSelectedMatch(id) {
            self.selectedMatch = id;
        },
        setActiveTab(tabName) {
            self.activeTab = tabName;
        },
        selectMatchOverview(id) {
            self.selectedMatchOverview = id;
        },
        setDataLoaded() {
            self.dataLoaded = true;
        },
        getMatchTime(id) {
            let match = self.matches.find((match) => match.id === id);
            if (match.date) {
                return formatTime(match.date);
            } else {
                return '';
            }
        },
        getMatchDate(id) {
            let match = self.matches.find((match) => match.id === id);
            if (match.date) {
                return formatDate(match.date);
            } else {
                return 'Not set';
            }
        },
    }));

export default MatchesOverviewStore;
