import React, { useState, useEffect } from 'react';
import translations from '../../translations/de';
import { useParams } from 'react-router-dom';
import clearObjProps from '../../helpers/clearObjProps';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import getNotificationFormInput from './getNotificationFormInput';
import { useHistory } from 'react-router-dom';
import ItemImage from '../../components/ItemImage';

const Notification = ({ store }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resetImageUrl, setResetImageUrl] = useState('');
    const history = useHistory();
    const { notification_id } = useParams();
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        !store.selectedNotification && store.getNotification(notification_id);
        return () => {
            store.selectNotification(undefined);
        };
    }, [store, notification_id]);

    const notificationModel = clearObjProps({ ...store.selectedNotification }, [
        'id',
        'recipients',
        'totalRecipeintsPages',
        'selectedRecipeintsPage',
        'type',
    ]);

    const submitHandler = (e) => {
        setLoading(true);
        store
            .editNotification({ ...e })
            .then(() => {
                setLoading(false);
                setEdit(false);
                history.push(`/notifications/${store.selectedNotification.id}`);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedNotification ? (
        <>
            <Item
                links={[{ path: '/notifications', name: translations.notifications }]}
                name={store.selectedNotification.title}
                id={`[ notification_ID: ${store.selectedNotification.id}]`}
                itemModel={{ ...notificationModel }}
                edit={edit}
                onSubmit={submitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getNotificationFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        resetValue: (name, value) => setResetImageUrl({ [name]: value }),
                    })
                }
                resetProp={resetImageUrl}
            >
                <ItemImage imgSrc={store.selectedNotification.image} />
            </Item>
        </>
    ) : null;
};

export default observer(Notification);
