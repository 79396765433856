import { types } from 'mobx-state-tree';
import Reward from '../rewardsStore/Reward';

const AcquiredItem = types.model('AcquiredItem', {
    id: types.identifierNumber,
    is_used: types.boolean,
    reward: types.frozen(Reward),
});

export default AcquiredItem;
