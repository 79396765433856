import api from './apiLibrary';

const API = {
    getAllRewards(params) {
        return api.get('/api/admin/reward', params);
    },
    getReward(id) {
        return api.get(`/api/admin/reward/${id}`);
    },
    getRewardTemplates(params) {
        return api.get('/api/admin/reward/templates', params);
    },
    createReward(data, params) {
        return api.post(`/api/admin/reward`, data, params);
    },
    editReward(id, data) {
        return api.put(`/api/admin/reward/${id}`, data);
    },
    deleteReward(id) {
        return api.delete(`/api/admin/reward/${id}`);
    },
    getWinners(reward_id, params) {
        return api.get(`/api/admin/acquired-item?reward_id=${reward_id}`, params);
    },
    getOrders(params) {
        return api.get(`/api/admin/voucher/order-ids`, params);
    },
    getRecieverOptions(params) {
        return api.get(`/api/admin/reward/users-by-pass-count`, params);
    },
};

export default API;
