import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams } from 'react-router-dom';
import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import clearObjProps from '../../helpers/clearObjProps';
import translations from '../../translations/de';
import Paths from '../../constants/paths';

const Sector = ({ store }) => {
    const { sector_id } = useParams();
    const SectorModel = clearObjProps({ ...store.selectedSector }, []);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !store.selectedSector && store.getSector(sector_id);
    }, [store, sector_id]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editSector({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedSector ? (
        <>
            <Item
                links={[{ path: Paths.SECTORS, name: translations.sectors }]}
                name={store.selectedSector.title}
                itemModel={SectorModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ name, register, error, disabled, index }) =>
                    name === 'title' && (
                        <Input
                            key={index}
                            label={translateLabel(name)}
                            name={name}
                            type="text"
                            {...register(name, {
                                required: true,
                            })}
                            error={error}
                            disabled={disabled}
                        />
                    )
                }
            ></Item>
        </>
    ) : null;
};

export default observer(Sector);
