import api from './apiLibrary';

const API = {
    getEvents(params) {
        return api.get('/api/admin/business-event', params);
    },
    getEvent(id, params) {
        return api.get(`/api/admin/business-event/${id}`, params);
    },
    getUsers(id, params) {
        return api.get(`/api/admin/business-event/users/${id}`, params);
    },
    createEvent(data) {
        return api.post('/api/admin/business-event', data);
    },
    editEvent(id, data) {
        return api.put(`/api/admin/business-event/${id}`, data);
    },
    deleteEvent(id) {
        return api.delete(`/api/admin/business-event/${id}`);
    },
    getInvitations(id, params) {
        return api.get(`/api/admin/business-event/invitations/${id}`, params);
    },
    getAttendees(id, params) {
        return api.get(`/api/admin/business-event/attendees/${id}`, params);
    },
    getParticipants(id, params) {
        return api.get(`/api/admin/business-event/participants/${id}`, params);
    },
};

export default API;
