import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import Select from '../../components/Select';
import translations from '../../translations/de';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/contact';
import countries from '../../constants/countries';

export default function getContactFormInput({
    value,
    name,
    register,
    control,
    error,
    disabled,
    index,
    users,
    searchQueryHandler,
    bottomReached,
    onBlur,
    isLoading,
}) {
    if (name === 'birthday') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                error={error}
                control={control}
                disabled={disabled}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'user_id') {
        return (
            <Select
                key={index}
                onInputChange={searchQueryHandler}
                name={name}
                label={translateLabel(translations.user)}
                options={users.map((user) => {
                    return {
                        value: user.id,
                        label: `${user.first_name} ${user.last_name} [${user.email}]`,
                    };
                })}
                placeholder={translations.type_to_search_users}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg={translations.no_users_found}
                isClearable
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                bottomReached={bottomReached}
                onBlur={onBlur}
                isLoading={isLoading}
            />
        );
    } else if (name === 'country') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name="country"
                options={countries}
                control={control}
                error={error}
                disabled={disabled}
            />
        );
    } else if (typeof value === 'string' || typeof value === 'number') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
