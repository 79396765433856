import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, PieChart, Pie, LabelList } from 'recharts';
import API from '../../api/matches';
import Loader from '../../components/Loader';
import { chartFormat } from '../../helpers/formatDate';
import translations from '../../translations/de';
import './charts.scss';
import { useHistory } from 'react-router-dom';
import InputSeason from '../../components/InputSeason/InputSeason';

const Graph = () => {
    const [passStats, setPassStats] = useState([]);
    const [gameStats, setGameStats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState(null);

    const history = useHistory();
    const renderLabel = (payload) => {
        return payload + '%';
    };

    function CustomTooltipPass({ payload }) {
        var pass = payload[0]?.payload?.payload;
        return (
            <div className="tooltip-wrapper">
                {pass?.visited_bracket !== 0 ? (
                    <p>{`${pass?.passes_used} passes visited ${pass?.visited_bracket}% of played games.`}</p>
                ) : (
                    <p>{`${pass?.passes_used} passes never visited any game.`}</p>
                )}
            </div>
        );
    }

    const CustomTooltipGame = ({ payload, active, label }) => {
        if (payload?.length > 0) {
            var game = payload[0]?.payload;
            console.log('this is payload', payload);
            if (game) {
                return (
                    <div className="custom-tooltip-pass">
                        <p className="label">{`Date: ${game.uv}`}</p>
                        <p className="label">{`Num of passes : ${game.pv}`}</p>
                    </div>
                );
            }
        }
        return null;
    };

    useEffect(() => {
        setLoading(true);
        async function getGraphStats() {
            try {
                const res = await API.getGraphStats({
                    params: {
                        season_id: selectedSeason,
                    },
                });
                setPassStats(res.data.pass_stats);
                var game_stats = res.data.game_stats;
                setGameStats({
                    min: game_stats.min,
                    max: game_stats.max,
                    average: game_stats.average,
                    stats: game_stats.stats.map((val) => {
                        return { pv: val.visited, uv: chartFormat(val.date), id: val.id };
                    }),
                });
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        selectedSeason && getGraphStats();
    }, [selectedSeason]);

    return (
        <>
            <div style={{ width: '200px' }}>
                <InputSeason
                    selectedSeason={selectedSeason}
                    setSelectedSeason={setSelectedSeason}
                />
            </div>
            <div className="content-wrapper">
                {!loading ? (
                    <div className="charts-wrapper">
                        <div className="chart-holder">
                            {passStats?.stats?.length > 0 ? (
                                <PieChart width={600} height={400} style={{ outline: 'none' }}>
                                    <Pie
                                        animationBegin={0}
                                        animationDuration={800}
                                        style={{ outline: 'none', overflow: 'visible' }}
                                        data={passStats.stats}
                                        dataKey="passes_used"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={130}
                                        fill="#8884d8"
                                        label
                                        transitions
                                    >
                                        <LabelList
                                            data={passStats.stats}
                                            dataKey={`visited_bracket`}
                                            formatter={renderLabel}
                                            position="right"
                                            style={{ fontSize: '10px' }}
                                        />
                                    </Pie>
                                    <Tooltip content={<CustomTooltipPass />} />
                                </PieChart>
                            ) : (
                                <div className="piechart-placeholder">
                                    No statistics data for passes.
                                </div>
                            )}
                        </div>
                        <div className="chart-holder">
                            {gameStats?.length > 0 ? (
                                <BarChart
                                    width={900}
                                    height={300}
                                    cursor={'pointer'}
                                    data={gameStats.stats}
                                    onClick={(e) =>
                                        history.push(`/matches/${e.activePayload[0].payload.id}`)
                                    }
                                >
                                    <XAxis dataKey="uv" tick={{ fontSize: '9px' }} />
                                    <YAxis tick={{ fontSize: '11px' }} />
                                    <Tooltip content={<CustomTooltipGame />} />
                                    <Legend />

                                    <Bar
                                        interval="50"
                                        dataKey="pv"
                                        barSize={24}
                                        fill="red"
                                        name={translations.passes_used}
                                    />
                                </BarChart>
                            ) : (
                                <div className="piechart-placeholder">
                                    No statistics data for games.
                                </div>
                            )}
                        </div>
                        {gameStats?.length > 0 && (
                            <div className="min-max_wrapper">
                                <div className="pass-stats">
                                    <p>Average: {Math.round(passStats.average * 100) / 100}</p>
                                    <p>Min: {passStats.min}</p>
                                    <p>Max: {passStats.max}</p>
                                </div>
                                <div className="game-stats">
                                    <p>Average: {Math.round(gameStats.average * 100) / 100}</p>
                                    <p>Min: {gameStats.min}</p>
                                    <p>Max: {gameStats.max}</p>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Loader size="big" theme="dark" />
                    </div>
                )}
            </div>
        </>
    );
};

export default Graph;
