import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CreateGame from '../../components/CreateGame';
import GameStore from '../../models/fanGamesStore/Game';
import { useParams } from 'react-router-dom';
import gameTypes from '../../constants/seasonGameTypes';
import translations from '../../translations/de';
import Paths from '../../constants/paths';

const NewFanGame = ({ store }) => {
    const { season_id } = useParams();
    const game = GameStore.create({ id: 0 });

    useEffect(() => {
        !store.selectedSeason && store.getSeason(season_id);
    }, [store, season_id]);

    return store.selectedSeason ? (
        <CreateGame
            links={[
                { path: Paths.SEASONS, name: translations.seasons },
                {
                    path: `/seasons/${store.selectedSeason.id}`,
                    name: store.selectedSeason.title,
                },
            ]}
            name={`Seasonal Fan Game ${translations.create}`}
            game={game}
            gameTypes={gameTypes}
            store={store}
        />
    ) : null;
};

export default observer(NewFanGame);
