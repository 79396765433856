import axios from 'axios';

// intercept each response
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (
            error.response.statusCode === 401 &&
            error.response.message === 'UserErrors.TokenExpired'
        ) {
            return Promise.reject(error.response.data).then(() => {
                window.location = '/login';
            });
        }
        return Promise.reject(error.response.data);
    },
);

const apiLibrary = {
    get(address, params) {
        return axios.get(`${address}`, params);
    },
    post(address, data, params) {
        return axios.post(`${address}`, data, params);
    },
    delete(address) {
        return axios.delete(`${address}`);
    },
    patch(address, data, params) {
        return axios.patch(`${address}`, data, params);
    },
    put(address, data) {
        return axios.put(`${address}`, data);
    },
};

export default apiLibrary;
