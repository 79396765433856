import React, { useRef, useEffect } from 'react';
import Item from '../../components/Item';
import { useForm } from 'react-hook-form';
import getAccessFormInput from './getAccessFormInput';
import { FaLongArrowAltRight, FaSms, FaPlus, FaPrint } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Paths from '../../constants/paths';
import { formatDate, formatTime } from '../../helpers/formatDate';
import translations from '../../translations/de';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import SmsForm from './SmsForm';
import translateLabel from '../../helpers/translateLabel';
import openLink from '../../helpers/printTicket';

const AccessForm = ({
    accessDetails,
    accessOwner,
    access = {},
    edit,
    name,
    onSubmit,
    onEdit,
    onCancel,
    error,
    loading,
    selectedItem,
    games,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({
        defaultValues: { ...accessDetails, ...accessOwner },
    });

    const accessDetailsData = Object.keys(accessDetails).map((key) => {
        return { name: 'Games', value: accessDetails[key] };
    });

    const accessOwnerData = Object.keys(accessOwner).map((key) => {
        return { name: key, value: accessOwner[key] };
    });

    const modalRef = useRef(null);
    const [modalResult, setModalResult] = useModal(false, modalRef);

    useEffect(() => {
        reset({ ...accessDetails, ...accessOwner });
    }, [accessDetails, accessOwner, reset]);

    return (
        <>
            <Item
                links={[{ path: Paths.E_TICKETS, name: translations.accesses }]}
                name={name}
                edit={edit}
                onSubmit={handleSubmit(onSubmit)}
                onEdit={onEdit}
                onCancel={() => {
                    reset({ ...accessDetails, ...accessOwner });
                    onCancel();
                }}
                error={error}
                loading={loading}
            >
                <form className="access-form">
                    <div className="row">
                        <div className="col">
                            <div className="segment">
                                <div className="segment-title">{translations.general}</div>
                                <div className="segment-content">
                                    {accessDetailsData.map((data, index) =>
                                        getAccessFormInput({
                                            value: data.value,
                                            name: data.name,
                                            register,
                                            error: errors[data.name],
                                            disabled: !edit,
                                            index,
                                            control,
                                            games,
                                        }),
                                    )}

                                    {!edit && access && (
                                        <>
                                            <p className="link-label">{translations.scanned_at}</p>
                                            <div>
                                                {access.scanned_at
                                                    ? `${formatDate(
                                                          access.scanned_at,
                                                      )}, ${formatTime(access.scanned_at)}`
                                                    : '-'}
                                            </div>
                                            {access.game && (
                                                <>
                                                    <p className="link-label">
                                                        {translations.match}
                                                    </p>
                                                    <Link to={`/matches/${access.game.id}`}>
                                                        <FaLongArrowAltRight />{' '}
                                                        <span>
                                                            {access.game.home_team.title} -{' '}
                                                            {access.game.away_team.title}{' '}
                                                            {formatDate(access.game.date)}
                                                        </span>
                                                    </Link>{' '}
                                                </>
                                            )}
                                            {access.pass.id && (
                                                <>
                                                    <p className="link-label">
                                                        {translations.pass}
                                                    </p>
                                                    <Link to={`/abos/${access.pass.id}`}>
                                                        <FaLongArrowAltRight />{' '}
                                                        <span>{access.pass.assign_token}</span>
                                                    </Link>{' '}
                                                </>
                                            )}
                                            {access.pass.user && (
                                                <>
                                                    <p className="link-label">
                                                        {translations.user}
                                                    </p>
                                                    <Link to={`/fans/${access.pass.user.id}`}>
                                                        <FaLongArrowAltRight />{' '}
                                                        <span>{`${access.pass.user.first_name} ${access.pass.user.last_name}`}</span>
                                                    </Link>{' '}
                                                </>
                                            )}
                                            <p className="link-label">{translations.seat}</p>
                                            <div>
                                                Entrance:{' '}
                                                {access?.pass?.seat?.seat_group?.sector?.title}{' '}
                                                <br />
                                                Block: {access.pass.seat.seat_group.title} <br />
                                                Row: {access.pass.seat.row} <br />
                                                Seat: {access.pass.seat.num} <br />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            {!edit && (
                                <div className="segment">
                                    <div className="segment-title">{translations.actions}</div>
                                    <div className="segment-content actions">
                                        <Button label="Add Lap Child" type="button">
                                            <FaPlus style={{ marginRight: '5px' }} />
                                        </Button>
                                        <Button
                                            label={`${translations.send} SMS`}
                                            type="button"
                                            onClick={() => setModalResult(true)}
                                        >
                                            <FaSms style={{ marginRight: '5px' }} />
                                        </Button>
                                        <Button
                                            label={`${translations.print_ticket}`}
                                            type="button"
                                            onClick={() =>
                                                openLink(
                                                    `https://my.scb.ch/eticket/${selectedItem.access_token}`,
                                                    true,
                                                )
                                            }
                                        >
                                            <FaPrint style={{ marginRight: '5px' }} />
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col">
                            <div className="segment">
                                <div className="segment-title">{translations.access_owner}</div>
                                <div className="segment-content">
                                    {accessOwnerData.map((data, index) =>
                                        getAccessFormInput({
                                            name: data.name,
                                            register,
                                            error: errors[data.name],
                                            disabled: !edit,
                                            index,
                                            control,
                                        }),
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Item>
            {modalResult && (
                <Modal
                    title={translateLabel('send_sms')}
                    ref={modalRef}
                    onClose={() => setModalResult(false)}
                    maxWidth="500px"
                >
                    <SmsForm access={selectedItem} showModal={(e) => setModalResult(e)} />
                </Modal>
            )}
        </>
    );
};

export default AccessForm;
