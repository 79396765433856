import { types, flow } from 'mobx-state-tree';
import API from '../../api/seats';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import Seat from './Seat';
import SeatGroup from './../SeatGroupsStore/SeatGroup';
import Sector from './../sectorsStore/Sector';

const SeatsStore = types
    .model('SeatsStore', {
        seats: types.array(Seat),
        sectors: types.array(Sector),
        seatGroups: types.array(SeatGroup),
        selectedSeat: types.maybe(types.reference(Seat)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getSeats: flow(function* getSeats(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getSeats({
                    params: {
                        search: searchQuery,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.seats = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        getSectors: flow(function* getSectors() {
            self.error = '';
            try {
                const res = yield API.getSectors();
                self.sectors = res.data.items;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        getSeatGroups: flow(function* getSeatGroups() {
            self.error = '';
            try {
                const res = yield API.getSeatGroups();
                self.seatGroups = res.data.items;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectSeat(id) {
            self.selectedSeat = id;
        },
        addSeat: flow(function* addSeat(newSeat) {
            try {
                const res = yield API.addSeat(newSeat);
                self.seats.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        editSeat: flow(function* editSeat(edited) {
            try {
                const res = yield API.editSeat(self.selectedSeat.id, edited);
                self.selectedSeat.editSeat(res.data);
            } catch (error) {
                throw error;
            }
        }),
        getSeat: flow(function* getSeat(id) {
            self.error = '';
            try {
                if (!self.selectedSeat) {
                    const res = yield API.getSeat(id);
                    self.dataLoaded = true;
                    self.seats = [];
                    self.seats.push(res.data);
                    self.selectedSeat = res.data.id;
                }
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        deleteSeat: flow(function* deleteSeat(id) {
            try {
                yield API.deleteSeat(id);
                const index = self.seats.findIndex((s) => s.id === id);
                self.seats.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default SeatsStore;
