import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import InputFile from '../../components/InputFile/index.js';
import Select from '../../components/Select';
import { shootSide, position } from '../../constants/playerConts';
import translations from '../../translations/de';
import DatePicker from '../../components/DatePicker';
import Checkbox from '../../components/Checkbox';
import mandatoryFields from '../../configs/player';

export default function getPlayerFormInput({
    value,
    name,
    register,
    error,
    disabled,
    index,
    resetValue,
    control,
    team_acronym,
    teams,
}) {
    if (name === 'avatar') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder={`uploads/teams/${team_acronym}/players`}
            />
        );
    } else if (name === 'team_id') {
        return (
            <Select
                key={index}
                label={translateLabel(name.replace('_id', ''))}
                name={name}
                options={teams.map((team) => {
                    return {
                        value: team.id,
                        label: team.title,
                        // label: (
                        //     <div style={{ display: 'flex', alignItems: 'center' }}>
                        //         <img
                        //             style={{
                        //                 width: '30px',
                        //                 height: '30px',
                        //                 marginRight: '5px',
                        //             }}
                        //             src={team?.logo_url}
                        //             alt="team_logo"
                        //         />
                        //         {team?.title}
                        //     </div>
                        // ),
                    };
                })}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg="No teams"
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                isClearable
            />
        );
    } else if (name === 'shot_side') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name={name}
                options={shootSide}
                control={control}
                error={error}
                disabled={disabled}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'position') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name={name}
                options={position}
                control={control}
                error={error}
                disabled={disabled}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'date_of_birth') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                defaultValue={new Date(value).setHours(6, 0, 0, 0)}
                control={control}
                disabled={disabled}
                error={error}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'height' || name === 'weight' || typeof value === 'number') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'external_player_id') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="number"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (typeof value === 'boolean') {
        return (
            <Checkbox
                key={index}
                disabled={disabled}
                text={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
