import { useState, useEffect } from 'react';
import API from '../api/teams';

const useStaffData = () => {
    const [staffRoles, setStaffRoles] = useState([]);
    const [staffSection, setStaffSection] = useState([]);
    const [staffCount, setStaffCount] = useState(0);

    useEffect(() => {
        const getStaffValues = async () => {
            try {
                const res = await API.getStaffValues();
                setStaffRoles(
                    Object.entries(res?.data?.roles).map(([value, label], index) => ({
                        key: index,
                        label: label,
                        value: label,
                    })),
                );
                setStaffSection(
                    Object.entries(res?.data?.sections).map(([value, label], index) => ({
                        key: index,
                        label: label,
                        value: label,
                    })),
                );
                setStaffCount(res?.data?.count);
            } catch (e) {
                console.log(e);
            }
        };
        getStaffValues();
    }, []);
    return [staffRoles, staffSection, staffCount];
};

export default useStaffData;
