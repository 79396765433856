const filterObject = (obj = {}, keysToReturn = []) =>
    keysToReturn.reduce(
        (accumulator, value) => ({
            ...accumulator,
            ...(obj.hasOwnProperty(value) ? { [value]: obj[value] } : {}),
        }),
        {},
    );

export default filterObject;
