import api from './apiLibrary';

const API = {
    getTeams(params) {
        return api.get('/api/admin/team', params);
    },
    getTeam(id, params) {
        return api.get(`/api/admin/team/${id}`, params);
    },
    createTeam(data) {
        return api.post('/api/admin/team', data);
    },
    editTeam(id, data) {
        return api.put(`/api/admin/team/${id}`, data);
    },
    /// player
    getPlayer(id, params) {
        return api.get(`/api/admin/player/${id}`, params);
    },
    getOwnerPlayers() {
        return api.get('/api/admin/player/owner');
    },
    createPlayer(data) {
        return api.post('/api/admin/player', data);
    },
    editPlayer(id, data) {
        return api.put(`/api/admin/player/${id}`, data);
    },
    getOwnersPlayers() {
        return api.get('/api/admin/player/owner');
    },
    deletePlayer(id) {
        return api.delete(`/api/admin/player/${id}`);
    },
    revivePlayer(id) {
        return api.put(`/api/admin/player/${id}/revive`);
    },
    /// staff
    getAllStaff(team_id, params) {
        return api.get(`/api/admin/staff?team_id=${team_id}`, params);
    },
    createStaff(data) {
        return api.post('/api/admin/staff', data);
    },
    getStaffMember(id, data) {
        return api.get(`/api/admin/staff/${id}`, data);
    },
    editStaff(id, data) {
        return api.put(`/api/admin/staff/${id}`, data);
    },
    deleteStaff(id) {
        return api.delete(`/api/admin/staff/${id}`);
    },
    reviveStaff(id) {
        return api.put(`/api/admin/staff/${id}/revive`);
    },
    getStaffValues() {
        return api.get(`/api/admin/player/staff/values`);
    },
};

export default API;
