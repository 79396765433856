import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Item from '../../components/Item';
import Paths from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import translations from '../../translations/de';
import getNewsFormInput from './getNewsFormInput';

const CreateNews = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    const [resetProp, setResetProp] = useState(null);

    store.setDataLoaded();
    return (
        <Item
            links={[{ path: Paths.NEWS, name: translations.news }]}
            name={translations.new_news}
            itemModel={{
                title: '',
                image: '',
                description: '',
                link: '',
                publishing_date: '',
                category: '',
                content: '',
            }}
            edit={true}
            onSubmit={(event) => {
                error && setError(null);
                setLoading(true);
                store
                    .createNews(event)
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.NEWS);
                    })
                    .catch((error) => {
                        setError(error);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, disabled, index }) =>
                getNewsFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setResetProp({ [name]: value }),
                })
            }
            resetProp={resetProp}
        />
    );
};

export default observer(CreateNews);
