import React, { useState, useContext } from 'react';
import translations from '../../translations/de.js';

import Button from '../../components/Button/index.js';
import Logo from './../../assets/images/logo/scb-logo.png';
import ReactCodeInput from 'react-code-input';
import './style.scss';
import { ResetPasswordContext } from './index.js';
import API from '../../api/auth';
import ErrorMessages from '../../components/ErrorMessages.js/index.js';

const ResetCodeInput = () => {
    const { email, code, setCode, nextStep, resetFlow } = useContext(ResetPasswordContext);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCode = async () => {
        try {
            const res = await API.verifyCode({ email: email, code: code });
            res.status === 201 && nextStep();
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    return (
        <div className="Login">
            <div className="Login-Container">
                <div className="logo">
                    <img src={Logo} alt="SCB Logo" />
                </div>
                <h2>{translations.app_name}</h2>
                <ReactCodeInput type="text" fields={6} onChange={(e) => setCode(e)} />
                <ErrorMessages messages={errorMessage} />
                <div style={{ marginTop: '35px' }} />
                <Button label={translations.send} fullwidth onClick={() => handleCode()} />
                <br />
                <Button
                    theme="gray"
                    label={translations.cancel}
                    fullwidth
                    onClick={() => resetFlow()}
                />
            </div>
        </div>
    );
};

export default ResetCodeInput;
