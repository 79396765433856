import { useState, useEffect } from 'react';
import getRewardInput from './getRewardInput';
import Button from '../Button';
import { observer } from 'mobx-react-lite';
import { useForm, useWatch } from 'react-hook-form';
import ErrorMessages from '../ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import Breadcrumb from '../Breadcrumb';
import { useHistory, useParams } from 'react-router-dom';
import FindRewardTemplate from '../FindRewardTemplate';
import { createRewardClearFields } from '../../helpers/createRewardClearFields';
import debounce from 'lodash.debounce';
import API from '../../api/rewards';

import { handleVoucher } from '../../pages/Rewards/NewReward.js';

const Reward = ({ rewardStore }) => {
    const history = useHistory();
    const { fan_game_id } = useParams();
    const [searchOrders, setSearchOrders] = useState('');
    const [orders, setOrders] = useState([]);
    const [voucherOrderID, setVoucherOrderID] = useState('');
    const [orderID, setOrderID] = useState('');

    const rewardObj = {
        image_url: '',
        name: '',
        redeem_description: '',
        popup_title: '',
        fan_game_id: Number(fan_game_id),
        popup_text: '',
        description: null,
        quantity: 0,
        expire_at: '',
        type: 'avatar',
        voucher_type: null,
        price_in_cents: 0,
        // special prop for deleting
        importCodesField: voucherOrderID,
        //
        order_id: orderID,
        fan_coins: 0,
        sponsor_link: null,
        use_unused: false,
    };

    var reward = Object.keys(rewardObj).map((key) => {
        return { name: key, value: rewardObj[key] };
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [resetProp, setResetProp] = useState(null);
    const [type, setType] = useState('avatar');
    const [voucherType, setVoucherType] = useState(null);
    const [disableVoucherFields, setDisableVoucherFields] = useState(false);

    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        watch,
        getValues,
    } = useForm({
        defaultValues: rewardObj,
    });

    let priceInCents = useWatch({ control, name: 'price_in_cents' });

    const onSave = async (event) => {
        setLoading(true);
        delete event.importCodesField;
        const formData = createRewardClearFields({
            ...event,
            order_id: orderID !== '' ? orderID?.value : event.order_id,
            use_unused: disableVoucherFields ? false : event.use_unused,
            type: disableVoucherFields ? 'consumable' : event.type,
        });

        try {
            await rewardStore.selectedGame.addReward(formData);
            setLoading(false);
            history.goBack();
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    const handleTypeChange = (e) => {
        var formValues = getValues();
        switch (e) {
            case 'consumable':
                reset({ ...formValues, fan_coins: 0 });
                break;
            case 'coins':
                unregister('voucher_type');
                reset({ ...formValues, fan_coins: 0 });
                break;
            default:
                reset({ ...formValues, fan_coins: 0 });
                unregister('voucher_type');
        }
        setType(e);
    };

    const searchOrdersHandler = debounce((value) => {
        setSearchOrders(value);
    }, 500);

    const handleOrder = (e) => {
        if (e) {
            reset({
                ...getValues(),
                type: 'consumable',
                fan_coins: 0,
                price_in_cents: 0,
                use_unused: false,
                sponsor_link: '',
            });
            setType('consumable');
            setDisableVoucherFields(true);
        } else {
            reset({
                ...getValues(),
                fan_coins: 0,
                use_unused: false,
            });
            setDisableVoucherFields(false);
        }
    };

    useEffect(() => {
        const getOrders = async () => {
            try {
                const res = await API.getOrders({
                    params: { search: searchOrders, voucher_type: voucherType },
                });
                setOrders(res.data);
            } catch (e) {
                console.error(e);
            }
        };
        searchOrders !== '' && getOrders();
    }, [searchOrders, voucherType]);

    useEffect(() => {
        if (resetProp) {
            reset({ ...watch(), ...resetProp });
        }
    }, [resetProp, reset, watch]);

    return (
        <div className="Item">
            <div className="Item-Header">
                <Breadcrumb
                    name={'New Reward'}
                    links={[
                        { path: Paths.FAN_GAMES, name: translations.fan_games },
                        {
                            path: `${Paths.FAN_GAMES}/${fan_game_id}`,
                            name: rewardStore?.selectedGame?.name,
                        },
                    ]}
                />
                <Button
                    style={{ marginLeft: '10px' }}
                    label={translations.save}
                    type="submit"
                    onClick={handleSubmit((e) => onSave(e))}
                    loading={loading}
                />
            </div>
            <div style={{ paddingTop: '2%' }} />

            <div className="Item-Content">
                <div style={{ width: '49%' }}>
                    <FindRewardTemplate
                        setResetProp={setResetProp}
                        rewardObj={rewardObj}
                        setType={(e) => setType(e)}
                        isDisabled={disableVoucherFields}
                    />
                </div>
                <form>
                    {rewardObj.length !== 0 &&
                        reward.map((data, index) =>
                            getRewardInput({
                                disabled: false,
                                value: data.value,
                                name: data.name,
                                register,
                                control,
                                error: errors[data.name],
                                index,
                                searchOrdersHandler,
                                resetValue: (name, value) => setResetProp({ [name]: value }),
                                resetVoucherValue: (name, value) => {
                                    setVoucherOrderID({ [name]: value });
                                    setResetProp({ [name]: value });
                                },
                                handleTypeChange: (e) => handleTypeChange(e),
                                handleVoucherTypeChange: (e) =>
                                    handleVoucher(
                                        e,
                                        setOrders,
                                        setVoucherType,
                                        getValues,
                                        reset,
                                        () => setDisableVoucherFields(false),
                                        setOrderID,
                                        setVoucherOrderID,
                                    ),
                                voucherType,
                                orderID,
                                setOrderID: (value) => setOrderID(value),
                                type,
                                orders,
                                handleOrder: (e) => handleOrder(e),
                                disableVoucherFields,
                                price_in_cents: priceInCents,
                            }),
                        )}

                    <ErrorMessages
                        messages={requsetErrorMessage(
                            error && error.message ? error.message : error,
                        )}
                    />
                </form>
            </div>
        </div>
    );
};

export default observer(Reward);
