import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import getVouchersFormInput from './getVouchersFormInput';
import rewardsAPI from '../../api/rewards';
import shopItemsAPI from '../../api/shopItems';
import debounce from 'lodash.debounce';
import Select from '../../components/Select';
import clearObjProps from '../../helpers/clearObjProps';

const NewVoucher = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shopItems, setShopItems] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [searchShopItems, setSearchShopItems] = useState('');
    const [searchRewards, setSearchRewards] = useState('');
    const [type, setType] = useState(''); // shop-item, reward, none
    const [voucherType, setVoucherType] = useState(null);
    const [resetProp, setResetProp] = useState(null);
    const [selectedItem, setSelectedItem] = useState(false);
    const [orderID, setOrderID] = useState(null);

    const searchRewardsHandler = debounce((value) => {
        setSearchRewards(value);
    }, 500);

    const searchShopItemsHandler = debounce((value) => {
        setSearchShopItems(value);
    }, 500);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    useEffect(() => {
        const getRewards = async () => {
            try {
                const res = await rewardsAPI.getAllRewards({
                    params: {
                        search: searchRewards,
                    },
                });
                setRewards(res.data.items);
            } catch (error) {
                console.log('Error in getting rewards from server');
            }
        };
        searchRewards && getRewards();
    }, [searchRewards]);

    useEffect(() => {
        const getShopItems = async () => {
            try {
                const res = await shopItemsAPI.getShopItems({
                    params: {
                        search: searchShopItems,
                    },
                });
                setShopItems(res.data.items);
            } catch (error) {
                console.log('Error in getting shop items from server');
            }
        };
        searchShopItems && getShopItems();
    }, [searchShopItems]);

    useEffect(() => {
        if (type === '') {
            setResetProp({ shop_item_id: null, reward_id: null });
        } else if (type === 'reward') {
            setResetProp({ shop_item_id: null });
        } else if (type === 'shop_item') {
            setResetProp({ reward_id: null });
        }
    }, [type]);

    return (
        <>
            <Item
                links={[{ path: '/vouchers', name: translations.vouchers }]}
                name={`${translations.vouchers} ${translations.create}`}
                itemModel={{
                    quantity: 0,
                    voucher_type: 'internal',
                    price_in_cents: 0,
                    shop_item_id: type === 'shop_item' ? '' : null,
                    reward_id: type === 'reward' ? '' : null,
                    order_id: '',
                }}
                watchedProperties={['voucher_type']}
                watchedPropertyActions={{
                    voucher_type: setVoucherType,
                }}
                edit={true}
                onSubmit={(event) => {
                    setLoading(true);
                    error && setError(null);
                    store
                        .addVoucher({
                            ...clearObjProps(event),
                            price_in_cents:
                                voucherType === 'eguma' ? Number(event.price_in_cents) : 0,
                            order_id: orderID,
                        })
                        .then(() => {
                            setLoading(false);
                            history.push(Paths.VOUCHERS);
                        })
                        .catch((err) => {
                            setError(err);
                            setLoading(false);
                        });
                }}
                onCancel={() => history.goBack()}
                error={error}
                loading={loading}
                determineInputType={({
                    value,
                    name,
                    register,
                    error,
                    isDisabled,
                    index,
                    control,
                    reset,
                    getValues,
                }) =>
                    getVouchersFormInput({
                        value,
                        name,
                        register,
                        error,
                        isDisabled,
                        index,
                        control,
                        searchRewardsHandler,
                        searchShopItemsHandler,
                        rewards,
                        shopItems: shopItems,
                        type,
                        voucherType,
                        reset,
                        getValues,
                        selectedItem,
                        setSelectedItem,
                        orderID,
                        setOrderID,
                    })
                }
                resetProp={resetProp}
            >
                <Select
                    label={`${translations.type.toUpperCase()}`}
                    isSearchable={false}
                    defaultValue={{ label: 'None', value: '' }}
                    options={[
                        { label: 'None', value: '' },
                        { label: 'Reward', value: 'reward' },
                        { label: 'Shop Item', value: 'shop_item' },
                    ]}
                    onChange={(e) => {
                        setType(e.value);
                    }}
                    style={{ width: '100%' }}
                />
            </Item>
        </>
    );
};

export default observer(NewVoucher);
