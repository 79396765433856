import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import Seasons from './Seasons';
import Season from './Season';
import NewSeason from './NewSeason';
import NewFanGame from './NewFanGame';
import FanGame from './FanGame';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const SeasonsTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.SEASONS}>
                <Seasons store={store} />
            </Route>
            <Route exact path={Paths.SEASON}>
                <Season store={store} />
            </Route>
            <Route exact path={Paths.NEW_SEASON}>
                <NewSeason store={store} />
            </Route>
            <Route exact path={Paths.SEASON_NEW_FAN_GAME}>
                <NewFanGame store={store} />
            </Route>
            <Route exact path={Paths.SEASON_FAN_GAME}>
                <FanGame store={store} />
            </Route>
        </Page>
    );
};

export default observer(SeasonsTab);
