import { useState, useEffect, useRef } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import getInstantRewardsFormInput from './getInstantRewardsFormInput';
import UsersTable from './UsersTable';
import translations from '../../translations/de';
import API from '../../api/users';
import APIRewards from '../../api/rewards';
import Input from '../../components/Input';
import debounce from 'lodash.debounce';
import translateLabel from '../../helpers/translateLabel';
import Button from '../../components/Button';
import NewVoucherPreview from '../../components/VoucherPreview/NewVoucherPreview';

import './style.scss';

const NewInstantReward = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchOrders, setSearchOrders] = useState('');
    const [orders, setOrders] = useState([]);
    const [disableVoucherFields, setDisableVoucherFields] = useState(false);
    const [showSingleVoucherCodeField, setShowSingleVoucherCodeField] = useState(false);
    const [shippingOnCreate, setShippingOnCreate] = useState(false);
    //field for tracking
    const [voucherType, setVoucherType] = useState(null);
    const [priceInCents, setPriceInCents] = useState(null);
    const [voucherOrderID, setVoucherOrderID] = useState('');
    const [orderID, setOrderID] = useState('');
    const [receiverOptions, setRecieverOptions] = useState([]);
    const [receiverCount, setReceiverCount] = useState(0);
    const [selectedReceiver, setSelectedReceiver] = useState('');
    //  preview screen
    const [showPreview, setShowPreview] = useState(false);
    const eventViewRef = useRef(null);

    //  new states for preview
    const [name, setName] = useState('');
    const [expireAt, setExpireAt] = useState('');
    const [description, setDescription] = useState('');
    const [redeemDescription, setRedeemDescription] = useState('');
    const [singleCode, setSingleCode] = useState('');
    const [image, setImage] = useState('');
    const [link, setLink] = useState('');
    // const handlePreviewChanges = (e) => {
    //     setPreviewValues((prevValues) => ({ ...prevValues, [e.target]: e.value }));
    // };

    const onSearchChangeHandler = debounce((e) => {
        setPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    const searchOrdersHandler = debounce((value) => {
        setSearchOrders(value);
    }, 500);

    const handleVoucher = (e, getValues, reset) => {
        if (e) {
            setOrders([]);
            setOrderID('');
            setVoucherOrderID('');
            var values = {
                ...getValues(),
                order_id: null,
                importCodesField: '',
            };
            reset(values);
        }
        setVoucherType(e);
    };

    const handleOrder = (e, getValues, reset) => {
        if (e) {
            reset({
                ...getValues(),
                fan_coins: 0,
                price_in_cents: 0,
                use_unused: false,
                sponsor_link: '',
            });
            setDisableVoucherFields(true);
        } else {
            reset({
                ...getValues(),
                fan_coins: 0,
                use_unused: false,
            });
            setDisableVoucherFields(false);
        }
    };

    const handleReceiverCount = (e) => {
        if (e === null) {
            setSelectedReceiver('');
        } else {
            setSelectedReceiver(e);
        }

        let obj = receiverOptions.filter((option) => option.value === e);
        obj.length !== 0 && setReceiverCount(obj[0].count);
        if (e === '') {
            setReceiverCount(0);
        }
    };

    useEffect(() => {
        const getOrders = async () => {
            try {
                const res = await APIRewards.getOrders({
                    params: { search: searchOrders, voucher_type: voucherType },
                });
                setOrders(res.data);
            } catch (e) {
                console.error(e);
            }
        };
        searchOrders !== '' && getOrders();
    }, [searchOrders, voucherType]);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
        return () => {
            store.setSendToAll(false);
        };
    }, [store]);

    useEffect(() => {
        const getProfiles = async () => {
            try {
                const res = await API.getUsers({
                    params: {
                        page,
                        search: searchQuery ? searchQuery : undefined,
                    },
                });
                setUsers(res.data.items);
                setTotalPages(res.data.total_pages_count);
            } catch (error) {
                console.log(error);
            }
        };
        getProfiles();
    }, [store, page, searchQuery]);

    useEffect(() => {
        const getRecieverCount = async () => {
            try {
                const res = await APIRewards.getRecieverOptions();
                setRecieverOptions(
                    Object.entries(res.data).map(([label, value], index) => ({
                        key: { index },
                        label: translateLabel(label),
                        value: label,
                        count: value,
                    })),
                );
            } catch (e) {
                console.log(e);
            }
        };
        getRecieverCount();
    }, []);

    return (
        <>
            <Item
                links={[{ path: '/instant-rewards', name: translations.vouchers }]}
                name={`${translations.voucher} ${translations.create}`}
                itemModel={{
                    name: '',
                    image_url: imageUrl,
                    redeem_description: '',
                    popup_title: '',
                    description: '',
                    popup_text: '',
                    expire_at: '',
                    voucher_type: null,
                    sponsor_link: null,
                    // new fields
                    receiver: null, /// all, season ticket, no season ticket
                    number_of_recipients: receiverCount,
                    //  title above this Codes
                    single_voucher_check: false,
                    shared_code: '',
                    importCodesField: voucherOrderID,
                    price_in_cents: 0,
                    order_id: orderID,
                    //  title above this - versand:
                    shipping_check: false,
                    schedule_date: '',
                    //title above this - Push
                    push_check: true,
                    assign_after_delivery: false,
                }}
                watchedProperties={[
                    'voucher_type',
                    'price_in_cents',
                    'single_voucher_check',
                    'shipping_check',
                    'receiver',
                    ///props for preview
                    'name',
                    'description',
                    'expire_at',
                    'shared_code',
                    'image_url',
                    'sponsor_link',
                    'redeem_description',
                ]}
                watchedPropertyActions={{
                    voucher_type: (e) => setVoucherType(e),
                    price_in_cents: (e) => setPriceInCents(e),
                    single_voucher_check: (e) => setShowSingleVoucherCodeField(e),
                    shipping_check: (e) => setShippingOnCreate(e),
                    receiver: (e) => handleReceiverCount(e),
                    ///props for preview
                    name: (e) => setName(e),
                    description: (e) => setDescription(e),
                    expire_at: (e) => setExpireAt(e),
                    shared_code: (e) => setSingleCode(e),
                    image_url: (e) => setImage(e),
                    sponsor_link: (e) => setLink(e),
                    redeem_description: (e) => setRedeemDescription(e),
                }}
                insertDummyFieldAt={[9, 13, 19]}
                edit={true}
                onSubmit={(event) => {
                    setLoading(true);
                    error && setError(null);
                    if (store.recipients.length === 0 && selectedReceiver === '') {
                        setError('Recipents list cannot be empty!');
                        setLoading(false);
                        return;
                    } else if (event.schedule_date > event.expire_at) {
                        setError(translations.delayed_delivery_error_1);
                        setLoading(false);
                    } else {
                        const receiverMapping = {
                            all_users: 'all',
                            seasonal_ticket_holders: 'to_season_pass_holders',
                            non_seasonal_ticket_holders: 'to_non_season_pass_holders',
                        };

                        if (receiverMapping[selectedReceiver]) {
                            event[receiverMapping[event.receiver]] = true;
                        }
                        var send_notification = event.push_check;

                        // List of properties to delete
                        const propertiesToDelete = [
                            'single_voucher_check',
                            'importCodesField',
                            'number_of_recipients',
                            'send_notification',
                            'receiver',
                            'shipping_check',
                            'push_check',
                        ];

                        propertiesToDelete.forEach((prop) => delete event[prop]);

                        // Prepare the final event object
                        const finalEvent = {
                            ...event,
                            order_id: orderID !== '' ? orderID?.value : event.order_id,
                            price_in_cents:
                                event.voucher_type === 'eguma' ? Number(event.price_in_cents) : 0,
                            use_unused: disableVoucherFields ? false : event.use_unused,
                            shared_code: showSingleVoucherCodeField ? event.shared_code : null,
                            schedule_date: shippingOnCreate ? null : event.schedule_date,
                        };
                        store
                            .addInstantReward(finalEvent, send_notification)
                            .then(() => {
                                setLoading(false);
                                history.push(Paths.INSTANT_REWARDS);
                            })
                            .catch((err) => {
                                setError(err);
                                setLoading(false);
                            });
                    }
                }}
                onCancel={() => history.goBack()}
                error={error}
                loading={loading}
                determineInputType={({
                    value,
                    name,
                    register,
                    error,
                    isDisabled,
                    index,
                    control,
                    getValues,
                    reset,
                }) =>
                    getInstantRewardsFormInput({
                        value,
                        name,
                        register,
                        error,
                        isDisabled,
                        index,
                        resetImageValue: (name, value) =>
                            setImageUrl((prevImageUrl) => ({ ...prevImageUrl, [name]: value })),
                        resetVoucherValue: (name, value) =>
                            setVoucherOrderID((prevVoucher) => ({ ...prevVoucher, [name]: value })),
                        control,
                        voucherType,
                        voucherOrderID,
                        setOrderID: (value) => setOrderID(value),
                        orderID: orderID,
                        searchOrdersHandler,
                        handleOrder: (e) => handleOrder(e, getValues, reset),
                        orders,
                        disableVoucherFields,
                        handleVoucherTypeChange: (e) => handleVoucher(e, getValues, reset),
                        price_in_cents: priceInCents,
                        showSingleVoucherCodeField,
                        shippingOnCreate,
                        receiverOptions,
                    })
                }
                resetProp={[voucherOrderID, imageUrl]}
            >
                {' '}
            </Item>
            <div className="voucher-preview-wrapper">
                <Button
                    theme="gray"
                    title="Show preview Screens"
                    disabled={voucherType === null || voucherType === ''}
                    label="Show preview Screens"
                    onClick={() => setShowPreview(!showPreview)}
                />
                {showPreview && (
                    <NewVoucherPreview
                        ref={eventViewRef}
                        onClose={() => setShowPreview(!showPreview)}
                        name={name}
                        image={image}
                        description={description}
                        redeemDescription={redeemDescription}
                        expireAt={expireAt}
                        link={link}
                        singleCode={singleCode}
                        voucherType={voucherType}
                    />
                )}
            </div>
            {selectedReceiver !== '' ? (
                <></>
            ) : (
                <>
                    <div className="tab-controls">
                        {' '}
                        <h2 className="title">Search for Recipients</h2>
                        <Input
                            placeholder={translations.type_to_search_users}
                            onChange={onSearchChangeHandler}
                        />
                    </div>
                    <UsersTable
                        users={users}
                        store={store}
                        onPageChange={(p) => setPage(p)}
                        totalPages={totalPages}
                        selectedPage={page}
                    />
                </>
            )}
        </>
    );
};

export default observer(NewInstantReward);
