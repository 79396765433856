import React, { useState, useEffect, useRef, useCallback } from 'react';
import Item from '../../components/Item';
import { useForm } from 'react-hook-form';
import getPassFormInput from './getPassFormInput';
import { FaLongArrowAltRight, FaPlus } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import Button from '../../components/Button';
import Select from '../../components/Select';
import userAPI from '../../api/users';
import seatAPI from '../../api/seats';
import debounce from 'lodash.debounce';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import API from '../../api/passesFriendship';
import uniqueById from '../../helpers/uniqueById';

const PassForm = ({
    passDetails,
    passBuyer,
    selectedPass,
    user = null,
    seat = null,
    passId,
    edit,
    name,
    numOfETickets = 0,
    onSubmit,
    onEdit,
    onCancel,
    error,
    loading,
    seasons = false,
    creatingNewEntity = false,
}) => {
    const { abo_id } = useParams();
    const history = useHistory();
    const statusRef = useRef(null);
    const [users, setUsers] = useState(user ? [{ ...user }] : []);
    const [seats, setSeats] = useState(seat ? [{ ...seat }] : []);
    const [searchUserQuery, setSearchUserQuery] = useState('');
    const [searchSeatQuery, setSearchSeatQuery] = useState('');
    const [loader, setLoader] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [dialog, setDialog] = useModal(false, statusRef);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({
        defaultValues: { ...passDetails, user_id: user?.id, seat_id: seat?.id, ...passBuyer },
    });

    const passDetailsData = Object.keys(passDetails).map((key) => {
        return { name: key, value: passDetails[key] };
    });

    const passBuyerData = Object.keys(passBuyer).map((key) => {
        return { name: key, value: passBuyer[key] };
    });

    const searchUserHander = debounce((value) => {
        setSearchUserQuery(value);
    }, 500);

    const searchSeatHander = debounce((value) => {
        setSearchSeatQuery(value);
    }, 500);

    const createTickets = (e) => {
        e.preventDefault();
        setLoader(true);
        const createAllTickets = async () => {
            try {
                const res = await API.createAllTickets(abo_id);
                setLoader(false);

                if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
                    setStatusMessage('success');
                } else {
                    setStatusMessage('failed');
                }
                setDialog(true);
            } catch (e) {
                setLoader(false);
                console.log(e);
            }
        };
        createAllTickets();
    };

    const closeDialog = (e, refresh = false) => {
        e.preventDefault();
        refresh && window.location.reload();
        setDialog(false);
    };

    const getUsers = useCallback(async () => {
        if (searchUserQuery !== '') {
            try {
                const res = await userAPI.getUsers({
                    params: {
                        page: page,
                        search: searchUserQuery,
                        limit: 10,
                    },
                });
                if (page === 1) {
                    setUsers(uniqueById(res.data.items));
                } else {
                    setUsers((users) => uniqueById([...users, ...res.data.items]));
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [page, searchUserQuery]);

    const checkValues = () => {
        if (
            !!selectedPass?.access_codes[0]?.access_token &&
            !!selectedPass?.access_codes[0]?.game_id &&
            !!selectedPass?.seat_id
        )
            return true;
        else return false;
    };

    useEffect(() => {
        getUsers().then(() => setIsLoading(false));
    }, [getUsers]);

    useEffect(() => {
        if (isLoading) {
            setPage((page) => page + 1);
        }
    }, [isLoading]);

    useEffect(() => {
        const getSeats = async () => {
            try {
                const res = await seatAPI.getSeats({
                    params: {
                        search: searchSeatQuery,
                        limit: 50,
                    },
                });
                setSeats(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchSeatQuery && getSeats();

        //cancel asynchronous tasks
        // return () => getSeats();
    }, [searchSeatQuery]);

    return (
        <Item
            // links={[{ path: Paths.PASSES_F, name: translations.passesFriendship }]}
            // name={passDetails?.ticket_id}
            showBackButton={true}
            edit={edit}
            onSubmit={handleSubmit(onSubmit)}
            onEdit={onEdit}
            onCancel={() => {
                reset({ ...passDetails, user_id: user?.id, seat_id: seat?.id, ...passBuyer });
                onCancel();
            }}
            error={error}
            loading={loading}
        >
            <form className="pass-form">
                <div className="row">
                    <div className="col">
                        <div className="segment">
                            <div className="segment-title">{translations.general}</div>
                            <div className="segment-content">
                                {edit && (
                                    <>
                                        <Select
                                            name="user_id"
                                            label={translations.user}
                                            control={control}
                                            options={users.map((user) => ({
                                                value: user.id,
                                                label: `${user.first_name} ${user.last_name} [${user.email}]`,
                                            }))}
                                            multiple={false}
                                            onInputChange={searchUserHander}
                                            placeholder={translations.type_to_search_users}
                                            noOptionsMsg={translations.no_users_found}
                                            isClearable
                                            isLoading={isLoading}
                                            bottomReached={() => setIsLoading(true)}
                                            onBlur={() => setPage(1)}
                                        />
                                        <Select
                                            name="seat_id"
                                            label={translations.seat}
                                            control={control}
                                            error={errors.seat_id}
                                            options={seats.map((seat) => ({
                                                value: seat.id,
                                                label: `E: ${seat.seat_group.sector.title}; B: ${seat.seat_group.title}; R: ${seat.row}; S: ${seat.num}`,
                                            }))}
                                            onInputChange={searchSeatHander}
                                            placeholder={translations.type_to_search_seats}
                                            noOptionsMsg={translations.no_seats_found}
                                            isClearable
                                            isRequired={true}
                                        />
                                        {seasons && (
                                            <Select
                                                name="season_id"
                                                label={translations.season}
                                                control={control}
                                                error={errors.season_id}
                                                options={seasons}
                                                placeholder={translations.select_season}
                                                noOptionsMsg={translations.no_seasons_found}
                                                isClearable
                                                isRequired={true}
                                            />
                                        )}
                                    </>
                                )}
                                {passDetailsData.map((data, index) =>
                                    getPassFormInput({
                                        value: data.value,
                                        name: data.name,
                                        register,
                                        error: errors[data.name],
                                        disabled: !edit,
                                        index,
                                        newEdit: creatingNewEntity,
                                    }),
                                )}
                                {!edit && (
                                    <>
                                        {user && (
                                            <>
                                                <p className="link-label">{translations.user}</p>
                                                <Link to={`/fans/${user.id}`}>
                                                    <FaLongArrowAltRight />{' '}
                                                    <span>{`${user.first_name} ${user.last_name}`}</span>
                                                </Link>{' '}
                                            </>
                                        )}
                                        <p className="link-label">{translations.accesses}</p>
                                        {numOfETickets ? (
                                            <>
                                                <Link to={`/etickets?pass_id=${passId}`}>
                                                    <FaLongArrowAltRight />{' '}
                                                    <span>
                                                        {' '}
                                                        {`${translations.show} [${numOfETickets}]`}
                                                    </span>
                                                </Link>
                                            </>
                                        ) : (
                                            '0'
                                        )}
                                        <p className="link-label">{translations.seat}</p>
                                        <div>
                                            Entrance: {seat?.seat_group?.sector?.title} <br />
                                            Block: {seat?.seat_group?.title} <br />
                                            Row: {seat?.row} <br />
                                            Seat: {seat?.num} <br />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {!edit && (
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'absolute',
                                }}
                            >
                                <Button
                                    disabled={!checkValues}
                                    label="e-Ticket"
                                    style={{ marginRight: '15px' }}
                                    onClick={() => {
                                        history.push({
                                            pathname: Paths.NEW_E_TICKET,
                                            state: {
                                                id: abo_id,
                                                access_token:
                                                    selectedPass?.access_codes[0]?.access_token,
                                                game_id: selectedPass?.access_codes[0]?.game_id,
                                                seat_id: selectedPass?.seat_id,
                                            },
                                        });
                                    }}
                                >
                                    <FaPlus style={{ marginRight: '5px' }} />
                                </Button>
                                <Button
                                    label={translations.create_all_tickets}
                                    loading={loader}
                                    onClick={(e) => {
                                        createTickets(e);
                                    }}
                                >
                                    <FaPlus style={{ marginRight: '5px' }} />
                                </Button>
                            </div>
                        )}
                        {dialog && statusMessage === 'success' && (
                            <Modal
                                ref={statusRef}
                                title="Operation successful"
                                onClose={(e) => closeDialog(e)}
                                maxWidth="500px"
                            >
                                <p style={{ color: 'green' }}>Accesses are successfully created!</p>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button onClick={(e) => closeDialog(e, true)}>OK</Button>
                                </div>
                            </Modal>
                        )}
                        {dialog && statusMessage === 'failed' && (
                            <Modal
                                ref={statusRef}
                                title="Operation failed"
                                onClose={(e) => setDialog(false)}
                                maxWidth="800px"
                            >
                                <p style={{ color: 'red' }}>Accesses couldn't be created!</p>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button onClick={(e) => closeDialog(e)}>OK</Button>
                                </div>
                            </Modal>
                        )}
                        {/* {!edit && user?.id && (
                            <div className="segment">
                                <div className="segment-title">{translations.actions}</div>
                                <div className="segment-content actions">
                                    <Button label="All eTickets" type="button">
                                        <FaFilePdf style={{ marginRight: "5px" }} />
                                    </Button>
                                    <Button label="All eTickets (bla bla)" type="button">
                                        <FaFilePdf style={{ marginRight: "5px" }} />
                                    </Button>
                                    <Button label={translations.access} type="button">
                                        <FaPlus style={{ marginRight: "5px" }} />
                                    </Button>
                                </div>
                            </div>
                        )} */}
                    </div>
                    <div className="col">
                        <div className="segment">
                            <div className="segment-title">{translations.buyer}</div>
                            <div className="segment-content">
                                {passBuyerData.map((data, index) =>
                                    getPassFormInput({
                                        name: data.name,
                                        register,
                                        error: errors[data.name],
                                        disabled: !edit,
                                        index,
                                        control,
                                    }),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Item>
    );
};

export default PassForm;
