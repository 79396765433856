import { types } from 'mobx-state-tree';

const Team = types.model('Team', {
    id: types.identifierNumber,
    title: types.optional(types.string, ''),
    logo_url: types.optional(types.string, ''),
    is_owner: types.boolean,
});

export default Team;
