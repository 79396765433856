import { types, applySnapshot } from 'mobx-state-tree';
import Player from './Player';

const Team = types
    .model('Team', {
        id: types.identifierNumber,
        title: types.optional(types.string, ''),
        logo_url: types.maybeNull(types.string),
        players: types.optional(types.array(Player), []),
        totomat_acronym: types.maybeNull(types.string),
        home_color: types.maybeNull(types.string),
        home_text_color: types.maybeNull(types.string),
        away_color: types.maybeNull(types.string),
        away_text_color: types.maybeNull(types.string),
        home_jersey_url: types.maybeNull(types.string),
        away_jersey_url: types.maybeNull(types.string),
    })
    .actions((self) => ({
        editTeam(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
        addPlayer(player) {
            self.players.push(player);
        },
        editPlayer(player) {
            self.players.forEach((element) => {
                if (element.id === player.id) {
                    element.editPlayer(player);
                }
            });
        },
    }));

export default Team;
