import { types, applySnapshot } from 'mobx-state-tree';

const Scenario = types
    .model('Scenario', {
        id: types.identifierNumber,
        title: types.optional(types.string, ''),
    })
    .actions((self) => ({
        editScenario(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Scenario;
