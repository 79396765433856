import './Select.scss';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { forwardRef } from 'react';
import translations from '../../translations/de';

const customStyles = {
    container: () => ({
        minHeight: '43px',
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        minHeight: '35px',
        backgroundColor: 'transparent',
        border: state.isDisabled ? '1px solid transparent' : '1px solid gray',
        borderBottom: state.isDisabled ? '1px solid gray' : '1px solid gray',
        color: '#262626',
        borderRadius: '3px',
        display: 'flex',
        opacity: state.isDisabled ? '0.6' : '1',
        '&:hover': {
            borderColor: state.isDisabled ? 'transparent' : 'gray',
            borderBottom: state.isDisabled ? '1px solid gray' : '1px solid gray',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2,
    }),

    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#e73c37' : 'transparent',
        '&:hover': {
            backgroundColor: '#fbe9ea',
        },
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        visibility: state.isDisabled ? 'hidden' : 'visible',
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        visibility: state.isDisabled ? 'hidden' : 'visible',
    }),
};
const CustomSelect = forwardRef(
    (
        {
            label = '',
            name,
            options,
            error = false,
            control,
            multiple = false,
            disabled,
            onChangeCallback,
            isRequired = false,
            placeholder = translations.select,
            isClearable = false,
            noOptionsMsg = 'No options',
            onInputChange,
            hasError = true,
            bottomReached = null,
            isLoading = false,
            wider_field = false,
            ...restProps
        },
        ref,
    ) => {
        const getLabel = (value) => {
            if (value) {
                let option = options.find((op) => op.value === value);
                return option && option.label;
            } else {
                return '';
            }
        };

        return (
            <div className={`Select ${wider_field && 'wider'}`}>
                {label && <label>{label}</label>}
                {control ? (
                    <Controller
                        control={control}
                        name={name}
                        rules={{ required: isRequired }}
                        render={({ field: { onChange, value, name, ref } }) => {
                            return (
                                <Select
                                    ref={ref}
                                    name={name}
                                    value={
                                        multiple && value && value.length !== 0
                                            ? value.map((val) => {
                                                  return { value: val, label: val };
                                              })
                                            : (value && typeof value === 'string') ||
                                              (value && typeof value === 'number')
                                            ? {
                                                  value: value,
                                                  label: getLabel(value),
                                              }
                                            : undefined
                                    }
                                    onChange={(e) => {
                                        !multiple
                                            ? onChange(e !== null ? e.value : '')
                                            : onChange(e.map((val) => val.value));
                                        onChangeCallback &&
                                            onChangeCallback(e && !multiple ? e.value : e);
                                    }}
                                    onInputChange={onInputChange}
                                    options={options}
                                    isMulti={multiple}
                                    isDisabled={disabled}
                                    styles={customStyles}
                                    placeholder={placeholder}
                                    isClearable={isClearable}
                                    noOptionsMessage={() => noOptionsMsg}
                                    onMenuScrollToBottom={(e) =>
                                        bottomReached !== null && bottomReached(e)
                                    }
                                    isLoading={isLoading}
                                    {...restProps}
                                />
                            );
                        }}
                    />
                ) : (
                    <Select
                        ref={ref}
                        options={options}
                        isMulti={multiple}
                        styles={customStyles}
                        onChange={(e) => onChangeCallback(e)}
                        onInputChange={onInputChange}
                        placeholder={placeholder}
                        isClearable={isClearable}
                        isDisabled={disabled}
                        noOptionsMessage={() => noOptionsMsg}
                        onMenuScrollToBottom={(e) => bottomReached !== null && bottomReached(e)}
                        isLoading={isLoading}
                        {...restProps}
                    />
                )}
                {hasError && (
                    <span className="error-msg">{error && <p>{translations.required}</p>}</span>
                )}
            </div>
        );
    },
);
export default CustomSelect;
