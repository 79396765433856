import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb';
import TabSwitcher from '../../components/TabSwitcher';
import './Fan.scss';
import Paths from '../../constants/paths';
import FanForm from './FanForm';
import Items from './Items.js';
import SeasonalMood from './SeasonalMood';
import translations from '../../translations/de';
import Tickets from './Tickets';
import { useHistory } from 'react-router';
import ContactsTable from './ContactsTable';
import TransactionsTable from './TransactionsTable.js';

const Fan = ({ store, adminStore }) => {
    const { user_id } = useParams();
    const history = useHistory();

    useEffect(() => {
        store.getUser(user_id);
        return () => store.selectUser(undefined);
    }, [store, user_id]);

    useEffect(() => {
        return history.listen(() => {
            if (history.action === 'POP') {
                history.push('/fans');
            }
        });
    }, [history]);

    return store.dataLoaded ? (
        <div className="Fan">
            <div className="Fan-Header">
                <Breadcrumb
                    name={store?.selectedUser?.first_name + ' ' + store?.selectedUser?.last_name}
                    links={[{ path: Paths.FANS, name: translations.user }]}
                />
            </div>
            <TabSwitcher
                initialTabName={history?.location?.state?.activeTabName ?? translations.general}
            >
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName={translations.general} />
                    <TabSwitcher.TabPill tabName="Rewards and Purchases" />
                    <TabSwitcher.TabPill tabName="Mood Seasonal Stats" />
                    <TabSwitcher.TabPill tabName="Abos" />
                    <TabSwitcher.TabPill tabName={translations.contacts} />
                    <TabSwitcher.TabPill tabName={translations.transactions} />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName={translations.general}>
                    <FanForm store={store} adminStore={adminStore} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Rewards and Purchases">
                    <Items store={store} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Mood Seasonal Stats">
                    <SeasonalMood store={store} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Abos">
                    <Tickets fanID={user_id} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.contacts}>
                    <ContactsTable store={store} user_id={user_id} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.transactions}>
                    <TransactionsTable store={store} user_id={user_id} />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </div>
    ) : null;
};

export default observer(Fan);
