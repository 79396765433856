const mandatoryFields = [
    'name',
    'price_in_cents',
    'description',
    'image_url',
    'popup_title',
    'popup_text',
    'fan_coins',
    'quantity',
    'expire_at',
    'redeem_description',
    'quantity',
    'receiver',
    'shared_code',
    'order_id',
];

export default mandatoryFields;
