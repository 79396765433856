import React, { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import getFanFormInput from './getFanFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import ItemImage from '../../components/ItemImage';
import API from '../../api/businessProfiles';
import debounce from 'lodash.debounce';
import uniqueById from '../../helpers/uniqueById';

const FanForm = ({ store, pathname, tabName, adminStore }) => {
    var userModel = clearObjProps({ ...store.selectedUser }, [
        'id',
        'items',
        'seasonalMoodStats',
        'business_profile',
        'seasonalMoodGameId',
    ]);

    const [resetProp, setResetProp] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profiles, setProfiles] = useState(
        store?.selectedUser?.business_profile ? [store.selectedUser.business_profile] : [],
    );
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitHandler = (e) => {
        setLoading(true);
        const edited = {
            ...e,
            birthday: e.birthday ? new Date(e.birthday) : '',
            apple_id: e?.apple_id === '' ? null : e?.apple_id,
        };
        error && setError(null);
        store
            .editUser(edited)
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(err);
            });
    };

    const bottomReached = () => {
        !isLoading && setIsLoading(true);
    };

    const searchQueryHandler = debounce((value) => {
        setSearchQuery(value);
    }, 500);

    useEffect(() => {
        if (isLoading) {
            setPage((page) => page + 1);
        }
    }, [isLoading]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await API.getProfiles({
                    params: {
                        page: page,
                        search: searchQuery,
                        limit: 10,
                    },
                });
                if (page === 1) {
                    setProfiles(uniqueById(res.data.items));
                } else {
                    setProfiles((profiles) => uniqueById([...profiles, ...res.data.items]));
                }
            } catch (error) {
                console.log(error);
            }
        };

        searchQuery && getUsers();
        setIsLoading(false);
    }, [page, searchQuery]);

    return (
        <Item
            hasNavigation={false}
            links={[{ path: pathname, name: tabName }]}
            name={store?.selectedUser?.first_name + ' ' + store?.selectedUser?.last_name}
            id={`[ user_ID: ${store?.selectedUser?.id} ]`}
            itemModel={userModel}
            edit={edit}
            onSubmit={onSubmitHandler}
            onEdit={() => setEdit(true)}
            onCancel={() => {
                error && setError(null);
                setEdit(false);
            }}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, disabled, index }) =>
                getFanFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setResetProp({ [name]: value }),
                    profiles,
                    searchQueryHandler,
                    isLoading,
                    bottomReached: bottomReached,
                    onBlur: () => setPage(1),
                    players: [
                        ...store.ownersPlayers.map((player) => {
                            return {
                                value: player.id,
                                label: `${player.first_name} ${player.last_name}`,
                            };
                        }),
                    ],
                    currentUser: adminStore,
                })
            }
            resetProp={resetProp}
        >
            {' '}
            <ItemImage imgSrc={store?.selectedUser?.avatar} isAvatar />
        </Item>
    );
};

export default observer(FanForm);
