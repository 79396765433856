import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import Passes from '../PassesFriendship/Passes';
import TabSwitcher from '../../components/TabSwitcher';
import translations from '../../translations/de';
import { formatDate } from '../../helpers/formatDate';
import Button from '../../components/Button';
import { FaSearch } from 'react-icons/fa';
import InputSeason from '../../components/InputSeason/InputSeason';

const MatchOverview = ({ store, freundeStore }) => {
    const overviewTabRef = useRef(null);
    const freundeTabRef = useRef(null);
    const [selectedSeason, setSelectedSeason] = useState(null);

    const showFreundeTickets = (id) => {
        //setMatchID(id);
        store.setSelectedMatch(id);
        freundeTabRef.current.click();
    };

    const overviewTabActive = () => {
        store.setSelectedMatch(undefined);
        //reset pagination
        store.setSelectedPage(1);
        freundeStore.setSelectedPage(1);
        store.setActiveTab('Overview');
    };

    const freundeTabRefActive = () => {
        store.setActiveTab('Freunde Tickets');
    };

    overviewTabRef?.current?.addEventListener('click', overviewTabActive);
    freundeTabRef?.current?.addEventListener('click', freundeTabRefActive);

    useEffect(() => {
        selectedSeason && store.getMatchesOverviews(selectedSeason);
    }, [store, selectedSeason]);

    return (
        <>
            <TabSwitcher
                initialTabName={store?.activeTab !== undefined ? store.activeTab : 'Overview'}
            >
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName="Overview" ref={overviewTabRef} />
                    <TabSwitcher.TabPill tabName="Freunde Tickets" ref={freundeTabRef} />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName="Overview">
                    <div className="tab-controls">
                        <div style={{ width: '200px' }}>
                            <InputSeason
                                selectedSeason={selectedSeason}
                                setSelectedSeason={setSelectedSeason}
                            />
                        </div>
                    </div>
                    {
                        <>
                            {store?.matchesOverview?.length > 0 || !store.dataLoaded ? (
                                <Table
                                    isLoading={!store.dataLoaded}
                                    tableName={'Matches Overview'}
                                    isRowClickable={false}
                                    columns={[
                                        {
                                            Header: 'Match',
                                            accessor: 'id',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        // {
                                        //     Header: translations.home_team,
                                        //     accessor: 'home_team',
                                        //     Cell: ({ value, row }) => (
                                        //         <div>
                                        //             <img src={value} alt="answer" />
                                        //         </div>
                                        //     ),
                                        // },
                                        // {
                                        //     Header: translations.away_team,
                                        //     accessor: 'away_team',
                                        //     Cell: ({ value, row }) => (
                                        //         <div>
                                        //             <img src={value} alt="answer" />
                                        //         </div>
                                        //     ),
                                        // },
                                        // {
                                        //     Header: translations.date,
                                        //     accessor: 'date',
                                        //     Cell: ({ value, row }) => (
                                        //         <div>{` ${formatDate(value)} ${formatTime(value)}`}</div>
                                        //     ),
                                        // },
                                        {
                                            Header: translations.match,
                                            accessor: 'game',
                                            Cell: ({ value, row }) => (
                                                <div>
                                                    {value && formatDate(value.date)} <br />
                                                    {value?.away_team?.title
                                                        ? value.away_team?.title
                                                        : null}
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: 'Total',
                                            accessor: 'total',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: 'Claimed',
                                            accessor: 'claimed',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: 'Used',
                                            accessor: 'used',
                                            Cell: ({ value, row }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: '',
                                            accessor: 'action',
                                            Cell: ({ row }) => (
                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            showFreundeTickets(row.cells[0].value);
                                                        }}
                                                        title={translations.show}
                                                    >
                                                        <FaSearch />
                                                    </Button>
                                                </div>
                                            ),
                                        },
                                    ]}
                                    data={store.matchesOverview.map(
                                        ({ game, total, used, claimed }) => {
                                            return {
                                                id: game.id,
                                                game,
                                                date: game?.date,
                                                total,
                                                used,
                                                claimed,
                                            };
                                        },
                                    )}
                                />
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '400px',
                                    }}
                                >
                                    <div className="piechart-placeholder">
                                        No data for selected season.
                                    </div>
                                </div>
                            )}
                        </>
                    }
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName="Freunde Tickets">
                    <Passes
                        store={freundeStore}
                        matchID={store.selectedMatch}
                        preselectedSeason={selectedSeason}
                    />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </>
    );
};

export default observer(MatchOverview);
