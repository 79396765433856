import { types } from 'mobx-state-tree';
import Quote from './Quote';

const GameAnswer = types
    .model('GameAnswer', {
        id: types.maybeNull(types.number),
        text: types.optional(types.string, ''),
        image_url: types.maybeNull(types.string),
        second_image_url: types.maybeNull(types.string),
        sound_url: types.maybeNull(types.string),
        video_url: types.maybeNull(types.string),
        twitter_hash_tags: types.maybeNull(types.string),
        share_url: types.maybeNull(types.string),
        share_title: types.maybeNull(types.string),
        share_description: types.maybeNull(types.string),
        quotes: types.maybeNull(types.array(Quote)),
        player_id: types.maybeNull(types.number),
        team_id: types.maybeNull(types.number),
    })
    .actions((self) => ({
        onChangeValue(propName, value) {
            self[propName] = value;
        },
        addQuote(quote) {
            if (!self.quotes) {
                self.quotes = [];
            }
            self.quotes.unshift(quote);
        },
        deleteQuote(id) {
            self.quotes.splice(id, 1);
        },
    }));

export default GameAnswer;
