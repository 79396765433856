import { types, flow, applySnapshot } from 'mobx-state-tree';
import GameAnswer from './GameAnswer';
import APIAnswers from '../../api/answers';

const Question = types
    .model('Question', {
        id: types.identifierNumber,
        title: types.optional(types.string, ''),
        image_url: types.maybeNull(types.string),
        text: types.optional(types.string, ''),
        fan_game_answers: types.optional(types.array(GameAnswer), []),
    })
    .views((self) => ({
        getAnswer(id) {
            return self.fan_game_answers.find((answer) => answer.id === id);
        },
    }))
    .actions((self) => ({
        editQuestion(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
        editAnswer: flow(function* editAnswer(id, edited) {
            try {
                const res = yield APIAnswers.editAnswer(id, edited);
                const index = self.fan_game_answers.findIndex((a) => a.id === res.data.id);
                self.fan_game_answers[index] = res.data;
            } catch (error) {
                throw error;
            }
        }),
        addAnswer: flow(function* addAnswer(answer) {
            try {
                const res = yield APIAnswers.createAnswer({
                    ...answer,
                    fan_game_question_id: self.id,
                });
                self.fan_game_answers.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteAnswer: flow(function* deleteAnswer(id) {
            try {
                yield APIAnswers.deleteAnswer(id);
                const index = self.fan_game_answers.findIndex((a) => a.id === id);
                self.fan_game_answers.splice(index, 1);
            } catch (error) {
                console.log('error => ' + error);
            }
        }),
    }));

export default Question;
