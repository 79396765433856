import './Drawer.scss';
import {
    management,
    users,
    ticketing,
    fan_interaction,
    business,
    gastro,
    home /*baseData,home, hardware,*/,
} from './links';
import Link from './Link';
import navigationConfig from '../../configs/navigation';
import useIgnoredOnPages from './../../customHooks/useIgnoredOnPages';
import { observer } from 'mobx-react-lite';
import { adminAccessPaths, adminAccessSections } from '../../helpers/adminAccess';
import drawerSections from '../../constants/drawerSections';

const Drawer = ({ store, adminStore }) => {
    const ignored = useIgnoredOnPages(true, navigationConfig.ignoredOnPages);
    return !ignored ? (
        <div className="Drawer">
            <div className={`Drawer-Content ${store.isDrawerVisible ? '' : 'close'}`}>
                {/* Zero Section - HOME */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.MANAGEMENT || sec === drawerSections.USERS,
                )
                    ? home.map((link, index) =>
                          adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                              <Link
                                  key={index}
                                  name={link.name}
                                  icon={link.icon}
                                  path={link.path}
                              />
                          ) : null,
                      )
                    : null}
                {/* First section - ADMIN */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.MANAGEMENT,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.MANAGEMENT}</span>
                    </div>
                ) : null}
                {management.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link key={index} name={link.name} icon={link.icon} path={link.path} />
                    ) : null,
                )}
                {/*Second Section - USERS*/}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.USERS,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.USERS}</span>
                    </div>
                ) : null}
                {users.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link key={index} name={link.name} icon={link.icon} path={link.path} />
                    ) : null,
                )}
                {/* Third Section - TICKETING */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.TICKETING,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.TICKETING}</span>
                    </div>
                ) : null}
                {ticketing.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link key={index} name={link.name} icon={link.icon} path={link.path} />
                    ) : null,
                )}
                {/* Fourth Section - FAN INTERACTION */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.FAN_INTERACTION,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.FAN_INTERACTION}</span>
                    </div>
                ) : null}
                {fan_interaction.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <span onClick={link.onClick} key={index}>
                            <Link key={index} name={link.name} icon={link.icon} path={link.path} />
                        </span>
                    ) : null,
                )}
                {/* Fifth Section - BUSINESS */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.BUSINESS,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.BUSINESS}</span>
                    </div>
                ) : null}
                {business.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link key={index} name={link.name} icon={link.icon} path={link.path} />
                    ) : null,
                )}
                {/* Sixt Section - GASTRO */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.GASTRO,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.GASTRO}</span>
                    </div>
                ) : null}
                {gastro.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link key={index} name={link.name} icon={link.icon} path={link.path} />
                    ) : null,
                )}
            </div>
            <div className={`Drawer-Spacer ${store.isDrawerVisible ? '' : 'hide'}`}></div>
        </div>
    ) : null;
};

export default observer(Drawer);
