import React, { useEffect } from 'react';
import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';
import BusinessProfiles from './BusinessProfiles';
import BusinessProfile from './BusinessProfile';
import NewBusinessProfiles from './NewBusinessProfile';

const BusinessProfilesTab = ({ store }) => {
    useEffect(() => {
        return () => {
            store.selectProfile(undefined);
        };
    }, [store]);

    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.BUSINESS_PROFILES}>
                <BusinessProfiles store={store} />
            </Route>{' '}
            <Route exact path={Paths.NEW_BUSINESS_PROFILE}>
                <NewBusinessProfiles store={store} />
            </Route>
            <Route exact path={Paths.BUSINESS_PROFILE}>
                <BusinessProfile store={store} />
            </Route>
        </Page>
    );
};

export default observer(BusinessProfilesTab);
