import React, { useState, useEffect } from 'react';
import api from '../../api/games';
import { formatDate } from '../../helpers/formatDate';
import translations from '../../translations/de';
import Select from '../Select';

const HomeGamesFilter = ({
    defaultValue = undefined,
    filterByGameDate,
    activeSeason = undefined,
}) => {
    const [games, setGames] = useState([]);
    const [value, setValue] = useState(null);

    const styles = {
        option: (styles, { isFocused }) => ({
            ...styles,
            fontSize: '15px',
            backgroundColor: isFocused ? 'red' : 'white',
            color: isFocused ? 'white' : 'black',
        }),
        control: (styles) => ({
            ...styles,
            width: '400px',
            boxShadow: 'none',
            borderColor: 'gray',
            '&:hover': {
                borderColor: 'gray',
            },
        }),
    };

    useEffect(() => {
        const getGameOwnerGames = async () => {
            try {
                const res = await api.getGameOwnerGames({
                    params: {
                        season_id: activeSeason,
                    },
                });
                if (res.data.length > 0) {
                    setGames(
                        res.data.map((game) => ({
                            value: game.id,
                            label: `${formatDate(game?.date)} - ${game.away_team_title}`,
                            date: game?.date,
                        })),
                    );
                } else {
                    setGames([]);
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (activeSeason) {
            setValue(null);
            getGameOwnerGames();
        }
    }, [activeSeason]);

    useEffect(() => {
        if (games?.length > 0) {
            if (defaultValue) {
                let preselected = games.find((game) => game.value === defaultValue) || null;
                setValue(preselected);
            }
        }
    }, [games, defaultValue]);

    return (
        <div className="Select false">
            <>
                <label>{translations.match}</label>
                <Select
                    name="Filter By Name"
                    options={games}
                    styles={styles}
                    value={value}
                    isSearchable={false}
                    onChangeCallback={(e) => {
                        setValue(e);
                        filterByGameDate(e);
                    }}
                    placeholder={translations.search_by_game}
                />
            </>
        </div>
    );
};

export default React.memo(HomeGamesFilter);
