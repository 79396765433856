import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import getSeatGroupFormInput from './getSeatGroupFormInput';
import { useParams } from 'react-router-dom';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import clearObjProps from '../../helpers/clearObjProps';

const SeatGroup = ({ store }) => {
    const { group_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const sectors = store.sectors;
    const SeatGroupModel = clearObjProps({ ...store.selectedSeatGroup }, ['id', 'sector']);
    useEffect(() => {
        !store.selectedSeatGroup && store.getSeatGroup(group_id);
    }, [store, group_id]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editSeatGroup({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };
    return store.selectedSeatGroup ? (
        <>
            <Item
                links={[{ path: Paths.SEAT_GROUPS, name: translations.seat_groups }]}
                name={store.selectedSeatGroup.title}
                id={`[ seatGroup_ID: ${store.selectedSeatGroup.id} ]`}
                itemModel={SeatGroupModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getSeatGroupFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        sectors,
                    })
                }
            />
        </>
    ) : null;
};
export default observer(SeatGroup);
