import { types, flow } from 'mobx-state-tree';
import Scanner from './Scanner';
import API from '../../api/scanners';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';

const ScannersStore = types
    .model('ScannersStore', {
        scanners: types.optional(types.array(Scanner), []),
        selectedScanner: types.maybe(types.reference(Scanner)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getScanners: flow(function* getScanners() {
            self.error = '';
            try {
                const res = yield API.getScanners({
                    params: {
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.scanners = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        selectScanner(id) {
            self.selectedScanner = id;
        },
        getScanner: flow(function* getScanner(id) {
            self.error = '';
            try {
                if (!self.selectedScanner) {
                    const res = yield API.getScanner(id);
                    self.dataLoaded = true;
                    self.scanners = [];
                    self.scanners.push(res.data);
                    self.selectedScanner = res.data.id;
                }
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editScanner: flow(function* editScanner(edited) {
            try {
                const res = yield API.editScanner(self.selectedScanner.id, edited);
                self.selectedScanner.editScanner(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addScanner: flow(function* addScanner(scanner) {
            try {
                const res = yield API.createScanner(scanner);
                self.scanners.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteScanner: flow(function* deleteScanner(id) {
            try {
                yield API.deleteScanner(id);
                const index = self.scanners.findIndex((s) => s.id === id);
                self.scanners.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default ScannersStore;
