import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import translations from '../../translations/de';
import { FaTrophy, FaSearch } from 'react-icons/fa';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import ItemImage from '../../components/ItemImage';
const Participants = ({ store }) => {
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const history = useHistory();

    const handleUser = (id) => {
        history.push(`/fans/${id}`);
    };

    useEffect(() => {
        store.getLotteryGameParticipiants(selectedPage).then((num) => {
            setTotalPages(num);
        });
    }, [store, selectedPage]);

    return (
        <div>
            {store.selectedLotteryGame.participants &&
                store.selectedLotteryGame.participants !== 0 && (
                    <Table
                        isRowClickable={false}
                        columns={[
                            {
                                Header: 'ID',
                                accessor: 'id',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.avatar,
                                accessor: 'avatar',
                                Cell: ({ value }) => (
                                    <div>
                                        <ItemImage imgSrc={value} isAvatar={true} listView={true} />
                                    </div>
                                ),
                            },
                            {
                                Header: translations.name,
                                accessor: 'name',
                                Cell: ({ value }) => <div>{value}</div>,
                            },
                            {
                                Header: translations.is_winner,
                                accessor: 'is_winner',
                                Cell: ({ value }) => (
                                    <div>
                                        {' '}
                                        {value === true && (
                                            <FaTrophy
                                                className="confirm"
                                                style={{ fontSize: '30px' }}
                                            />
                                        )}
                                    </div>
                                ),
                            },
                            {
                                Header: translations.tickets,
                                accessor: 'tickets',
                                Cell: ({ value }) => (
                                    <div style={{ flexWrap: 'wrap' }}>
                                        {value.length > 0 &&
                                            value.map((ticket, index) => (
                                                <span
                                                    key={ticket.id}
                                                    style={{
                                                        fontWeight: ticket.is_winner
                                                            ? 'bold'
                                                            : 'normal',
                                                    }}
                                                >
                                                    {`${ticket.number}`}
                                                    {index !== value.length - 1 && `,`}
                                                </span>
                                            ))}
                                    </div>
                                ),
                            },
                            {
                                accessor: 'action',
                                Cell: ({ row }) => (
                                    <div>
                                        <Button
                                            onClick={() => {
                                                handleUser(row.cells[0].value);
                                            }}
                                            title={translations.show}
                                        >
                                            <FaSearch />
                                        </Button>
                                    </div>
                                ),
                            },
                        ]}
                        data={store.selectedLotteryGame.participants.map(
                            ({ id, first_name, last_name, avatar, is_winner, tickets }) => {
                                return {
                                    id,
                                    name: `${first_name} ${last_name}`,
                                    avatar,
                                    is_winner,
                                    tickets,
                                };
                            },
                        )}
                    />
                )}
            {totalPages > 1 && (
                <Pagination
                    totalPages={totalPages}
                    selectedPage={selectedPage}
                    onSelectedPageChange={(selected) => setSelectedPage(selected)}
                />
            )}
        </div>
    );
};

export default observer(Participants);
