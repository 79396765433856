import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { useHistory } from 'react-router-dom';
import getShopFormInput from './getShopFormInput';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import API from '../../api/rewards';
import debounce from 'lodash.debounce';

const NewItem = ({ store }) => {
    const history = useHistory();
    const [imageUrl, setImageUrl] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showVoucherTypes, setShowVoucherTypes] = useState(false);
    const [searchOrders, setSearchOrders] = useState('');
    const [orders, setOrders] = useState([]);
    const [orderIdHaveValue, setOrderIdHaveValue] = useState(false);
    //fields for tracking
    const [orderID, setOrderID] = useState('');
    const [voucherType, setVoucherType] = useState(null);
    const [voucherOrderID, setVoucherOrderID] = useState('');
    const [priceInCents, setPriceInCents] = useState(null);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    const handleTypeChange = (type) => {
        setShowVoucherTypes(type === 'consumable');
    };

    const handleOrder = (e, getValues, reset) => {
        if (e) {
            reset({
                ...getValues(),
                type: 'consumable',
            });
            setShowVoucherTypes(true);
            setOrderIdHaveValue(true);
        } else {
            setOrderIdHaveValue(false);
        }
    };

    const handleVoucherType = (e, getValues, reset) => {
        e === null && setOrderIdHaveValue(false);
        setOrderID('');
        setVoucherOrderID('');
        var values = {
            ...getValues(),
            order_id: null,
            importCodesField: '',
        };
        reset(values);
        setVoucherType(e);
    };

    const searchOrdersHandler = debounce((value) => {
        setSearchOrders(value);
    }, 500);

    useEffect(() => {
        const getOrders = async () => {
            try {
                const res = await API.getOrders({
                    params: { search: searchOrders, voucher_type: voucherType },
                });
                setOrders(res.data);
            } catch (e) {
                console.error(e);
            }
        };
        searchOrders !== '' && getOrders();
    }, [searchOrders, voucherType]);

    return (
        <>
            <Item
                links={[{ path: Paths.SHOP, name: translations.shop_items }]}
                name={`${translations.shop_item} ${translations.create}`}
                itemModel={{
                    name: '',
                    image_url: imageUrl,
                    start_stock: 0,
                    expire_at: '',
                    visible_until: '',
                    type: 'avatar',
                    redeem_description: '',
                    description: '',
                    voucher_type: null,
                    //price_in_cents: 0,
                    price: 0,
                    // special prop for deleting
                    importCodesField: voucherOrderID,
                    //
                    order_id: orderID,
                    active: false,
                    deactivate_on_sold: false,
                }}
                edit={true}
                onSubmit={(event) => {
                    setLoading(true);
                    error && setError(null);
                    delete event.importCodesField;

                    store
                        .addItem({
                            ...event,
                            order_id: orderID !== '' ? orderID?.value : event.order_id,
                            type: orderIdHaveValue ? 'consumable' : event.type,
                            // price_in_cents:
                            //     voucherType !== 'eguma' ? undefined : event.price_in_cents,
                        })
                        .then(() => {
                            setLoading(false);
                            history.push(Paths.SHOP);
                        })
                        .catch((err) => {
                            setError(err);
                            setLoading(false);
                        });
                }}
                onCancel={() => history.goBack()}
                watchedProperties={['price']}
                watchedPropertyActions={{ price: setPriceInCents }}
                error={error}
                loading={loading}
                determineInputType={({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    control,
                    getValues,
                    reset,
                }) =>
                    getShopFormInput({
                        value,
                        name,
                        register,
                        error,
                        disabled,
                        index,
                        resetImageValue: (name, value) => setImageUrl({ [name]: value }),
                        resetVoucherValue: (name, value) =>
                            setVoucherOrderID((prevVoucher) => ({ ...prevVoucher, [name]: value })),
                        control,
                        handleTypeChange,
                        showVoucherTypes,
                        searchOrdersHandler,
                        voucherType,
                        setVoucherType: (e) => {
                            handleVoucherType(e, getValues, reset);
                        },
                        voucherOrderID,
                        orderID,
                        setOrderID,
                        handleOrder: (e) => handleOrder(e, getValues, reset),
                        orders,
                        orderIdHaveValue,
                        price_in_cents: priceInCents,
                    })
                }
                resetProp={[imageUrl, voucherOrderID]}
            ></Item>
        </>
    );
};

export default NewItem;
