import React, { useEffect, useState, useRef } from 'react';
import API from '../../api/teams';
import Button from '../../components/Button';
import translations from '../../translations/de';
import { FaPlus, FaSearch, FaTrash, FaUndoAlt } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import Table from '../../components/Table';
import { formatDate } from '../../helpers/formatDate';
import ItemImage from '../../components/ItemImage';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';

const StaffTab = () => {
    const [staff, setStaff] = useState([]);
    const { team_id } = useParams();
    const history = useHistory();
    const [staffMemberID, setStaffMemberID] = useState('');
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);

    const handleStaffMember = (staff_member_id) => {
        history.push({
            pathname: `/teams/${team_id}/staff/${staff_member_id}`,
        });
    };

    const isNotDeletedAlready = (row) => {
        return !row.original.deleted_at;
    };

    const deleteStaffMember = () => {
        try {
            API.deleteStaff(staffMemberID).then(() => {
                var temp = [...staff];
                temp.forEach((member) => {
                    if (member.id === staffMemberID) {
                        member.deleted_at = 'deleted';
                    }
                });
                setStaff(temp);
            });
        } catch (e) {
            console.log(e);
        }
    };

    const reviveStaffMember = (id) => {
        try {
            API.reviveStaff(id).then(() => {
                var temp = [...staff];
                staff.forEach((member) => {
                    if (member.id === id) {
                        member.deleted_at = null;
                    }
                });
                setStaff(temp);
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const getAllStaff = async () => {
            try {
                const res = await API.getAllStaff(team_id, {
                    params: {
                        limit: 100,
                    },
                });
                setStaff(res.data.items);
            } catch (e) {
                console.log(e);
            }
        };
        getAllStaff();
    }, [team_id]);

    useEffect(() => {
        history.location.state = { from: 'staff' };
    }, [history.location]);

    return (
        <>
            <div style={{ marginLeft: '2.5%' }}>
                <Button
                    label={translations.staff}
                    onClick={() => {
                        history.push(`${team_id}/new_staff_member`);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {staff.length !== 0 && (
                <Table
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.image,
                            accessor: 'image_url',
                            Cell: ({ value, row }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="team" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.first_name,
                            accessor: 'first_name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.last_name,
                            accessor: 'last_name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.birthday,
                            accessor: 'date_of_birth',
                            Cell: ({ value, row }) => (
                                <div>
                                    <p>{formatDate(value)}</p>
                                </div>
                            ),
                        },
                        {
                            Header: 'Elite Sport Link',
                            accessor: 'elite_sports_link',
                            Cell: ({ value, row }) => (
                                <div>
                                    <a href={`${value}`} target="_blank" rel="noreferrer">
                                        {value}
                                    </a>
                                </div>
                            ),
                        },
                        {
                            Header: translations.role,
                            accessor: 'role',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => handleStaffMember(row.cells[0].value)}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    {isNotDeletedAlready(row) ? (
                                        <Button
                                            onClick={() => {
                                                setStaffMemberID(row.cells[0].value);
                                                setModal(true);
                                            }}
                                            title={translations.delete}
                                        >
                                            <FaTrash />
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => reviveStaffMember(row.cells[0].value)}
                                        >
                                            <FaUndoAlt />
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    data={staff.map(
                        ({
                            id,
                            first_name,
                            last_name,
                            image_url,
                            date_of_birth,
                            role,
                            elite_sports_link,
                            deleted_at,
                        }) => {
                            return {
                                id,
                                first_name,
                                last_name,
                                image_url,
                                date_of_birth,
                                role,
                                elite_sports_link,
                                deleted_at,
                            };
                        },
                    )}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Staff Member"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete staff member with id ${staffMemberID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                deleteStaffMember();
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default StaffTab;
