import React, { useState, useEffect } from 'react';
import './BusinessEvent.scss';
import { observer } from 'mobx-react-lite';
import translations from '../../translations/de';
import BusinessProfileTable from './BusinessProfileTable';
import APIProfiles from '../../api/businessProfiles';
import APIPartners from '../../api/businessPartners';
import Input from '../../components/Input';
import Select from '../../components/Select';
import debounce from 'lodash.debounce';
import partnerTypes from '../../constants/partnerTypes';
import { useParams } from 'react-router-dom';

const Invitations = ({ store, title = 'INVITATIONS', className, isEdit = false }) => {
    const [profiles, setProfiles] = useState([]);
    const [searchProfile, setSearchProfile] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [partners, setPartners] = useState([]);
    const [searchPartner, setSearchPartner] = useState('');
    const { event_id } = useParams();

    const onSearchProfileHandler = debounce((e) => {
        setPage(1);
        setSearchProfile(e.target.value);
    }, 200);

    const onSearchPartnerHandler = debounce((e) => {
        setSearchPartner(e);
    }, 200);

    const onPartnerSelectHandler = async (e) => {
        const ids = e.map((p) => p.value);
        try {
            const res = await APIProfiles.getProfilesByPartners({
                params: {
                    company_ids: ids.join(','),
                },
            });
            const removeProfiles = [];
            store.partners_profiles.forEach((profile) => {
                if (!res.data.find((d) => d.id === profile.id)) {
                    removeProfiles.push(profile);
                }
            });
            store.addRemovePartnersInvitations(res.data, removeProfiles);
            store.setPartnersProfiles(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getBusinessPartnersByType = async (e) => {
        const query = e.map((type) => type.value);
        if (query.length !== 0) {
            try {
                const res = await APIPartners.getPartners({
                    params: {
                        type: query.join(','),
                        limit: 1000,
                    },
                });
                setPartners(res.data.items);
                store.setSelectedPartners(
                    res.data.items.map((par) => ({
                        value: par.id,
                        label: par.name,
                    })),
                );
                onPartnerSelectHandler(store.selected_partners);
            } catch (error) {
                console.log(error);
            }
        } else {
            setPartners([]);
            store.setSelectedPartners([]);
            onPartnerSelectHandler(store.selected_partners);
        }
    };

    const onPartnerSelect = debounce((e) => {
        store.setSelectedPartners(e);
        onPartnerSelectHandler(e);
    }, 200);

    const onPartnerTypeSelect = debounce((e) => {
        store.setSelectedPartnerTypes(e);
        getBusinessPartnersByType(e);
    }, 200);

    useEffect(() => {
        const getBusinessProfiles = async () => {
            try {
                const res = await APIProfiles.getProfiles({
                    params: {
                        search: searchProfile ? searchProfile : undefined,
                        page,
                        event_id: event_id ?? undefined,
                    },
                });
                setProfiles(res.data.items);
                setTotalPages(res.data.total_pages_count);
            } catch (error) {
                console.log(error);
            }
        };
        getBusinessProfiles();
    }, [searchProfile, page, event_id]);

    useEffect(() => {
        const getBusinessPartners = async () => {
            try {
                const res = await APIPartners.getPartners({
                    params: {
                        search: searchPartner ? searchPartner : undefined,
                    },
                });
                setPartners(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchPartner && getBusinessPartners();
    }, [searchPartner]);

    return (
        <div className={className}>
            <div className="tab-controls">
                {title && <h2 className="title">{title}</h2>}
                <Input
                    label={translations.people}
                    placeholder={translations.search_name_email}
                    onChange={onSearchProfileHandler}
                    style={{ marginRight: '80px' }}
                />
                {!isEdit && (
                    <>
                        <Select
                            label="Select by Partner Type"
                            options={partnerTypes}
                            multiple={true}
                            defaultValue={store.selected_partner_types}
                            onChangeCallback={onPartnerTypeSelect}
                            placeholder={translations.select}
                        />
                        <Select
                            label="Select by Business Partner"
                            options={partners.map((par) => ({ value: par.id, label: par.name }))}
                            multiple={true}
                            value={store.selected_partners}
                            onChangeCallback={onPartnerSelect}
                            onInputChange={onSearchPartnerHandler}
                            placeholder={translations.search_partner_name}
                            noOptionsMsg={translations.noPartnersOptions}
                        />
                    </>
                )}

                <p className="feedback" style={{ visibility: 'hidden' }}>
                    {`${store.creating_invitations.length} People selected `}
                </p>
            </div>
            <BusinessProfileTable
                profiles={profiles}
                store={store}
                onPageChange={(p) => setPage(p)}
                totalPages={totalPages}
                selectedPage={page}
                isEdit={isEdit}
            />
        </div>
    );
};

export default observer(Invitations);
