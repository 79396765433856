const uniqueById = (items) => {
    const set = new Set();
    return items.filter((item) => {
        const isDuplicate = set.has(item.id);
        set.add(item.id);
        return !isDuplicate;
    });
};

export default uniqueById;
