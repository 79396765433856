import React from 'react';
import './Loader.scss';

export default function Loader({ size = '', theme = '', ...restProps }) {
    return (
        <div
            className={['Loader', size === 'big' ? 'big' : '', theme === 'dark' ? 'dark' : ''].join(
                ' ',
            )}
            {...restProps}
        />
    );
}
