import { types } from 'mobx-state-tree';
import AcquiredItem from './AcquiredItem';

const FanAnswer = types.model('FanAnswer', {
    id: types.identifierNumber,
    fan_game_answer: types.frozen({ text: '', image_url: '' }),
    fan_user: types.frozen({
        email: '',
        first_name: '',
        last_name: '',
        acquired_items: types.array(AcquiredItem),
    }),
    fan_user_id: types.number,
});

export default FanAnswer;
