import { types, flow, applySnapshot } from 'mobx-state-tree';
import BusinessProfile from '../businessProfilesStore/BusinessProfile';
import API from './../../api/businessEvents';
import dateToISOStringInObj from '../../helpers/dateToISOStringInObj';
import clearEmptyFields from '../../helpers/clearEmptyFields';
import clearObjNulls from '../../helpers/clearObjNulls';

const CreatingBusinessEvent = types
    .model('Creating Event', {
        title: '',
        image_url: '',
        start: '',
        end: '',
        subtitle: '',
        text: '',
        program: '',
        address: '',
        lat: types.maybeNull(types.number),
        long: types.maybeNull(types.number),
        location: '',
        creating_invitations: types.array(BusinessProfile),
        selected_partners: types.array(types.frozen({ value: '', label: '' })),
        selected_partner_types: types.array(types.frozen({ value: '', label: '' })),
        partners_profiles: types.array(BusinessProfile),
        is_respondable: false,
        time_to_respond: types.maybeNull(types.string, ''),
    })
    .actions((self) => ({
        updateEventData(data) {
            applySnapshot(self, { ...self, ...dateToISOStringInObj(data) });
        },
        createEvent: flow(function* createEvent() {
            const profiles = self.creating_invitations.map((i) => i.id);
            const event = {
                title: self.title,
                image_url: self.image_url,
                start: self.start,
                end: self.end,
                subtitle: self.subtitle,
                text: self.text,
                program: self.program,
                location: self.location,
                address: self.address,
                lat: self.lat,
                long: self.long,
                profiles,
                is_respondable: self.is_respondable,
                time_to_respond: self.time_to_respond,
            };
            try {
                yield API.createEvent(clearEmptyFields(clearObjNulls(event)));
            } catch (error) {
                throw error;
            }
        }),
        addInvitation(profile) {
            self.creating_invitations.push(profile);
        },
        removeInvitation(id) {
            self.creating_invitations = self.creating_invitations.filter(
                (invitation) => invitation.id !== id,
            );
        },
        resetNewEvent() {
            self.title = '';
            self.subtitle = '';
            self.program = '';
            self.start = '';
            self.end = '';
            self.image_url = '';
            self.location = '';
            self.address = '';
            self.text = '';
            self.creating_invitations = [];
        },
        addRemovePartnersInvitations(res, removedProfiles) {
            const newSet = [];
            const update = [];
            res.forEach((result) => {
                const profile = self.creating_invitations.find((pr) => pr.id === result.id);

                if (!profile) {
                    newSet.push(result);
                }
            });
            self.creating_invitations.forEach((ivnivation) => {
                const profile = removedProfiles.find((pr) => pr.id === ivnivation.id);

                if (!profile) {
                    update.push(ivnivation);
                }
            });
            self.creating_invitations = [...update, ...newSet];
        },
        setSelectedPartners(partners) {
            self.selected_partners = partners;
        },
        setSelectedPartnerTypes(types) {
            self.selected_partner_types = types;
        },
        setPartnersProfiles(profiles) {
            self.partners_profiles = profiles;
        },
    }));

export default CreatingBusinessEvent;
