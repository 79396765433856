import { types, applySnapshot } from 'mobx-state-tree';
import BusinessProfile from '../businessProfilesStore/BusinessProfile';
import dateToISOStringInObj from '../../helpers/dateToISOStringInObj';

const BusinessEvent = types
    .model('Event', {
        id: types.identifierNumber,
        title: types.optional(types.string, ''),
        image_url: types.optional(types.string, ''),
        start: types.maybeNull(types.string),
        end: types.maybeNull(types.string),
        subtitle: types.optional(types.string, ''),
        text: types.optional(types.string, ''),
        program: types.maybeNull(types.optional(types.string, '')),
        location: types.optional(types.string, ''),
        address: types.optional(types.string, ''),
        attendees: types.array(BusinessProfile),
        invitations: types.array(BusinessProfile),
        participants: types.array(BusinessProfile),
        is_respondable: types.boolean,
        time_to_respond: types.maybeNull(types.string),
    })
    .actions((self) => ({
        editEvent(edited) {
            applySnapshot(self, { ...self, ...dateToISOStringInObj(edited) });
        },
        setAdditionalData(data) {
            self.attendees = data.attendees;
            self.invitations = data.invitations;
            self.participants = data.participants;
        },
    }));
export default BusinessEvent;
