import fanGamesTabs from '../constants/fanGameTabs';

const getFanGameTabName = (val) => {
    let activeTab = fanGamesTabs.find((obj) => {
        return obj.value === val;
    });

    return activeTab.label;
};

export default getFanGameTabName;
