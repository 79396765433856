import api from './apiLibrary';

const API = {
    getSeasons(params) {
        return api.get('/api/admin/season', params);
    },
    createSeason(data) {
        return api.post('/api/admin/season', data);
    },
    editSeason(id, data) {
        return api.put(`/api/admin/season/${id}`, data);
    },
    getSeason(id) {
        return api.get(`/api/admin/season/${id}`);
    },
    deleteSeason(id) {
        return api.delete(`/api/admin/season/${id}`);
    },
    getCurrnentSeason() {
        return api.get('/api/admin/season/current');
    },
};

export default API;
