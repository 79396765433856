import api from './apiLibrary';

const API = {
    getAccesses(params) {
        return api.get('/api/admin/access', params);
    },
    getAccess(id, params) {
        return api.get(`/api/admin/access/${id}`, params);
    },
    createAccess(data) {
        return api.post('/api/admin/access', data);
    },
    editAccess(id, data) {
        return api.put(`/api/admin/access/${id}`, data);
    },
    deleteAccess(id) {
        return api.delete(`/api/admin/access/${id}`);
    },
    sendSmsTicket(id, data) {
        return api.post(`/api/admin/access/${id}/send-sms-ticket`, data);
    },
};

export default API;
