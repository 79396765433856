import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import Button from '../../components/Button';
import { useParams, useHistory } from 'react-router-dom';
import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import clearObjProps from '../../helpers/clearObjProps';
import { FaPlus } from 'react-icons/fa';
import Table from '../../components/Table';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import ItemImage from '../../components/ItemImage';
import Checkbox from '../../components/Checkbox';

const Season = ({ store }) => {
    const { season_id } = useParams();
    const history = useHistory();
    const SeasonModel = clearObjProps({ ...store.selectedSeason }, ['id', 'fan_games', 'games']);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !store.selectedSeason && store.getSeason(season_id);
    }, [store, season_id]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editSeason({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedSeason ? (
        <>
            <Item
                links={[{ path: Paths.SEASONS, name: translations.seasons }]}
                name={store.selectedSeason.title}
                itemModel={SeasonModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ name, register, error, disabled, index }) => {
                    if (name === 'title') {
                        return (
                            <Input
                                key={index}
                                label={translateLabel(name)}
                                name={name}
                                type="text"
                                {...register(name, {
                                    required: true,
                                })}
                                error={error}
                                disabled={disabled}
                            />
                        );
                    } else if (name === 'current') {
                        return (
                            <Checkbox
                                key={index}
                                disabled={disabled}
                                text={translateLabel(name)}
                                name={name}
                                {...register(name, { required: false })}
                            />
                        );
                    }
                }}
            ></Item>
            {store.selectedSeason.fan_games.length === 0 ? (
                <div style={{ marginLeft: '2.5%' }}>
                    <Button
                        label="Seasonal Game"
                        onClick={() => {
                            history.push(`/seasons/${store.selectedSeason.id}/new_fan_game`);
                        }}
                    >
                        <FaPlus style={{ marginRight: '5px' }} />
                    </Button>
                </div>
            ) : (
                <div className="Fan-Games">
                    <p className="Fan-Games-Header">Seasonal Fan Games</p>

                    {store.selectedSeason.fan_games.map((game) => (
                        <div
                            className="game"
                            key={game.id}
                            onClick={() => {
                                store.selectGame(game.id);
                                history.push(
                                    `/seasons/${store.selectedSeason.id}/fan-game/${game.id}`,
                                );
                            }}
                        >
                            <span>{translateLabel(game.fan_game_type)}</span>
                        </div>
                    ))}
                </div>
            )}
            {store.selectedSeason.games && store.selectedSeason.games.length !== 0 && (
                <Table
                    isRowClickable={false}
                    tableName={translations.matches}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.home_team,
                            accessor: 'home_team',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="home team" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.away_team,
                            accessor: 'away_team',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="away team" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.date,
                            accessor: 'date',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.time,
                            accessor: 'time',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.score,
                            accessor: 'score',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                    ]}
                    data={store.selectedSeason.games.map(
                        ({ id, home_team, away_team, date, score_away_team, score_home_team }) => {
                            return {
                                id,
                                home_team: home_team.logo_url,
                                away_team: away_team.logo_url,
                                date: new Date(date).toLocaleDateString('en-GB'),
                                time: new Date(date).toLocaleTimeString('en', {
                                    timeStyle: 'short',
                                    hour12: false,
                                }),
                                score:
                                    new Date(date) < new Date()
                                        ? `${score_home_team} : ${score_away_team}`
                                        : '',
                            };
                        },
                    )}
                />
            )}
        </>
    ) : null;
};

export default observer(Season);
