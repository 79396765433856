import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams } from 'react-router-dom';
import getInstantRewardsFormInput from './getInstantRewardsFormInput';
import translations from '../../translations/de';
import Breadcrumb from '../../components/Breadcrumb';
import TabSwitcher from '../../components/TabSwitcher';
import Acquirers from '../../components/Acquirers/Acquirers';
import ItemImage from '../../components/ItemImage';
import RewardVouchers from '../../components/RewardVouchers/RewardVouchers';

const InstantReward = ({ store }) => {
    const { reward_id } = useParams();
    const [imageUrl, setImageUrl] = useState('');
    const [voucherType, setVoucherType] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);

    const onSubmitHandler = (event) => {
        error && setError(null);
        store
            .editInstantReward({
                ...event,
            })
            .then(() => {
                setEdit(false);
            })
            .catch((err) => {
                setError(err);
            });
    };

    useEffect(() => {
        store.getInstantReward(reward_id);
        return () => {
            store.selectInstantReward(undefined);
        };
    }, [store, reward_id]);

    return store.selectedInstantReward ? (
        <>
            <div className="shop_item-header">
                <Breadcrumb
                    name={store.selectedInstantReward.name}
                    links={[{ path: '/instant-rewards', name: translations.vouchers }]}
                />
            </div>
            <TabSwitcher initialTabName={translations.general}>
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName={translations.general} />
                    <TabSwitcher.TabPill tabName={translations.acquirer} />
                    <TabSwitcher.TabPill tabName={translations.vouchers} />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName={translations.general}>
                    <Item
                        links={[{ path: '/instant-rewards', name: translations.instant_rewards }]}
                        name={store.selectedInstantReward.name}
                        hasNavigation={false}
                        id={`[instantReward_ID]: ${store.selectedInstantReward.id}`}
                        onSubmit={onSubmitHandler}
                        onCancel={() => {
                            error && setError(null);
                            setEdit(false);
                        }}
                        edit={edit}
                        onEdit={() => setEdit(true)}
                        itemModel={{
                            name: store.selectedInstantReward.name,
                            image_url: store.selectedInstantReward.image_url,
                            redeem_description: store.selectedInstantReward.redeem_description,
                            popup_title: store.selectedInstantReward.popup_title,
                            description: store.selectedInstantReward.description,
                            popup_text: store.selectedInstantReward.popup_text,
                            expire_at: store.selectedInstantReward.expire_at,
                            voucher_type: store.selectedInstantReward.voucher_type,
                            sponsor_link: store.selectedInstantReward.sponsor_link,
                            quantity: store.selectedInstantReward.quantity,
                            acquired: store.selectedInstantReward.acquired,
                            used: store.selectedInstantReward.used,
                            price_in_cents: store.selectedInstantReward.price_in_cents,
                            type: store.selectedInstantReward.type,
                            assign_after_delivery:
                                store.selectedInstantReward.assign_after_delivery,
                        }}
                        error={error}
                        loading={false}
                        watchedProperties={['voucher_type']}
                        watchedPropertyActions={{ voucher_type: setVoucherType }}
                        determineInputType={({
                            value,
                            name,
                            register,
                            control,
                            error,
                            disabled,
                            index,
                        }) =>
                            getInstantRewardsFormInput({
                                value,
                                name,
                                register,
                                control,
                                error,
                                disabled,
                                index,
                                resetValue: (name, value) => setImageUrl({ [name]: value }),
                                sendToRandom: true,
                                voucher_type: voucherType,
                                comesFromEdit: true,
                            })
                        }
                        resetProp={imageUrl}
                    >
                        <ItemImage imgSrc={store.selectedInstantReward.image_url}></ItemImage>
                    </Item>
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.acquirer}>
                    <Acquirers reward_id={reward_id} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.vouchers}>
                    <RewardVouchers />
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </>
    ) : null;
};

export default observer(InstantReward);
