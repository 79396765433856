import { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '../Button';
import Question from './Question';
import QuestionInstance from '../../models/questionsStore/NewQuestion';
import './Questions.scss';
import Table from '../Table';
import Modal from '../Modal';
import useModal from '../../customHooks/useModal';
import questionsConfig from '../../configs/questions';
import { FaTrash, FaEdit, FaPlus } from 'react-icons/fa';
import translations from '../../translations/de';
import ItemImage from '../ItemImage';

const Questions = ({ gameStore }) => {
    const [question, setQuestion] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);

    const handleSelectedQuestion = (id) => {
        gameStore.selectQuestion(id);
        setQuestion(gameStore.selectedQuestion);
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
        setLoading(false);
        setQuestion(null);
        setError('');
        gameStore.selectQuestion(null);
    };

    return (
        <div className="Questions">
            {questionsConfig.addingQuestionsAllowed(gameStore.fan_game_type) && (
                <Button
                    label="Question"
                    onClick={() => {
                        gameStore.selectQuestion(null);
                        setQuestion(QuestionInstance.create());
                        setModal(true);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            )}
            {gameStore.questions && gameStore.questions.length !== 0 && (
                <Table
                    isRowClickable={false}
                    tableName="Questions"
                    columns={[
                        {
                            Header: '',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.title,
                            accessor: 'title',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.image,
                            accessor: 'image_url',
                            Cell: ({ value, row }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="question" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.text,
                            accessor: 'text',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        title={translations.edit}
                                        onClick={() => {
                                            handleSelectedQuestion(row.cells[0].value);
                                        }}
                                    >
                                        <FaEdit />
                                    </Button>
                                    {questionsConfig.deletingQuestionsAllowed(
                                        gameStore.fan_game_type,
                                    ) && (
                                        <Button
                                            title={translations.delete}
                                            onClick={() => {
                                                gameStore.deleteQuestion(row.cells[0].value);
                                            }}
                                            style={{ marginRight: '5px' }}
                                        >
                                            <FaTrash />
                                        </Button>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                    data={gameStore.questions.map(({ id, title, image_url, text }) => {
                        return {
                            id,
                            title,
                            image_url,
                            text,
                        };
                    })}
                />
            )}
            {question && modal && (
                <Modal title="Question" onClose={closeModal} ref={modalRef}>
                    <Question
                        questionStore={question}
                        gameType={gameStore.fan_game_type}
                        onCancel={closeModal}
                        onSave={(id, event) => {
                            setLoading(true);
                            id
                                ? gameStore
                                      .editQuestion(id, event)
                                      .then(closeModal)
                                      .catch((err) => {
                                          setError(err);
                                          setLoading(false);
                                      })
                                : gameStore
                                      .addQuestion(event)
                                      .then(closeModal)
                                      .catch((err) => {
                                          setError(err);
                                          setLoading(false);
                                      });
                        }}
                        error={error}
                        loading={loading}
                    />
                </Modal>
            )}
        </div>
    );
};

export default observer(Questions);
