import { types, flow, getSnapshot, applySnapshot } from 'mobx-state-tree';
import Reward from '../rewardsStore/Reward';
import Question from '../questionsStore/Question';
import API from '../../api/games';
import APIQuestion from '../../api/questions';
import APIRewards from '../../api/rewards';
import dateToISOStringInObj from '../../helpers/dateToISOStringInObj';
import clearObjProps from '../../helpers/clearObjProps';

const Game = types
    .model('Game', {
        id: types.identifierNumber,
        fan_game_type: types.optional(types.string, ''),
        game_id: types.maybeNull(types.number),
        user_count: types.maybeNull(types.number),
        name: types.optional(types.string, ''),
        description: types.optional(types.string, ''),
        popup_title: types.optional(types.string, ''),
        popup_text: types.optional(types.string, ''),
        participation_fan_coins: types.optional(types.number, 0),
        archive_at: types.maybeNull(types.string),
        archived: types.optional(types.boolean, false),
        publish_at: types.maybeNull(types.string),
        published: types.optional(types.boolean, false),
        start_at: types.maybeNull(types.string),
        started: types.optional(types.boolean, false),
        end_at: types.maybeNull(types.string),
        ended: types.optional(types.boolean, false),
        rewards: types.array(Reward),
        questions: types.array(Question),
        opponent_logo: types.maybeNull(types.string, ''),
        game: types.maybeNull(
            types.frozen({
                id: types.identifierNumber,
                date: types.maybeNull(types.string, ''),
                home_team: types.frozen({
                    id: types.number,
                    title: '',
                    logo_url: '',
                    is_owner: false,
                }),
                away_team: types.frozen({
                    id: types.number,
                    title: '',
                    logo_url: '',
                    is_owner: false,
                }),
                home_team_id: types.number,
                away_team_id: types.number,
                location: types.optional(types.string, ''),
                scenario_id: types.number,
                phase_name: types.maybeNull(types.string, ''),
                season_id: types.number,
                penalties: types.number,
                spectators: types.number,
                score_home_team: types.number,
                score_away_team: types.number,
                one_third_home_score: types.number,
                one_third_away_score: types.number,
                two_thirds_home_score: types.number,
                two_thirds_away_score: types.number,
                three_thirds_home_score: types.number,
                three_thirds_away_score: types.number,
            }),
        ),
        result: types.maybeNull(
            types.frozen({
                first_scorer: {
                    // scorer_id: types.number,
                    // minutes: types.number,
                    // assist_one_id: types.number,
                    // assist_two_id: types.number,
                    // team_id: types.number,
                },
            }),
        ),
        selectedReward: types.maybeNull(types.number),
        selectedQuestion: types.maybeNull(types.reference(Question)),
    })
    .views((self) => ({
        getReward(id) {
            return self.rewards.find((reward) => reward.id === id);
        },
        getQuestion(id) {
            return self.questions.find((question) => question.id === id);
        },
    }))
    .actions((self) => ({
        onChangeValue(propName, value) {
            self[propName] = value;
        },
        onChangeValues(values) {
            applySnapshot(self, { ...self, ...dateToISOStringInObj(values) });
        },
        getGameDetails: flow(function* getGameDetails(id) {
            try {
                const res = yield API.getGame(id);
                self.rewards = res.data.fan_game_details.rewards;
                self.questions = res.data.fan_game_details.questions;
            } catch (error) {
                console.log('error => ' + error);
            }
        }),
        controlGame: flow(function* controlGame(option) {
            try {
                const res = yield API.controlGame(self.id, option);
                applySnapshot(self, { ...self, ...res.data });
            } catch (error) {
                throw error;
            }
        }),
        editGame: flow(function* editGame(edited) {
            const snapshot = {
                ...getSnapshot(self),
                ...edited,
            };
            const clearedSnapshoot = clearObjProps(snapshot, [
                'id',
                'fan_game_type',
                'selectedQuestion',
                'archived',
                'published',
                'started',
                'ended',
                'result',
                'game_id',
                'selectedReward',
                'game',
                'user_count',
                'questions',
            ]);
            try {
                const res = yield API.editGame(self.id, clearedSnapshoot);
                applySnapshot(self, { ...self, ...res.data });
            } catch (error) {
                throw error;
            }
        }),
        editReward: flow(function* editReward(id, edited) {
            try {
                const res = yield APIRewards.editReward(id, edited);
                const index = self.rewards.findIndex((r) => r.id === res.data.id);
                self.rewards[index].editReward(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addReward: flow(function* addReward(reward) {
            try {
                const res = yield APIRewards.createReward({
                    ...reward,
                });
                self.rewards.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        selectReward(id) {
            self.selectedReward = id;
        },
        deleteReward: flow(function* deleteReward(id) {
            try {
                yield APIRewards.deleteReward(id);
                const index = self.rewards.findIndex((r) => r.id === id);
                self.rewards.splice(index, 1);
            } catch (error) {
                console.log('error => ' + error);
            }
        }),
        selectQuestion(id) {
            self.selectedQuestion = id;
        },
        editQuestion: flow(function* editQuestion(id, edited) {
            try {
                const res = yield APIQuestion.editQuestion(id, {
                    ...edited,
                    fan_game_id: self.id,
                });
                const index = self.questions.findIndex((q) => q.id === res.data.id);
                self.questions[index].editQuestion(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addQuestion: flow(function* addQuestion(question) {
            try {
                const res = yield APIQuestion.createQuestion({ ...question, fan_game_id: self.id });
                self.questions.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteQuestion: flow(function* deleteQuestion(id) {
            try {
                yield APIQuestion.deleteQuestion(id);
                const index = self.questions.findIndex((q) => q.id === id);
                self.questions.splice(index, 1);
            } catch (error) {
                console.log('error => ' + error);
            }
        }),
        setGameResult(data) {
            applySnapshot(self, { ...self, ...data });
        },
    }));

export default Game;
