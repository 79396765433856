import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import InputFile from '../../components/InputFile/index.js';
import Select from '../../components/Select';
import Textarea from '../../components/Textarea';
import bannerSize from '../../constants/banner';
import mandatoryFields from '../../configs/banners';

export default function getBannerFormInput({
    value,
    name,
    register,
    error,
    disabled,
    index,
    control,
    resetValue,
    screens,
    bannerTypes = [],
}) {
    if (name === 'image_url') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/application-screens"
            />
        );
    } else if (name === 'screen_id') {
        return (
            <Select
                key={index}
                label={translateLabel(name.replace('_id', ''))}
                name={name}
                options={screens.map((screen) => {
                    return { value: screen.id, label: screen.name };
                })}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg="No screens"
                isRequired={true}
                isClearable
            />
        );
    } else if (name === 'banner_id') {
        let filteredBanners = bannerTypes.filter((banner) => banner.type !== null);
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name={name}
                options={filteredBanners.map((type) => {
                    return { value: type.id, label: translateLabel(type.type) };
                })}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg="No types"
                isRequired={true}
                isClearable
            />
        );
    } else if (name === 'description') {
        return (
            <Textarea
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'size') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name={name}
                options={bannerSize.map((size) => {
                    return size;
                })}
                control={control}
                error={error}
                disabled={disabled}
                isRequired={true}
                isClearable
            />
        );
    } else if (typeof value === 'string') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name=== 'position'){
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="number"
                {...register(name, {
                    valueAsNumber: true,
                    required: false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
    else
    {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
