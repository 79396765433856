import React from 'react';
import Breadcrumb from '../Breadcrumb';

import translations from '../../translations/de';
import { useParams } from 'react-router-dom';
import Paths from '../../constants/paths';

const FinalScoreBreadcrumb = () => {
    const { fan_game_id } = useParams();

    return (
        <div style={{ marginLeft: '2.5%' }}>
            <div className="Item-Breadcrumb">
                <Breadcrumb
                    links={[{ path: Paths.FAN_GAMES, name: translations.fan_games }]}
                    name={fan_game_id}
                />
            </div>
        </div>
    );
};

export default FinalScoreBreadcrumb;
