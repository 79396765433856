import { useState, useEffect, useRef } from 'react';
import Item from '../../components/Item';
import Button from '../../components/Button';
import getVouchersFormInput from './getVouchersFormInput';
import { useHistory } from 'react-router-dom';
import translations from '../../translations/de';
import API from '../../api/vouchers';
import Paths from '../../constants/paths';
import { FaUpload } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';

const NewVoucherFileUpload = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const voucherRef = useRef();
    const [inputValue, setInputValue] = useState('No File Choosen');
    const [voucherType, setVoucherType] = useState(null);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    const handleFileChange = () => {
        setInputValue(voucherRef?.current?.files[0]?.name ?? undefined);
    };

    const handleUpload = (event) => {
        if (voucherRef.current.files[0]) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', voucherRef?.current?.files[0]);
            const getVouchers = async () => {
                try {
                    await API.sendVoucherCodes(voucherType ?? 'internal', formData, {
                        params: {
                            price: Number(event.price_in_cents),
                            order_id: event?.order_id ?? undefined,
                        },
                    });
                    setLoading(false);
                    history.push(Paths.VOUCHERS);
                } catch (err) {
                    setLoading(false);
                    console.error(err);
                    setError({
                        ...err,
                        message:
                            'Something went wrong.Please check the content of uploaded file and try again.',
                    });
                }
            };
            getVouchers();
        } else {
            setError('File input empty!');
        }
    };

    return (
        <>
            <Item
                links={[{ path: '/vouchers', name: translations.vouchers }]}
                name={`${translations.vouchers} ${translations.create}`}
                itemModel={{
                    voucher_type: 'internal',
                    price_in_cents: 0,
                    order_id: null,
                }}
                edit={true}
                btnSaveLabel={translations.upload}
                onSubmit={handleUpload}
                onCancel={() => history.goBack()}
                error={error}
                loading={loading}
                watchedProperties={['voucher_type']}
                watchedPropertyActions={{ voucher_type: setVoucherType }}
                determineInputType={({
                    value,
                    name,
                    register,
                    error,
                    isDisabled,
                    index,
                    control,
                }) =>
                    getVouchersFormInput({
                        value,
                        name,
                        register,
                        error,
                        isDisabled,
                        index,
                        control,
                        type: '',
                        voucherType,
                    })
                }
            >
                {
                    <div className="Input">
                        <div className="label">
                            <span>{translations.voucher_code} </span>
                        </div>
                        <div className="Input-File">
                            <input
                                type="text"
                                value={inputValue}
                                onClick={(e) => {
                                    e.preventDefault();
                                    voucherRef.current.click();
                                }}
                                required={true}
                                onChange={() => {}}
                            />
                            <input
                                type="file"
                                ref={voucherRef}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={handleFileChange}
                                label={translations.voucher_code}
                                name="voucher_code"
                                required={true}
                                style={{ display: 'none' }}
                            />
                            <div className="Input-File-Controls">
                                <Button
                                    title="Upload Local Files"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        voucherRef.current.click();
                                    }}
                                >
                                    <FaUpload />
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </Item>
        </>
    );
};

export default observer(NewVoucherFileUpload);
