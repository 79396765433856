const mandatoryFields = [
    'team_id',
    'shot_side',
    'position',
    'external_player_id',
    'nation',
    'first_name',
    'last_name',
    'number',
];

export default mandatoryFields;
