import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import Input from '../../components/Input';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import { FaTrash, FaPlus, FaSearch, FaEye, FaEyeSlash } from 'react-icons/fa';
import Pagination from './../../components/Pagination';
import debounce from 'lodash.debounce';
import translations from '../../translations/de';
import Select from '../../components/Select';
import itemTypes from '../../constants/shopItemTypes';
import ItemImage from '../../components/ItemImage';

const Items = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [itemID, setItemID] = useState(null);
    const [searchQuery, setSearchQuery] = useState(store.searchQuery);
    const [itemType, setItemType] = useState('');

    
    const handleItem = (id) => {
        store.selectItem(id);
        history.push(`/shop/${store.selectedItem.id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
        store.setSearchQuery(e.target.value);
    }, 500);

    const handleActive = (id, active) => {
        store.editActive(id, active);
    };

    useEffect(() => {
        store.getShopItems(searchQuery, itemType);
    }, [store, store.selectedPage, searchQuery, itemType]);

    return (
        <>
            <div className="tab-controls">
                <Input
                    label={`${translations.shop_item} ${translations.name} / ${translations.description}`}
                    placeholder={translations.search}
                    defaultValue={searchQuery}
                    onChange={onSearchChangeHandler}
                />
                <Select
                    label={`${translations.shop_item} ${translations.type}`}
                    defaultValue={{ label: 'All', value: '' }}
                    options={[{ label: 'All', value: '' }, ...itemTypes]}
                    onChange={(e) => {
                        store.setSelectedPage(1);
                        setItemType(e.value);
                    }}
                />
            </div>
            <Button
                label={translations.shop_item}
                onClick={() => {
                    store.selectItem(undefined);
                    history.push(Paths.SHOP_NEW_ITEM);
                }}
                style={{ marginLeft: '2.5%' }}
            >
                <FaPlus style={{ marginRight: '5px' }} />
            </Button>
            <Table
                tablePagination={
                    store.totalPages > 1 && (
                        <Pagination
                            totalPages={store.totalPages}
                            selectedPage={store.selectedPage}
                            onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                        />
                    )
                }
                tableName="Items"
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.shop_item_name,
                        accessor: 'name',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.image,
                        accessor: 'image_url',
                        Cell: ({ value, row }) => (
                            <div>
                                <ItemImage imgSrc={value} alt="answer" listView={true} />
                            </div>
                        ),
                    },
                    {
                        Header: translations.stock,
                        accessor: 'stock',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.sold,
                        accessor: 'sold',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.used,
                        accessor: 'used',
                        Cell: ({ value, row }) => (
                            <div>{row.cells[6].value === 'consumable' ? value : '/'}</div>
                        ),
                    },
                    {
                        Header: translations.type,
                        accessor: 'type',
                        Cell: ({ value }) => <div>{translations[value]}</div>,
                    },
                    {
                        Header: '',
                        accessor: 'action',
                        Cell: ({ value, row }) => (
                            <div>
                                <Button
                                    onClick={() => {
                                        handleItem(row.cells[0].value);
                                    }}
                                    title={translations.show}
                                >
                                    <FaSearch />
                                </Button>
                                {value ? (
                                    <Button onClick={() => handleActive(row.cells[0].value)}>
                                        <FaEye />
                                    </Button>
                                ) : (
                                    <Button onClick={() => handleActive(row.cells[0].value)}>
                                        <FaEyeSlash />
                                    </Button>
                                )}
                                <Button
                                    onClick={() => {
                                        setItemID(row.cells[0].value);
                                        setModal(true);
                                    }}
                                    title={translations.delete}
                                >
                                    <FaTrash />
                                </Button>
                            </div>
                        ),
                    },
                ]}
                data={store.items.map(
                    ({ id, name, image_url, sold, used, stock, type, active }) => {
                        return {
                            id,
                            name,
                            image_url,
                            stock,
                            sold,
                            used,
                            type,
                            action: active,
                        };
                    },
                )}
            />

            {modal && (
                <Modal
                    title="Delete Shop Item"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this shop item?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteItem(itemID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(Items);
