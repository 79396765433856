import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams, Link } from 'react-router-dom';
import getVouchersFormInput from './getVouchersFormInput';
import translations from '../../translations/de';
import RedeemData from './RedeemData';
import ItemImage from '../../components/ItemImage';
import './Voucher.scss';
import { FaLongArrowAltRight } from 'react-icons/fa';
import rewardsAPI from '../../api/rewards';
import shopItemsAPI from '../../api/shopItems';
import debounce from 'lodash.debounce';

const Voucher = ({ store }) => {
    const { voucher_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shopItems, setShopItems] = useState(
        store?.selectedVoucher?.shop_item ? [{ ...store.selectedVoucher.shop_item }] : [],
    );
    const [rewards, setRewards] = useState(
        store?.selectedVoucher?.reward ? [{ ...store.selectedVoucher.reward }] : [],
    );
    const [searchShopItems, setSearchShopItems] = useState('');
    const [searchRewards, setSearchRewards] = useState('');

    useEffect(() => {
        store.getVoucher(voucher_id).then(() => {
            if (store?.selectedVoucher?.shop_item) {
                setShopItems([{ ...store.selectedVoucher.shop_item }]);
            }
            if (store?.selectedVoucher?.reward) {
                setRewards([{ ...store.selectedVoucher.reward }]);
            }
        });
        return () => {
            store.selectVoucher(undefined);
        };
    }, [store, voucher_id]);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editVoucher({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        const getRewards = async () => {
            try {
                const res = await rewardsAPI.getAllRewards({
                    params: {
                        search: searchRewards,
                    },
                });
                setRewards(res.data.items);
            } catch (error) {
                console.log('Error in getting rewards from server');
            }
        };
        searchRewards && getRewards();
    }, [searchRewards]);

    useEffect(() => {
        const getShopItems = async () => {
            try {
                const res = await shopItemsAPI.getShopItems({
                    params: {
                        search: searchShopItems,
                    },
                });
                setShopItems(res.data.items);
            } catch (error) {
                console.log('Error in getting shop items from server');
            }
        };
        searchShopItems && getShopItems();
    }, [searchShopItems]);

    const searchRewardsHandler = debounce((value) => {
        setSearchRewards(value);
    }, 500);

    const searchShopItemsHandler = debounce((value) => {
        setSearchShopItems(value);
    }, 500);

    return store.selectedVoucher ? (
        <>
            <Item
                links={[{ path: '/vouchers', name: translations.voucher }]}
                name={store.selectedVoucher.id}
                id={`[voucher_ID]: ${store.selectedVoucher.id}`}
                itemModel={{
                    price_in_cents: store.selectedVoucher.price_in_cents,
                    voucher_code: store.selectedVoucher.voucher_code,
                    used_at: store.selectedVoucher.used_at,
                    expiry_date: store.selectedVoucher.reward?.expire_at,
                    order_id: store.selectedVoucher.order_id,
                    reward_id: store.selectedVoucher.reward_id,
                    shop_item_id: store.selectedVoucher.shop_item_id,
                }}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                canEdit={true}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getVouchersFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        searchRewardsHandler,
                        searchShopItemsHandler,
                        rewards: rewards,
                        shopItems: shopItems,
                    })
                }
            >
                {
                    <div className="voucher-type">
                        {store?.selectedVoucher?.shop_item && (
                            <>
                                <ItemImage imgSrc={store.selectedVoucher.shop_item.image_url} />
                                <div className="details">
                                    <p>{translations.shop_item}</p>
                                    <p>
                                        Id:{' '}
                                        <Link to={`/shop/${store.selectedVoucher.shop_item.id}`}>
                                            <FaLongArrowAltRight />{' '}
                                            <span>{store.selectedVoucher.shop_item.id}</span>
                                        </Link>{' '}
                                    </p>
                                    <p>
                                        {translations.name}{' '}
                                        <span>{store.selectedVoucher.shop_item.name}</span>
                                    </p>
                                    <p>
                                        {translations.description}{' '}
                                        <span>{store.selectedVoucher.shop_item.description}</span>
                                    </p>
                                </div>
                            </>
                        )}
                        {store?.selectedVoucher?.reward && (
                            <>
                                <ItemImage imgSrc={store.selectedVoucher.reward.image_url} />
                                <div className="details">
                                    <p>{translations.reward}</p>
                                    <p>
                                        Id: <span>{store.selectedVoucher.reward.id}</span>
                                    </p>
                                    <p>
                                        {translations.name}{' '}
                                        <span>{store.selectedVoucher.reward.name}</span>
                                    </p>
                                    <p>
                                        {translations.description}{' '}
                                        <span>{store.selectedVoucher.reward.description}</span>
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                }
            </Item>
            {store.selectedVoucher.redeem_data && (
                <div style={{ width: '100%' }}>
                    <RedeemData store={store} />
                </div>
            )}
        </>
    ) : null;
};

export default observer(Voucher);
