import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import InputFile from '../../components/InputFile/index.js';
import DatePicker from '../../components/DatePicker';
import Select from '../../components/Select';
import mandatoryFields from '../../configs/staff';

import translations from '../../translations/de.js';

export default function getStaffMemberFormInput({
    value,
    name,
    register,
    error,
    disabled,
    index,
    resetValue,
    control,
    team_acronym,
    staffRoles = [],
    staffSection = [],
    staffCount,
}) {
    if (name === 'image_url') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: false,
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder={`uploads/teams/${team_acronym}/staff`}
            />
        );
    } else if (name === 'sort_num') {
        let options = [];
        for (let i = 1; i <= staffCount; i++) {
            options.push({
                value: i,
                label: i,
            });
        }
        return (
            <Select
                key={`index`}
                name={name}
                label={translateLabel(translations.staff_order)}
                options={options}
                control={control}
                placeholder={translations.staff_order_placeholder}
                error={error}
                defaultValue={value}
                disabled={disabled}
                noOptionsMsg={translations.no_staff_order_found}
                isClearable
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'date_of_birth') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translateLabel('select_date')}
                name={name}
                defaultValue={new Date(value).setHours(6, 0, 0, 0)}
                control={control}
                error={error}
                disabled={disabled}
                //withTime
            />
        );
    } else if (name === 'role') {
        return (
            <Select
                key={index}
                isSearchable={false}
                label={translateLabel(name)}
                name={name}
                disabled={disabled}
                options={staffRoles}
                control={control}
                error={error}
                isRequired={true}
            />
        );
    } else if (name === 'section') {
        return (
            <Select
                key={index}
                isSearchable={false}
                label={translateLabel(name)}
                name={name}
                disabled={disabled}
                options={staffSection}
                control={control}
                error={error}
                isRequired={true}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
