import React, { useEffect, useState } from 'react';
import API from '../../api/rewards';
import Select from '../Select';
import translations from '../../translations/de';
import debounce from 'lodash.debounce';

const FindRewardTemplate = ({
    setResetProp,
    rewardObj,
    setType,
    isDisabled,
    setVoucherType = null,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [shopItems, setShopItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const chooseFromShopHander = (e) => {
        var item = e?.value && shopItems.find((i) => i.name === e.value);
        item?.type && setType(item.type);
        setResetProp(
            item
                ? {
                      image_url: item.image_url,
                      name: item.name,
                      description: item.description,
                      redeem_description: item.redeem_description,
                      type: item.type,
                      voucher_type: item.voucher_type ?? '',
                      popup_text: item.popup_text ?? '',
                      popup_title: item.popup_title ?? '',
                      quantity: item.quantity ?? 0,
                      expire_at: item.expire_at ?? '',
                      use_unused: item.use_unused ?? false,
                  }
                : {
                      image_url: '',
                      name: '',
                      description: '',
                      redeem_description: '',
                      type: null,
                      voucher_type: null,
                      popup_text: '',
                      popup_title: '',
                      quantity: 0,
                      expire_at: '',
                      use_unused: false,
                  },
        );
        setVoucherType && setVoucherType(item?.voucher_type);
    };

    const searchItemHander = debounce((value) => {
        setSearchQuery(value);
    }, 500);

    useEffect(() => {
        const getItems = async () => {
            try {
                setLoading(true);
                const res = await API.getRewardTemplates({
                    params: {
                        search: searchQuery,
                    },
                });
                setShopItems(res.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        searchQuery && getItems();
    }, [searchQuery]);

    return (
        <>
            <Select
                isLoading={loading}
                label="CHOOSE TEMPLATE"
                isDisabled={isDisabled}
                options={shopItems.map((item) => ({
                    value: item.name,
                    label: item.name,
                }))}
                defaultValue={
                    rewardObj.name ? { value: rewardObj.name, label: rewardObj.name } : null
                }
                placeholder={translations.search_reward}
                noOptionsMsg="No items found"
                onInputChange={searchItemHander}
                onChangeCallback={chooseFromShopHander}
                isClearable
            />
        </>
    );
};

export default FindRewardTemplate;
