import { types, applySnapshot } from 'mobx-state-tree';
import Sector from './../sectorsStore/Sector';

const SeatGroup = types
    .model('SeatGroup', {
        id: types.identifierNumber,
        title: types.optional(types.string, ''),
        sector: types.maybeNull(Sector),
        sector_id: types.number,
    })
    .actions((self) => ({
        editSeatGroup(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default SeatGroup;
