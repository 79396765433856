import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import {
    FaTrash,
    FaFile,
    FaPlus,
    FaSearch,
    FaCheck,
    FaTimes,
    FaShopify,
    FaAward,
} from 'react-icons/fa';
import translations from '../../translations/de';
import debounce from 'lodash.debounce';
import Input from '../../components/Input';
import Pagination from '../../components/Pagination';
import { formatTime, formatDate } from '../../helpers/formatDate';

const VouchersGastro = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [voucherID, setVoucherID] = useState(null);
    const [searchQuery, setSearchQuery] = useState();

    useEffect(() => {
        store.getVouchers(searchQuery, 'eguma');
    }, [store, store.selectedPage, searchQuery]);

    const handleVoucher = (id) => {
        store.selectVoucher(id);
        history.push(`/gastro-vouchers/${store.selectedVoucher.id}`);
    };

    const onSearchChangeHandler = debounce((e) => {
        store.setSelectedPage(1);
        setSearchQuery(e.target.value);
    }, 500);

    return (
        <div style={{ width: '100%' }}>
            <div className="tab-controls">
                <Input placeholder={translations.search} onChange={onSearchChangeHandler} />
            </div>
            <div className="tab-controls">
                <Button
                    label={translations.new_voucher_manually}
                    onClick={() => {
                        store.selectVoucher(undefined);
                        history.push(Paths.NEW_GASTRO_VOUCHER);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
                <div style={{ marginLeft: '25px' }}>
                    <Button
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                            store.selectVoucher(undefined);
                            history.push(Paths.NEW_GASTRO_VOUCHER_FROM_FILE);
                        }}
                    >
                        <FaPlus style={{ marginRight: '5px' }} />
                        {translations.new_voucher_from_file}
                        <FaFile style={{ marginLeft: '5px' }} />
                    </Button>
                </div>
            </div>
            <Table
                tablePagination={
                    store.totalPages > 1 && (
                        <Pagination
                            totalPages={store.totalPages}
                            selectedPage={store.selectedPage}
                            onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                        />
                    )
                }
                tableName={translations.vouchers}
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.price_in_cents,
                        accessor: 'price_in_cents',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.type,
                        accessor: 'item_type',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.order_id,
                        accessor: 'order_id',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.voucher_code,
                        accessor: 'voucher_code',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.used,
                        accessor: 'used',
                        Cell: ({ value }) => (
                            <div>
                                {value === true ? (
                                    <FaCheck className="confirm" />
                                ) : (
                                    <FaTimes className="error" />
                                )}
                            </div>
                        ),
                    },
                    {
                        Header: translations.expiry_date,
                        accessor: 'expiry_date',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },

                    {
                        Header: '',
                        accessor: 'action',
                        Cell: ({ row }) => (
                            <div>
                                <Button
                                    onClick={() => {
                                        handleVoucher(row.cells[0].value);
                                    }}
                                    title={translations.show}
                                >
                                    <FaSearch />
                                </Button>
                                <Button
                                    onClick={() => {
                                        setVoucherID(row.cells[0].value);
                                        setModal(true);
                                    }}
                                    title={translations.delete}
                                >
                                    <FaTrash />
                                </Button>
                            </div>
                        ),
                    },
                ]}
                data={store.vouchers.map(
                    ({
                        id,
                        price_in_cents,
                        voucher_code,
                        redeem_data,
                        expiry_date,
                        order_id,
                        shop_item_id,
                        reward_id,
                    }) => {
                        return {
                            id,
                            price_in_cents,
                            voucher_code,
                            used: redeem_data ? true : false,
                            expiry_date: `${formatDate(expiry_date)} - ${formatTime(expiry_date)}`,
                            order_id,
                            item_type: shop_item_id ? (
                                <FaShopify
                                    title="Shop item"
                                    style={{ color: 'green', fontSize: '24px' }}
                                />
                            ) : reward_id ? (
                                <FaAward
                                    title="Reward"
                                    style={{ color: 'green', fontSize: '24px' }}
                                />
                            ) : (
                                <FaTimes className="error" />
                            ),
                        };
                    },
                )}
            />
            {modal && (
                <Modal
                    title="Delete Voucher"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this voucher?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteVoucher(voucherID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default observer(VouchersGastro);
