const formatName = (name) => {
    if (name === '') {
        return name.toUpperCase();
    }
    let words = name?.split('_');
    let newName = words?.join(' ');
    return newName.toUpperCase();
};

export default formatName;
