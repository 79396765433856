import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import getFeedFormInput from './getFeedFormInput.js';
import clearObjProps from '../../helpers/clearObjProps';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import { useParams } from 'react-router';

const Feed = ({ store }) => {
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { feed_id } = useParams();
    const feedModelEdit = clearObjProps({ ...store.selectedFeed }, ['id', 'deleted_at']);
    const [resetProp, setResetProp] = useState(null);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        Object.keys(e).forEach((key) => {
            if (e[key] === '') {
                e[key] = null;
            }
        });
        store
            .editFeed({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        store.getFeed(feed_id);
        return () => {
            store.selectFeed(undefined);
        };
    }, [store, feed_id]);

    return store.selectedFeed ? (
        <>
            <Item
                links={[{ path: Paths.FEEDS, name: translations.feeds }]}
                name={`${store.selectedFeed.id}`}
                id={`[ feed_ID: ${store.selectedFeed.id} ]`}
                itemModel={feedModelEdit}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getFeedFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        resetValue: (name, value) => setResetProp({ [name]: value }),
                    })
                }
                resetProp={resetProp}
            />
        </>
    ) : null;
};

export default observer(Feed);
