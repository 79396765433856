const mandatoryFields = [
    'name',
    'image_url',
    'stock',
    'start_stock',
    'type',
    'description',
    'visible_until',
    'expire_at',
];

export default mandatoryFields;
