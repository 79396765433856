import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import Item from '../../components/Item';
import getBusinessProfileFormInput from './getBusinessProfileFormInput';
import API from '../../api/users';
import APIPartners from '../../api/businessPartners';
import debounce from 'lodash.debounce';
import ItemImage from '../../components/ItemImage';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import clearObjProps from '../../helpers/clearObjProps';

const BusinessProfile = ({ store }) => {
    const { profile_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const BusinessProfileModel = clearObjProps({ ...store.selectedProfile }, [
        'id',
        'user',
        'company',
    ]);
    const [users, setUsers] = useState(
        store?.selectedProfile?.user ? [{ ...store.selectedProfile.user }] : [],
    );
    const [partners, setPartners] = useState(
        store?.selectedProfile?.company ? [{ ...store.selectedProfile.company }] : [],
    );
    const [searchQueryPartner, setSearchQueryPartner] = useState('');

    const [resetProp, setResetProp] = useState(null);

    useEffect(() => {
       
            store.getProfile(profile_id).then(() => {
                setUsers(store.selectedProfile.user ? [{ ...store.selectedProfile.user }] : []);
                setPartners([{ ...store.selectedProfile.company }]);
            });
            return () => {
                store.selectProfile(undefined);
            };
    }, [store, profile_id]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await API.getUsers({
                    params: {
                        search: searchQuery,
                        limit: 50,
                    },
                });
                setUsers(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchQuery && getUsers();
    }, [searchQuery]);

    useEffect(() => {
        const getPartners = async () => {
            try {
                const res = await APIPartners.getPartners({
                    params: {
                        search: searchQueryPartner,
                        limit: 50,
                    },
                });
                setPartners(res.data.items);
            } catch (error) {
                console.log(error);
            }
        };
        searchQueryPartner && getPartners();
    }, [searchQueryPartner]);

    const searchQueryHandler = debounce((value) => {
        setSearchQuery(value);
    }, 500);
    const searchQueryHandlerPartner = debounce((value) => {
        setSearchQueryPartner(value);
    }, 500);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        store
            .editProfile({
                ...event,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };
    return store.selectedProfile ? (
        <>
            <Item
                links={[{ path: Paths.BUSINESS_PROFILES, name: translations.business_profiles }]}
                name={store.selectedProfile.first_name + ' ' + store.selectedProfile.last_name}
                id={`[ Business_Profile_ID: ${store.selectedProfile.id}]`}
                itemModel={BusinessProfileModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ value, name, register, control, error, disabled, index }) =>
                    getBusinessProfileFormInput({
                        value,
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                        users: users,
                        partners: partners,
                        searchQueryHandler,
                        searchQueryHandlerPartner,
                        resetValue: (name, value) => setResetProp({ [name]: value }),
                    })
                }
                resetProp={resetProp}
            >
                <ItemImage imgSrc={store.selectedProfile.avatar} isAvatar />
            </Item>
        </>
    ) : null;
};

export default observer(BusinessProfile);
