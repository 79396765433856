import React, { useState, useEffect } from 'react';
import API from '../../api/games';
import Table from '../Table';
import Pagination from '../Pagination';
import translations from '../../translations/de';
import { useParams } from 'react-router-dom';
import ItemImage from '../ItemImage';
import Button from '../Button';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import FinalScoreBreadcrumb from '../FinalScoreBreadcrumb/FinalScoreBreadcrumb';
import { formatDate } from '../../helpers/formatDate';

const FanGameRewards = ({ store }) => {
    const [rewards, setRewards] = useState([]);
    const { fan_game_id } = useParams();
    const history = useHistory();
    const [selectedpage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        sessionStorage.setItem('activeFanGameTab', 'Final Score');
        const getRewards = async () => {
            var res = await API.getRewards(fan_game_id, {
                params: {
                    page: selectedpage,
                },
            });
            setRewards(res.data.items);
            setTotalPages(res.data.total_pages_count);
        };
        getRewards();
    }, [fan_game_id, selectedpage]);

    const handleReward = (reward_id) => {
        history.push(`/rewards/${reward_id}`);
    };

    return (
        <>
            <FinalScoreBreadcrumb />
            <div style={{ marginLeft: '2.5%' }}>
                <Button
                    label="Reward"
                    onClick={() => {
                        history.push({
                            pathname: `/fan-games/${fan_game_id}/new_reward`,
                        });
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {
                <Table
                    tablePagination={
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={selectedpage}
                                onSelectedPageChange={(selected) => setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName="Rewards"
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },

                        {
                            Header: translations.image,
                            accessor: 'image_url',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="reward" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.expire_at,
                            accessor: 'expire_at',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.stock,
                            accessor: 'quantity',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.rewarded,
                            accessor: 'acquired',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },

                        {
                            Header: translations.used,
                            accessor: 'used',
                            Cell: ({ value, row }) => (
                                <div>{row.cells[6].value === 'consumable' ? value : '/'}</div>
                            ),
                        },

                        {
                            Header: translations.type,
                            accessor: 'type',
                            Cell: ({ value }) => <div>{translations[value]}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ value, row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleReward(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={
                        rewards.length > 0
                            ? rewards.map(
                                  ({
                                      id,
                                      name,
                                      image_url,
                                      type,
                                      used,
                                      acquired,
                                      quantity,
                                      expire_at,
                                  }) => {
                                      return {
                                          id,
                                          name,
                                          image_url,
                                          expire_at: formatDate(expire_at),
                                          type,
                                          used,
                                          acquired,
                                          quantity,
                                      };
                                  },
                              )
                            : []
                    }
                />
            }
        </>
    );
};

export default FanGameRewards;
