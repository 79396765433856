import React, { useState } from 'react';
import './DatePicker.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import translations from '../../translations/de';

const Picker = ({
    label = '',
    name = '',
    selectText = '',
    error = false,
    control,
    disabled,
    defaultValue,
    onChange,
    withTime = false,
    isRequired = false,
    minDate,
}) => {
    const [date, setDate] = useState(dateIsValid(defaultValue) ? new Date(defaultValue) : '');
    const changeHandler = (date) => {
        onChange(name, date);
        setDate(date);
    };

    function dateIsValid(date) {
        return !Number.isNaN(new Date(date).getTime());
    }

    return (
        <div className="Date-Picker">
            <label>{label}</label>
            {control ? (
                <Controller
                    control={control}
                    name={name}
                    rules={{ required: isRequired }}
                    render={({ field: { onChange, value, name, ref } }) => (
                        <DatePicker
                            ref={ref}
                            name={name}
                            onChange={onChange}
                            selected={
                                value !== null ? (dateIsValid(value) ? new Date(value) : '') : ''
                            }
                            showMonthDropdown
                            showYearDropdown
                            dateFormat={withTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                            showTimeSelect={withTime}
                            timeIntervals={15}
                            isClearable={!disabled}
                            disabled={disabled}
                            placeholderText={!disabled ? selectText : 'No date selected'}
                            minDate={minDate}
                            autoComplete="off"
                        />
                    )}
                />
            ) : (
                <DatePicker
                    name={name}
                    showMonthDropdown
                    showYearDropdown
                    placeholderText={!disabled ? selectText : 'No date selected'}
                    dateFormat={withTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                    selected={date}
                    isClearable
                    showTimeSelect={withTime}
                    onChange={(name, date) => changeHandler(name, date)}
                    disabled={disabled}
                    minDate={minDate}
                />
            )}
            <span className="error-msg">{error && <p>{translations.required}</p>}</span>
        </div>
    );
};

export default Picker;
