import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import filterObject from '../../helpers/filterObject';
import { useParams } from 'react-router-dom';
import './Access.scss';
import AccessForm from './AccessForm';
import translations from '../../translations/de';
import API from '../../api/games.js';
const Access = ({ store }) => {
    const { eticket_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState([]);

    const accessDetails = filterObject({ ...store.selectedAccess }, ['access_token']);

    const accessOwner = filterObject({ ...store.selectedAccess }, [
        'phone',
        'first_name',
        'last_name',
    ]);

    useEffect(() => {
        store.getAccess(eticket_id);
        return () => {
            store.selectAccess(undefined);
        };
    }, [store, eticket_id]);

    useEffect(() => {
        API.getHomeGames()
            .then((res) => {
                setGames(res.data);
            })
            .catch((e) => console.log('greska: ', e));
    }, []);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        delete e.game_id;
        store
            .editAccess({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };
    return store.selectedAccess ? (
        <>
            <AccessForm
                edit={edit}
                error={error}
                loading={loading}
                name={`${translations.access} ${store.selectedAccess.id}`}
                access={store.selectedAccess}
                accessDetails={accessDetails}
                accessOwner={accessOwner}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                selectedItem={store.selectedAccess}
                games={games}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
            />
        </>
    ) : null;
};

export default observer(Access);
