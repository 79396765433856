import api from './apiLibrary';

const API = {
    getAllQuestions(params) {
        return api.get('/api/admin/fan-game-question', params);
    },
    createQuestion(data) {
        return api.post('/api/admin/fan-game-question', data);
    },
    editQuestion(id, data) {
        return api.put(`/api/admin/fan-game-question/${id}`, data);
    },
    deleteQuestion(id) {
        return api.delete(`/api/admin/fan-game-question/${id}`);
    },
};

export default API;
