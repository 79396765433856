import './ItemImage.scss';
import { FaUser, FaImage } from 'react-icons/fa';
import Placeholder from './../../assets/images/Placeholder.png';
import userPlaceholder from './../../assets/images/user_placeholder.png';

const ItemImage = ({ imgSrc, isAvatar = false, listView = false }) => {
    return listView ? (
        <div className="Item-Image list">
            {imgSrc ? (
                <img
                    src={imgSrc}
                    alt="item"
                    onError={(e) => {
                        e.target.src = isAvatar ? userPlaceholder : Placeholder; //replacement image imported above
                        e.target.onerror = null;
                        e.target.style = 'padding: 8px; margin: 16px'; // inline styles in html format
                    }}
                />
            ) : isAvatar ? (
                <img src={userPlaceholder} alt="user_icon" />
            ) : (
                <div className="list-view-placeholder">
                    {' '}
                    <img src={Placeholder} alt="user_icon" />
                </div>
            )}
        </div>
    ) : (
        <div className="Item-Image">
            {imgSrc ? (
                <div className={`img-wrapper ${isAvatar ? 'avatar' : ''}`}>
                    <img src={imgSrc} alt="item" />
                </div>
            ) : (
                <div className="placeholder">{isAvatar ? <FaUser /> : <FaImage />}</div>
            )}
        </div>
    );
};

export default ItemImage;
