import { types, flow } from 'mobx-state-tree';
import API from '../../api/seatGroups';
import APISectors from '../../api/sectors';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import SeatGroup from './SeatGroup';
import Sector from './../sectorsStore/Sector';

const SeatGroups = types
    .model('SeatGroups', {
        seatGroups: types.array(SeatGroup),
        sectors: types.array(Sector),
        selectedSeatGroup: types.maybe(types.reference(SeatGroup)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getSeatGroups: flow(function* getSeatGroups(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getSeatGroups({
                    params: {
                        search: searchQuery,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.seatGroups = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        getSectors: flow(function* getSectors() {
            self.error = '';
            try {
                const res = yield APISectors.getSectors();
                self.sectors = res.data.items;
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectSeatGroup(id) {
            self.selectedSeatGroup = id;
        },
        getSeatGroup: flow(function* getSeatGroup(id) {
            self.error = '';
            try {
                const res = yield API.getSeatGroup(id);
                self.dataLoaded = true;
                self.seatGroups = [];
                self.seatGroups.push(res.data);
                self.selectedSeatGroup = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editSeatGroup: flow(function* editSeatGroup(edited) {
            try {
                const res = yield API.editSeatGroup(self.selectedSeatGroup.id, edited);
                self.selectedSeatGroup.editSeatGroup(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addSeatGroup: flow(function* addSeatGroup(newSeatGroup) {
            try {
                const res = yield API.createSeatGroup(newSeatGroup);
                self.seatGroups.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteSeatGroup: flow(function* deleteSeatGroup(id) {
            try {
                yield API.deleteSeatGroup(id);
                const index = self.seatGroups.findIndex((b) => b.id === id);
                self.seatGroups.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default SeatGroups;
