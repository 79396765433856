import React, { createContext, useContext, useState, useEffect } from 'react';
import API from '../api/seasons';
import { removeTwoDigits } from '../helpers/seasonFunctions';

const SeasonsContext = createContext();

export const SeasonsProvider = ({ children }) => {
    const [seasons, setSeasons] = useState(null);
    const [currentSeason, setCurrentSeason] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSeasons = async () => {
            try {
                const response = await API.getSeasons();
                let formatedSeasons = response?.data?.map((season) => {
                    return { label: removeTwoDigits(season.title), value: season.id };
                });
                setSeasons(formatedSeasons);
                let currSeason = response?.data?.find((season) => season.current === true);
                setCurrentSeason(currSeason.id);
            } catch (err) {
                console.log(err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSeasons();
    }, []);
    return (
        <SeasonsContext.Provider value={{ seasons, currentSeason, loading, error }}>
            {children}
        </SeasonsContext.Provider>
    );
};

export const useSeasons = () => useContext(SeasonsContext);
