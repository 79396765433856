import Checkbox from '../Checkbox';
import { FaCheck, FaTimes } from 'react-icons/fa';

const PlayerAnswers = ({ players, answers, onChangePlayer, selectAllPlayers }) => {
    const checkIfIsAnswer = (id) => {
        const answer = answers.find((a) => a === id);
        return answer ? true : false;
    };

    return (
        <div className="player-answers">
            <div className="header">
                <h3>Answers</h3>
                <Checkbox
                    onChange={(e) => selectAllPlayers(e.target.checked)}
                    text="Select All"
                    reverseText
                />
            </div>
            {players.map((player) => (
                <div
                    className="player"
                    key={player.id}
                    onClick={() => onChangePlayer(player, !checkIfIsAnswer(player.id))}
                >
                    <span className="name">{player.first_name + ' ' + player.last_name}</span>
                    <div className="image">
                        <img src={player.avatar} alt="avatar" />
                    </div>
                    <div className="checked">
                        {checkIfIsAnswer(player.id) ? (
                            <FaCheck className="confirm" />
                        ) : (
                            <FaTimes className="error" />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PlayerAnswers;
