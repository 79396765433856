import { types } from 'mobx-state-tree';
import Reward from '../rewardsStore/Reward';
import ShopItem from '../shopStore/ShopItem';

const Item = types.model('Item', {
    id: types.identifierNumber,
    acquire_type: types.optional(types.string, ''),
    quantity: types.optional(types.number, 0),
    is_used: types.optional(types.boolean, false),
    reward: types.maybeNull(types.frozen(Reward)),
    shop_item: types.maybeNull(types.frozen(ShopItem)),
});

export default Item;
