import React, { useEffect } from 'react';
import NewReward from '../../components/Rewards/NewReward';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
const Index = ({ store }) => {
    const { fan_game_id } = useParams();

    useEffect(() => {
        store.getFanGame(fan_game_id);
    }, [store, fan_game_id]);

    return store.selectedGame ? (
        <div className="Rewards">
            <NewReward rewardStore={store} />
        </div>
    ) : null;
};

export default observer(Index);
