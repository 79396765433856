import Input from '../../components/Input';
import Select from '../../components/Select';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/seat';

export default function getSeatFormInput({
    name,
    register,
    control,
    error,
    disabled,
    index,
    sectors,
    seatGroups,
    searchSeatGroup,
}) {
    if (name === 'sector_id') {
        return (
            <Select
                key={index}
                name={name}
                label={translateLabel(name.replace('_id', ''))}
                options={sectors.map((sector) => {
                    return { value: sector.id, label: sector.title };
                })}
                control={control}
                error={error}
                disabled={disabled}
                noOptionMsg="No Sectors"
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                isClearable
            />
        );
    } else if (name === 'seat_group_id') {
        return (
            <Select
                key={index}
                name={name}
                onInputChange={searchSeatGroup}
                label={translateLabel(name.replace('_id', ''))}
                options={seatGroups.map((sector) => {
                    return { value: sector.id, label: sector.title };
                })}
                control={control}
                error={error}
                disabled={disabled}
                noOptionMsg="No Seat Group"
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                isClearable
            />
        );
    } else if (name === 'row') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'num') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
