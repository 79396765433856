const roles = [
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Super-Admin', value: 'SUPER_ADMIN' },
    { label: 'Admin-User', value: 'ADMIN_USER' },
    { label: 'Admin-Ticketing', value: 'ADMIN_TICKETING' },
    { label: 'Admin-Fan-Interaction', value: 'ADMIN_FAN_INTERACTION' },
    { label: 'Admin-Gastro', value: 'ADMIN_GASTRO' },
    { label: 'Admin-Business', value: 'ADMIN_BUSINESS' },
    { label: 'Admin-Scanner', value: 'ADMIN_SCANNER' },
    { label: 'Admin-Operator', value: 'ADMIN_OPERATOR' },
];

export default roles;
