import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Game from '../../components/Game';
import { useParams } from 'react-router-dom';
import FanAnswersStore from '../../models/fanAnswersStore/fanAnswers';
import translations from '../../translations/de';
import Paths from '../../constants/paths';

const FanGame = ({ store }) => {
    const { fan_game_id } = useParams();
    const fanAnswers = FanAnswersStore.create();

    useEffect(() => {
        store.getFanGame(fan_game_id);
    }, [store, fan_game_id]);

    return store.selectedGame ? (
        <Game
            store={store}
            links={[{ path: Paths.FAN_GAMES, name: translations.fan_games }]}
            fanAnswers={fanAnswers}
        />
    ) : null;
};

export default observer(FanGame);
