import { types, applySnapshot } from 'mobx-state-tree';
import Reward from '../rewardsStore/Reward';
import ShopItem from '../shopStore/ShopItem';
import Participant from './Participant';

const LotteryGame = types
    .model('Lottery Game', {
        id: types.identifierNumber,
        draw_when_sold_out: types.boolean,
        send_notification: types.maybeNull(types.boolean),
        draw_at: types.maybeNull(types.string),
        visible_until: types.maybeNull(types.string),
        start_at: types.maybeNull(types.string),
        reward_id: types.maybeNull(types.number),
        shop_item_id: types.maybeNull(types.number),
        reward: types.maybeNull(types.frozen(Reward)),
        shop_item: types.maybeNull(types.frozen(ShopItem)),
        participants: types.optional(types.array(Participant), []),
        winners: types.optional(types.array(Participant), []),
    })
    .actions((self) => ({
        editLotteryGame(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default LotteryGame;
