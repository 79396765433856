import React, { useState, forwardRef } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import translations from '../../translations/de';
import copy from 'clipboard-copy';

import copyIcon from '../../assets/images/icons/copy_paste.svg';
import linkIcon from '../../assets/images/icons/link_icon.svg';
import templateImage from '../../assets/images/Placeholder.png';
import mobileMenu from '../../assets/images/mobile-menu.png';

import './style.scss';
import QRCode from 'react-qr-code';

const tempCode = '2313dssada213';

const NewVoucherPreview = forwardRef(
    (
        {
            onClose,
            name,
            image,
            description,
            redeemDescription,
            expireAt,
            link,
            singleCode = tempCode,
            voucherType,
        },
        ref,
    ) => {
        const [showMenu, setShowMenu] = useState(false);
        const [copyLabel, setCopyLabel] = useState(translations.copy);

        const date = new Date(expireAt);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = day && month && year ? `${day}.${month}.${year}` : '';

        function getDomain(url) {
            try {
                const { hostname } = new URL(url);
                return hostname || '';
            } catch (error) {
                return '';
            }
        }

        const copyToClipboard = async (code) => {
            try {
                await copy(code);
                setCopyLabel('KOPIERT');
                setTimeout(() => {
                    setCopyLabel(translations.copy);
                }, 2000);
            } catch (error) {
                console.error('Something went wrong!', { stack: false });
            }
        };

        return (
            <div className="preview-screen-wrapper" ref={ref}>
                <div className="preview-screen-title">
                    <p>{name}</p>
                    <button onClick={() => onClose()}>
                        <IoCloseSharp />
                    </button>
                </div>
                <div className="preview-screen-content">
                    <img src={image || templateImage} alt="" />
                </div>
                <div className="preview-screen-description">
                    <p>{description}</p>
                    <p>Code: {singleCode !== '' ? singleCode : tempCode}</p>
                    <p className="validation-date">
                        {translations.valid_until}: {formattedDate}
                    </p>
                </div>
                <div className="preview-screen-action">
                    <button onClick={() => setShowMenu(!showMenu)}>
                        {translations.voucher_reedem}
                    </button>
                </div>
                <div className="preview-screen-menu">
                    <img src={mobileMenu} alt="mobile menu" />
                </div>
                {showMenu && (
                    // different content for different voucher type
                    // 1. voucher type = external
                    <div className="voucher-detail-show">
                        <div className="detail-header">
                            <button onClick={() => setShowMenu(!showMenu)}>
                                <IoCloseSharp />
                            </button>
                        </div>
                        {voucherType === 'external' && (
                            <>
                                <div className="detail-content_external">
                                    {/* in case of external type */}
                                    <img src={image || templateImage} alt="" />
                                </div>
                                <div className="detail-controlls">
                                    <p>{translations.voucher_reedem}</p>
                                    <span>{redeemDescription}</span>
                                    <p>{singleCode !== '' ? singleCode : tempCode}</p>
                                    <div className="copy-action">
                                        <button onClick={() => copyToClipboard(singleCode)}>
                                            <img src={copyIcon} alt="copy-icon" />
                                            <p>{copyLabel}</p>
                                        </button>
                                    </div>

                                    {getDomain(link) ? (
                                        <div className="link-action">
                                            <button>
                                                <img src={linkIcon} alt="link icon" />
                                                <a
                                                    href={link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {getDomain(link) ?? ''}
                                                </a>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="link-action-placeholder" />
                                    )}
                                </div>
                            </>
                        )}

                        {/* // 2.3. voucher type = eguma/internal */}
                        {voucherType !== 'external' && (
                            <>
                                <div className="detail-content_eguma">
                                    <p className="preview-title">{translations.credit}</p>
                                    <p className="qr-code-desc">
                                        {voucherType === 'eguma'
                                            ? translations.qr_code_desc_eguma
                                            : translations.qr_code_desc_internal}
                                    </p>
                                    <div className="qr-wrapper">
                                        <QRCode
                                            size={170}
                                            value={singleCode !== '' ? singleCode : tempCode}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    <p className="code">
                                        {singleCode !== '' ? singleCode : tempCode}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    },
);

export default NewVoucherPreview;
