import { useEffect } from 'react';
import Item from '../../components/Item';
import Input from '../../components/Input';
import { useHistory } from 'react-router-dom';
import translateLabel from '../../helpers/translateLabel';
import Paths from '../../constants/paths';
import { useState } from 'react';
import translations from '../../translations/de';

const NewSeason = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    return (
        <Item
            links={[{ path: Paths.SEASONS, name: translations.seasons }]}
            name={`${translations.season} ${translations.create}`}
            itemModel={{
                title: '',
            }}
            edit={true}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .addSeason(event)
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.SEASONS);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ name, register, error, disabled, index }) => (
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    type="text"
                    {...register(name, {
                        required: true,
                    })}
                    error={error}
                    disabled={disabled}
                />
            )}
        ></Item>
    );
};

export default NewSeason;
