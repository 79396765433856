import React, { useState, useRef, useContext } from 'react';
import translations from '../../translations/de.js';
import { useForm } from 'react-hook-form';

import Input from '../../components/Input/index.js';
import Button from '../../components/Button/index.js';
import ErrorMessages from '../../components/ErrorMessages.js/index.js';
import Modal from '../../components/Modal/index.js';
import Logo from './../../assets/images/logo/scb-logo.png';
import requsetErrorMessage from '../../helpers/requsetErrorMessage.js';

import API from '../../api/auth.js';
import useModal from '../../customHooks/useModal.js';
import './style.scss';
import { ResetPasswordContext } from './index.js';

const ResetPassword = () => {
    const modalRef = useRef(null);
    const [modalResult, setModalResult] = useModal(false, modalRef);
    const [errorMessage, setErrorMessage] = useState('');
    const { email, code, nextStep, resetFlow } = useContext(ResetPasswordContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ criteriaMode: 'all' });

    const handleResetPassword = async (e) => {
        try {
            if (e.new_password === e.repeat_password) {
                const res = await API.resetPassword({
                    new_password: e.new_password,
                    email: email,
                    code: code,
                });
                res.status === 201 && setModalResult(true);
            } else {
                throw new Error(translations.passwords_not_matching);
            }
        } catch (e) {
            setErrorMessage(requsetErrorMessage(e.message));
        }
    };

    return (
        <div className="Login">
            <div className="Login-Container">
                <div className="logo">
                    <img src={Logo} alt="SCB Logo" />
                </div>
                <h2>{translations.app_name}</h2>
                <form onSubmit={handleSubmit(handleResetPassword)}>
                    <Input
                        label={translations.new_password}
                        ispassword={true}
                        {...register('new_password', {
                            required: true,
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                                message:
                                    'Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.',
                            },
                        })}
                        error={errors.new_password}
                    />
                    <div style={{ marginTop: '30px' }} />
                    <Input
                        label={translations.repeat_password}
                        type={'password'}
                        {...register('repeat_password', { required: true })}
                        error={errors.repeat_password}
                    />

                    <ErrorMessages messages={errorMessage} />
                    <Button type="submit" label={translations.save_changes} fullwidth />
                    <br />
                </form>
                <Button
                    theme="gray"
                    label={translations.cancel}
                    fullwidth
                    onClick={() => resetFlow()}
                />
            </div>
            {modalResult && (
                <Modal
                    title={translations.success}
                    ref={modalRef}
                    onClose={() => {
                        nextStep();
                        setModalResult(false);
                    }}
                    maxWidth="500px"
                >
                    <p style={{ color: 'black' }}>{translations.reset_password_success_message}</p>
                </Modal>
            )}
        </div>
    );
};

export default ResetPassword;
