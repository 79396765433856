import React, { useEffect } from 'react';
import Table from '../Table';
import { observer } from 'mobx-react-lite';
import Pagination from '../Pagination';
import { FaCheck, FaTimes, FaTrash } from 'react-icons/fa';
import Button from '../Button';
import translations from '../../translations/de';
import { useHistory } from 'react-router';

const FanAnswers = ({ store, gameID }) => {
    const history = useHistory();

    const handleUser = (id) => {
        store.items.forEach((item) => {
            if (item.id === id) {
                history.push(`/fans/${item.fan_user_id}`);
            }
        });
    };

    useEffect(() => {
        store.getFanAnswers(gameID);
    }, [store, gameID, store.selectedPage]);

    return (
        <>
            {store.items.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={store.setSelectedPage}
                            />
                        )
                    }
                    isRowClickable={true}
                    tableName="Fans Answers"
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ row, value }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                            ),
                        },
                        {
                            Header: translations.user,
                            accessor: 'user',
                            Cell: ({ row, value }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                            ),
                        },

                        {
                            Header: translations.email,
                            accessor: 'user_email',
                            Cell: ({ row, value }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                            ),
                        },
                        {
                            Header: 'Answer',
                            accessor: 'answer',
                            Cell: ({ row, value }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                            ),
                        },
                        {
                            Header: 'Rewarded',
                            accessor: 'rewarded',
                            Cell: ({ row, value }) => (
                                <div onClick={() => handleUser(row.cells[0].value)}>
                                    {value === true ? (
                                        <FaCheck className="confirm" />
                                    ) : (
                                        <FaTimes className="error" />
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            store.deleteFanAnswer(row.cells[0].value);
                                        }}
                                        title={translations.delete}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.items.map(({ id, fan_user, fan_game_answer }) => {
                        return {
                            id,
                            user: fan_user.first_name + ' ' + fan_user.last_name,
                            user_email: fan_user.email,
                            answer: fan_game_answer.text,
                            rewarded: fan_user.acquired_items.length ? true : false,
                        };
                    })}
                />
            )}
        </>
    );
};

export default observer(FanAnswers);
