import api from './apiLibrary';

const API = {
    getLotteryGames(params) {
        return api.get('/api/admin/lottery', params);
    },
    getLotteryGame(id) {
        return api.get(`/api/admin/lottery/${id}`);
    },
    createLotteryGame(data, params) {
        return api.post('/api/admin/lottery', data, params);
    },
    editLotteryGame(id, data) {
        return api.patch(`/api/admin/lottery/${id}`, data);
    },
    getLotteryUsers(id, params) {
        return api.get(`/api/admin/lottery/${id}/participants`, params);
    },
    drawLotteryGame(id) {
        return api.patch(`/api/admin/lottery/${id}/draw`);
    },
};

export default API;
