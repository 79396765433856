import { useState, useRef, useEffect } from 'react';
import './Game.scss';
import Breadcrumb from '../Breadcrumb';
import TabSwitcher from '../TabSwitcher';
import formatName from './../../helpers/formatName';
import { observer } from 'mobx-react-lite';
import GameSetUp from '../GameSetUp';
import Rewards from '../Rewards';
import Questions from '../Questions';
import Modal from '../Modal';
import useModal from '../../customHooks/useModal';
import SetResult from './SetResult';
import ErrorMessages from '../ErrorMessages.js';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import Button from '../Button';
import GameResult from './GameResult';
import FanAnswers from '../FanAnswers';
import FanGameRanking from '../FanGameRanking';
import FanGameWinners from '../FanGameWinners';
import translations from '../../translations/de';

const Game = ({ store, links, fanAnswers }) => {
    const selectedGame = store.selectedGame;
    const modalRef = useRef(null);
    const [modalResult, setModalResult] = useModal(false, modalRef);
    const [error, setError] = useState(null);
    
    const is_end_game_result = store?.selectedGame?.fan_game_type === 'end_game_result';

    useEffect(() => {
        return () => {
            if (store.selectGame) {
                store.selectGame(undefined);
            } else if (store.selectFanGame) {
                store.selectFanGame(undefined);
            }
        };
    }, [store]);

    return (
        <>
            <div className="Game">
                <div className="Game-Header">
                    <Breadcrumb
                        name={
                            formatName(selectedGame.fan_game_type) +
                            ' - ' +
                            formatName(selectedGame?.name)
                        }
                        links={links}
                    />
                    {selectedGame.fan_game_type === 'first_scorer' &&
                        selectedGame.questions.length !== 0 && (
                            <Button
                                label={selectedGame.result ? 'Show Result' : 'Set Result'}
                                onClick={() => {
                                    error && setError(null);
                                    setModalResult(true);
                                }}
                            />
                        )}
                </div>
                {error && (
                    <ErrorMessages
                        messages={requsetErrorMessage(
                            error && error.message ? error.message : error,
                        )}
                    />
                )}
                <TabSwitcher initialTabName="Set Up">
                    <TabSwitcher.Tabs>
                        <TabSwitcher.TabPill tabName="Set Up" />
                        <TabSwitcher.TabPill tabName="Rewards" />
                        {is_end_game_result ? (
                            <>
                                <TabSwitcher.TabPill tabName="Ranking" />
                                <TabSwitcher.TabPill tabName={translations.acquirer} />
                            </>
                        ) : (
                            <>
                                <TabSwitcher.TabPill tabName="Questions" />
                                <TabSwitcher.TabPill tabName="Fans Answers" />
                            </>
                        )}
                    </TabSwitcher.Tabs>
                    <TabSwitcher.TabPanel activeWhenTabName="Set Up">
                        <GameSetUp
                            id={`[ fan_game_ID: ${selectedGame.id} ]`}
                            gameStore={selectedGame}
                        />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName="Rewards">
                        <Rewards gameStore={selectedGame} />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName="Questions">
                        <Questions gameStore={selectedGame} />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName="Fans Answers">
                        <FanAnswers store={fanAnswers} gameID={selectedGame.id} />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName="Ranking">
                        <FanGameRanking />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.acquirer}>
                        <FanGameWinners />
                    </TabSwitcher.TabPanel>
                </TabSwitcher>
            </div>
            {modalResult && (
                <Modal
                    title={`First Scorer ${selectedGame.result ? '' : 'Setting'} Result`}
                    ref={modalRef}
                    onClose={() => setModalResult(false)}
                    maxWidth="500px"
                >
                    {!selectedGame.result ? (
                        <SetResult store={selectedGame} onClose={() => setModalResult(false)} />
                    ) : (
                        <GameResult
                            result={selectedGame.result}
                            onClose={() => setModalResult(false)}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};

export default observer(Game);
