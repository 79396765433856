import { types } from 'mobx-state-tree';

const Ticket = types.model('Ticket', {
    id: types.identifierNumber,
    is_winner: types.maybeNull(types.boolean),
    number: types.maybeNull(types.number),
});

const Participant = types.model('Participant', {
    id: types.identifierNumber,
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    avatar: types.maybeNull(types.string),
    fan_name: types.maybeNull(types.string),
    is_winner: types.maybeNull(types.boolean),
    tickets: types.optional(types.array(Ticket), []),
});

export default Participant;
