import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import { FaCheck, FaSearch, FaTimes, FaCheckSquare, FaTrash, FaPlus } from 'react-icons/fa';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import translations from '../../translations/de';

const Seasons = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [seasonID, setSeasonID] = useState(null);

    useEffect(() => {
        store.getSeasons();
    }, [store]);

    const handleSeason = (id) => {
        store.selectSeason(id);
        history.push(`/seasons/${store.selectedSeason.id}`);
    };

    return (
        <>
            <div style={{ marginLeft: '2.5%' }}>
                <Button
                    label={translations.season}
                    onClick={() => {
                        store.selectSeason(undefined);
                        history.push(Paths.NEW_SEASON);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            <Table
                tableName={translations.seasons}
                columns={[
                    {
                        Header: '',
                        accessor: 'id',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.season,
                        accessor: 'title',
                        Cell: ({ value, row }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.current,
                        accessor: 'current',
                        Cell: ({ value, row }) => (
                            <div>
                                {value === true ? (
                                    <FaCheck className="confirm" />
                                ) : (
                                    <FaTimes className="error" />
                                )}
                            </div>
                        ),
                    },
                    {
                        Header: '',
                        accessor: 'action',
                        Cell: ({ row }) => (
                            <div>
                                <Button
                                    onClick={() => {
                                        handleSeason(row.cells[0].value);
                                    }}
                                    title={translations.show}
                                >
                                    <FaSearch />
                                </Button>
                                {!row.values.current && (
                                    <>
                                        <Button
                                            onClick={() => {
                                                setSeasonID(row.cells[0].value);
                                                setModal(true);
                                            }}
                                            title={translations.delete}
                                        >
                                            <FaTrash />
                                        </Button>
                                        <Button
                                            title="Make this season current"
                                            onClick={() =>
                                                store.switchCurrentSeason(row.cells[0].value)
                                            }
                                        >
                                            <FaCheckSquare />
                                        </Button>
                                    </>
                                )}
                            </div>
                        ),
                    },
                ]}
                data={store.seasons.map(({ id, title, current }) => {
                    return {
                        id,
                        title,
                        current,
                    };
                })}
            />
            {modal && (
                <Modal
                    title="Delete Season"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        Are you sure you want to delete this season?
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteSeason(seasonID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default observer(Seasons);
