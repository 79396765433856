import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Paths from '../../constants/paths';
import Item from '../../components/Item';
import translations from '../../translations/de';
import clearObjProps from '../../helpers/clearObjProps';
import getNewsFormInput from './getNewsFormInput';
import ItemImage from '../../components/ItemImage';
import { observer } from 'mobx-react-lite';

const SingleNews = ({ store }) => {
    const { news_id } = useParams();
    //const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');

    const newsModel = clearObjProps({ ...store.selectedNews }, ['id', 'deleted_at', 'hide']);

    const onSubmitHandler = (data) => {
        setLoading(true);
        store
            .editNews(data)
            .then(() => {
                //setEdit(false);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
                //setEdit(false);
            });
    };

    useEffect(() => {
        store.getSingleNews(news_id);
    }, [store, news_id]);

    return store.dataLoaded ? (
        <Item
            links={[{ path: Paths.NEWS, name: translations.news }]}
            name={`${store.selectedNews.title}`}
            id={`[ news_ID: ${store.selectedNews.id}]`}
            itemModel={newsModel}
            edit={false}
            onSubmit={onSubmitHandler}
            // onEdit={() => setEdit(true)}
            onCancel={() => {
                error && setError(null);
                //setEdit(false);
            }}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, control, error, disabled, index }) =>
                getNewsFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    disabled,
                    index,
                })
            }
        >
            <ItemImage imgSrc={store.selectedNews.image}></ItemImage>
        </Item>
    ) : null;
};

export default observer(SingleNews);
