import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import translations from '../../translations/de';
import Pagination from '../../components/Pagination';
import { useParams } from 'react-router-dom';
import API from './../../api/businessEvents';
import Invitations from './Invitations';

const IntivationsTab = ({ isDisabled = false, className = 'block' }) => {
    const { event_id } = useParams();
    const [inviations, setInvitaions] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);

    useEffect(() => {
        const getInvitations = async () => {
            try {
                const res = await API.getInvitations(event_id, {
                    params: {
                        limit: 10,
                        page: selectedPage,
                    },
                });
                setInvitaions(res.data.items);
                setTotalPages(res.data.total_pages_count);
            } catch (e) {
                console.log(e);
            }
        };

        event_id && getInvitations();
    }, [event_id, selectedPage]);

    return (
        <div className={className}>
            {Invitations.length !== 0 ? (
                <Table
                    tableName={translations.business_profiles}
                    tablePagination={
                        !isDisabled &&
                        totalPages > 1 && (
                            <Pagination
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                onSelectedPageChange={(selected) => setSelectedPage(selected)}
                            />
                        )
                    }
                    isRowClickable={!isDisabled}
                    columns={[
                        {
                            Header: `ID`,
                            accessor: 'id',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.name}`,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.email}`,
                            accessor: 'email',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.partner}`,
                            accessor: 'partner_name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'Partner type',
                            accessor: 'partner_type',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: `${translations.phone}`,
                            accessor: 'phone',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                    ]}
                    data={inviations.map(({ id, name, company, avatar, email, phone }) => {
                        return {
                            id,
                            name,
                            email,
                            partner_name: company?.name,
                            partner_type: company?.type,
                            avatar,
                            phone,
                        };
                    })}
                />
            ) : (
                <div className="no-data">No Data Yet...</div>
            )}
            {}
        </div>
    );
};

export default IntivationsTab;
