import React from 'react';
import { useTable } from 'react-table';
import '../../components/Table/Table.scss';
import translations from '../../translations/de';
import { Fragment } from 'react';

const defaultPropGetter = () => ({});

const BusinessEventsMainTable = ({
    columns,
    data,
    tablePagination,
    tableName,
    isRowClickable = false,
    getRowProps = defaultPropGetter,
}) => {
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
        columns,
        data,
    });
    var passedEvents = [];
    var upcomingEvents = [];

    rows.forEach((element) => {
        element.original.status === 'Upcoming'
            ? upcomingEvents.push(element)
            : passedEvents.push(element);
    });

    return (
        <div className="Table">
            {tableName && (
                <div className="Table-Name">
                    <p>{tableName}</p>
                    {tablePagination}
                </div>
            )}
            <table className="Table-Table" {...getTableProps()}>
                <thead className="">
                    <tr className="Table-Header">
                        {headers.map((column, i) => (
                            <Fragment key={i}>
                                <th className="Table-Header-Cell" {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            </Fragment>
                        ))}
                    </tr>
                </thead>

                <tbody className="Table-Body" {...getTableBodyProps()}>
                    {upcomingEvents.map((row, i) => {
                        prepareRow(row);
                        // dve sekcije, na osnovu statusa raspodeli svakoj odgovarajuci event
                        return (
                            <Fragment key={i}>
                                {upcomingEvents.length !== 0 && i === 0 && (
                                    <tr className="Table-Name">
                                        <td>{translations.upcoming}</td>
                                    </tr>
                                )}

                                <tr
                                    className={`Table-Body-Row ${
                                        isRowClickable ? 'clickable' : ''
                                    }`}
                                    {...row.getRowProps(getRowProps(row))}
                                >
                                    {row.cells.map((cell, ii) => {
                                        return (
                                            <Fragment key={ii}>
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="Table-Body-Row-Cell"
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            </Fragment>
                                        );
                                    })}
                                </tr>
                                {passedEvents.length !== 0 && i === upcomingEvents.length - 1 && (
                                    <tr className="Table-Name">
                                        <td>{translations.passed}</td>
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })}
                    {passedEvents.map((row, i) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={i}>
                                <tr
                                    className={`Table-Body-Row ${
                                        isRowClickable ? 'clickable' : ''
                                    }`}
                                    {...row.getRowProps(getRowProps(row))}
                                >
                                    {row.cells.map((cell, ii) => {
                                        return (
                                            <React.Fragment key={ii}>
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="Table-Body-Row-Cell"
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default BusinessEventsMainTable;
