import { types, applySnapshot } from 'mobx-state-tree';
const BusinessProfile = types
    .model('Profile', {
        id: types.identifierNumber,
        gender: types.enumeration('Gender', ['male', 'female', 'other']),
        first_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        avatar: types.maybeNull(types.string),
        role: types.maybeNull(types.string),
        phone_mobile: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        user_id: types.maybeNull(types.number),
        company_id: types.maybeNull(types.number),
        club: types.maybeNull(types.boolean),
        executive: types.maybeNull(types.boolean),
        senior_executive: types.maybeNull(types.boolean),
        deleted_at: types.maybeNull(types.string),
        user: types.maybeNull(
            types.frozen({
                id: types.number,
                first_name: types.string,
                last_name: types.string,
                email: types.string,
            }),
        ),
        company: types.maybeNull(
            types.frozen({
                id: types.maybeNull(types.number),
                name: types.maybeNull(types.string),
            }),
        ),
    })
    .actions((self) => ({
        editProfile(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));
export default BusinessProfile;
