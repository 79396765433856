import { types, flow } from 'mobx-state-tree';
import API from '../../api/vouchers';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import Voucher from './Voucher.js';
import clearObjNulls from '../../helpers/clearObjNulls';

const Vouchers = types
    .model('Vouchers', {
        vouchers: types.array(Voucher),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        selectedVoucher: types.maybeNull(types.reference(Voucher)),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getVouchers: flow(function* getVouchers(searchQuery, type) {
            self.error = '';
            try {
                const params = {
                    type: type ?? undefined,
                    limit: 10,
                    page: self.selectedPage,
                };

                if (searchQuery !== '') {
                    params.search = searchQuery;
                }
                const res = yield API.getVouchers({ params });
                self.dataLoaded = true;
                self.vouchers = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectVoucher(id) {
            self.selectedVoucher = id;
        },
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setTotalPages(total_pages_count) {
            self.totalPages = total_pages_count;
        },
        getVoucher: flow(function* getVoucher(id) {
            self.error = '';
            try {
                const res = yield API.getVoucher(id);
                self.dataLoaded = true;
                self.vouchers = [];
                self.vouchers.push(res.data);
                self.selectedVoucher = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                console.log(error);
            }
        }),
        addVoucher: flow(function* addVoucher(voucher) {
            try {
                voucher.product_id = '0798f56b-50a4-47ab-a9b6-0ffb7c4060fd';
                if (voucher.reward_id === '') {
                    voucher.reward_id = null;
                }
                if (voucher.shop_item_id === '') {
                    voucher.shop_item_id = null;
                }
                const res = yield API.createVoucher(clearObjNulls(voucher));
                self.vouchers.push(res.data[0]);
            } catch (error) {
                throw error;
            }
        }),
        addGastroVoucher: flow(function* addVoucher(voucher) {
            try {
                voucher.product_id = '0798f56b-50a4-47ab-a9b6-0ffb7c4060fd';
                if (voucher.reward_id === '') {
                    voucher.reward_id = null;
                }
                if (voucher.shop_item_id === '') {
                    voucher.shop_item_id = null;
                }
                const res = yield API.createVoucher(
                    clearObjNulls({ ...voucher, voucher_type: 'eguma' }),
                );
                self.vouchers.push(res.data[0]);
            } catch (error) {
                throw error;
            }
        }),
        editVoucher: flow(function* editVoucher(edited) {
            try {
                const res = yield API.editVoucher(self.selectedVoucher.id, edited);
                self.selectedVoucher.editVoucher(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteVoucher: flow(function* deleteVoucher(id) {
            try {
                yield API.deleteVoucher(id);
                const index = self.vouchers.findIndex((v) => v.id === id);
                self.vouchers.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default Vouchers;
