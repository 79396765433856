import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import SeatGroups from './SeatGroups';
import NewSeatGroup from './NewSeatGroup';
import ErrorMessages from '../../components/ErrorMessages.js';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import SeatGroup from './SeatGroup';

const SeatGroupTab = ({ store }) => {
    useEffect(() => {
        store.getSectors();
    }, [store]);
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.SEAT_GROUPS}>
                <SeatGroups store={store} />
            </Route>
            <Route exact path={Paths.SEAT_GROUP}>
                <SeatGroup store={store}></SeatGroup>
            </Route>
            <Route exact path={Paths.NEW_SEAT_GROUP}>
                <NewSeatGroup store={store} />
            </Route>
        </Page>
    );
};

export default observer(SeatGroupTab);
