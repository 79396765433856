import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import translations from '../../translations/de';
import getVouchersFormInput from './getVouchersFormInput';
import rewardsAPI from '../../api/rewards';
import shopItemsAPI from '../../api/shopItems';
import debounce from 'lodash.debounce';
import Select from '../../components/Select';
import clearObjProps from '../../helpers/clearObjProps';

const NewGastroVoucher = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shopItems, setShopItems] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [searchShopItems, setSearchShopItems] = useState('');
    const [searchRewards, setSearchRewards] = useState('');
    const [type, setType] = useState('');
    const [resetProp, setResetProp] = useState(null);
        const [selectedItem, setSelectedItem] = useState(false);
        const [orderID, setOrderID] = useState(null);

        useEffect(() => {
            !store.dataLoaded && store.setDataLoaded();
        }, [store]);

        useEffect(() => {
            const getRewards = async () => {
                try {
                    const res = await rewardsAPI.getAllRewards({
                        params: {
                            search: searchRewards,
                        },
                    });
                    setRewards(res.data.items);
                } catch (error) {
                    console.log('Error in getting rewards from server');
                }
            };
            searchRewards && getRewards();
        }, [searchRewards]);

        useEffect(() => {
            const getShopItems = async () => {
                try {
                    const res = await shopItemsAPI.getShopItems({
                        params: {
                            search: searchShopItems,
                        },
                    });
                    setShopItems(res.data.items);
                } catch (error) {
                    console.log('Error in getting shop items from server');
                }
            };
            searchShopItems && getShopItems();
        }, [searchShopItems]);

        const searchRewardsHandler = debounce((value) => {
            setSearchRewards(value);
        }, 500);

        const searchShopItemsHandler = debounce((value) => {
            setSearchShopItems(value);
        }, 500);

        useEffect(() => {
            if (type === '') {
                setResetProp({ shop_item_id: null, reward_id: null });
            } else if (type === 'reward') {
                setResetProp({ shop_item_id: null });
            } else if (type === 'shop_item') {
                setResetProp({ reward_id: null });
            }
        }, [type]);

        return (
            <>
                <Item
                    links={[{ path: '/vouchers', name: translations.vouchers }]}
                    name={`${translations.vouchers} ${translations.create}`}
                    itemModel={{
                        price_in_cents: 0,
                        quantity: 0,
                        shop_item_id: type === 'shop_item' ? '' : null,
                        reward_id: type === 'reward' ? '' : null,
                        order_id: null,
                    }}
                    edit={true}
                    onSubmit={(event) => {
                        setLoading(true);
                        error && setError(null);
                        store
                            .addGastroVoucher(clearObjProps({ ...event, order_id: orderID }))
                            .then(() => {
                                setLoading(false);
                                history.push(Paths.VOUCHERS_GASTRO);
                            })
                            .catch((err) => {
                                setError(err);
                                setLoading(false);
                            });
                    }}
                    onCancel={() => history.goBack()}
                    error={error}
                    loading={loading}
                    determineInputType={({
                        value,
                        name,
                        register,
                        error,
                        isDisabled,
                        index,
                        control,
                        reset,
                        getValues,
                    }) =>
                        getVouchersFormInput({
                            value,
                            name,
                            register,
                            error,
                            isDisabled,
                            index,
                            control,
                            searchRewardsHandler,
                            searchShopItemsHandler,
                            rewards,
                            shopItems,
                            type,
                            voucherType: 'eguma',
                            reset,
                            getValues,
                            selectedItem,
                            setSelectedItem,
                            orderID,
                            setOrderID,
                        })
                    }
                    resetProp={resetProp}
                >
                    <Select
                        label={`${translations.type.toUpperCase()}`}
                        isSearchable={false}
                        defaultValue={{ label: 'None', value: '' }}
                        options={[
                            { label: 'None', value: '' },
                            { label: 'Reward', value: 'reward' },
                            { label: 'Shop Item', value: 'shop_item' },
                        ]}
                        onChange={(e) => {
                            setType(e.value);
                        }}
                        style={{ width: '100%' }}
                    />
                </Item>
            </>
        );
};

export default observer(NewGastroVoucher);
