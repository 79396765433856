import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import getFormInput from './getFormInput';
import translations from '../../translations/de';

const NewAdmin = ({ store }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !store.dataLoaded && store.setDataLoaded();
    }, [store]);

    return (
        <Item
            links={[{ path: Paths.ADMINS, name: translations.admin_users }]}
            name={`${translations.admin_user} ${translations.create}`}
            itemModel={{
                first_name: '',
                last_name: '',
                email: '',
                username: '',
                password: '',
                phone: '',
                roles: ['ADMIN'],
            }}
            edit={true}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .createAdmin({ ...event })
                    .then(() => {
                        setLoading(false);
                        history.push(Paths.ADMINS);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            error={error}
            loading={loading}
            determineInputType={({ control, name, register, error, disabled, index }) =>
                getFormInput({
                    name,
                    register,
                    control,
                    error,
                    disabled,
                    index,
                })
            }
        ></Item>
    );
};

export default observer(NewAdmin);
