import api from './apiLibrary';

const API = {
    getProfiles(params) {
        return api.get('/api/admin/business-profile', params);
    },
    getProfilesByPartners(params) {
        return api.get('/api/admin/business-profile/by-partner', params);
    },
    getProfile(id, params) {
        return api.get(`/api/admin/business-profile/${id}`, params);
    },
    createProfile(data) {
        return api.post('/api/admin/business-profile', data);
    },
    editProfile(id, data) {
        return api.put(`/api/admin/business-profile/${id}`, data);
    },
    deleteProfile(id) {
        return api.delete(`api/admin/business-profile/${id}`);
    },
    reviveProfile(id) {
        return api.put(`/api/admin/business-profile/${id}/revive`);
    },
    exportProfiles() {
        return api.get(`/api/admin/business-profile/export`);
    },
};

export default API;
