import Textarea from '../Textarea';
import Button from '../Button';
import { observer } from 'mobx-react-lite';
import translations from '../../translations/de';
import { FaTrash } from 'react-icons/fa';

const Quote = ({ quote, id, onDelete }) => {
    return (
        <div className="Quote">
            <div className="Quote-Header">
                <p>Quote</p>
                <Button title={translations.delete} onClick={() => onDelete(id)}>
                    <FaTrash />
                </Button>
            </div>
            <div className="Quote-Form">
                <Textarea
                    label="TEXT"
                    name="text"
                    value={quote.text}
                    onChange={(e) => quote.onChangeValue(e.target.name, e.target.value)}
                />
            </div>
        </div>
    );
};

export default observer(Quote);
