import { types, applySnapshot } from 'mobx-state-tree';
import Reward from '../rewardsStore/Reward';
import ShopItem from '../shopStore/ShopItem';

const Voucher = types
    .model('Voucher', {
        id: types.identifierNumber,
        price_in_cents: types.number,
        voucher_code: types.string,
        used_at: types.maybeNull(types.string),
        expiry_date: types.maybeNull(types.string),
        order_id: types.string,
        reward_id: types.maybeNull(types.number),
        shop_item_id: types.maybeNull(types.number),
        acquired_item_id: types.maybeNull(types.number),
        redeem_data: types.maybeNull(
            types.frozen({
                account_company_name: types.maybeNull(types.string),
                account_id: types.maybeNull(types.string),
                new_balance_in_cents: types.maybeNull(types.number),
                product_name: types.maybeNull(types.string),
                product_id: types.maybeNull(types.string),
                redeemed_amount_in_cents: types.maybeNull(types.number),
            }),
        ),
        reward: types.maybeNull(Reward),
        shop_item: types.maybeNull(ShopItem),
    })
    .actions((self) => ({
        editVoucher(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Voucher;
