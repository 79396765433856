import Input from '../../components/Input';
import Select from '../../components/Select';
import translateLabel from '../../helpers/translateLabel';
import mandatoryFields from '../../configs/businessProfile';
import InputFile from '../../components/InputFile/index.js';
import translations from '../../translations/de';
import genderTypes from '../../constants/genderTypes';
import Checkbox from '../../components/Checkbox';

export default function getBusinessProfileFormInput({
    name,
    register,
    control,
    error,
    disabled,
    index,
    resetValue,
    users,
    searchQueryHandler,
    searchQueryHandlerPartner,
    partners,
}) {
    if (name === 'company_id') {
        return (
            <Select
                key={index}
                onInputChange={searchQueryHandlerPartner}
                name={name}
                label={translateLabel(name.replace('_id', ''))}
                options={partners.map((partner) => {
                    return {
                        value: partner.id,
                        label: `${partner.name}`,
                    };
                })}
                placeholder={translations.search_partner}
                control={control}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                error={error}
                disabled={disabled}
                noOptionsMsg={translations.noPartnersOptions}
                isClearable
            />
        );
    } else if (name === 'user_id') {
        return (
            <Select
                key={index}
                onInputChange={searchQueryHandler}
                name={name}
                label={translateLabel(translations.user_name_long)}
                options={users.map((user) => {
                    return {
                        value: user.id,
                        label: `${user.first_name} ${user.last_name} [${user.email}]`,
                    };
                })}
                placeholder={translations.type_to_search_users}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg={translations.no_users_found}
                isClearable
            />
        );
    } else if (name === 'gender') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name="gender"
                options={genderTypes}
                error={error}
                control={control}
                disabled={disabled}
            />
        );
    } else if (name === 'avatar') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder="uploads/business-profiles"
            />
        );
    } else if (
        name === 'executive' ||
        name === 'senior_executive' ||
        name === 'club' ||
        name === 'deleted_at'
    ) {
        return (
            <Checkbox
                disabled={disabled}
                key={index}
                text={translateLabel(name)}
                name={name}
                {...register(name, { required: false })}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={name === 'name' ? translations.specialName : translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
