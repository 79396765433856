import React, { useEffect, useRef, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import Paths from '../../constants/paths';
import { FaPlus, FaCheck, FaTimes, FaTrash, FaSearch } from 'react-icons/fa';
import useModal from '../../customHooks/useModal';
import Modal from '../../components/Modal';
import Pagination from './../../components/Pagination';
import translations from '../../translations/de';

const Scanners = ({ store }) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [scannerID, setScannerID] = useState(null);

    useEffect(() => {
        store.getScanners();
    }, [store, store.selectedPage]);

    const handleScanner = (id) => {
        store.selectScanner(id);
        history.push(`/scanners/${id}`);
    };

    return (
        <>
            <div style={{ marginLeft: '2.5%' }}>
                <Button
                    label={translations.scanner}
                    onClick={() => {
                        store.selectScanner(undefined);
                        history.push(Paths.NEW_SCANNER);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button>
            </div>
            {store.scanners && store.scanners.length !== 0 && (
                <Table
                    tablePagination={
                        store.totalPages > 1 && (
                            <Pagination
                                totalPages={store.totalPages}
                                selectedPage={store.selectedPage}
                                onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                            />
                        )
                    }
                    tableName={translations.scanner}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.device_id,
                            accessor: 'device_id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.title,
                            accessor: 'title',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.enabled,
                            accessor: 'enabled',
                            Cell: ({ value, row }) => (
                                <div>
                                    {value === true ? (
                                        <FaCheck className="confirm" />
                                    ) : (
                                        <FaTimes className="error" />
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: translations.app_version,
                            accessor: 'app_version',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleScanner(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setScannerID(row.cells[0].value);
                                            setModal(true);
                                        }}
                                        title={translations.delete}
                                    >
                                        <FaTrash />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.scanners.map(({ id, device_id, title, enabled, app_version }) => {
                        return {
                            id,
                            device_id,
                            title,
                            enabled,
                            app_version,
                        };
                    })}
                />
            )}
            {modal && (
                <Modal
                    title="Delete Scanner"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete this scanner with id ${scannerID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteScanner(scannerID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )}
            {}
        </>
    );
};

export default observer(Scanners);
