import api from './apiLibrary';

const API = {
    getPartners(params) {
        return api.get('/api/admin/business-partner', params);
    },
    getPartner(id, params) {
        return api.get(`/api/admin/business-partner/${id}`, params);
    },
    createPartner(data) {
        return api.post('/api/admin/business-partner', data);
    },
    editPartner(id, data) {
        return api.put(`/api/admin/business-partner/${id}`, data);
    },
    deletePartner(id) {
        return api.delete(`/api/admin/business-partner/${id}`);
    },
    revivePartner(id) {
        return api.put(`/api/admin/business-partner/${id}/revive`);
    },
    exportPartners() {
        return api.get(`/api/admin/business-partner/export`);
    },
};

export default API;
