import React from 'react';
import translations from '../../translations/de';
import Table from '../Table';
import { useHistory } from 'react-router-dom';
import Pagination from '../Pagination';
import { formatDate, formatTime } from '../../helpers/formatDate';

const Sales = ({ data, totalPages, selectedPage, setPage }) => {
    const history = useHistory();

    const handleUser = (id) => {
        data.forEach((sale) => {
            if (sale.id === id) {
                history.push({
                    pathname: `/fans/${sale.user.id}`,
                    state: { activeTabName: 'Rewards and Purchases' },
                });
            }
        });
    };

    return data?.length > 0 ? (
        <Table
            tableName={translations.acquirer}
            const
            tablePagination={
                totalPages > 1 && (
                    <Pagination
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        onSelectedPageChange={(selected) => setPage(selected)}
                    />
                )
            }
            isRowClickable={true}
            columns={[
                {
                    Header: 'ID',
                    accessor: 'id',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
                {
                    Header: translations.name,
                    accessor: 'name',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
                {
                    Header: translations.date_of_sale,
                    accessor: 'date_of_sale',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
                {
                    Header: translations.date_of_scan,
                    accessor: 'date_of_scan',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
                {
                    Header: translations.date_of_expire,
                    accessor: 'date_of_expire',
                    Cell: ({ row, value }) => (
                        <div onClick={() => handleUser(row.cells[0].value)}>{value}</div>
                    ),
                },
            ]}
            data={data.map(({ id, user, voucher, date_of_sale, date_of_scan }) => {
                return {
                    id,
                    name: user ? `${user.first_name} ${user.last_name}` : '',
                    date_of_sale: date_of_sale
                        ? `${formatDate(date_of_sale)} ${formatTime(date_of_sale)}`
                        : '/',
                    date_of_scan: date_of_scan
                        ? `${formatDate(date_of_scan)} ${formatTime(date_of_scan)}`
                        : '/',
                    date_of_expire: voucher?.expiry_date
                        ? `${formatDate(voucher.expiry_date)} ${formatTime(voucher.expiry_date)}`
                        : '/',
                };
            })}
        />
    ) : (
        <div className="no-data">No Data Yet...</div>
    );
};

export default Sales;
