import React from 'react';
import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import MatchStatistics from './MatchStatistics';
import Graph from './Graph';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';
import TabSwitcher from '../../components/TabSwitcher';
import translations from '../../translations/de';

const MatchStatisticsTab = ({ store }) => {
    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.MATCH_STATISTICS}>
                <TabSwitcher initialTabName={translations.graph}>
                    <TabSwitcher.Tabs>
                        <TabSwitcher.TabPill tabName={translations.graph} />
                        <TabSwitcher.TabPill tabName={translations.data} />
                    </TabSwitcher.Tabs>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.graph}>
                        <Graph store={store} />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName={translations.data}>
                        {' '}
                        <MatchStatistics store={store} />
                    </TabSwitcher.TabPanel>
                </TabSwitcher>
            </Route>
        </Page>
    );
};

export default observer(MatchStatisticsTab);
