import React, { useEffect, useState } from 'react';
import translations from '../../translations/de';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Textarea from '../Textarea';
import API from '../../api/passes';

const PassNotifications = ({ closeScreen }) => {
    const [active, setActive] = useState(false);
    const [emails, setEmails] = useState('');
    const [loader, setLoader] = useState(false);

    const saveChanges = () => {
        const recip = formatEmails(emails);
        const data = { send_email: active, recipients: recip };
        setLoader(true);
        try {
            API.setNotificationData(data);

            setLoader(false);
            closeScreen();
        } catch (e) {
            console.log(e);
            setLoader(false);
        }
    };

    const formatEmails = (data) => {
        return data
            .split(',')
            .map((email) => `${email.trim()}`)
            .filter((email) => email !== '');
    };

    const formatReverseEmails = (data) => {
        return data.join(', ');
    };

    useEffect(() => {
        const getInputData = async () => {
            setLoader(true);
            try {
                const res = await API.getNotificationData();
                setActive(res.data.send_email);
                setEmails(formatReverseEmails(res.data.recipients));
                setLoader(false);
            } catch (e) {
                console.log(e);
                setLoader(false);
            }
        };

        getInputData();
    }, []);

    return (
        <>
            {
                <div className="notification-settings">
                    <div className="notification-buttons">
                        <h3>Email - Benachrichtigungen</h3>
                        <Button onClick={closeScreen}>{translations.cancel}</Button>
                        <Button
                            label={translations.save}
                            loading={loader}
                            onClick={() => saveChanges()}
                        />
                    </div>
                    <div className="notification-form">
                        <div className="notification-status">
                            <label>Email-Benachrichtigungen</label>
                            <Checkbox
                                id={'active'}
                                text={translations.active2}
                                checked={active}
                                onChange={(e) => setActive(e.target.checked)}
                            />
                        </div>
                        <div className="notification-list">
                            <label>Emails (comma separated):</label>
                            <Textarea
                                type="text"
                                defaultValue={emails}
                                onChange={(e) => setEmails(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default PassNotifications;
